import React, { useMemo } from "react";

import { Card } from "react-bootstrap";
import { Check, X } from "react-feather";

import DataTable from "../../../../components/Datatable";

const FBAStorageRestrictionsTable = ({ listingAccounts, handleRowClick }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "listingAccount",
      },
      {
        Header: "Active",
        accessor: (listingAccount) => {
          if (listingAccount.isActive) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Standard",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(1)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Oversize",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(2)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Apparel",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(3)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Shoes",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(4)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Heavy",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(5)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Jewelery",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(6)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
      {
        Header: "Hazmat",
        accessor: (listingAccount) => {
          if (listingAccount.restrictedShipmentCategoryIds.includes(7)) {
            return (
              <X
                color={listingAccount.isActive ? "#d9534f" : "#737d85"}
                size={24}
              />
            );
          } else {
            return (
              <Check
                color={listingAccount.isActive ? "#4BBF73" : "#737d85"}
                size={24}
              />
            );
          }
        },
        Cell: (cell) => <div className="text-center">{cell.value}</div>,
      },
    ],
    []
  );

  return (
    <Card>
      <Card.Body>
        <DataTable
          columns={columns}
          data={listingAccounts}
          onRowClick={handleRowClick}
        />
      </Card.Body>
    </Card>
  );
};

export default FBAStorageRestrictionsTable;
