import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import UIButton from "../../../../components/UIButton";
import UICard from "../../../../components/UICard";
import UIInput from "../../../../components/UIInput";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const StartOverButton = () => (
  <UIButton
    variant={"danger"}
    text="Start Over"
    className={"mx-3"}
    onClick={() => (window.location = "/put-away")}
  />
);

const PutAwayForm = () => {
  const userId = JSON.parse(localStorage.getItem("user")).userID;

  const [productIdValue, setProductIdValue] = useState("");
  const [location, setLocation] = useState("");
  const [account, setAccount] = useState("");
  const [showFlagModal, setShowFlagModal] = useState(false);

  useEffect(() => {
    if (account === "NonAmazon") {
      setAccount("Ebay");
    }
  }, [account]);

  const getAccount = async (e) => {
    e.preventDefault();
    if (!productIdValue) {
      toast.error("Please enter an LPN or FNSKU");
      return;
    }
    if (!account) {
      try {
        const response = (
          await axios.get(`${HOST_URL}/put-away?productId=${productIdValue}`)
        ).data;
        console.log(response);
        if (response.account) {
          setAccount(response.account);
        } else {
          toast.error("Item not found. Please try again.");
        }
      } catch (error) {
        console.log(error.response.data);
      }
    }
  };

  async function submitPutAway() {
    try {
      const body = {
        productId: productIdValue,
        location: location,
        userId: userId,
      };
      const response = (await axios.post(`${HOST_URL}/put-away`, body)).data;
      if (response.success) {
        toast.success("Put away successful");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }

      console.log(response);
    } catch (error) {
      console.log(error.response.data);
    }
  }

  const header = (
    <h4>
      <div>Put Away</div>
    </h4>
  );
  const body = (
    <>
      {!account ? (
        <>
          <UIInput
            required
            autoFocus
            value={productIdValue}
            onChange={(e) => {
              setProductIdValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                getAccount(e);
              }
            }}
            type="text"
            placeholder="Enter an LPN or FNSKU"
            size="lg"
            className="my-3 w-75 m-auto text-center"
          />{" "}
          <UIButton
            variant="primary"
            text="Submit"
            onClick={(e) => getAccount(e)}
          />
          <StartOverButton />
        </>
      ) : (
        <>
          <h4>Account: {account}</h4>
          <UIInput
            required
            autoFocus
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                submitPutAway(e);
              }
            }}
            type="text"
            placeholder="Enter a Location"
            size="lg"
            className="my-3 w-75 m-auto text-center"
          />{" "}
          <UIButton
            variant="primary"
            text={location ? "Submit" : "Submit without location"}
            onClick={(e) => submitPutAway(e)}
          />
          <StartOverButton />
        </>
      )}
    </>
  );
  return (
    <UICard header={header} body={body} className="text-center w-75 m-auto" />
  );
};

export default PutAwayForm;
