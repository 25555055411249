import { useState } from "react";
import axios from "axios";
import ChooseDates from "../../../../components/ChooseDates";
import UICard from "../../../../components/UICard";
import EmployeeTable from "./ActivityTable";
import COLUMNS from "./employeeActivityColumns";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function EmployeeActivity() {
  const [employeeActivity, setEmployeeActivity] = useState([]);

  async function getEmployeeActivity(startDate, endDate) {
    try {
      const events = (
        await axios.get(
          `${HOST_URL}/employee?startDate=${startDate}&endDate=${endDate}`
        )
      ).data.events;
      setEmployeeActivity(events);
    } catch (error) {
      console.log(error);
    }
  }

  const header = <h4>Employee Activity</h4>;
  const body = (
    <>
      <ChooseDates goButtonAction={getEmployeeActivity} />
      <EmployeeTable data={employeeActivity} columns={COLUMNS} />
    </>
  );
  return <UICard header={header} body={body} className={"text-center"} />;
}

export default EmployeeActivity;
