import moment from "moment";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";

const DownloadCSV = ({ headers, data, fileName, disabled }) => {
  //remove any commas from the data
  const cleanData = data.map((row) => {
    const newRow = {};
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === "string") {
        newRow[key] = row[key].replace(/,/g, "");
      } else {
        newRow[key] = row[key];
      }
    });
    return newRow;
  });

  return disabled ? (
    <Button variant="primary" disabled>
      Download
    </Button>
  ) : (
    <CSVLink
      title="Download CSV"
      headers={headers}
      data={cleanData}
      filename={`${fileName}_${moment().format("YYYY-MM-DD")}.csv`}
    >
      <Button variant="primary" disabled={disabled}>
        Download
      </Button>
    </CSVLink>
  );
};
export default DownloadCSV;
