import React, { useState } from "react";

import { Alert, Row, Col, Card, Badge, Carousel, Image } from "react-bootstrap";
import { AlertOctagon } from "react-feather";

import imageNotFound from "../../../../assets/img/imageNotFound.png";
import PrintButton from "../../../../components/PrintButton";

const ProcessingItemData = ({ processingItem }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const { processingData, itemData, amazonData, catalogData } = processingItem;

  const { imageLink } = itemData;

  const asin = processingData.asin || processingData.receivedAsin;

  return (
    <Card>
      <Card.Header className="pb-0 text-center">
        <div className="d-flex justify-content-center">
          <h3>{processingData?.lpn}</h3>
          <PrintButton value={processingData?.lpn} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col md={4}>
            {processingData?.receivedId && (
              <Alert className="p-2" variant="info">
                <div className="d-flex flex-column w-100">
                  <h4>Received</h4>
                  <span className="font-weight-bold">
                    {processingData.vendorName}
                  </span>
                  <div className="d-flex justify-content-between text-muted">
                    <div>{processingData.receivedBy}</div>
                    <div>
                      {new Date(
                        processingData.receivedEndTime
                      ).toLocaleString()}
                    </div>
                  </div>
                </div>
              </Alert>
            )}
          </Col>
          <Col md={4}>
            {processingData?.inspectedId && (
              <Alert className="p-2" variant="info">
                <div className="d-flex flex-column w-100">
                  <h4>Inspected</h4>
                  <span className="font-weight-bold">
                    {processingData.inspectCondition}
                  </span>
                  <div className="d-flex justify-content-between text-muted">
                    <div>{processingData.inspectedBy}</div>
                    <div>
                      {new Date(
                        processingData.inspectedEndTime
                      ).toLocaleString()}
                    </div>
                  </div>
                </div>
              </Alert>
            )}
          </Col>
          <Col md={4}>
            {processingData?.listedId && (
              <Alert className="p-2" variant="info">
                <div className="d-flex flex-column w-100">
                  <h4>Listed</h4>
                  <Row className="font-weight-bold">
                    <Col>{processingData.listedAsin}</Col>
                    <Col className="d-flex justify-content-end">
                      {processingData.listingAccount}
                    </Col>
                  </Row>
                  <span className="font-weight-bold">
                    {processingData.vendorName}
                  </span>
                  <div className="d-flex justify-content-between text-muted">
                    <div>{processingData.listedBy}</div>
                    <div>
                      {new Date(processingData.listedEndTime).toLocaleString()}
                    </div>
                  </div>
                </div>
              </Alert>
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          {asin && (
            <Col md={3} className="mb-3">
              <Card.Title>Images</Card.Title>
              {!catalogData?.images[0]?.images ? (
                <a
                  href={`https://www.amazon.com/dp/${asin}?th=1&psc=1`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    fluid
                    thumbnail
                    src={imageNotFound}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = imageNotFound;
                    }}
                  />
                </a>
              ) : (
                <Carousel
                  variant="dark"
                  indicators={true}
                  controls={true}
                  interval={null}
                  activeIndex={carouselIndex}
                  onSelect={(selectedIndex) => setCarouselIndex(selectedIndex)}
                >
                  {catalogData.images[0].images
                    .reduce((acc, curr) => {
                      if (
                        !acc.some((image) => image.variant === curr.variant)
                      ) {
                        const largestImage = catalogData.images[0].images
                          .filter((image) => image.variant === curr.variant)
                          .reduce((acc, curr) => {
                            if (acc) {
                              if (
                                curr.height * curr.width >
                                acc.height * acc.width
                              ) {
                                acc = curr;
                              }
                            } else {
                              acc = curr;
                            }

                            return acc;
                          });

                        acc.push(largestImage);
                      }

                      return acc;
                    }, [])
                    .map((image, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <Image src={`${image.link}`} fluid />
                        </Carousel.Item>
                      );
                    })}
                </Carousel>
              )}
            </Col>
          )}

          <Col md={9}>
            <Row className="mb-3">
              <Col>
                <Card.Title>ASIN</Card.Title>
                <Card.Text>
                  {asin ? (
                    <a
                      href={`https://www.amazon.com/dp/${asin}?th=1&psc=1`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {asin}
                    </a>
                  ) : (
                    "No ASIN"
                  )}
                </Card.Text>
              </Col>
              {catalogData?.identifiers[0]?.identifiers?.length <= 3 &&
                catalogData.identifiers[0].identifiers.map(
                  (identifier, index) => {
                    return (
                      <Col key={index}>
                        <Card.Title>{identifier.identifierType}</Card.Title>
                        <Card.Text>{identifier.identifier}</Card.Text>
                      </Col>
                    );
                  }
                )}
            </Row>

            {(itemData?.itemName || amazonData?.brand) && (
              <Row className="mb-3">
                {itemData.itemName && (
                  <Col xs={8}>
                    <Card.Title>Title</Card.Title>
                    <Card.Text>{itemData.itemName}</Card.Text>
                  </Col>
                )}
                {amazonData?.brand && (
                  <Col>
                    <Card.Title>Brand</Card.Title>
                    <Card.Text>{amazonData.brand}</Card.Text>
                  </Col>
                )}
              </Row>
            )}
            {catalogData?.summaries?.length > 0 && (
              <Row>
                {catalogData.summaries[0].size && (
                  <Col xs={4} className="mb-3">
                    <Card.Title>Size</Card.Title>
                    <Card.Text>{catalogData.summaries[0].size}</Card.Text>
                  </Col>
                )}
                {catalogData.summaries[0].color && (
                  <Col xs={4} className="mb-3">
                    <Card.Title>Color</Card.Title>
                    <Card.Text>{catalogData.summaries[0].color}</Card.Text>
                  </Col>
                )}
                {catalogData.summaries[0].style && (
                  <Col xs={4} className="mb-3">
                    <Card.Title>Style</Card.Title>
                    <Card.Text>{catalogData.summaries[0].style}</Card.Text>
                  </Col>
                )}
                {catalogData.summaries[0].modelNumber && (
                  <Col xs={4} className="mb-3">
                    <Card.Title>Model Number</Card.Title>
                    <Card.Text>
                      {catalogData.summaries[0].modelNumber}
                    </Card.Text>
                  </Col>
                )}
                {catalogData.summaries[0].partNumber && (
                  <Col xs={4} className="mb-3">
                    <Card.Title>Part Number</Card.Title>
                    <Card.Text>{catalogData.summaries[0].partNumber}</Card.Text>
                  </Col>
                )}
                {catalogData.summaries[0].manufacturer && (
                  <Col xs={4} className="mb-3">
                    <Card.Title>Manufacturer</Card.Title>
                    <Card.Text>
                      {catalogData.summaries[0].manufacturer}
                    </Card.Text>
                  </Col>
                )}
              </Row>
            )}
            {(itemData.returnReason || itemData.customerComments) && (
              <Row className="mb-3">
                <Col>
                  {itemData.returnReason && (
                    <div className="">
                      <Card.Title>Return Reason</Card.Title>
                      <Card.Text>{itemData.returnReason}</Card.Text>
                    </div>
                  )}
                </Col>
                <Col>
                  {itemData.customerComments && (
                    <div className="">
                      <Card.Title>Customer Comments</Card.Title>
                      <Card.Text>{itemData.customerComments}</Card.Text>
                    </div>
                  )}
                </Col>
              </Row>
            )}
            {itemData.price && (
              <div className="d-flex mb-3">
                <div className="me-3">
                  <Card.Title>Price</Card.Title>
                  <Card.Text>{itemData.price}</Card.Text>
                </div>
                {!itemData.valuableSale && (
                  <Alert variant="danger" className="flex-fill alert-blink">
                    <div className="alert-icon">
                      <AlertOctagon
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message">
                      The price is too low for this item. Please select one of
                      the <strong>LIQUIDATE</strong> conditions.
                    </div>
                  </Alert>
                )}
              </div>
            )}
            {(amazonData?.classificationRanks?.length > 0 ||
              amazonData?.displayGroupRanks?.length > 0 ||
              amazonData?.packageDimensions) && (
              <Row className="mb-3">
                {(amazonData?.classificationRanks?.length > 0 ||
                  amazonData?.displayGroupRanks?.length > 0) && (
                  <Col>
                    <Card.Title>Rank</Card.Title>
                    {amazonData.classificationRanks?.length > 0 &&
                      amazonData.classificationRanks.map(
                        (classification, i) => {
                          return (
                            <Card.Text key={i} className="mb-0">
                              <Badge bg="info">#{classification.rank}</Badge> in{" "}
                              {classification.title}
                            </Card.Text>
                          );
                        }
                      )}
                    {amazonData?.displayGroupRanks?.length > 0 &&
                      amazonData?.displayGroupRanks.map((group, i) => {
                        return (
                          <Card.Text key={i} className="m-0">
                            <Badge bg="info">#{group.rank}</Badge> in{" "}
                            {group.title}
                          </Card.Text>
                        );
                      })}
                  </Col>
                )}
                {(amazonData?.packageDimensions?.length ||
                  amazonData?.packageDimensions?.width ||
                  amazonData?.packageDimensions?.height ||
                  amazonData?.packageDimensions?.weight) && (
                  <Col>
                    <Card.Title>Package Dimensions</Card.Title>
                    {amazonData?.packageDimensions?.length && (
                      <Card.Text className="mb-0">
                        Length:{" "}
                        {Math.round(
                          (amazonData?.packageDimensions?.length?.value +
                            Number.EPSILON) *
                            100
                        ) / 100}{" "}
                        {amazonData?.packageDimensions?.length?.unit}
                      </Card.Text>
                    )}
                    {amazonData?.packageDimensions?.width && (
                      <Card.Text className="mb-0">
                        Width:{" "}
                        {Math.round(
                          (amazonData?.packageDimensions?.width?.value +
                            Number.EPSILON) *
                            100
                        ) / 100}{" "}
                        {amazonData?.packageDimensions?.width?.unit}
                      </Card.Text>
                    )}
                    {amazonData?.packageDimensions?.height && (
                      <Card.Text className="mb-0">
                        Height:{" "}
                        {Math.round(
                          (amazonData?.packageDimensions?.height?.value +
                            Number.EPSILON) *
                            100
                        ) / 100}{" "}
                        {amazonData?.packageDimensions?.height?.unit}
                      </Card.Text>
                    )}
                    {amazonData?.packageDimensions?.weight && (
                      <Card.Text className="mb-0">
                        Weight:{" "}
                        {Math.round(
                          (amazonData?.packageDimensions?.weight?.value +
                            Number.EPSILON) *
                            100
                        ) / 100}{" "}
                        {amazonData?.packageDimensions?.weight?.unit}
                      </Card.Text>
                    )}
                  </Col>
                )}
              </Row>
            )}
            <Row className="mb-3">
              {processingData.receivedItemNote && (
                <Col>
                  <Card.Title>Received Note</Card.Title>
                  <Card.Text>{processingData.receivedItemNote}</Card.Text>
                </Col>
              )}
              <Col>
                <Card.Title>Received Quantity</Card.Title>
                <Card.Text>{processingData.receivedQuantity || 1}</Card.Text>
              </Col>
            </Row>
          </Col>
        </Row>

        {amazonData.prepInstructions?.length > 0 && (
          <div className="mb-3">
            <Card.Title>Amazon Prep Instructions</Card.Title>
            <Card.Text>Coming Soon...</Card.Text>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProcessingItemData;
