import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { Button, Card } from "react-bootstrap";

import { Trash2 } from "react-feather";

import Datatable from "../../../../components/Datatable";
import ModalGeneric from "../../../../components/ModalGeneric";

import FlagForm from "./FlagForm";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const FlagTable = ({ flagType, title, tableColumns, initialFormValues }) => {
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    getFlags().then((response) => {
      setFlags(response);
    });
  }, []);

  const getFlags = async () => {
    try {
      const flagsResponse = (
        await axios.get(`${HOST_URL}/management/flags?flagType=${flagType}`)
      ).data.flagsResponse;

      return flagsResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const addFlag = async (flag) => {
    try {
      await axios.post(
        `${HOST_URL}/management/flags?flagType=${flagType}`,
        flag
      );
      toast.success(`Added Flag.`);
      setFlags(await getFlags());
    } catch (error) {
      toast.error("Error Adding Flag.");
    }
  };

  const editFlag = async (flag) => {
    try {
      await axios.post(
        `${HOST_URL}/management/flags?flagType=${flagType}`,
        flag
      );
      toast.success(`Flag Edited.`);
      setFlags(await getFlags());
    } catch (error) {
      toast.error("Error Editing Flag.");
    }
  };

  const deleteFlag = async (flag) => {
    try {
      await axios.delete(
        `${HOST_URL}/management/flags/${flag.flagID}?flagType=${flagType}`
      );
      toast.success(`Flag Deleted.`);
      setFlags(await getFlags());
    } catch (error) {
      toast.error("Error Deleting Flag.");
    }
  };

  //Add the action column to the tableColumns array.
  const foundColumn = tableColumns.find((tc) => tc.Header === "Actions");
  if (foundColumn === undefined) {
    tableColumns.push({
      Header: "Actions",
      disableSortBy: true,
      accessor: (flag) => {
        return (
          <div>
            <FlagForm
              flag={flag}
              editFlag={editFlag}
              initialFormValues={initialFormValues}
            />
            <ModalGeneric
              trigger={
                <Button variant="danger">
                  <Trash2 size={18} />
                </Button>
              }
              title="Delete Flag"
              body={`Are you sure you would like to delete this flag?`}
              actionButtonText={"Delete"}
              actionButtonCallback={(flag) => deleteFlag(flag)}
              actionButtonCallbackParams={{ flag }}
            />
          </div>
        );
      },
    });
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className="d-flex justify-content-end ">
          <FlagForm addFlag={addFlag} initialFormValues={initialFormValues} />
        </div>
        <Datatable columns={tableColumns} data={flags} />
      </Card.Body>
    </Card>
  );
};

export default FlagTable;
