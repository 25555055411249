import React from "react";
import { Card } from "react-bootstrap";

import FBAStorageRestrictionsForm from "./FBAStorageRestrictionsForm";

const FBAStorageRestrictionsCard = ({
  selectedAccount,
  setListingAccounts,
  setShowCard,
}) => {
  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      {selectedAccount && (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center">
              Update FBA Storage Restrictions:
            </Card.Title>
            <Card.Subtitle className="text-center">
              {selectedAccount.listingAccount}
            </Card.Subtitle>
          </Card.Header>
          <Card.Body>
            <FBAStorageRestrictionsForm
              selectedAccount={selectedAccount}
              setListingAccounts={setListingAccounts}
              setShowCard={setShowCard}
            />
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default FBAStorageRestrictionsCard;
