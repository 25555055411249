import { useContext } from "react";
import { Button } from "react-bootstrap";

import { InspectContext } from "../../Inspect";

function InspectOption({ option, submitInspection, variant }) {
  const {
    currentItem,
    previousInspection,
    isLoading,
    setShowClaimModal,
    setShouldSubmitInspection,
    setInspectConditionId,
  } = useContext(InspectContext);

  const { processingData } = currentItem;

  const { label, inspectConditionId } = option;

  const previousInspectionMatch =
    previousInspection.inspectedId &&
    previousInspection.inspectConditionId === inspectConditionId;

  const handleSubmit = () => {
    if (!processingData.claim) {
      switch (inspectConditionId) {
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          if (processingData.isSignedUpForClaims) {
            setShowClaimModal(true);
          } else {
            submitInspection({
              inspectConditionId,
            });
          }
          break;
        default:
          submitInspection({
            inspectConditionId,
          });
      }
    } else {
      if (processingData.isSignedUpForClaims) {
        setShowClaimModal(true);
      } else {
        submitInspection({
          inspectConditionId,
        });
      }
    }
  };

  return (
    <Button
      variant={previousInspectionMatch ? `${variant}` : `outline-${variant}`}
      size="lg"
      className="p-2 my-1"
      onClick={() => {
        setInspectConditionId(inspectConditionId);
        setShouldSubmitInspection(true);
        handleSubmit();
      }}
      disabled={isLoading}
    >
      {label}
    </Button>
  );
}

export default InspectOption;
