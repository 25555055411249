import React from "react";

import { Button, Card } from "react-bootstrap";

import DataTable from "../../../../components/Datatable";
import ModalGeneric from "../../../../components/ModalGeneric";

const RestrictionsTable = ({ restrictions, deleteRestriction }) => {
  const tableColumns = [
    {
      Header: "ASIN",
      accessor: "asin",
    },
    {
      Header: "Condition",
      accessor: "condition",
    },
    {
      Header: "Amazon Account",
      accessor: "listingAccount",
    },
    {
      Header: "Date Added",
      accessor: "DateAdded",
      Cell: ({ value }) => {
        if (value) {
          return <span>{new Date(value).toLocaleDateString()}</span>;
        } else {
          return null;
        }
      },
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: (restriction) => {
        return (
          <div className="d-flex justify-content-between">
            <Button
              variant="primary"
              href={restriction.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Request Approval
            </Button>
            <ModalGeneric
              trigger={<Button variant="danger">Delete</Button>}
              title="Delete Restriction"
              type="submit"
              body={"Are you sure you would like to delete this restriction?"}
              actionButtonText={"Delete"}
              actionButtonCallback={(restriction) =>
                deleteRestriction(restriction)
              }
              actionButtonCallbackParams={{ restriction }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Card>
      <Card.Body>
        <DataTable columns={tableColumns} data={restrictions} />
      </Card.Body>
    </Card>
  );
};

export default RestrictionsTable;
