import React, { useMemo, useState } from "react";

import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table as BTable,
  Button,
  Col,
  Form,
  InputGroup,
  Pagination,
  Row,
} from "react-bootstrap";
import { Filter, X } from "react-feather";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import GlobalFilter from "./GlobalFilter";

const Table = ({
  columns,
  data,
  onRowClick,
  size,
  initialPageSize = 10,
  initialFilterValues,
}) => {
  const [showFilters, setShowFilters] = useState(
    initialFilterValues ? true : false
  );

  const DefaultColumnFilter = ({ column }) => {
    const { filterValue, setFilter, Header: header } = column;

    return (
      <InputGroup className="mb-3">
        <Form.Control
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder={`Search ${header}...`}
        />
        {filterValue && (
          <Button
            variant={filterValue ? "danger" : "outline-secondary"}
            disabled={!filterValue}
            onClick={() => {
              setFilter(undefined);
            }}
          >
            <X size={16} />
          </Button>
        )}
      </InputGroup>
    );
  };

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    state: { pageIndex, globalFilter, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize || 20,
        filters: initialFilterValues ? initialFilterValues.filters : [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Boolean for filter customization
  const hasFilters = filters.length > 0;

  return (
    data.length > 0 && (
      <>
        <Row className="mb-3">
          <Col sm={11} className="d-flex align-items-center">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              data={data}
            />
            <Button
              className="ms-3"
              variant={hasFilters ? "outline-warning" : "outline-secondary"}
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              <Filter size={16} /> Filters
            </Button>
            {hasFilters && (
              <Button
                variant="danger"
                className="ms-3"
                onClick={() => setAllFilters([])}
              >
                <Filter size={16} /> Clear All
              </Button>
            )}
          </Col>
        </Row>
        <BTable striped hover size={size} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.canSort &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faSortDown}
                                className="ms-2"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faSortUp}
                                className="ms-2"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                          ))}
                      </span>
                    </div>
                    <div>
                      {column.canFilter && showFilters
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={onRowClick ? () => onRowClick(row) : null}
                  className={onRowClick ? "cursor-pointer" : ""}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
        {data.length > 10 && (
          <Row className="mt-4">
            <Col md={9}>
              <span className="mx-2">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>
              </span>
              <span className="ms-3 me-2">Show:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">Go to page:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                min={1}
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const page =
                    !e.target.value || Number(e.target.value) <= 0
                      ? 1
                      : Number(e.target.value) > pageCount
                      ? pageCount
                      : Number(e.target.value);
                  gotoPage(page - 1);
                }}
                style={{ width: "75px" }}
                autoComplete="off"
              />
            </Col>
            <Col md={3}>
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Item
                  onClick={() => gotoPage(0)}
                  active={1 === pageIndex + 1}
                >
                  {1}
                </Pagination.Item>
                {pageOptions.map((page, index) => {
                  if (
                    (page + 1 === pageIndex + 1 - 3 && page + 1 !== 1) ||
                    (page + 1 === pageIndex + 1 + 3 && page + 1 !== pageCount)
                  ) {
                    return <Pagination.Ellipsis key={index} disabled />;
                  }
                  if (
                    page + 1 >= pageIndex + 1 - 2 &&
                    page + 1 <= pageIndex + 1 + 2 &&
                    page + 1 !== 1 &&
                    page + 1 !== pageCount
                  ) {
                    return (
                      <Pagination.Item
                        key={index}
                        onClick={() => gotoPage(page)}
                        active={page === pageIndex}
                      >
                        {page + 1}
                      </Pagination.Item>
                    );
                  }
                  return null;
                })}
                {pageCount !== 1 && (
                  <Pagination.Item
                    onClick={() => gotoPage(pageCount - 1)}
                    active={pageCount === pageIndex + 1}
                  >
                    {pageCount}
                  </Pagination.Item>
                )}
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </>
    )
  );
};

export default Table;
