import React from "react";
import { Helmet } from "react-helmet-async";

import { Container } from "react-bootstrap";

import Header from "./Header";

function UIDefaultPage({ title = "RWW-APP", children }) {
  return (
    <React.Fragment>
      <Helmet title={title} />
      <Container fluid>
        <Header headerTitle={title} />
        {children}
      </Container>
    </React.Fragment>
  );
}

export default UIDefaultPage;
