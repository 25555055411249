import { faDollyFlatbed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { AlertCircle } from "react-feather";
import toast from "react-hot-toast";

import shipmentService from "../../../../services/shipmentService";

const ItemDesignation = ({
  submitting,
  setSubmitting,
  fnsku,
  setFnsku,
  designatedBay,
  setDesignatedBay,
  quantity,
  fnskuInputElementRef,
  rackIdInputElementRef,
  setQuantity,
}) => {
  const [shipmentRackId, setShipmentRackId] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async () => {
    if (!shipmentRackId) {
      toast.error("Please enter a Rack ID.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (shipmentRackId.toUpperCase() === "W") {
      toast.error("Please re-scan the Rack ID or enter it manually.");
      setShipmentRackId("");
      return;
    }

    if (Number(shipmentRackId) !== designatedBay.shipmentRackId) {
      const message = (
        <span>
          Rack ID: <strong>{shipmentRackId}</strong> does not match the
          designated consolidation bay's Rack ID.
        </span>
      );
      setErrorMessage(message);
      if (rackIdInputElementRef.current) {
        rackIdInputElementRef.current.focus();
        rackIdInputElementRef.current.select();
      }
      return;
    }

    setSubmitting(true);
    setErrorMessage();

    const result = await shipmentService.addItemToRack({
      fnsku,
      shipmentRackId,
      quantity: Number(quantity),
    });

    if (result.success) {
      toast.success(`Item successfully added to rack ${shipmentRackId}!`, {
        duration: 5000,
      });
      setSubmitting(false);
      setDesignatedBay();
      setFnsku("");
      setQuantity(1);
      if (fnskuInputElementRef.current) {
        fnskuInputElementRef.current.focus();
      }
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setSubmitting(false);
    }
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start">
        <FontAwesomeIcon icon={faDollyFlatbed} size="3x" className="me-3" />
        <div>
          <Card.Title>Item Designation</Card.Title>
          <Card.Subtitle>
            Place the item on the rack located in the consolidation bay
            displayed below. Then scan or enter the corresponding Rack ID.
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        {designatedBay && (
          <Alert
            variant="success"
            className="alert-blink border border-success"
          >
            <div className="alert-message text-center">
              <strong style={{ fontSize: "46px" }}>
                Bay {designatedBay.consolidationBayId}
              </strong>
              <h3>{designatedBay.consolidationBayName}</h3>
            </div>
          </Alert>
        )}
        {errorMessage && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertCircle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">{errorMessage}</div>
          </Alert>
        )}
        <Form.Label className="mb-0">Rack ID</Form.Label>
        <InputGroup>
          <Form.Control
            autoFocus
            size="lg"
            ref={rackIdInputElementRef}
            placeholder="Scan or Enter Rack ID..."
            value={shipmentRackId}
            onChange={(e) => setShipmentRackId(e.target.value)}
            onFocus={(e) => {
              e.target.select();
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            disabled={submitting}
          />
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <span>
                <Spinner
                  className="me-2"
                  animation="border"
                  size="sm"
                  role="status"
                />
                Submitting...
              </span>
            ) : (
              "Submit"
            )}
          </Button>
        </InputGroup>
      </Card.Body>
    </Card>
  );
};

export default ItemDesignation;
