import React from "react";

import useAuth from "../../hooks/useAuth";

import { Dropdown } from "react-bootstrap";

import { PieChart, Settings, User } from "react-feather";

import avatar from "../../assets/img/avatars/avatar-1.jpg";

const NavbarUser = () => {
  const { signOut } = useAuth();

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="text-dark">{user?.name}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item onClick={() => (window.location = "/settings")}>
          Settings
        </Dropdown.Item>
        <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
