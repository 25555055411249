import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import CartonForm from "./components/fbaLabel/CartonForm";
import DisplayImage from "./components/fbaLabel/DisplayImage";

import printService from "../../services/printService";

const FBALabelPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const [shipmentCartonId, setShipmentCartonId] = useState("");
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const shipmentId = queryParams.get("shipmentId");
    let start = queryParams.get("start") || 1;
    let quantity = queryParams.get("quantity") || 1;

    const generateFbaShipmentLabels = async () => {
      await printService.generateFbaShipmentLabels({
        shipmentId,
        start,
        quantity,
      });
    };

    if (queryParams.size > 0 && shipmentId !== null) {
      generateFbaShipmentLabels();
    }
  }, []);

  return (
    <>
      <Helmet title="Print FBA Labels" />
      <h1 className="mb-3">Print FBA Labels</h1>
      <Container fluid className="p-0">
        <Row>
          <Col xl={{ span: 4, offset: 4 }}>
            <CartonForm
              submitting={submitting}
              setSubmitting={setSubmitting}
              shipmentCartonId={shipmentCartonId}
              setShipmentCartonId={setShipmentCartonId}
              setImageSrc={setImageSrc}
            />
          </Col>
        </Row>
        {imageSrc && !submitting && (
          <Row>
            <Col xl={{ span: 4, offset: 4 }}>
              <DisplayImage
                shipmentCartonId={shipmentCartonId}
                imageSrc={imageSrc}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default FBALabelPage;
