import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Collapse,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { AlertOctagon } from "react-feather";
import toast from "react-hot-toast";

import Capture from "./Capture";
import Gallery from "./Gallery";
import Scan from "./Scan";

import receivingService from "../../../../services/receiving.service";

import { ReceivingContext } from "../../ReceivingPage";

const DocumentsModal = ({ showDocumentModal, setShowDocumentModal }) => {
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const [images, setImages] = useState([]);

  // Method of obtaining the document
  // 1 = Scan, 2 = Capture
  const [method, setMethod] = useState(1);

  const { documents, setDocuments, shipment } = useContext(ReceivingContext);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setImages(documents);
    }
  }, []);

  const handleSubmitDocuments = async () => {
    setSubmitting(true);

    const response = await receivingService.updateDocuments({
      shipmentId: shipment.shipmentId,
      images,
    });

    if (response.success) {
      toast.success("Documents successfully updated.");
      setDocuments(images);
      setSubmitting(false);
      setShowDocumentModal(false);
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
      setSubmitting(false);
    }
  };

  return (
    <Modal
      show={showDocumentModal}
      size="lg"
      onHide={() => setShowDocumentModal(false)}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faFileImage} size="3x" className="me-3" />
          <div>
            <Card.Title>Documents</Card.Title>
            <Card.Subtitle>
              Scan, capture, or remove documents for this shipment.
            </Card.Subtitle>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ButtonGroup className="d-flex justify-content-center mb-3">
          <Button
            variant="outline-primary"
            onClick={() => {
              setMethod(1);
            }}
            className={method === 1 ? "active" : ""}
            disabled={submitting}
          >
            Scan Document
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              setMethod(2);
            }}
            className={method === 2 ? "active" : ""}
            disabled={submitting}
          >
            Capture Image
          </Button>
        </ButtonGroup>
        {error && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertOctagon
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              {error.message}
            </div>
          </Alert>
        )}
        <Collapse in={method === 1}>
          <div>
            <Scan setImages={setImages} disabled={submitting} />
          </div>
        </Collapse>
        <Collapse in={method === 2}>
          <div>
            <Capture setImages={setImages} disabled={submitting} />
          </div>
        </Collapse>
        {images && (
          <>
            {images.length > 0 && (
              <Gallery
                images={images}
                setImages={setImages}
                disabled={submitting}
              />
            )}
            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    variant="success"
                    onClick={handleSubmitDocuments}
                    disabled={submitting}
                  >
                    {submitting && (
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {submitting ? "Saving Changes..." : "Save Changes"}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    variant="danger"
                    onClick={() => {
                      setShowDocumentModal(false);
                      setImages(documents);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DocumentsModal;
