import React, { useEffect, useState } from "react";

import { Button, Card, Container, Modal } from "react-bootstrap";
import { Users } from "react-feather";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Table from "../../components/tables/Table";
import UserForm from "./components/users/UserForm";

import userService from "../../services/management/user.service";

import tableColumns from "./components/users/tableColumns";

// Exporting setting modal state to the tableColumns module.
export let handleModal = () => {};

const UsersPage = () => {
  // Modal/Form Types:
  // 0 = Create User
  // 1 = Edit User
  // 2 = Delete User
  const [modalState, setModalState] = useState({
    show: false,
    type: 0,
    data: undefined,
  });
  const [users, setUsers] = useState();

  handleModal = (modalState) => {
    setModalState(modalState);
  };

  // Get all the users from the BE
  const getUsers = async () => {
    const result = await userService.getUsers();

    if (result.success) {
      setUsers(result.users);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  // CRUD Operations
  const createUser = async (user) => {
    const result = await userService.createUser(user);

    if (result.success) {
      toast.success(`Added ${user.realName}`);
      setModalState((prevState) => ({
        ...prevState,
        show: false,
        data: undefined,
      }));
      setTimeout(() => {
        getUsers();
      }, 3000);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const updateUser = async (user) => {
    const result = await userService.updateUser(user);

    if (result.success) {
      toast.success(`Edited ${user.realName}`);
      setModalState((prevState) => ({
        ...prevState,
        show: false,
        data: undefined,
      }));
      setTimeout(() => {
        getUsers();
      }, 3000);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const deleteUser = async (userId) => {
    const result = await userService.deleteUser(userId);

    if (result.success) {
      toast.success(`Deleted ${modalState.data.realName}`);
      setModalState((prevState) => ({
        ...prevState,
        show: false,
        data: undefined,
      }));
      setTimeout(() => {
        getUsers();
      }, 3000);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Users" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Users</h1>
        <Modal
          show={modalState.show}
          onHide={() =>
            setModalState((prevState) => ({
              ...prevState,
              show: false,
              data: undefined,
            }))
          }
          backdrop="static"
          size="sm"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState.type === 0
                ? "Create User"
                : modalState.type === 1
                ? "Edit User"
                : "Delete User"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalState.type === 2 ? (
              <>
                <span>
                  Are you sure you want to delete{" "}
                  <strong>{modalState.data?.realName}</strong>? This action
                  cannot be undone.
                </span>
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    variant="danger"
                    onClick={() => {
                      deleteUser(modalState.data.userId);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setModalState((prevState) => ({
                        ...prevState,
                        show: false,
                        data: undefined,
                      }))
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <UserForm
                modalState={modalState}
                setModalState={setModalState}
                createUser={createUser}
                updateUser={updateUser}
              />
            )}
          </Modal.Body>
        </Modal>

        {users && (
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="d-flex">
                <Users className="me-3" size={40} />
                <div>
                  <Card.Title>Users</Card.Title>
                  <Card.Subtitle>
                    Create, Search, Edit, and Delete users.
                  </Card.Subtitle>
                </div>
              </div>
              <span>
                <Button
                  variant="primary"
                  onClick={() =>
                    setModalState({ show: true, type: 0, data: undefined })
                  }
                >
                  Create User
                </Button>
              </span>
            </Card.Header>
            <Card.Body className="pb-0">
              <Table columns={tableColumns} data={users} />
            </Card.Body>
          </Card>
        )}
      </Container>
    </React.Fragment>
  );
};

export default UsersPage;
