import axios from "axios";
import { handleError } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const createVendor = async (vendor) => {
  try {
    return (await axios.post(`${HOST_URL}/management/vendor`, vendor)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const getVendors = async () => {
  try {
    return (await axios.get(`${HOST_URL}/management/vendor`)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const updateVendor = async (vendor) => {
  try {
    return (
      await axios.post(
        `${HOST_URL}/management/vendor/${vendor.vendorId}`,
        vendor
      )
    ).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const deleteVendor = async (vendorId) => {
  try {
    return (await axios.delete(`${HOST_URL}/management/vendor/${vendorId}`))
      .data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const services = {
  createVendor,
  getVendors,
  updateVendor,
  deleteVendor,
};

export default services;
