import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import ClaimForm from "./ClaimForm";

import { InspectContext } from "../../Inspect";

const ClaimModal = ({
  claim,
  showClaimModal,
  setShowClaimModal,
  submitClaim,
  shouldSubmitInspection,
  inspectConditionId,
  submitInspection,
}) => {
  const [claimImages, setClaimImages] = useState([]);
  const [claimReasonId, setClaimReasonId] = useState();
  const [claimSubReasonId, setClaimSubReasonId] = useState();
  const [claimReasonNote, setClaimReasonNote] = useState();

  const { currentItem, userId, isLoading, makeClaim, setMakeClaim } =
    useContext(InspectContext);

  useEffect(() => {
    if (claim) {
      setClaimImages(currentItem.processingData.claim.claimImages);
      setClaimReasonId(currentItem.processingData.claim.claimReasonId);
      setClaimSubReasonId(currentItem.processingData.claim.claimSubReasonId);
      setClaimReasonNote(currentItem.processingData.claim.claimReasonNote);
    }
  }, []);

  return (
    <Modal
      show={showClaimModal}
      onHide={() => {
        setShowClaimModal(false);
        setMakeClaim(false);
      }}
      backdrop="static"
      keyboard={false}
      size={makeClaim ? "lg" : "sm"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Make A Damage Report</Modal.Title>
      </Modal.Header>
      {makeClaim ? (
        <>
          <Modal.Body>
            <ClaimForm
              claim={claim}
              images={claimImages}
              setImages={setClaimImages}
              setClaimReasonId={setClaimReasonId}
              setClaimSubReasonId={setClaimSubReasonId}
              claimReasonNote={claimReasonNote}
              setClaimReasonNote={setClaimReasonNote}
              isLoading={isLoading}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                submitClaim({
                  claimReasonId,
                  claimSubReasonId,
                  claimReasonNote,
                  lpn: currentItem.processingData.lpn,
                  userId,
                  claimImages,
                });
              }}
              disabled={
                claimImages.length <= 0 ||
                !claimReasonId ||
                !claimSubReasonId ||
                isLoading ||
                (claimSubReasonId === 17 && !claimReasonNote) // 17 is the "Other" option.
              }
            >
              {claim ? "Save Changes" : "Submit"}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowClaimModal(false);
                setMakeClaim(false);
                if (claim) {
                  setClaimImages(currentItem.processingData.claim.claimImages);
                  setClaimReasonId(
                    currentItem.processingData.claim.claimReasonId
                  );
                  setClaimSubReasonId(
                    currentItem.processingData.claim.claimSubReasonId
                  );
                  setClaimReasonNote(
                    currentItem.processingData.claim.claimReasonNote
                  );
                } else {
                  setClaimImages([]);
                  setClaimReasonId();
                  setClaimSubReasonId();
                  setClaimReasonNote();
                }
              }}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body className="text-center">
            {`Would you like to ${
              claim ? "edit the existing" : "create a"
            } damage report?`}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="success" onClick={() => setMakeClaim(true)}>
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowClaimModal(false);
                setMakeClaim(false);

                if (shouldSubmitInspection) {
                  submitInspection({
                    inspectConditionId,
                  });
                }
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ClaimModal;
