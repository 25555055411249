import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Copy } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

function OtpItem({ otpData }) {
  const { accountLogin, companyName, otp } = otpData;

  const handleCopy = () => {
    toast.success(`${otp} copied to clipboard`);
  };

  return (
    <Card className="m-2 p-3">
      <Row className="justify-content-between">
        <Col>
          <div>
            <h4>{companyName}</h4>
            <span>{accountLogin}</span>
          </div>
        </Col>
        <Col>
          <div className="d-flex align-items-center justify-content-end">
            <h4>{otp}</h4>
            <CopyToClipboard text={otp} onCopy={handleCopy}>
              <Button
                className="mx-2 px-1 py-0 btn-info"
                title="Copy to clipboard"
              >
                <Copy style={{ width: "16px", height: "16px" }} />
              </Button>
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default OtpItem;
