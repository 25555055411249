import { Formik } from "formik";
import { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";

import printService from "../../../../services/printService";
import receivingService from "../../../../services/receiving.service";

import { ReceivingContext } from "../../ReceivingPage";

const DISPOSITIONS = [
  { dispositionID: 1, disposition: "Received" },
  { dispositionID: 2, disposition: "Accepted" },
  { dispositionID: 3, disposition: "Trash" },
  { dispositionID: 4, disposition: "Liquidated" },
];

const DISPOSED_REASONS = [
  { disposedDispositionID: 1, disposition: "No Value" },
  { disposedDispositionID: 2, disposition: "Damaged" },
  { disposedDispositionID: 3, disposition: "Expired" },
  { disposedDispositionID: 4, disposition: "Can't Sell" },
];

const ItemForm = ({ formikRef, setError, submitting, setSubmitting }) => {
  const {
    currentItem,
    editing,
    items,
    setCurrentItem,
    setItems,
    setShowItemForm,
    shipment,
    vendor,
  } = useContext(ReceivingContext);

  const {
    itemId,
    lpn,
    asin,
    fnsku,
    upc,
    quantity,
    dispositionId,
    disposedReasonId,
    note,
  } = currentItem;

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        lpn: lpn || "",
        upc: upc || "",
        asin: asin || "",
        fnsku: fnsku || "",
        quantity: quantity || 1,
        dispositionId: dispositionId || 2,
        disposedReasonId: disposedReasonId || 0,
        note: note || "",
      }}
      validationSchema={Yup.object().shape({
        lpn: Yup.string().required("LPN is required."),
        asin: Yup.string()
          .min(10, "ASIN must be 10 characters long.")
          .max(10, "ASIN must be 10 characters long."),
      })}
      onSubmit={async (values) => {
        setSubmitting(true);

        // Check for asin rules -----------------------------------------------
        let asinRule = currentItem.asinRule;

        // If the input asin is different that the currentItem asin,
        // if so, check for asinRules on the new asin.
        if (
          asin &&
          values.asin &&
          values.asin.toString().toUpperCase().trim() !==
            asin.toString().toUpperCase().trim()
        ) {
          const response = await receivingService.checkAsinRule({
            asin: values.asin,
          });

          if (response.success && response.asinRule.length > 0) {
            asinRule = response.asinRule[0];
          }
        }

        if (
          asinRule &&
          values.asin.toString().toUpperCase().trim() ===
            asinRule.asin.toString().toUpperCase().trim()
        ) {
          if (
            asinRule.vendorRestrictedTo &&
            asinRule.vendorRestrictedTo !== vendor.vendorId
          ) {
            // This asin is restricted to a different vendor.
            setError({
              message: `An item with ASIN ${values.asin} cannot be added to a ${vendor.vendorName} shipment.`,
            });

            setSubmitting(false);
            return;
          }

          if (asinRule.restrictProcessing) {
            // This asin is not allowed to be processed.
            setError({
              message: `An item with ASIN ${values.asin} is not allowed to be processed.`,
            });

            setSubmitting(false);
            return;
          }

          if (
            asinRule.restrictLiquidation &&
            Number(values.dispositionId) === 4
          ) {
            // This asin is not allowed to be liquidated.
            setError({
              message: `An item with ASIN ${values.asin} cannot be liquidated.`,
            });

            setSubmitting(false);
            return;
          }
        }
        // End check for asin rules -----------------------------------------------

        // Update the asin lookup table if asin is available.
        if (values.asin && (values.fnsku || values.upc)) {
          await receivingService.updateAsinLookup({
            asin: values.asin,
            fnsku: values.fnsku,
            upc: values.upc,
          });
        }

        // Combine the data together.
        const data = {
          ...values,
          title: currentItem.title,
          returnReason: currentItem.returnReason,
          customerComments: currentItem.customerComments,
          itemId,
          shipmentId: shipment.shipmentId,
          vendorId: vendor.vendorId,
        };

        // Check if editing or adding an item.
        if (editing) {
          const response = await receivingService.updateReceivedItem(data);

          if (response.success) {
            setItems((prevState) =>
              prevState.map((item) =>
                item.itemId === itemId ? response.updatedItem : item
              )
            );
            setSubmitting(false);
            setShowItemForm(false);
            setCurrentItem();
          } else {
            toast.error("Something went wrong, please try again.");
            setError(response.error);
            setSubmitting(false);
          }
        } else {
          const response = await receivingService.addReceivedItem(data);

          if (response.success) {
            // If there are items already, add to the array, else initialize array with new item.
            if (items) {
              setItems((prevState) => prevState.concat(response.newItem));
            } else {
              setItems([response.newItem]);
            }

            // Print the rpn label if the item isn't being trashed or liquidated.
            if (
              values.lpn.startsWith("RPN") &&
              (Number(values.dispositionId) === 1 ||
                Number(values.dispositionId) === 2)
            ) {
              printService.printBarcodeLabel(values.lpn);
            }

            setSubmitting(false);
            setCurrentItem();
          } else {
            toast.error("Something went wrong, please try again.");
            setError(response.error);
            setSubmitting(false);
          }
        }
      }}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values,
      }) => (
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">LPN</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="lpn"
                  value={values.lpn}
                  isInvalid={Boolean(touched.lpn && errors.lpn)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={submitting}
                  required
                />
                {!!touched.lpn && (
                  <Form.Control.Feedback type="invalid">
                    {errors.lpn}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">UPC</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="upc"
                  value={values.upc}
                  isInvalid={Boolean(touched.upc && errors.upc)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={submitting}
                />
                {!!touched.upcCode && (
                  <Form.Control.Feedback type="invalid">
                    {errors.upc}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">ASIN</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="asin"
                  value={values.asin}
                  isInvalid={Boolean(touched.asin && errors.asin)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={submitting}
                />
                {!!touched.asin && (
                  <Form.Control.Feedback type="invalid">
                    {errors.asin}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">FNSKU</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="fnsku"
                  value={values.fnsku}
                  isInvalid={Boolean(touched.fnsku && errors.fnsku)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={submitting}
                />
                {!!touched.fnsku && (
                  <Form.Control.Feedback type="invalid">
                    {errors.fnsku}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="note"
                  placeholder="Enter notes..."
                  value={values.note}
                  isInvalid={Boolean(touched.note && errors.note)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={submitting}
                />
                {!!touched.note && (
                  <Form.Control.Feedback type="invalid">
                    {errors.note}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Disposition</Form.Label>
                <Form.Select
                  name="dispositionId"
                  size="lg"
                  value={values.dispositionId}
                  onChange={({ target }) => {
                    const value = target.value;
                    setFieldValue("dispositionId", value);
                  }}
                  disabled={submitting}
                >
                  {DISPOSITIONS.map((disposition, index) => (
                    <option key={index} value={disposition.dispositionID}>
                      {disposition.disposition}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            {(Number(values.dispositionId) === 3 ||
              Number(values.dispositionId) === 4) && (
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Disposition Reason</Form.Label>
                  <Form.Select
                    name="disposedReasonId"
                    size="lg"
                    value={values.disposedReasonId}
                    onChange={({ target }) => {
                      const value = target.value;
                      setFieldValue("disposedReasonId", value);
                    }}
                    disabled={submitting}
                  >
                    {DISPOSED_REASONS.map((disposition, index) => (
                      <option
                        key={index}
                        value={disposition.disposedDispositionID}
                      >
                        {disposition.disposition}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            )}

            {values.lpn.startsWith("RPN") && (
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-0">Quantity</Form.Label>
                  <Form.Control
                    size="lg"
                    type="number"
                    min="1"
                    name="quantity"
                    value={values.quantity}
                    isInvalid={Boolean(touched.quantity && errors.quantity)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={submitting}
                  />
                  {!!touched.quantity && (
                    <Form.Control.Feedback type="invalid">
                      {errors.quantity}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ItemForm;
