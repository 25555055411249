import { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import Select from "react-select";

import Bays from "./components/consolidate/Bays";
import BaysPlaceholder from "./components/consolidate/BaysPlaceholder";
import FnskuForm from "./components/consolidate/FnskuForm";
import ItemDesignation from "./components/consolidate/ItemDesignation";

import shipmentService from "../../services/shipmentService";

const ConsolidatePage = () => {
  const [areaOptions, setAreaOptions] = useState([]);
  const [userArea, setUserArea] = useState();

  const [bays, setBays] = useState();
  const [fnsku, setFnsku] = useState("");
  const [designatedBay, setDesignatedBay] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(1);

  // Input refs for controlling focus
  const fnskuInputElementRef = useRef(null);
  const rackIdInputElementRef = useRef(null);

  const user = JSON.parse(localStorage.getItem("user"));

  const userId = user?.userID;

  // Get the areas on first render.
  /* useEffect(() => {
    getAreaOptions();
  }, []); */

  useEffect(
    () => {
      /* userArea &&  */ getConsolidationBays();
    },
    [
      /* userArea */
    ]
  );

  const getConsolidationBays = async () => {
    setLoading(true);
    const result = await shipmentService.getConsolidationBays({
      userId: userId,
    });

    if (result.success) {
      setBays(result.consolidationBays);
      setLoading(false);
    } else {
      toast.error(result.message);
      setLoading(false);
    }
  };

  const getAreaOptions = async () => {
    const result = await shipmentService.getAreaOptions();

    if (result.success) {
      const selectOptions = result.consolidationAreas.map((area) => ({
        value: area.areaId,
        label: area.areaId,
      }));
      setAreaOptions(selectOptions);
    } else {
      toast.error(result.message);
    }
  };

  return (
    <>
      <Helmet title="Consolidation" />
      <h1 className="mb-3">Consolidation</h1>
      <Container fluid className="p-0">
        {/* <Row xs="auto">
          <Col className="d-flex align-items-center mb-3">
            Area:
            <Select
              color="grey"
              className="react-select-container ms-1"
              classNamePrefix="react-select"
              placeholder="Select an Area"
              onChange={(selectedArea) => {
                setUserArea(selectedArea);
              }}
              options={areaOptions}
              isSearchable
              value={userArea}
            />
          </Col>
        </Row> */}
        {/*userArea && ( */}
        <Row>
          <Col
            xs={{ order: "last" }}
            md={{ span: 5, order: "first" }}
            xl={4}
            xxl={3}
          >
            {loading ? (
              <BaysPlaceholder />
            ) : (
              bays && (
                <Bays
                  bays={bays}
                  setBays={setBays}
                  setDesignatedBay={setDesignatedBay}
                />
              )
            )}
          </Col>
          <Col md={7} xl={6}>
            <div className="sticky-top" style={{ top: 20 }}>
              <FnskuForm
                submitting={submitting}
                setSubmitting={setSubmitting}
                fnsku={fnsku}
                setFnsku={setFnsku}
                setDesignatedBay={setDesignatedBay}
                quantity={quantity}
                setQuantity={setQuantity}
                fnskuInputElementRef={fnskuInputElementRef}
                rackIdInputElementRef={rackIdInputElementRef}
              />
              {designatedBay && (
                <ItemDesignation
                  submitting={submitting}
                  setSubmitting={setSubmitting}
                  fnsku={fnsku}
                  setFnsku={setFnsku}
                  designatedBay={designatedBay}
                  setDesignatedBay={setDesignatedBay}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  fnskuInputElementRef={fnskuInputElementRef}
                  rackIdInputElementRef={rackIdInputElementRef}
                />
              )}
            </div>
          </Col>
        </Row>
        {/*)*/}
      </Container>
    </>
  );
};

export default ConsolidatePage;
