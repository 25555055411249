import { useEffect, useState } from "react";

import { Button, Form, InputGroup } from "react-bootstrap";
import { X } from "react-feather";
import { useAsyncDebounce } from "react-table";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  data,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // Sets the input value to "" every time data is updated.
  useEffect(() => {
    setValue("");
  }, [data]);

  return (
    <span>
      <InputGroup>
        <Form.Control
          placeholder={`Search ${count} records...`}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        <Button
          variant={value ? "danger" : "outline-secondary"}
          disabled={!value}
          onClick={() => {
            setValue(undefined);
            onChange(undefined);
          }}
        >
          <X size={16} />
        </Button>
      </InputGroup>
    </span>
  );
};

export default GlobalFilter;
