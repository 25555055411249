import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo } from "react";
import { Card, Image } from "react-bootstrap";

import imageNotFound from "../../../../assets/img/imageNotFound.png";

import CheckboxTable from "../../../../components/tables/CheckboxTable";

import { ShipmentsContext } from "./ShipmentAccount";

const ShipmentItems = () => {
  const {
    submitting,
    selectedShipment,
    shipmentItems,
    setSelectedShipmentItems,
    handleScroll,
  } = useContext(ShipmentsContext);

  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: (row) => {
          const asin = row.sku.split("-")[0];
          return (
            <Image
              style={{ maxHeight: "75px" }}
              fluid
              thumbnail
              src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = imageNotFound;
              }}
            />
          );
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "FNSKU",
        accessor: "fnsku",
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
    ],
    []
  );

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faGem} size="3x" className="me-3" />
          <div>
            <Card.Title>Shipments Items</Card.Title>
            <Card.Subtitle>
              All the items for shipment{" "}
              <strong>{selectedShipment.amazonShipmentId}</strong>.
            </Card.Subtitle>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <h1>{selectedShipment.amazonShipmentId}</h1>
        </div>
      </Card.Header>
      <Card.Body>
        {shipmentItems && (
          <CheckboxTable
            columns={columns}
            data={shipmentItems}
            setSelectedRows={setSelectedShipmentItems}
            // useCheckboxes={true}
            submitting={submitting}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default ShipmentItems;
