import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

//pass any parameters for the actionButtonCallback as an object
export default function ModalGeneric({
  showWithoutTrigger = false,
  trigger,
  body,
  title,
  size = "md",
  variant = "primary",
  showCloseButton = true,
  actionButtonText = "OK",
  actionButtonCallback,
  actionButtonCallbackParams,
  actionButtonProps,
  onCloseCallback,
  closeAfterAction = true,
}) {
  const [show, setShow] = useState(showWithoutTrigger);
  const handleClose = () => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //this attaches the handleShow function to the trigger that was passed in.
  let triggerClone;

  if (trigger) {
    triggerClone = React.cloneElement(trigger, { onClick: handleShow });
  }

  const handleActionButtonClick = () => {
    if (actionButtonCallback) {
      if (actionButtonCallbackParams)
        actionButtonCallback(...Object.values(actionButtonCallbackParams));
      else actionButtonCallback();
    }
    if (closeAfterAction) {
      handleClose();
    }
  };

  return (
    <>
      {triggerClone}
      <Modal
        show={show}
        onHide={handleClose}
        size={size}
        aria-labelledby="modal-title-lg"
      >
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          {showCloseButton ? (
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          ) : null}

          <Button
            variant={variant}
            onClick={handleActionButtonClick}
            {...actionButtonProps}
          >
            {actionButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
