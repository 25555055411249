import { Field, ErrorMessage } from "formik";
import { Alert, Form } from "react-bootstrap";

const createFormValidationErrorAlert = ({
  message,
  errorAlertClassName: className = "",
}) => {
  return (
    <Form.Control.Feedback type="invalid" className={`${className} mb-2`}>
      {message}
    </Form.Control.Feedback>
    // <Alert
    //   variant={"danger"}
    //   className={`${className} p-2 justify-content-center`}
    // >
    //   {message}
    // </Alert>
  );
};

export default function UIFormField({
  width = "100",
  name,
  placeholder,
  size = "",
  autoFocus = false,
  fieldClassName = "",
  errorAlertClassName = "",
  errorMessage,
  withValidation = true,
}) {
  return (
    <div className={`w-${width} m-auto`}>
      <Field
        name={name}
        placeholder={placeholder}
        className={`${fieldClassName} my-2 text-center form-control form-control-${size} ${
          withValidation && errorMessage ? "is-invalid" : ""
        }`}
        autoFocus={autoFocus}
      />
      {withValidation && (
        <ErrorMessage
          name={name}
          component={({ children: message }) =>
            createFormValidationErrorAlert({
              message,
              errorAlertClassName,
            })
          }
        />
      )}
    </div>
  );
}
