import React from "react";
import { Col, Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";

function TaskButtons() {
  return (
    <div className="d-flex justify-content-center">
      <Button className="mx-1 btn-info">Custom Task 1</Button>{" "}
      <Button className="mx-1 btn-info">Custom Task 2</Button>
      <Button className="mx-1 btn-info">Custom Task 3</Button>
      {/* <ButtonGroup className="me-2">
        <Button>5</Button> <Button>6</Button> <Button>7</Button>
      </ButtonGroup> */}
      {/* <ButtonGroup>
        <Button>8</Button>
      </ButtonGroup> */}
    </div>
  );
}

export default TaskButtons;
