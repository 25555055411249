import axios from "axios";
import { handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getPickList = async () => {
  try {
    return (await axios.get(`${HOST_URL}/fulfillment/getPickList`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const markAsPicked = async ({ AllocationRowID }) => {
  try {
    return (
      await axios.patch(`${HOST_URL}/fulfillment/markAsPicked`, {
        AllocationRowID,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const markAsNotFound = async ({ AllocationRowID, orderNumber }) => {
  console.log(AllocationRowID, orderNumber);
  try {
    return (
      await axios.patch(`${HOST_URL}/fulfillment/markAsNotFound`, {
        AllocationRowID,
        orderNumber,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const addAlternate = async ({ EbayOrderLineID, InventoryRowAutoID }) => {
  try {
    return (
      await axios.post(`${HOST_URL}/fulfillment/addAlternate`, {
        EbayOrderLineID,
        InventoryRowAutoID,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const resyncEbayOrderData = async () => {
  await axios.get(`${HOST_URL}/fulfillment/resyncEbayOrderData`);
};

const resyncWalmartOrderData = async () => {
  await axios.get(`${HOST_URL}/fulfillment/resyncWalmartOrderData`);
};

const services = {
  getPickList,
  markAsPicked,
  markAsNotFound,
  addAlternate,
  resyncEbayOrderData,
  resyncWalmartOrderData,
};

export default services;
