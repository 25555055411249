import { useState, useEffect } from "react";
import Vendor from "../../../warehouse/models/Vendor";
import Select from "react-select";

const optionizeVendor = (vendor) => {
  return {
    value: vendor.vendorId,
    label: `${vendor.vendorName ? vendor.vendorName : "(No Name)"} (${
      vendor.vendorListingSKUPrefix ? vendor.vendorListingSKUPrefix : ""
    }${vendor.vendorListingSKUPrefix && vendor.vendorRMACode ? " - " : ""}${
      vendor.vendorRMACode ? `${vendor.vendorRMACode}` : ""
    })`,
  };
};

function VendorSelect({ vendors, vendorId, setSelectedVendor }) {
  const [vendor, setVendor] = useState();

  useEffect(() => {
    if (vendorId) {
      const foundVendor = vendors.find((v) => v.vendorId === vendorId);
      const vendor = optionizeVendor(foundVendor);
      setVendor(vendor);
    }
  }, []);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder="Select Vendor"
      onChange={(selectedValue) => {
        const selectedVendorRaw = selectedValue.label.split("(");
        const selectedVendorName = selectedVendorRaw[0];
        const selectedVendorSKUPrefixRaw = selectedVendorRaw[1]?.split("-")[0];
        const selectedVendorSKUPrefix = selectedVendorSKUPrefixRaw;
        const selectedVendorRMACodeRaw = selectedVendorRaw[1]?.split("-")[1];
        const selectedVendorRMACode = selectedVendorRMACodeRaw?.substring(
          0,
          selectedVendorRMACodeRaw.length - 1
        );

        setVendor(selectedValue);

        setSelectedVendor(
          new Vendor(
            selectedValue.value,
            selectedVendorRMACode,
            selectedVendorName,
            selectedVendorSKUPrefix
          )
        );
      }}
      options={[{ value: null, label: "NONE" }].concat(
        vendors.map((vendor) => {
          return optionizeVendor(vendor);
        })
      )}
      value={vendor}
      isSearchable
      autoFocus
    />
  );
}

export default VendorSelect;
