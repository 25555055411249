import React, { createContext, useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";

import { Container, Row, Col } from "react-bootstrap";

import ItemLookupForm from "./components/itemLookup/ItemLookupForm";
import ItemLookupResult from "./components/itemLookup/ItemLookupResult";
import Loader from "../../components/Loader";

const queryTypes = [
  {
    value: 1,
    label: "LPN",
  },
  {
    value: 2,
    label: "Item Listing ID",
  },
  // {
  //   id: 3,
  //   label: "SKU",
  // },
  {
    value: 4,
    label: "FNSKU",
  },
  {
    value: 5,
    label: "ASIN",
  },
  {
    value: 6,
    label: "Seller Chain Data - ASIN",
  },
  {
    value: 7,
    label: "Seller Chain Data - SKU",
  },
];

export const ItemLookupContext = createContext({
  currentItem: {},
  setCurrentItem: () => {},
  queryValue: "",
  setQueryValue: () => {},
  queryType: {
    value: 1,
    label: "LPN",
  },
  setQueryType: () => {},
  error: {},
  setError: () => {},
  queryTypes,
  isLoading: false,
  setIsLoading: () => {},
});

const ItemLookupPage = () => {
  const [queryResult, setQueryResult] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [queryValue, setQueryValue] = useState("");
  const [queryType, setQueryType] = useState(queryTypes[0]);

  const itemLookupContext = useMemo(
    () => ({
      queryResult,
      setQueryResult,
      queryTypes,
      queryValue,
      setQueryValue,
      queryType,
      setQueryType,
      error,
      setError,
      isLoading,
      setIsLoading,
    }),
    [
      queryResult,
      setQueryResult,
      queryValue,
      setQueryValue,
      queryType,
      error,
      setError,
      isLoading,
      setIsLoading,
    ]
  );

  return (
    <>
      <Helmet title="Item Lookup" />
      <h3 className="mb-3">Item Lookup</h3>
      <ItemLookupContext.Provider value={itemLookupContext}>
        <Container fluid className="p-0">
          <Row>
            <Col xl={{ span: 10, offset: 1 }}>
              <ItemLookupForm />
            </Col>
          </Row>
          {isLoading && <Loader />}
          {queryResult && (
            <Row>
              <Col xl={{ span: 10, offset: 1 }}>
                <ItemLookupResult />
              </Col>
            </Row>
          )}
        </Container>
      </ItemLookupContext.Provider>
    </>
  );
};

export default ItemLookupPage;
