import { faDollyFlatbed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Tab } from "react-bootstrap";
import { Info } from "react-feather";

import ShipmentRackTable from "./ShipmentRacksTable";
import { shipmentRacksTableColumns } from "./tableColumns";

const ShipmentRacks = ({
  selectedAccount,
  accountShipmentRacks,
  accountShipmentCategories,
  setSelectedShipmentRack,
  submitting,
}) => {
  const [activeTab, setActiveTab] = useState();
  const [filteredShipmentRacks, setFilteredShipmentRacks] = useState([]);

  // Reset activeTab when account is selected.
  useEffect(() => {
    setActiveTab();
  }, [selectedAccount]);

  // Filter the shipment racks by category when a category is selected.
  useEffect(() => {
    // clear selected shipment rack when category is selected/changed
    // (so will no longer show shipment rack items and the shipment carton card)
    setSelectedShipmentRack();

    const categoryFilteredShipmentRacks = accountShipmentRacks.filter(
      (shipmentRack) => shipmentRack.shipmentCategoryId === activeTab
    );

    if (categoryFilteredShipmentRacks.length > 0) {
      setFilteredShipmentRacks(categoryFilteredShipmentRacks);
    }
  }, [activeTab]);

  const handleRowClick = async (row) => {
    setSelectedShipmentRack(row.original);
  };

  return (
    <Card>
      <Tab.Container activeKey={activeTab}>
        <Card.Header>
          <div className="d-flex justify-content-start mb-3">
            <FontAwesomeIcon icon={faDollyFlatbed} size="3x" className="me-3" />
            <div>
              <Card.Title>Shipment Racks</Card.Title>
              <Card.Subtitle>
                Select a shipment category below to view{" "}
                <strong>{selectedAccount.listingAccount}</strong>'s shipment
                racks.
              </Card.Subtitle>
            </div>
          </div>
          <ButtonGroup className="d-flex">
            {accountShipmentCategories.map((category, index) => {
              return (
                <Button
                  key={index}
                  variant="outline-primary"
                  onClick={() => {
                    setActiveTab(category.shipmentCategoryId);
                  }}
                  className={
                    activeTab === category.shipmentCategoryId ? "active" : ""
                  }
                  disabled={submitting}
                >
                  {category.shipmentCategory}
                </Button>
              );
            })}
          </ButtonGroup>
        </Card.Header>
        <Tab.Content>
          {accountShipmentCategories.length > 0 ? (
            accountShipmentCategories.map((category, index) => {
              return (
                <Tab.Pane key={index} eventKey={category.shipmentCategoryId}>
                  <Card.Body>
                    <ShipmentRackTable
                      columns={shipmentRacksTableColumns}
                      data={filteredShipmentRacks}
                      onRowClick={handleRowClick}
                      submitting={submitting}
                    />
                  </Card.Body>
                </Tab.Pane>
              );
            })
          ) : (
            <Card.Body>
              <Alert variant="warning">
                <div className="alert-icon">
                  <Info
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message">
                  There doesn't seem to be any shipment racks for{" "}
                  <strong>{selectedAccount.listingAccount}</strong>. Try
                  selecting a different account to view current shipment racks.
                </div>
              </Alert>
            </Card.Body>
          )}
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default ShipmentRacks;
