import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Col, Container, Row, Spinner } from "react-bootstrap";
import FBAStorageRestrictionsCard from "./components/fbaStorageRestrictions/FBAStorageRestrictionsCard";
import FBAStorageRestrictionsTable from "./components/fbaStorageRestrictions/FBAStorageRestrictionsTable";

import managementService from "../../services/management/management.service";

const FBAStorageRestrictionsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [listingAccounts, setListingAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getListingAccounts = async () => {
      const response = await managementService
        .getListingAccounts()
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });

      setListingAccounts(response);

      setIsLoading(false);
    };

    getListingAccounts();
  }, []);

  const handleRowClick = (row) => {
    setSelectedAccount(row.original);
    setShowCard(true);
  };

  return (
    <>
      <Helmet title="Listing Stores" />
      <h1 className="h3 mb-3">FBA Storage Restrictions</h1>
      {isLoading ? (
        <div className="d-flex flex-column align-items-center justify-content-center h-50">
          <span className="px-2 font-weight-bold"> Loading... </span>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <Container fluid className="p-0">
          <Row>
            <Col xl={9}>
              <FBAStorageRestrictionsTable
                listingAccounts={listingAccounts}
                handleRowClick={handleRowClick}
              />
            </Col>
            {selectedAccount && showCard && (
              <Col xl={3}>
                <FBAStorageRestrictionsCard
                  selectedAccount={selectedAccount}
                  setListingAccounts={setListingAccounts}
                  setShowCard={setShowCard}
                />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default FBAStorageRestrictionsPage;
