import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useState, useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import differenceInDays from "date-fns/differenceInDays";

/* Date range picker component using npm package react-daterange-picker.
 * params: goButtonAction - function that is called when go button is clicked
 * this function should take in startDate and endDate.
 */

const ChooseDates = ({
  goButtonAction,
  immediateAction = true,
  maxWeek = true,
}) => {
  const [dateRange, setDateRange] = useState([
    new Date(new Date().setHours(0, 0, 0, 0)),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ]);

  useEffect(() => {
    immediateAction && goButtonAction(dateRange[0], dateRange[1]);
  }, []);

  const onChangeHandler = (values) => {
    if (values) {
      setDateRange(values);
    } else {
      // dateRange was cleared, so set back to default
      setDateRange([
        new Date(new Date().setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(23, 59, 59, 999)),
      ]);
    }
  };

  const goButtonClickHandler = () => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];
    // validate
    if (maxWeek && differenceInDays(endDate, startDate) > 7) {
      toast.error("Date range cannot exceed a week. ");
      return;
    }
    if (startDate > endDate) {
      toast.error("Start date must be before end date.");
      return;
    }
    const todayEnd = new Date(new Date().setHours(23, 59, 59, 999));
    if (startDate > todayEnd || endDate > todayEnd) {
      toast.error("Date selections cannot be for future dates. ");
      return;
    }
    // then call the action prop
    goButtonAction(startDate, endDate);
  };

  return (
    <Container className="mb-3">
      <Row>
        <Col>
          <label className="mx-2">Choose Dates:</label>
          <DateRangePicker
            onChange={(values) => onChangeHandler(values)}
            value={dateRange}
          />
          <Button className="m-2" onClick={goButtonClickHandler}>
            Go
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ChooseDates;
