import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import moment from "moment";

const DownloadCSV = ({ headers, data, filename }) => {
  return (
    <CSVLink
      headers={headers}
      data={data}
      filename={`${filename}_${moment().format("YYYY-MM-DD_h-mm-ssa")}.csv`}
    >
      <Button variant="outline-info">Download CSV</Button>
    </CSVLink>
  );
};
export default DownloadCSV;
