import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Container, Spinner, Button } from "react-bootstrap";

import OtpList from "./components/otpPage/OtpList";

import managementService from "../../services/management/management.service";
import toast from "react-hot-toast";

const OTPPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [otps, setOtps] = useState();
  const [lastRefreshed, setLastRefreshed] = useState(0);
  const getOtps = async () => {
    setIsLoading(true);
    setLastRefreshed(0);

    const response = await managementService
      .getOtps({ isAdmin: true })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

    setOtps(response);

    setIsLoading(false);
  };

  const handleRefresh = () => {
    toast.success("Refreshing...");
    getOtps();
  };

  useEffect(() => {
    getOtps();
  }, []);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const currentSeconds = new Date().getSeconds();
      if (currentSeconds === 0 || currentSeconds === 30) {
        getOtps();
      }
      setLastRefreshed(lastRefreshed + 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [lastRefreshed]);

  return (
    <>
      <Helmet title="OTPs" />
      <h1 className="h3 mb-3">OTPs</h1>
      {isLoading ? (
        <div className="d-flex flex-column align-items-center justify-content-center h-50">
          <span className="px-2 font-weight-bold"> Loading... </span>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <Container fluid className="p-0">
          <div className="w-25 m-auto">
            <div className="d-flex justify-content-center">
              <Button onClick={handleRefresh}>Refresh</Button>
            </div>

            <OtpList otps={otps} />
          </div>
        </Container>
      )}
    </>
  );
};

export default OTPPage;
