import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import ASINRulesTable from "./components/asinRules/ASINRulesTable";

const ASINRules = () => {
  return (
    <>
      <Helmet title="ASIN Rules" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">ASIN Rules</h1>
        <ASINRulesTable />
      </Container>
    </>
  );
};

export default ASINRules;
