import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { deepEqual } from "../../../../utils/helper";

const ListingForm = ({
  currentItem,
  isEditing,
  setIsEditing,
  conditionOptions,
  setConditionOptions,
  vendorOptions,
  shipmentCategoryOptions,
  setSelectedShipmentCategoryOption,
  selectedShipmentCategoryOption,
  allowNewCondition,
  handleSubmitChanges,
  changes,
  setChanges,
}) => {
  const [isRPN, setIsRPN] = useState(false);
  const [initialChanges, setInitialChanges] = useState(changes);

  // Check if item has RPN and if allowNewCondition for filtering.
  useEffect(() => {
    if (currentItem.processingData.lpn.startsWith("RPN")) {
      setIsRPN(true);
    }

    // If force new as used, filter out new option.
    if (!allowNewCondition) {
      const filteredConditionOptions = conditionOptions.filter(
        (option) => option.amazonConditionId !== 1
      );

      setConditionOptions(filteredConditionOptions);
    }
  }, [setConditionOptions, conditionOptions, currentItem, allowNewCondition]);

  const validationSchema = Yup.object().shape({
    asin: Yup.string().length(10, "ASIN must be 10 characters long."),
    inspectConditionId: Yup.string().required("Inspect Condition is required."),
    receivedVendorId: Yup.string().required("Vendor is required."),
    shipmentCategoryId: Yup.string().required("Shipment Category is required."),
  });

  const handleAsinChange = (e, handleChange) => {
    const value = e.target.value;
    setInitialChanges({
      ...initialChanges,
      asin: value,
    });
    handleChange(e);
  };
  const handleQuantityChange = (e, handleChange) => {
    const value = e.target.value;
    setInitialChanges({
      ...initialChanges,
      quantity: value,
    });
    handleChange(e);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialChanges}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (
          values.receivedVendorId !==
            currentItem.processingData.receivedVendorId &&
          currentItem.processingData.asinRule?.vendorRestrictedTo !==
            values.receivedVendorId
        ) {
          toast.error("The selected vendor is not valid for this ASIN.");
        } else {
          setChanges(values);
          handleSubmitChanges(false, values);
          setIsEditing(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
        touched,
        dirty,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={2}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">ASIN</Form.Label>
                <Form.Control
                  type="text"
                  name="asin"
                  size="lg"
                  placeholder="Enter ASIN..."
                  value={values.asin}
                  isInvalid={Boolean(touched.asin && errors.asin)}
                  onBlur={handleBlur}
                  onChange={(e) => handleAsinChange(e, handleChange)}
                  disabled={!isEditing}
                />
                {!!touched.asin && (
                  <Form.Control.Feedback type="invalid">
                    {errors.asin}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Condition</Form.Label>
                <Select
                  className={`react-select-container${
                    !isEditing ? "-disabled" : ""
                  }`}
                  classNamePrefix="react-select"
                  name="inspectConditionId"
                  placeholder="Select a Condition..."
                  onChange={(selectedOption) => {
                    setFieldValue(
                      "inspectConditionId",
                      selectedOption.inspectConditionId
                    );
                    setInitialChanges({
                      ...initialChanges,
                      inspectConditionId: selectedOption.inspectConditionId,
                      inspectConditionLabel: selectedOption.label,
                    });
                  }}
                  value={conditionOptions.find(
                    (option) =>
                      option.inspectConditionId === values.inspectConditionId
                  )}
                  options={conditionOptions}
                  isSearchable
                  isDisabled={!isEditing}
                />
                {!!touched.inspectConditionId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.inspectConditionId}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Vendor</Form.Label>
                <Select
                  className={`react-select-container${
                    !isEditing ? "-disabled" : ""
                  }`}
                  classNamePrefix="react-select"
                  name="receivedVendorId"
                  placeholder="Select a Vendor..."
                  onChange={(selectedOption) => {
                    setFieldValue("receivedVendorId", selectedOption.vendorId);
                    setInitialChanges({
                      ...initialChanges,
                      receivedVendorId: selectedOption.vendorId,
                      vendorLabel: selectedOption.label,
                    });
                  }}
                  value={vendorOptions.find(
                    (option) => option.vendorId === values.receivedVendorId
                  )}
                  options={vendorOptions}
                  isSearchable
                  isDisabled={!isEditing}
                />
                {!!touched.receivedVendorId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.receivedVendorId}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col lg={2}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Shipping Category</Form.Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="shipmentCategoryId"
                  placeholder="Select a Shipping Category..."
                  onChange={(selectedOption) => {
                    setSelectedShipmentCategoryOption(selectedOption);
                    setFieldValue(
                      "shipmentCategoryId",
                      selectedOption.shipmentCategoryId
                    );
                    setInitialChanges({
                      ...initialChanges,
                      shipmentCategoryId: selectedOption.shipmentCategoryId,
                      shipmentCategoryLabel: selectedOption.label,
                    });
                  }}
                  value={shipmentCategoryOptions.find(
                    (option) =>
                      option.shipmentCategoryId === values.shipmentCategoryId
                  )}
                  options={shipmentCategoryOptions}
                  isSearchable
                  isDisabled={!isEditing}
                />
              </Form.Group>
            </Col>
            <Col lg={2}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Quantity</Form.Label>
                <Form.Control
                  size="lg"
                  type="number"
                  name="quantity"
                  min={1}
                  value={values.quantity}
                  onChange={(e) => handleQuantityChange(e, handleChange)}
                  disabled={!isEditing || !isRPN}
                />
              </Form.Group>
            </Col>
          </Row>
          {isEditing && (
            <div className="text-center">
              <Button
                className="me-3"
                variant="success"
                onClick={handleSubmit}
                disabled={deepEqual(initialChanges, changes)}
              >
                Save
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setIsEditing(false);
                  resetForm();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ListingForm;
