import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Tab, Row, Col } from "react-bootstrap";
import { AlignJustify } from "react-feather";

import Table from "../.././../../components/tables/Table";
import ShipmentRackItems from "./ShipmentRackItems";

const ShipmentRacks = ({
  selectedAccount,
  accountShipmentRacks,
  accountShipmentCategories,
  selectedShipmentRack,
  setSelectedShipmentRack,
  getShipmentRacksWithoutItems,
}) => {
  const [activeTab, setActiveTab] = useState();
  const [filteredShipmentRacks, setFilteredShipmentRacks] = useState([]);

  // Reset activeTab when account is selected.
  useEffect(() => {
    setActiveTab();
  }, [selectedAccount]);

  // this useEffect is separate from one below so will only run
  //  when active tab changes, not when shipment racks are refetched
  //  after adding or removing an item from a rack
  useEffect(() => {
    // clear selected shipment rack:
    setSelectedShipmentRack();
  }, [activeTab]);

  // Filter the shipment racks by shipment category, when a category is selected
  // or when list of shipment racks is refetched
  useEffect(() => {
    const categoryFilteredShipmentRacks = accountShipmentRacks.filter(
      (shipmentRack) => shipmentRack.shipmentCategoryId === activeTab
    );

    if (categoryFilteredShipmentRacks.length > 0) {
      setFilteredShipmentRacks(categoryFilteredShipmentRacks);
    }
  }, [activeTab, accountShipmentRacks]);

  useEffect(() => {
    // reset selected shipment rack from the newly fetched data
    // so will have updated list of items:
    const updatedSelectedShipmentRack = filteredShipmentRacks.find(
      (shipmentRack) =>
        shipmentRack.shipmentRackId === selectedShipmentRack?.shipmentRackId
    );

    setSelectedShipmentRack(updatedSelectedShipmentRack);
  }, [filteredShipmentRacks]);

  const handleRowClick = (row) => {
    setSelectedShipmentRack(row.original);
    window.scrollTo(0, 0);
  };

  const tableColumns = [
    {
      Header: "Shipment Rack ID",
      accessor: "shipmentRackId",
    },
    /* {
      Header: "Total Items On Rack ",
      accessor: "totalItems",
    }, */
  ];

  return (
    <Row>
      <Col xl={4}>
        <Card>
          <Tab.Container activeKey={activeTab}>
            <Card.Header>
              <div className="d-flex justify-content-start mb-3">
                <AlignJustify size="50" className="me-3" />
                <div>
                  <Card.Title>Shipment Racks</Card.Title>
                  {/* <Card.Subtitle>
                    Select a shipment category below to view{" "}
                    <strong>{selectedAccount.listingAccount}</strong>'s shipment
                    racks.
                  </Card.Subtitle> */}
                </div>
              </div>
              <ButtonGroup className="d-flex">
                {accountShipmentCategories.map((category, index) => {
                  return (
                    <Button
                      key={index}
                      variant="outline-primary"
                      onClick={() => {
                        setActiveTab(category.shipmentCategoryId);
                      }}
                      className={
                        activeTab === category.shipmentCategoryId
                          ? "active"
                          : ""
                      }
                    >
                      {category.shipmentCategory}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Card.Header>
            <Tab.Content>
              {accountShipmentCategories.map((category, index) => {
                return (
                  <Tab.Pane key={index} eventKey={category.shipmentCategoryId}>
                    <Card.Body>
                      <Table
                        columns={tableColumns}
                        data={filteredShipmentRacks}
                        onRowClick={handleRowClick}
                      />
                    </Card.Body>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Tab.Container>
        </Card>
      </Col>
      {selectedShipmentRack && (
        <Col
          className="sticky-top"
          xl={{ span: 8, order: "last" }}
          style={{ top: 20 }}
        >
          <div id="shipment-rack-items">
            <ShipmentRackItems
              selectedShipmentRack={selectedShipmentRack}
              getShipmentRacksWithoutItems={getShipmentRacksWithoutItems}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default ShipmentRacks;
