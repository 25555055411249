import React, { useState } from "react";

const BackgroundContext = React.createContext();

function BackgroundProvider({ children }) {
  const [background, setBackground] = useState();

  let bgColor;

  if (background === "danger") {
    bgColor = "#FF0000";
  } else if (background === "success") {
    bgColor = "#00FF00";
  } else if (background === "warning") {
    bgColor = "#FFFF00";
  }

  return (
    <BackgroundContext.Provider
      value={{
        setBackground,
      }}
    >
      <div style={{ backgroundColor: bgColor }}>{children}</div>
    </BackgroundContext.Provider>
  );
}

export { BackgroundProvider, BackgroundContext };
