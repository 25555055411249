import { Form } from "react-bootstrap";

const DefaultColumnFilter = ({ column }) => {
  const { filterValue, setFilter, Header: header } = column;

  return (
    <Form.Control
      size="lg"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      className="text-center"
      placeholder={`Search ${header}`}
    />
  );
};

export default DefaultColumnFilter;
