import { Card, Col } from "react-bootstrap";

import ModalGeneric from "../../../../components/ModalGeneric";
import InspectUserDetail from "./InspectUserDetail";
import ReceiveUserDetail from "./ReceiveUserDetail";

export default function StatTable({ title, stats, showPercents }) {
  const totalItems =
    stats &&
    stats.reduce((initial, stat) => {
      return stat.processedAndLiquidatedItems >= 0
        ? initial + stat.processedAndLiquidatedItems
        : initial + stat.processedItems;
    }, 0);

  const totalPreviousDayItems =
    showPercents && stats
      ? stats.reduce((initial, stat) => {
          return stat.previousDayProcessedAndLiquidatedItems >= 0
            ? initial + stat.previousDayProcessedAndLiquidatedItems
            : initial + stat.previousDayProcessedItems;
        }, 0)
      : 0;

  /* Percent Comparison value compares the selected day's total items to the 
     previous day's total items for all users */
  const percentComparisonToPreviousDayTotalItems =
    totalPreviousDayItems > 0
      ? (
          ((totalItems - totalPreviousDayItems) / totalPreviousDayItems) *
          100
        ).toFixed(0)
      : null;

  return (
    <Col>
      <Card className="px-4 pt-3">
        <h3 className="text-center">{title}</h3>
        <div className="row align-items-center justify-content-center">
          {!Array.isArray(stats) || !stats.length ? (
            <h2 className="text-center">No Data To Display</h2>
          ) : (
            <table className="table table-bordered table-striped text-center">
              <thead>
                <tr>
                  <th scope="col">Ranking:</th>
                  <th scope="col">Name:</th>
                  <th scope="col">Processed Items:</th>
                  {stats[0].liquidatedItems >= 0 && (
                    <>
                      <th scope="col">Liquidated Items:</th>
                      <th scope="col">Total Items:</th>
                    </>
                  )}
                  {showPercents && (
                    <th scope="col">Previous Day Comparison:</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {stats &&
                  stats.map((stat, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {stat.receiveDispositionsWithItems ? (
                          <ModalGeneric
                            trigger={
                              <td>
                                <a href="#" title="Click to view user detail">
                                  {stat.name}
                                </a>
                              </td>
                            }
                            title="Receiving User Detail"
                            body={
                              <ReceiveUserDetail
                                stats={stats}
                                selectedUserId={stat.userId}
                                selectedUserName={stat.name}
                                selectedReceiveDispositionsWithItems={
                                  stat.receiveDispositionsWithItems
                                }
                                selectedTotalItems={
                                  stat.processedAndLiquidatedItems
                                }
                                showPercents={showPercents}
                              />
                            }
                            showCloseButton={false}
                          />
                        ) : stat.inspectConditionsWithItems ? (
                          <ModalGeneric
                            trigger={
                              <td>
                                <a href="#" title="Click to view user detail">
                                  {stat.name}
                                </a>
                              </td>
                            }
                            title="Inspecting User Detail"
                            body={
                              <InspectUserDetail
                                stats={stats}
                                selectedUserId={stat.userId}
                                selectedUserName={stat.name}
                                selectedInspectConditionsWithItems={
                                  stat.inspectConditionsWithItems
                                }
                                selectedTotalItems={
                                  stat.processedAndLiquidatedItems
                                }
                                showPercents={showPercents}
                              />
                            }
                            showCloseButton={false}
                          />
                        ) : (
                          <td>{stat.name}</td>
                        )}
                        {stat.liquidatedItems >= 0 ? (
                          <>
                            <td>{stat.processedItems}</td>
                            <td>{stat.liquidatedItems}</td>
                            {/* Percent Comparison value compares the selected day's total items to the 
                            previous day's total items for each user */}
                            {showPercents ? (
                              <>
                                <td>{stat.processedAndLiquidatedItems}</td>
                                <td>
                                  {stat.percentComparisonToPreviousDay >= 0 ? (
                                    <span className="text-success">
                                      +{stat.percentComparisonToPreviousDay}%
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      {stat.percentComparisonToPreviousDay}%
                                    </span>
                                  )}
                                </td>
                              </>
                            ) : (
                              <td>{stat.processedAndLiquidatedItems}</td>
                            )}
                          </>
                        ) : (
                          <>
                            {/* Percent Comparison value compares the selected day's total items to the 
                            previous day's total items for each user */}
                            {showPercents ? (
                              <>
                                <td>{stat.processedItems}</td>
                                <td>
                                  {stat.percentComparisonToPreviousDay >= 0 ? (
                                    <span className="text-success">
                                      +{stat.percentComparisonToPreviousDay}%
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      {stat.percentComparisonToPreviousDay}%
                                    </span>
                                  )}
                                </td>
                              </>
                            ) : (
                              <td>{stat.processedItems}</td>
                            )}
                          </>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr className="font-weight-bold">
                  {percentComparisonToPreviousDayTotalItems ? (
                    <>
                      <td colSpan="3">
                        {totalItems && `Total: ${totalItems}`}
                      </td>
                      <td colSpan="3">
                        Total Previous Day Comparison:{" "}
                        {percentComparisonToPreviousDayTotalItems >= 0 ? (
                          <span className="text-success">
                            +{percentComparisonToPreviousDayTotalItems}%
                          </span>
                        ) : (
                          <span className="text-danger">
                            {percentComparisonToPreviousDayTotalItems}%
                          </span>
                        )}
                      </td>
                    </>
                  ) : (
                    <td colSpan="6">{totalItems && `Total: ${totalItems}`}</td>
                  )}
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </Card>
    </Col>
  );
}
