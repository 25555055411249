import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { AlertOctagon, Flag } from "react-feather";
import toast from "react-hot-toast";

import DocumentsModal from "./DocumentsModal";

import receivingService from "../../../../services/receiving.service";

import { ReceivingContext } from "../../ReceivingPage";

import imageNotFound from "../../../../assets/img/imageNotFound.png";

const Shipment = () => {
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  const { documents, flags, items, manifest, setManifest, shipment, vendor } =
    useContext(ReceivingContext);

  useEffect(() => {
    if (manifest && items) {
      calculateQuantities();
    }
  }, [items]);

  const calculateQuantities = () => {
    setManifest((prevState) => {
      return prevState.map((mItem) => {
        const matchedItems = items.filter(
          (sItem) =>
            mItem.fnsku.toString().toUpperCase().trim() ===
            sItem?.fnsku?.toString().toUpperCase().trim()
        );

        if (matchedItems.length > 0) {
          const actualQuantity = matchedItems.reduce((acc, curr) => {
            acc += curr.quantity;
            return acc;
          }, 0);

          mItem.actualQuantity = actualQuantity;
        } else {
          mItem.actualQuantity = 0;
        }

        return mItem;
      });
    });
  };

  const handleSubmitShipment = async () => {
    setSubmitting(true);

    // Calculate the shipment status based on the manifest
    let shipmentStatusId;

    if (manifest) {
      const { missing, extra } = manifest.reduce(
        (acc, curr) => {
          if (curr.expectedQuantity > curr.actualQuantity) {
            acc.missing += curr.expectedQuantity - curr.actualQuantity;
          } else if (curr.expectedQuantity < curr.actualQuantity) {
            acc.extra += curr.actualQuantity - curr.expectedQuantity;
          }

          return acc;
        },
        { missing: 0, extra: 0 }
      );

      // Received Shipment Statuses
      // receivedShipmentStatusId	| receivedShipmentStatus
      //                        1 | Incomplete - Missing Items
      //                        2 | Incomplete - Extra Items
      //                        3 | Incomplete - Missing and Extra Items
      //                        4 | Complete

      if (missing !== 0 && extra !== 0) {
        // Both missing and extra items.
        shipmentStatusId = 3;
      } else if (missing !== 0) {
        // Missing items
        shipmentStatusId = 1;
      } else if (extra !== 0) {
        // Extra items
        shipmentStatusId = 2;
      } else {
        // No missing and no extra items.
        shipmentStatusId = 4;
      }
    }

    const response = await receivingService.submitReceivedShipment({
      shipmentId: shipment.shipmentId,
      shipmentStatusId,
    });

    if (response.success) {
      toast.success("Shipment successfully submitted.");
      setTimeout(() => {
        window.location = "/receiving";
      }, 2000);
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
    }

    setSubmitting(false);
  };

  const handleDeleteShipment = async () => {
    if (shipment.wasSubmitted) {
      window.location.reload(false);
      return;
    }

    setSubmitting(true);

    const response = await receivingService.deleteReceivedShipment({
      shipmentId: shipment.shipmentId,
    });

    if (response.success) {
      toast.success("Shipment successfully deleted.");
      setTimeout(() => {
        window.location = "/receiving";
      }, 2000);
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        size="sm"
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this shipment? You cannot undo this
          action.
          <Row className="mt-3">
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="danger"
                  onClick={handleDeleteShipment}
                  disabled={submitting}
                >
                  {submitting && (
                    <Spinner
                      className="me-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {submitting ? "Deleting..." : "Delete Shipment"}
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="secondary"
                  onClick={() => setShowConfirmModal(false)}
                  disabled={submitting}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <DocumentsModal
        showDocumentModal={showDocumentModal}
        setShowDocumentModal={setShowDocumentModal}
      />
      <Card>
        <Card.Header className="border-bottom">
          <div className="d-flex justify-content-start">
            <FontAwesomeIcon icon={faTruckLoading} size="3x" className="me-3" />
            <div>
              <Card.Title>Shipment</Card.Title>
              <Card.Subtitle>
                Information about the current shipment.
              </Card.Subtitle>
            </div>
          </div>
          {error && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertOctagon
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {error.message}
              </div>
            </Alert>
          )}
          <Row className="mt-3">
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="success"
                  onClick={() => {
                    handleSubmitShipment();
                    setError();
                  }}
                  disabled={submitting}
                >
                  {submitting && (
                    <Spinner
                      className="me-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {submitting ? "Submitting..." : "Submit Shipment"}
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="danger"
                  onClick={() => {
                    if (shipment.wasSubmitted) {
                      handleDeleteShipment();
                    } else {
                      setShowConfirmModal(true);
                      setError();
                    }
                  }}
                  disabled={submitting}
                >
                  {shipment.wasSubmitted
                    ? "Start Over"
                    : submitting
                    ? "Deleting..."
                    : "Delete Shipment"}
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {shipment.trackingNumber && (
            <div className="mb-3">
              <h4 className="mb-0">Tracking Number</h4>
              {shipment.trackingNumber}
              {flags?.trackingNumberFlags &&
                flags.trackingNumberFlags.length > 0 && (
                  <Alert variant="warning" className="flex-fill alert-blink">
                    <div className="alert-icon">
                      <Flag
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message font-weight-bold">
                      {flags.trackingNumberFlags.map((flag, index) => (
                        <div key={index}>{flag.message}</div>
                      ))}
                    </div>
                  </Alert>
                )}
            </div>
          )}
          <div className="mb-3">
            <h4 className="mb-0">Vendor</h4>
            <span className="bold">Name:</span> {vendor.vendorName}
            <div>
              <span className="bold">RMA:</span> {vendor.vendorRMACode}
            </div>
            {flags?.vendorFlags && flags.vendorFlags.length > 0 && (
              <Alert variant="warning" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <Flag
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {flags.vendorFlags.map((flag, index) => (
                    <div key={index}>{flag.message}</div>
                  ))}
                </div>
              </Alert>
            )}
          </div>
          <div>
            <h4 className="mb-0">Documents</h4>
            <div className="d-flex">
              <Button
                className="flex-fill"
                variant="info"
                onClick={() => setShowDocumentModal(true)}
              >
                Edit Documents
              </Button>
            </div>
            {documents && (
              <Row className="mt-3">
                {documents.map((image, index) => {
                  return (
                    <Col xs={6} key={index} className="mb-3">
                      <Image src={image.imageSrc} fluid thumbnail />
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
          {manifest && (
            <div className="mt-3">
              <h4 className="mb-0">Manifest</h4>
              <ListGroup variant="flush">
                {manifest.map((item, index) => {
                  return (
                    <ListGroup.Item
                      key={index}
                      variant={
                        item.actualQuantity === item.expectedQuantity
                          ? "success"
                          : item.actualQuantity < item.expectedQuantity
                          ? "warning"
                          : "danger"
                      }
                    >
                      {item.asin ? (
                        <>
                          <Row>
                            <Col sm="auto">
                              <a
                                href={`https://www.amazon.com/dp/${item.asin}?th=1&psc=1`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Image
                                  style={{
                                    maxHeight: "75px",
                                    maxWidth: "75px",
                                  }}
                                  fluid
                                  thumbnail
                                  src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${item.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = imageNotFound;
                                  }}
                                />
                              </a>
                            </Col>
                            <Col>
                              <Row>
                                <Col>
                                  <div className="mb-2">
                                    <h5 className="mb-0">ASIN</h5>
                                    <span>{item.asin}</span>
                                  </div>
                                  <div className="mb-2">
                                    <h5 className="mb-0">FNSKU</h5>
                                    <span>{item.fnsku}</span>
                                  </div>
                                </Col>
                                <Col>
                                  <h5 className="mb-0">Quantity</h5>
                                  <span>
                                    {item.actualQuantity} /{" "}
                                    {item.expectedQuantity}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row>
                          <Col>
                            <h5 className="mb-0">FNSKU</h5>
                            <span>{item.fnsku}</span>
                          </Col>
                          <Col>
                            <h5 className="mb-0">Quantity</h5>
                            <span>
                              {item.actualQuantity} / {item.expectedQuantity}
                            </span>
                          </Col>
                        </Row>
                      )}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Shipment;
