import { faPeopleCarry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, ListGroup, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";
import NonFixedTable from "../../components/tables/NonFixedTable";
import DownloadCSV from "./components/fulfillmentPicklist/DownloadCSV";
import tableColumns from "./components/fulfillmentPicklist/tableColumns";

import fulfillmentService from "../../services/fulfillment.service";

export let handleFound = async () => {};
export let handleNotFound = async () => {};

const FulfillmentPicklistPage = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pickList, setPickList] = useState([]);
  const [alternates, setAlternates] = useState();
  const [EbayOrderLineID, setEbayOrderLineID] = useState();

  // Generate the headers for the CSV download from the tableColumns file.
  const headers = tableColumns
    .map((col) =>
      typeof col.accessor === "string"
        ? { label: col.Header, key: col.accessor }
        : null
    )
    .filter(Boolean);

  // Get the picklist on first render.
  useEffect(() => {
    if (pickList.length === 0) {
      getPickList();
    }
  }, []);

  const getPickList = async () => {
    setLoading(true);

    const response = await fulfillmentService.getPickList();

    if (response.success) {
      // Sort the list by date
      const sortedList = response.pickList.sort((a, b) => {
        return new Date(a.shipByDate) - new Date(b.shipByDate);
      });

      setPickList(sortedList);
      setLoading(false);
    } else {
      toast.error("Something went wrong, please try again.");
      setLoading(false);
    }
  };

  // Mark the item as found.
  handleFound = async (row) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    const response = await fulfillmentService.markAsPicked({
      AllocationRowID: row.AllocationRowID,
    });

    if (response.success) {
      toast.success('Item successfully marked as "Found"!');
      setSubmitting(false);
      getPickList();
    } else {
      toast.error("Something went wrong, please try again.");
      setSubmitting(false);
    }
  };

  // Mark the item as not found.
  handleNotFound = async (row) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    const response = await fulfillmentService.markAsNotFound({
      AllocationRowID: row.AllocationRowID,
      orderNumber: row.orderNumber,
    });

    if (response.success) {
      toast.success('Item successfully marked as "Not Found"!');
      if (response?.alternates?.length > 0) {
        setAlternates(response.alternates);
        setEbayOrderLineID(response.EbayOrderLineID);
        setShowModal(true);
      } else {
        toast.error("No alternate items found.", {
          duration: 5000,
        });
      }
      setSubmitting(false);
      getPickList();
    } else {
      toast.error("Something went wrong, please try again.");
      setSubmitting(false);
    }
  };

  // Add the alternate item to the picklist.
  const handleAddAlternate = async ({
    EbayOrderLineID,
    InventoryRowAutoID,
  }) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    const response = await fulfillmentService.addAlternate({
      EbayOrderLineID,
      InventoryRowAutoID,
    });

    if (response.success) {
      toast.success("Alternate item successfully added to picklist.");
      setAlternates();
      setEbayOrderLineID();
      setShowModal(false);
      setSubmitting(false);
      getPickList();
    } else {
      toast.error("Something went wrong, please try again.");
      setSubmitting(false);
    }
  };

  const resyncEbayOrderData = async () => {
    setLoading(true);
    try {
      await fulfillmentService.resyncEbayOrderData();
      await getPickList();
    } catch (error) {
      toast.error("Error. Unable to resync Ebay Order Data.");
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const resyncWalmartOrderData = async () => {
    setLoading(true);
    try {
      await fulfillmentService.resyncWalmartOrderData();
      await getPickList();
    } catch (error) {
      toast.error("Error. Unable to resync Walmart Order Data.");
      console.log("error: ", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet title="Fulfillment Picklist" />
      <h1 className="mb-3">Fulfillment Picklist</h1>
      <Container fluid className="p-0">
        <Modal
          size="sm"
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Alternate Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card.Text>Select an alternate item from the list below.</Card.Text>
            <ListGroup>
              {alternates?.map((option, index) => (
                <ListGroup.Item
                  key={index}
                  className="text-center"
                  action
                  onClick={() => {
                    handleAddAlternate({
                      EbayOrderLineID: EbayOrderLineID,
                      InventoryRowAutoID: option.InventoryRowAutoID,
                    });
                  }}
                >
                  {option["Avail SKU"]}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
        </Modal>
        <Card>
          <Card.Header className="border-bottom">
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <FontAwesomeIcon
                  icon={faPeopleCarry}
                  size="3x"
                  className="me-3"
                />
                <div>
                  <Card.Title>Picklist</Card.Title>
                  <Card.Subtitle>
                    A picklist for all merchant fulfilled orders.
                  </Card.Subtitle>
                </div>
              </div>
              <div>
                <DownloadCSV
                  headers={headers}
                  data={pickList}
                  fileName={"Fulfillment_Picklist"}
                  disabled={loading}
                />
                <Button
                  className="ms-3"
                  variant="info"
                  onClick={() => getPickList()}
                  disabled={loading}
                >
                  Refresh
                </Button>
              </div>
            </div>
            <div className="mt-3">
              <Button
                variant="info"
                onClick={() => resyncEbayOrderData()}
                disabled={loading}
              >
                Resync Ebay Orders
              </Button>
              <Button
                className="ms-3"
                variant="info"
                onClick={() => resyncWalmartOrderData()}
                disabled={loading}
              >
                Resync Walmart Orders
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            {loading ? (
              <Loader />
            ) : (
              <NonFixedTable size="sm" columns={tableColumns} data={pickList} />
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default FulfillmentPicklistPage;
