import React, { useContext, useEffect, useState } from "react";

import cryptoRandomString from "crypto-random-string";
import { Alert, Col, Row } from "react-bootstrap";
import { AlertCircle, Flag } from "react-feather";
import toast from "react-hot-toast";

import ProcessingItemData from "../inspect/ProcessingItemData";
import BoxOptions from "./BoxOptions";
import EditListingParameters from "./EditListingParameters";
import ListingOptions from "./ListingOptions";

import listingService from "../../../../services/listingService";
import printService from "../../../../services/printService";

import { ListingItemContext } from "../../ListingPage";

function ListingItem() {
  const [startTime] = useState(new Date().toISOString());
  // Boolean states
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showBoxOptions, setShowBoxOptions] = useState(false);

  // Select input states
  const [conditionOptions, setConditionOptions] = useState();
  const [vendorOptions, setVendorOptions] = useState();
  const [shipmentCategoryOptions, setShipmentCategoryOptions] = useState();

  // Selected Option States
  const [selectedShipmentCategoryOption, setSelectedShipmentCategoryOption] =
    useState({ shipmentCategoryId: 1, label: "Standard" });

  const [changes, setChanges] = useState();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  const {
    currentItem,
    setCurrentItem,
    listingOptions,
    setListingOptions,
    listingRequirements,
    setListingRequirements,
    isLoading,
    setIsLoading,
    setError,
    flags,
    previouslyListedLPN,
  } = useContext(ListingItemContext);

  const restrictLiquidation =
    currentItem?.processingData.asinRule?.restrictLiquidation === true;

  const {
    forceEbayListing,
    forceNewAsUsed,
    forceLiquidation,
    isAmazonRestricted,
    isVendorRestricted,
    restrictAmazonNewListing,
    restrictEbayNewListing,
    isVendorRestrictedAsin,
    isInAmazonCatalog,
  } = listingRequirements;

  useEffect(() => {
    const getOptions = async () => {
      if (!conditionOptions || conditionOptions?.length === 0) {
        const inspectConditions = await listingService
          .getConditions()
          .catch((error) => {
            console.error(error);
            setError(error.response.data.message);
          });
        setConditionOptions(inspectConditions);
      }

      setIsLoading(false);
    };
    getOptions();
  }, [conditionOptions, setError, setIsLoading]);

  useEffect(() => {
    const getOptions = async () => {
      if (!vendorOptions || vendorOptions?.length === 0) {
        const vendors = await listingService.getVendors().catch((error) => {
          console.error(error);
          setError(error.response.data.message);
        });
        setVendorOptions(vendors);
      }

      setIsLoading(false);
    };
    getOptions();
  }, [vendorOptions, setError, setIsLoading]);

  useEffect(() => {
    const getOptions = async () => {
      if (!shipmentCategoryOptions || shipmentCategoryOptions?.length === 0) {
        const shipmentCategories = await listingService
          .getShipmentCategories()
          .catch((error) => {
            console.error(error);
            setError(error.response.data.message);
          });
        setShipmentCategoryOptions(shipmentCategories);
      }

      setIsLoading(false);
    };
    getOptions();
  }, [shipmentCategoryOptions, setError, setIsLoading]);

  useEffect(() => {
    if (conditionOptions && vendorOptions && shipmentCategoryOptions) {
      const inspectConditionLabel =
        conditionOptions.find(
          (option) =>
            option.inspectConditionId ===
            currentItem.processingData.inspectConditionId
        )?.label || "Item was set to be liquidated.";

      const vendorLabel = vendorOptions.find(
        (option) =>
          option.vendorId === currentItem.processingData.receivedVendorId
      )?.label;

      const shipmentCategoryLabel = shipmentCategoryOptions.find(
        (option) =>
          option.shipmentCategoryId ===
          currentItem.processingData.shipmentCategoryId
      )?.label;

      setChanges({
        asin:
          currentItem?.processingData?.asin ||
          currentItem?.processingData?.receivedAsin ||
          "",
        inspectConditionId: currentItem?.processingData?.inspectConditionId,
        inspectConditionLabel,
        receivedVendorId: currentItem?.processingData?.receivedVendorId,
        vendorLabel,
        shipmentCategoryId:
          currentItem?.processingData?.shipmentCategoryId || 1,
        shipmentCategoryLabel: shipmentCategoryLabel || "Standard",
        quantity: currentItem?.processingData?.quantity || 1,
      });
    }
  }, [conditionOptions, vendorOptions, shipmentCategoryOptions]);

  const handleSubmitChanges = async (findCandidateListingOptions, changes) => {
    setIsLoading(true);
    let query;

    if (findCandidateListingOptions) {
      query = {
        asin:
          currentItem.processingData.asin ||
          currentItem.processingData.receivedAsin,
        inspectConditionId: currentItem.processingData.inspectConditionId,
        receivedVendorId: currentItem.processingData.receivedVendorId,
        shipmentCategoryId:
          currentItem?.processingData?.shipmentCategoryId || 1,
        findCandidateListingOptions,
      };
    } else {
      query = {
        asin: changes?.asin,
        inspectConditionId: changes?.inspectConditionId,
        receivedVendorId: changes?.receivedVendorId,
        shipmentCategoryId: changes?.shipmentCategoryId,
        findCandidateListingOptions,
      };
    }

    const response = await listingService
      .getListingOptions(query)
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setError(error.response.data.message);
      });

    if (!response) {
      return;
    }

    const { amazonListingOptions, processingItem } = response;
    const { itemData, processingData, amazonData } = processingItem;
    const { itemName } = itemData;
    const { asin, inspectConditionId, price, valuableSale } = processingData;
    const resetListingRequirements = !findCandidateListingOptions;

    setListingOptions(amazonListingOptions);
    if (resetListingRequirements) {
      setListingRequirements(response.listingRequirements);
    }

    setCurrentItem({
      ...currentItem,
      amazonData,
      itemData,
      processingData: {
        ...currentItem.processingData,
        asin,
        inspectConditionId,
        quantity:
          changes?.quantity || currentItem.processingData.receivedQuantity,
        itemName,
        price,
        valuableSale,
      },
    });

    setIsLoading(false);
  };

  const handleSubmitListing = async ({
    listingAccount,
    listingAccountId,
    fnsku,
  }) => {
    if (
      (currentItem.processingData.receivedAsin === "" ||
        !currentItem.processingData.receivedAsin) &&
      (currentItem.processingData.asin === "" ||
        !currentItem.processingData.asin)
    ) {
      currentItem.processingData.asin = `R${cryptoRandomString({
        length: 9,
        type: "distinguishable",
      })}`;
    }

    const marketplace =
      listingAccount !== "Ebay" && listingAccount !== "Liquidate"
        ? "amazon"
        : "nonAmazon";

    const nonAmazonReason =
      marketplace === "nonAmazon"
        ? forceLiquidation
          ? "UnderValue"
          : isAmazonRestricted
          ? "AmazonRestricted"
          : isVendorRestricted
          ? "VendorRestricted"
          : isVendorRestrictedAsin
          ? "VendorRestrictedAsin"
          : forceEbayListing
          ? "BadASIN"
          : !isInAmazonCatalog
          ? "NotInAmazonCatalog"
          : "Override"
        : null;

    const data = {
      account: listingAccount,
      asin:
        currentItem.processingData.asin ||
        currentItem.processingData.receivedAsin,
      fnsku,
      inspectConditionId: currentItem.processingData.inspectConditionId,
      inspectedItemId: currentItem.processingData.inspectedId,
      listingAccountId,
      lpn: currentItem.processingData.lpn,
      marketplace,
      nonAmazonReason,
      quantity: currentItem.processingData.quantity || 1,
      receivedVendorId: currentItem.processingData.receivedVendorId,
      shipmentCategoryId: selectedShipmentCategoryOption.shipmentCategoryId,
      startTime,
      userId,
    };

    setIsSubmitting(true);

    const result = await listingService.submitListing(data).catch((error) => {
      console.error(error);
      setError(error.response.data.message);
      setIsSubmitting(false);
    });
    if (result?.data?.success) {
      if (listingAccount !== "Liquidate") {
        const labelData = {
          fnsku: fnsku || result.data.newFnsku,
          title:
            currentItem.itemData.itemName ||
            `No Title... ${String.fromCodePoint(0x1f631)}`,
          condition: currentItem.processingData.amazonListingConditionLabel,
          sku: listingOptions.sku,
          account: listingAccount,
          quantity: currentItem.processingData.receivedQuantity,
          listingId: result.data.newListingId,
        };

        printService.printListingLabel(labelData);
      }

      setIsSubmitting(false);
      setIsSubmitted(true);

      toast.success(`Listing submitted successfully!`);
    } else {
      setIsSubmitting(false);
      toast.error("Listing not submitted.");
    }
    setTimeout(() => {
      window.location = "/listing";
    }, 3000);
  };

  return (
    <Row>
      <Col xl={8}>
        {currentItem?.processingData.asinRule?.restrictProcessing === true && (
          <Alert variant="danger">
            <div className="alert-message">{`This item should not be processed.`}</div>
          </Alert>
        )}
        {flags.length > 0 && (
          <Alert variant="warning" className="flex-fill alert-blink">
            <div className="alert-icon">
              <Flag
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message">
              {flags.map((flag, index) => (
                <li key={index}>{flag}</li>
              ))}
            </div>
          </Alert>
        )}
        {previouslyListedLPN && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertCircle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message">
              This Item was already listed. Redoing it will overwrite the
              previous listing.
            </div>
          </Alert>
        )}
        <ProcessingItemData processingItem={currentItem} />
        {
          <EditListingParameters
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            listingRequirements={listingRequirements}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            changes={changes}
            setChanges={setChanges}
            showBoxOptions={showBoxOptions}
            conditionOptions={conditionOptions}
            setConditionOptions={setConditionOptions}
            vendorOptions={vendorOptions}
            setVendorOptions={setVendorOptions}
            shipmentCategoryOptions={shipmentCategoryOptions}
            handleSubmitChanges={handleSubmitChanges}
            selectedShipmentCategoryOption={selectedShipmentCategoryOption}
            setSelectedShipmentCategoryOption={
              setSelectedShipmentCategoryOption
            }
          />
        }
      </Col>
      <Col xl={4}>
        {listingOptions && (
          <ListingOptions
            isSubmitting={isSubmitting}
            isEditing={isEditing}
            isSubmitted={isSubmitted}
            handleSubmitChanges={handleSubmitChanges}
            handleSubmitListing={handleSubmitListing}
            conditionId={currentItem.processingData.inspectConditionId}
            restrictLiquidation={restrictLiquidation}
          />
        )}
      </Col>
    </Row>
  );
}

export default ListingItem;
