import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Card, Col, Image, ListGroup, Row } from "react-bootstrap";

import { ReceivingContext } from "../../ReceivingPage";

import imageNotFound from "../../../../assets/img/imageNotFound.png";

const Items = () => {
  const {
    items,
    setCurrentItem,
    setEditing,
    setShowItemDetails,
    setShowItemForm,
  } = useContext(ReceivingContext);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start border-bottom">
        <FontAwesomeIcon icon={faReceipt} size="3x" className="me-3" />
        <div>
          <Card.Title>Items</Card.Title>
          <Card.Subtitle>
            {items?.length
              ? `${items.length} item${
                  items.length > 1 ? "s" : ""
                } currently on this
            shipment.`
              : "No items currently on this shipment."}
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          {items.map((item, index) => {
            return (
              <ListGroup.Item
                key={index}
                className="px-0"
                action
                onClick={() => {
                  setCurrentItem(item);
                  setShowItemDetails(true);
                  setEditing(false);
                  setShowItemForm(false);
                }}
              >
                {item.asin ? (
                  <>
                    <Row>
                      <Col sm="auto">
                        <Image
                          style={{ maxHeight: "75px", maxWidth: "75px" }}
                          fluid
                          thumbnail
                          src={item.images ? item.images[0] : imageNotFound}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = imageNotFound;
                          }}
                        />
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <h5 className="mb-0">LPN</h5>
                            <span className="text-muted">{item.lpn}</span>
                          </Col>
                          {item.fnsku && (
                            <Col>
                              <h5 className="mb-0">FNSKU</h5>
                              <span className="text-muted">{item.fnsku}</span>
                            </Col>
                          )}
                          <Col>
                            <h5 className="mb-0">ASIN</h5>
                            <span className="text-muted">{item.asin}</span>
                          </Col>
                          <Col>
                            <h5 className="mb-0">Quantity</h5>
                            <span className="text-muted">{item.quantity}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col>
                      <h5 className="mb-0">LPN</h5>
                      <span className="text-muted">{item.lpn}</span>
                    </Col>
                    {item.fnsku && (
                      <Col>
                        <h5 className="mb-0">FNSKU</h5>
                        <span className="text-muted">{item.fnsku}</span>
                      </Col>
                    )}
                    <Col>
                      <h5 className="mb-0">Quantity</h5>
                      <span className="text-muted">{item.quantity}</span>
                    </Col>
                  </Row>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default Items;
