import axios from "axios";
import { handleError, buildQueryString } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getReceivedShipments = async (queryParams = {}) => {
  const { shipmentId, trackingNumber } = queryParams;
  // Form the query string if parameters are passed.
  let queryString = "";
  if (trackingNumber || shipmentId) {
    queryString =
      "?" +
      buildQueryString({
        trackingNumber,
        shipmentId,
      });
  }

  try {
    return (await axios.get(`${HOST_URL}/receiving/shipments${queryString}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const createReceivedShipment = async ({ userId, vendorId, trackingNumber }) => {
  const data = { userId, vendorId, trackingNumber };

  try {
    return (await axios.post(`${HOST_URL}/receiving/shipments`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

const submitReceivedShipment = async (queryParams) => {
  const { shipmentId, shipmentStatusId } = queryParams;

  let queryString = "";
  if (shipmentId || shipmentStatusId) {
    queryString =
      "?" +
      buildQueryString({
        shipmentId,
        shipmentStatusId,
      });
  }

  try {
    return (await axios.patch(`${HOST_URL}/receiving/shipments${queryString}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteReceivedShipment = async ({ shipmentId }) => {
  try {
    return (
      await axios.delete(
        `${HOST_URL}/receiving/shipments?shipmentId=${shipmentId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const addReceivedItem = async (data) => {
  try {
    return (await axios.post(`${HOST_URL}/receiving/items`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

const updateReceivedItem = async (data) => {
  try {
    return (await axios.put(`${HOST_URL}/receiving/items`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

const removeReceivedItem = async ({ itemId }) => {
  try {
    return (await axios.delete(`${HOST_URL}/receiving/items?itemId=${itemId}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const updateDocuments = async (data) => {
  try {
    return (await axios.post(`${HOST_URL}/receiving/documents`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

const lookupItem = async (queryParams) => {
  const { lpn, fnsku, upc, ean } = queryParams;

  // Form the query string if parameters are passed.
  let queryString = "";
  if (lpn || fnsku || upc || ean) {
    queryString =
      "?" +
      buildQueryString({
        lpn,
        fnsku,
        upc,
        ean,
      });
  }

  try {
    if (lpn) {
      return (await axios.get(`${HOST_URL}/receiving/lpnLookup${queryString}`))
        .data;
    } else {
      return (await axios.get(`${HOST_URL}/receiving/asinLookup${queryString}`))
        .data;
    }
  } catch (error) {
    return handleError(error);
  }
};

const updateAsinLookup = async (queryParams) => {
  const { asin, fnsku, upc } = queryParams;

  // Form the query string if parameters are passed.
  let queryString = "";
  if (asin || fnsku || upc) {
    queryString =
      "?" +
      buildQueryString({
        asin,
        fnsku,
        upc,
      });
  }

  try {
    return (await axios.post(`${HOST_URL}/receiving/asinLookup${queryString}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const checkAsinRule = async ({ asin }) => {
  try {
    return (await axios.get(`${HOST_URL}/receiving/asinRules?asin=${asin}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getReceivedShipments,
  updateReceivedItem,
  createReceivedShipment,
  lookupItem,
  updateAsinLookup,
  addReceivedItem,
  removeReceivedItem,
  checkAsinRule,
  submitReceivedShipment,
  deleteReceivedShipment,
  updateDocuments,
};

export default services;
