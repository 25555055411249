import React, { useState } from "react";
import { Button, Card, Image } from "react-bootstrap";
import cryptoRandomString from "crypto-random-string";
import listingService from "../../../../services/listingService";
import toast from "react-hot-toast";

import printService from "../../../../services/printService";
import imageNotFound from "../../../../assets/img/imageNotFound.png";
import QuantityModal from "./QuantityModal";
import Loader from "../../../../components/Loader";

const ClothingListingOption = ({
  clothingListingOption,
  setSubmitting,
  handleStartOver,
}) => {
  return (
    <>
      <Card>
        <Card.Body>
          <h3>{clothingListingOption.productName}</h3>
          <a
            href={`https://www.amazon.com/dp/${clothingListingOption?.asin}?th=1&psc=1`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4>{clothingListingOption.asin}</h4>
            <h6>
              {clothingListingOption?.itemPrice
                ? `$${clothingListingOption.itemPrice}`
                : ""}
            </h6>
            <Image
              fluid
              thumbnail
              src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${clothingListingOption?.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = imageNotFound;
              }}
            />
          </a>
          <div className="d-flex justify-content-center my-2">
            <QuantityModal
              clothingListingOption={clothingListingOption}
              setSubmitting={setSubmitting}
              handleStartOver={handleStartOver}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClothingListingOption;
