import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import UIButton from "../../../../components/UIButton";
import UICard from "../../../../components/UICard";
import UIInput from "../../../../components/UIInput";
import Loader from "../../../../components/Loader";
import shipmentService from "../../../../services/shipmentService";

const StartOverButton = () => (
  <UIButton
    variant={"danger"}
    text="Start Over"
    className={"mx-3"}
    onClick={() => (window.location = "/put-away")}
  />
);

const PalletForm = ({ selectedTrailer, setPallets, handleScroll }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [palletIdValue, setPalletIdValue] = useState("");

  const addPalletToTrailer = async (e) => {
    e.preventDefault();
    if (!palletIdValue) {
      toast.error("Please enter a Pallet ID");
      return;
    }
    setIsLoading(true);
    const response = await shipmentService
      .assignTrailerToPallet(selectedTrailer, palletIdValue)
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    setIsLoading(false);
    if (response && response.success) {
      toast.success("Pallet successfully added to trailer.");
      setPalletIdValue("");
      const response2 = await shipmentService
        .getTrailerPallets({
          shipmentTrailerId: selectedTrailer.shipmentTrailerId,
        })
        .catch((error) => {
          console.error(error);
          // setError(error);
          // setIsLoading(false);
        });

      setPallets(response2);

      handleScroll("trailer-pallets");
    } else {
      toast.error("Error adding pallet to trailer.");
    }
  };

  const header = (
    <h4>
      <div>Add Pallet</div>
    </h4>
  );
  const body = isLoading ? (
    <Loader />
  ) : (
    <>
      <UIInput
        required
        autoFocus
        value={palletIdValue}
        onChange={(e) => {
          setPalletIdValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            addPalletToTrailer(e);
          }
        }}
        type="text"
        placeholder="Enter a Pallet ID"
        size="lg"
        className="my-3 w-75 m-auto text-center"
        disabled={selectedTrailer.shipmentTrailerType === 2}
      />
      <UIButton
        variant="primary"
        text="Add"
        onClick={(e) => addPalletToTrailer(e)}
        disabled={selectedTrailer.shipmentTrailerType === 2}
      />
      {selectedTrailer.shipmentTrailerType === 2 && (
        <div>Can't add pallets to unlocked trailers.</div>
      )}
    </>
  );

  return (
    <UICard
      header={header}
      body={body}
      className="text-center m-auto border border-secondary"
    />
  );
};

export default PalletForm;
