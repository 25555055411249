import axios from "axios";
import { handleError } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getFlags = async ({ vendorId, asin }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/utils/getFlags?vendorID=${vendorId}${
          asin ? `&ASIN=${asin}` : ""
        }`
      )
    ).data.flags;
  } catch (error) {
    return handleError(error);
  }
};

export const getItemToInspect = async ({ lpn, itemId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/warehouse/getProcessingItem?${lpn ? `lpn=${lpn}` : ""}${
          itemId ? `itemId=${itemId}&step=inspect` : ""
        }`
      )
    ).data.processingItem;
  } catch (error) {
    return handleError(error);
  }
};

const getInspectOptions = async () => {
  try {
    const options = (
      await axios.get(`${HOST_URL}/utils/getInspectConditionOptions`)
    ).data.conditionOptions;

    const optionsGroupedByDisposition = options.reduce(
      (acc, option) => {
        if (option.inspectDispositionId === 1) {
          acc.listOptions.push(option);
        } else {
          acc.liquidateOptions.push(option);
        }
        return acc;
      },
      { listOptions: [], liquidateOptions: [] }
    );

    return optionsGroupedByDisposition;
  } catch (error) {
    return handleError(error);
  }
};

export const submitInspection = async (data) => {
  try {
    return (await axios.post(`${HOST_URL}/inspect`, data)).data?.result;
  } catch (error) {
    return handleError(error);
  }
};

const getClaimReasons = async () => {
  try {
    return (await axios.get(`${HOST_URL}/claim/get_claim_reasons`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getFlags,
  getItemToInspect,
  getInspectOptions,
  submitInspection,
  getClaimReasons,
};

export default services;
