import React, { useMemo, useState } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Alert,
  ListGroup,
  InputGroup,
} from "react-bootstrap";
import { Plus, Trash2 } from "react-feather";
import toast from "react-hot-toast";

import shipmentService from "../../../../services/shipmentService";

export default function TrailerEditForm({
  selectedTrailer,
  setEditing,
  getTrailers,
  setSelectedTrailer,
}) {
  const [trailerStatus, setTrailerStatus] = useState(
    selectedTrailer.shipmentTrailerStatus
  );
  const [destinationCenterIds, setDestinationCenterIds] = useState(
    selectedTrailer.destinationCenterIds.split(",").sort()
  );
  const [showDestinationAlert, setShowDestinationAlert] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [dcAutoFocus, setDCAutoFocus] = useState(false);

  const changeDestinationCenters = (index, newValue) => {
    const newDestinationCenters = destinationCenterIds.map((centerId, i) => {
      if (i === index) {
        return newValue;
      } else {
        return centerId;
      }
    });
    setDestinationCenterIds(newDestinationCenters);
  };

  const handleEditTrailerSubmit = async (e) => {
    e.preventDefault();
    setShowDestinationAlert(false);
    // check destination  set:

    const filteredDestinations = destinationCenterIds.filter(
      (centerId) => centerId !== ""
    );

    if (
      !filteredDestinations.length &&
      selectedTrailer.shipmentTrailerType !== "2"
    ) {
      setShowDestinationAlert(true);
      return;
    }
    const response = await shipmentService
      .editShipmentTrailer(
        selectedTrailer.shipmentTrailerId,
        trailerStatus,
        filteredDestinations
      )
      .catch((error) => {
        console.error(error);
        // setError(error);
        // setIsLoading(false);
        toast.error("Error editing trailer.");
      });

    console.log(response);

    if (response && response.success) {
      toast.success("Trailer updated.");
      setEditing(false);
      getTrailers();
      setSelectedTrailer(response.updatedTrailer[0]);
      //how do we keep this trailer selected?
    } else {
      toast.error("Error editing trailer.");
      console.error(response);
    }
  };

  return (
    <Form
      onChange={() => saveDisabled && setSaveDisabled(false)}
      onSubmit={handleEditTrailerSubmit}
    >
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <Form.Group>
            <Row>
              <Col className="fw-bold">
                <Form.Label>Status</Form.Label>
              </Col>
              <Col>
                <Form.Select
                  value={trailerStatus}
                  onChange={(e) => setTrailerStatus(e.target.value)}
                >
                  <option value="1">Available</option>
                  <option value="2">Unavailable</option>
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
        </ListGroup.Item>
        <ListGroup.Item>
          <Form.Group>
            <Row>
              <Col className="fw-bold">
                <Form.Label>Destination Center IDs</Form.Label>
              </Col>
              <Col>
                {destinationCenterIds.map((centerId, index) => (
                  <InputGroup className="mb-1">
                    <Form.Control
                      key={index}
                      type="text"
                      name={`destinationCenterId${index + 1}`}
                      placeholder="Enter Destination Center ID..."
                      value={centerId}
                      onChange={(e) =>
                        changeDestinationCenters(index, e.target.value)
                      }
                      autoFocus={dcAutoFocus}
                    />
                    <Button
                      variant="danger"
                      onClick={() => {
                        setDestinationCenterIds(
                          destinationCenterIds.toSpliced(index, 1)
                        );
                        setSaveDisabled(false);
                      }}
                    >
                      <Trash2 size={16} />
                    </Button>
                  </InputGroup>
                ))}
                <div className="d-flex justify-content-end ">
                  <Button
                    className="m-2"
                    variant="info"
                    title={"Add a destination center."}
                    onClick={() => {
                      setDestinationCenterIds([...destinationCenterIds, ""]);
                      setDCAutoFocus(true);
                    }}
                  >
                    <Plus size={16} />
                  </Button>
                </div>
              </Col>
            </Row>
            {showDestinationAlert && (
              <Alert variant="warning">
                Please enter a destination center ID.
              </Alert>
            )}
          </Form.Group>
        </ListGroup.Item>
      </ListGroup>
      <div className="d-flex justify-content-end ">
        <Button
          className="me-3"
          variant="success"
          type="submit"
          disabled={saveDisabled}
        >
          Save
        </Button>
        <Button
          variant="danger"
          type="button"
          onClick={() => setEditing(false)}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}
