import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import ListingOption from "./ListingOption";

const AmazonListingOptions = ({
  options,
  handleSubmitListing,
  restrictAmazonNewListing,
  conditionId,
}) => {
  const existingOptions = [];
  const availableOptions = [];
  const preferredOptions = [];

  // Map the options into 3 arrays.
  options.map((option) =>
    option.isPreferredAccount
      ? preferredOptions.push(option)
      : option.fnsku
      ? existingOptions.push(option)
      : availableOptions.push(option)
  );

  return conditionId === 1 && restrictAmazonNewListing ? (
    <Alert variant="danger">This item cannot be listed as new on Amazon.</Alert>
  ) : (
    <>
      {preferredOptions.length > 0 && (
        <>
          <h4 className="my-1">Preferred Account</h4>
          {options &&
            preferredOptions.map((option, index) => (
              <ListingOption
                key={index}
                option={option}
                handleSubmitListing={handleSubmitListing}
                variant="success"
              />
            ))}
        </>
      )}
      <Row className="my-2">
        {existingOptions.length > 0 && (
          <Col className="border rounded mx-2">
            <h4 className="my-1">Existing Listings</h4>
            {options &&
              existingOptions.map((option, index) => (
                <ListingOption
                  key={index}
                  option={option}
                  handleSubmitListing={handleSubmitListing}
                  variant="success"
                />
              ))}
          </Col>
        )}
        {availableOptions.length > 0 && (
          <Col className="border rounded mx-2">
            <h4 className="my-1">Available Accounts</h4>
            {options &&
              availableOptions.map((option, index) => (
                <ListingOption
                  key={index}
                  option={option}
                  handleSubmitListing={handleSubmitListing}
                  variant="warning"
                />
              ))}
          </Col>
        )}
      </Row>
    </>
  );
};

export default AmazonListingOptions;
