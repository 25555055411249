import React, { useCallback } from "react";

import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Pagination, Row, Table } from "react-bootstrap";
import { useExpanded, usePagination, useSortBy, useTable } from "react-table";

import ItemLookupResultExtra from "./ItemLookupResultExtra";

const ItemLookupResultTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return <ItemLookupResultExtra rowData={row.original} />;
  }, []);

  return (
    data.length > 0 && (
      <>
        <Table striped hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <React.Fragment key={index}>
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className="ms-2"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faSortUp}
                                  className="ms-2"
                                />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                            )}
                          </span>
                        )}
                      </th>
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
        {data.length > 10 && (
          <Row className="mt-4">
            <Col md="9">
              <span className="mx-2">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>
              </span>
              <span className="ms-3 me-2">Show:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">Go to page:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                min={1}
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const page =
                    !e.target.value || Number(e.target.value) <= 0
                      ? 1
                      : Number(e.target.value) > pageCount
                      ? pageCount
                      : Number(e.target.value);
                  gotoPage(page - 1);
                }}
                style={{ width: "75px" }}
                autoComplete="off"
              />
            </Col>
            <Col md="3">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Item
                  onClick={() => gotoPage(0)}
                  active={1 === pageIndex + 1}
                >
                  {1}
                </Pagination.Item>
                {pageOptions.map((page, index) => {
                  if (
                    (page + 1 === pageIndex + 1 - 3 && page + 1 !== 1) ||
                    (page + 1 === pageIndex + 1 + 3 && page + 1 !== pageCount)
                  ) {
                    return <Pagination.Ellipsis key={index} disabled />;
                  }
                  if (
                    page + 1 >= pageIndex + 1 - 2 &&
                    page + 1 <= pageIndex + 1 + 2 &&
                    page + 1 !== 1 &&
                    page + 1 !== pageCount
                  ) {
                    return (
                      <Pagination.Item
                        key={index}
                        onClick={() => gotoPage(page + 1)}
                        active={page + 1 === pageIndex + 1}
                      >
                        {page + 1}
                      </Pagination.Item>
                    );
                  }
                  return null;
                })}
                {pageCount !== 1 && (
                  <Pagination.Item
                    onClick={() => gotoPage(pageCount)}
                    active={pageCount === pageIndex + 1}
                  >
                    {pageCount}
                  </Pagination.Item>
                )}
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </>
    )
  );
};

export default ItemLookupResultTable;
