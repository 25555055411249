import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Col,
  Container,
  ListGroup,
  Row,
  Card,
} from "react-bootstrap";
import { Eye, Info } from "react-feather";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";
import ShipmentRacks from "../warehouse/components/shipmentRacks/ShipmentRacks";
import shipmentService from "../../services/shipmentService";
import utilsService from "../../services/utils.service";

const Navigation = ({
  selectedAccount,
  setSelectedAccount,
  listingAccounts,
  allShipmentRacks,
}) => {
  return (
    <ListGroup horizontal>
      {listingAccounts &&
        listingAccounts.map((account, index) => {
          return (
            <ListGroup.Item
              tag="a"
              key={index}
              onClick={() => {
                setSelectedAccount(account);
              }}
              action
              active={
                selectedAccount?.listingAccountId === account?.listingAccountId
              }
              disabled={allShipmentRacks.length === 0}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span>{account.listingAccount}</span>
                <Badge bg="dark">
                  {allShipmentRacks &&
                  allShipmentRacks.filter(
                    (shipmentRack) =>
                      shipmentRack.listingAccountId === account.listingAccountId
                  ).length > 0
                    ? allShipmentRacks.filter(
                        (shipmentRack) =>
                          shipmentRack.listingAccountId ===
                          account.listingAccountId
                      ).length
                    : 0}
                </Badge>
              </div>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};

const ShipmentRacksPage = () => {
  const [loading, setLoading] = useState(true);
  const [listingAccounts, setListingAccounts] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [allShipmentCategories, setAllShipmentCategories] = useState([]);
  const [allShipmentRacks, setAllShipmentRacks] = useState([]);
  const [accountShipmentCategories, setAccountShipmentCategories] = useState(
    []
  );
  const [accountShipmentRacks, setAccountShipmentRacks] = useState([]);
  const [selectedShipmentRack, setSelectedShipmentRack] = useState();

  useEffect(() => {
    getListingAccounts();
    getShipmentRacksWithoutItems({});
    getShipmentCategories();
    setLoading(false);
  }, []);

  // clear selected rack when account changes
  // (this useEffect is separate from the one below because
  // we don't want to clear the selected rack when racks are refetched
  // after adding or removing an item)
  useEffect(() => {
    setSelectedShipmentRack();
  }, [selectedAccount]);

  // When an account is selected/changed or shipment racks are refetched,
  // filter the shipment racks and categories by the selected listing account:
  useEffect(() => {
    if (selectedAccount) {
      const accountFilteredShipmentRacks = allShipmentRacks.filter(
        (shipmentRack) =>
          shipmentRack.listingAccountId === selectedAccount.listingAccountId
      );

      if (accountFilteredShipmentRacks.length > 0) {
        setAccountShipmentRacks(accountFilteredShipmentRacks);
      } else {
        setAccountShipmentRacks([]);
      }

      const reducedShipmentCategories = allShipmentCategories.reduce(
        (result, category) => {
          const foundCategory = accountFilteredShipmentRacks.find(
            (shipmentRack) =>
              shipmentRack.shipmentCategoryId === category.shipmentCategoryId
          );

          if (foundCategory) {
            result.push(category);
          }

          return result;
        },
        []
      );

      setAccountShipmentCategories(reducedShipmentCategories);
    }
  }, [selectedAccount, allShipmentRacks]);

  const getListingAccounts = async () => {
    const result = await utilsService.getListingAccounts();

    if (result.success) {
      // Remove the liquidate account from the array of listing accounts.
      const filteredAccounts = result.listingAccounts.filter(
        (account) => account.listingAccountId !== 8
      );
      setListingAccounts(filteredAccounts);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
    }
  };

  const getShipmentRacksWithoutItems = async () => {
    const result = await shipmentService.getShipmentRacksWithoutItems({});

    if (result.success) {
      setAllShipmentRacks(result.shipmentRacks);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
    }
  };

  const getShipmentCategories = async () => {
    const result = await shipmentService.getShipmentCategories();

    if (result.success) {
      setAllShipmentCategories(result.shipmentCategories);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
    }
  };

  return (
    <>
      <Helmet title="Shipment Racks" />
      <h1 className="mb-3">Shipment Racks</h1>
      <Container fluid className="p-0">
        {loading || !allShipmentRacks.length ? (
          <Loader />
        ) : (
          <>
            <Row className="mb-3">
              <Col xxl={8}>
                <Navigation
                  loading={loading}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  listingAccounts={listingAccounts}
                  allShipmentRacks={allShipmentRacks}
                />
              </Col>
            </Row>
            {!selectedAccount ? (
              <Row>
                <Col xxl={8}>
                  <Alert variant="primary">
                    <div className="alert-icon">
                      <Eye
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message">
                      Select an <strong>account</strong> to view shipment racks.
                    </div>
                  </Alert>
                </Col>
              </Row>
            ) : (
              <>
                {accountShipmentCategories.length > 0 ? (
                  <Row className="mb-3">
                    <ShipmentRacks
                      selectedAccount={selectedAccount}
                      accountShipmentRacks={accountShipmentRacks}
                      accountShipmentCategories={accountShipmentCategories}
                      selectedShipmentRack={selectedShipmentRack}
                      setSelectedShipmentRack={setSelectedShipmentRack}
                      getShipmentRacksWithoutItems={
                        getShipmentRacksWithoutItems
                      }
                    />
                  </Row>
                ) : (
                  <Card.Body>
                    <Alert variant="warning">
                      <div className="alert-icon">
                        <Info
                          className="position-relative top-50 start-50 translate-middle"
                          size={20}
                        />
                      </div>
                      <div className="alert-message">
                        There doesn't seem to be any shipment racks for{" "}
                        <strong>{selectedAccount.listingAccount}</strong>. Try
                        selecting a different account to view current shipment
                        racks.
                      </div>
                    </Alert>
                  </Card.Body>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ShipmentRacksPage;
