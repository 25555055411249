import { faPallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Alert, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { AlertTriangle } from "react-feather";
import toast from "react-hot-toast";

import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../../../components/tables/DateRangeFilter";
import Table from "../../../../components/tables/Table";

import printService from "../../../../services/printService";
import shipmentService from "../../../../services/shipmentService";

const ShipmentPallets = ({
  submitting,
  setSubmitting,
  setDestinations,
  selectedDestination,
  setSelectedShipmentPallet,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleRowClick = (row) => {
    setSelectedShipmentPallet(row.original);
  };

  const createShipmentPallet = async () => {
    setSubmitting(true);
    const response = await shipmentService.createShipmentPallet(
      selectedDestination.destinationCenterId
    );

    if (response.success) {
      const { newShipmentPallet } = response;

      //print pallet label
      const palletLabelData = {
        shipmentPalletId: newShipmentPallet.shipmentPalletId,
        destinationCenterId: newShipmentPallet.destinationCenterId,
      };

      await printService.printPalletLabel(palletLabelData);

      toast.success("Successfully created Shipment Pallet.");

      setDestinations((prevState) => {
        return prevState.map((destination) => {
          if (
            destination.destinationCenterId.toUpperCase() ===
            newShipmentPallet.destinationCenterId.toUpperCase()
          ) {
            destination.totalPallets++;
            destination.shipmentPallets.push(newShipmentPallet);
          }
          return destination;
        });
      });
      setSelectedShipmentPallet(newShipmentPallet);
    } else {
      toast.error("Unable to create Shipment Pallet.");
    }

    setSubmitting(false);
  };

  const columns = [
    {
      Header: "Shipment Pallet Id",
      accessor: "shipmentPalletId",
    },
    {
      Header: "Status",
      accessor: "shipmentPalletStatus",
    },
    {
      Header: "Created At",
      id: "createdAt",
      accessor: ({ createdAt }) => new Date(createdAt),
      Cell: ({ value }) => {
        return <span>{new Date(value).toLocaleString()}</span>;
      },
      sortType: "datetime",
      Filter: DateRangeFilter,
      filter: dateBetweenFilterFn,
    },
  ];

  return (
    <>
      <Modal show={showModal} size="sm" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Shipment Pallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <div>Destination Center</div>
            <h1 className="mb-0">{selectedDestination.destinationCenterId}</h1>
            <div>
              {selectedDestination.shipToCity},{" "}
              {selectedDestination.shipToState}
            </div>
          </div>
          <Row>
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="success"
                  onClick={() => {
                    createShipmentPallet();
                    setShowModal(false);
                  }}
                  disabled={submitting}
                >
                  {submitting ? "Creating..." : "Create"}
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="danger"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  disabled={submitting}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <FontAwesomeIcon icon={faPallet} className="me-3" size="3x" />
            <div>
              <Card.Title>Shipment Pallets</Card.Title>
              <Card.Subtitle>
                These are all the shipment pallets going to{" "}
                <strong>{selectedDestination.destinationCenterId}</strong>.
              </Card.Subtitle>
            </div>
          </div>
          <Button
            variant="outline-primary"
            onClick={() => setShowModal(true)}
            disabled={submitting}
          >
            Create Shipment Pallet
          </Button>
        </Card.Header>
        <Card.Body>
          {selectedDestination?.shipmentPallets?.length > 0 ? (
            <Table
              columns={columns}
              data={selectedDestination.shipmentPallets}
              onRowClick={handleRowClick}
            />
          ) : (
            <Alert
              variant="warning"
              className="flex-fill alert-blink mt-3 mb-0"
            >
              <div className="alert-icon">
                <AlertTriangle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                There doesn't seem to be any shipment pallets created for this
                destination.
              </div>
            </Alert>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ShipmentPallets;
