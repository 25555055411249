import * as JSPM from "jsprintmanager";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const scan = async () => {
  return new Promise((resolve, reject) => {
    console.log("in Promise");
    JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = async function () {
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        JSPM.JSPrintManager.getScanners().then(function (scannersList) {
          console.log(scannersList);
        });

        // Create the ClientScanJob and set its options.
        const csj = new JSPM.ClientScanJob();
        csj.scannerName = "TW-Brother DS-640";
        csj.imageFormat = JSPM.ScannerImageFormatOutput.JPG;
        csj.enableFeeder = true;

        csj.onUpdate = async (data) => {
          console.log(data);
          // Check if the scan data is a Blob.
          if (!(data instanceof Blob)) {
            console.info(data);
            return;
          }

          const imgBlob = new Blob([data], { type: "image/jpeg" });
          if (imgBlob.size === 0) return;

          const imageSrc = await convertBlobToBase64String(imgBlob);
          resolve(imageSrc); // Resolve the Promise with imageSrc.
        };

        csj.onError = function (data, is_critical) {
          console.error(data, is_critical);
          reject(data); // Reject the Promise if an error occurs.
        };

        csj.sendToClient().then((data) => {
          console.info("data", data);
        });
      }
    };
  });
};

/**
 * Converts a Blob to a base64 encoded string.
 *
 * @param {Blob} blob - The Blob to be converted to a base64 string.
 * @returns {Promise<string>} A Promise that resolves with the base64 encoded string.
 * @rejects {Error} If an error occurs during the conversion.
 */
const convertBlobToBase64String = async (blob) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onError = function (error) {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};

const services = { scan };

export default services;
