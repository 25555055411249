import React from "react";
import UIDefaultPage from "../../components/UIDefaultPage";
import RackPutawayForm from "./components/putAway/RackPutawayForm";

const InventoryPutawayPage = () => {
  return (
    <UIDefaultPage title={"Inventory Putaway"} children={<RackPutawayForm />} />
  );
};

export default InventoryPutawayPage;
