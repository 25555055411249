import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Image } from "react-bootstrap";

const DisplayImage = ({ shipmentCartonId, imageSrc }) => {
  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faImage} size="3x" className="me-3" />
          <div>
            <Card.Title>FBA Shipment Label</Card.Title>
            <Card.Subtitle>
              Here is a preview of <strong>{shipmentCartonId}</strong>'s FBA
              Shipment Label.
            </Card.Subtitle>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="border border-2">
            <Image src={imageSrc} fluid />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default DisplayImage;
