import React, { useEffect, useState } from "react";
import { Col, Container, Row, Fade } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import OptionsSelect from "./components/clothingListing/OptionsSelect";
import ClothingDataList from "./components/clothingListing/ClothingDataList";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const ClothingListingPage = () => {
  const [clothingOptions, setClothingOptions] = useState();
  const [clothingData, setClothingData] = useState();

  const clothingOptionsFromLs = JSON.parse(
    localStorage.getItem("selectedClothingOptions")
  );

  const getClothingOptions = async () => {
    const optionsResponse = await axios.get(
      `${HOST_URL}/listing/getClothingOptions`
    );
    const clothingOptions =
      optionsResponse.data.clothingOptions.clothingOptions;

    if (clothingOptionsFromLs) {
      const { brand, style, color, size } = clothingOptionsFromLs;

      const brandOption = clothingOptions.find(
        (option) => option.brand === brand.value
      );

      //sort the brand options so that the first option is the one that was selected
      clothingOptions.sort((a, b) => {
        if (a.brand === brand.value) return -1;
        if (b.brand === brand.value) return 1;
        return 0;
      });

      const styleOption = brandOption.styles.find(
        (option) => option.style === style.value
      );
      // sort the style options so that the first option is the one that was selected
      brandOption.styles.sort((a, b) => {
        if (a.style === style.value) return -1;
        if (b.style === style.value) return 1;
        return 0;
      });

      const colorOption = styleOption?.colors.find(
        (option) => option.color === color.value
      );
      //sort the color options so that the first option is the one that was selected
      styleOption?.colors.sort((a, b) => {
        if (a.color === color.value) return -1;
        if (b.color === color.value) return 1;
        return 0;
      });

      //sort the size options so that the first option is the one that was selected
      colorOption?.sizes.sort((a, b) => {
        if (a.size === size.value) return -1;
        if (b.size === size.value) return 1;
        return 0;
      });
    }

    setClothingOptions(clothingOptions);
  };

  useEffect(() => {
    getClothingOptions();
  }, [clothingData]);

  return (
    <>
      <Helmet title="Clothing Listing" />
      <h1 className="mb-3">Clothing Listing</h1>
      <Container fluid className="p-0">
        <Row className="justify-content-center">
          <Col xl={10}>
            {clothingOptions && !clothingData ? (
              <div className="w-50 m-auto">
                <OptionsSelect
                  clothingOptions={clothingOptions}
                  setClothingData={setClothingData}
                />
              </div>
            ) : clothingData ? (
              <ClothingDataList
                clothingData={clothingData}
                setClothingData={setClothingData}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClothingListingPage;
