import { X, Check } from "react-feather";

import { ButtonGroup, Button } from "react-bootstrap";
import { handleModal } from "../../UsersPage";

const tableColumns = [
  {
    Header: "Name",
    accessor: "realName",
  },
  {
    Header: "Username ",
    accessor: "username",
  },
  {
    Header: "Administrator",
    accessor: "isAdmin",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.isAdmin ? -1 : 1),
  },
  {
    Header: "Actions",
    accessor: (row) => {
      return (
        <ButtonGroup size="sm">
          <Button
            variant="warning"
            onClick={() =>
              handleModal({
                show: true,
                type: 1,
                data: row,
              })
            }
          >
            Edit
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              handleModal({
                show: true,
                type: 2,
                data: row,
              })
            }
          >
            Delete
          </Button>
        </ButtonGroup>
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
];

export default tableColumns;
