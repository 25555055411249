import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as LogoColor } from "../../assets/img/rww-brandmark-fullcolor.svg";
import { ReactComponent as LogoWhite } from "../../assets/img/rww-brandmark-white.svg";
import useTheme from "../../hooks/useTheme";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();
  const { theme } = useTheme();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            {theme === "light" ? <LogoColor /> : <LogoWhite />}
            <span>Returns Worldwide</span>
          </a>
          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
