import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Alert, Col, Container, Row } from "react-bootstrap";
import { Eye, Info } from "react-feather";

import shipmentService from "../../services/shipmentService";
import TrailerPallets from "./components/trailers/TrailerPallets";
import Trailers from "./components/trailers/Trailers";
import StickySidebar from "./components/trailers/StickySidebar";

const TrailersPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [palletsLoading, setPalletsLoading] = useState(false);

  const [error, setError] = useState();
  const [allTrailers, setAllTrailers] = useState([]);
  const [selectedTrailer, setSelectedTrailer] = useState();
  const [pallets, setPallets] = useState([]);

  const getTrailers = async () => {
    setIsLoading(true);

    const response = await shipmentService.getTrailers().catch((error) => {
      console.error(error);
      setError(error);
      setIsLoading(false);
    });
    setAllTrailers(response);
    setIsLoading(false);
  };

  const handleScroll = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } /* else {
      window.scrollTo(0, document.body.scrollHeight);
    } */
  };

  useEffect(() => {
    getTrailers();
  }, []);

  useEffect(() => {
    async function getPallets() {
      setPalletsLoading(true);

      const response = await shipmentService
        .getTrailerPallets({
          shipmentTrailerId: selectedTrailer.shipmentTrailerId,
        })
        .catch((error) => {
          console.error(error);
          // setError(error);
          setPalletsLoading(false);
        });

      setPallets(response);
      if (response.length > 0) {
        handleScroll("trailer-pallets");
      }
      setPalletsLoading(false);
    }
    if (selectedTrailer) {
      getPallets();
    }
  }, [selectedTrailer]);

  return (
    <>
      <Helmet title="Shipment Trailers" />
      <h1 className="h3 mb-3">Shipment Trailers</h1>
      <Container fluid className="p-0">
        {error && (
          <Alert variant="danger">
            <div className="alert-icon">
              <Eye
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message">{error.message}</div>
          </Alert>
        )}

        {
          //allTrailers.length > 0 ? (
          <Row>
            <Col
              className="sticky-top"
              xl={{ span: 4, order: "last" }}
              style={{ top: 20 }}
            >
              <StickySidebar
                selectedTrailer={selectedTrailer}
                getTrailers={getTrailers}
                setPallets={setPallets}
                handleScroll={handleScroll}
                setSelectedTrailer={setSelectedTrailer}
              />
            </Col>
            <Col xl={8}>
              <Trailers
                allTrailers={allTrailers}
                setSelectedTrailer={setSelectedTrailer}
                getTrailers={getTrailers}
                handleScroll={handleScroll}
                isLoading={isLoading}
              />

              {selectedTrailer && (
                <TrailerPallets
                  selectedTrailer={selectedTrailer}
                  pallets={pallets}
                  handleScroll={handleScroll}
                  palletsLoading={palletsLoading}
                />
              )}
            </Col>
          </Row>
          /* ) : (
              <Row>
                <Col xxl={8}>
                  <Alert variant="warning">
                    <div className="alert-icon">
                      <Info
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message">No trailers found.</div>
                  </Alert>
                </Col>
              </Row>
            ) */
        }
      </Container>
    </>
  );
};

export default TrailersPage;
