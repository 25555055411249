class Vendor {
  constructor(vendorId, vendorRMACode, vendorName, vendorListingSKUPrefix) {
    this.vendorId = vendorId;
    this.vendorRMACode = vendorRMACode;
    this.vendorName = vendorName;
    this.vendorListingSKUPrefix = vendorListingSKUPrefix;
  }
}

export default Vendor;
