import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cryptoRandomString from "crypto-random-string";
import React, { useContext, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { AlertOctagon, Info } from "react-feather";
import toast from "react-hot-toast";

import receivingService from "../../../../services/receiving.service";

import { ReceivingContext } from "../../ReceivingPage";

const LookupForm = () => {
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  const [submitting, setSubmitting] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const { setCurrentItem, setShowItemDetails, setShowItemForm, vendor } =
    useContext(ReceivingContext);

  const handleSubmit = async () => {
    // Lookup types
    // 1 = LPN, 2 = FNSKU, 3 = UPC, 4 = EAN
    let lookupType = 1;

    // Check if the input exists.
    if (!inputValue) {
      toast.error("Please enter an LPN, FNSKU, UPC or EAN.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (inputValue.toUpperCase() === "W") {
      toast.error(
        "Please re-scan LPN, FNSKU, UPC, or EAN or enter it manually."
      );
      setInputValue("");
      return;
    }

    // Determine the lookup type
    if (
      inputValue.toUpperCase().startsWith("LPN") ||
      inputValue.toUpperCase().startsWith("RPN")
    ) {
      // Possible LPN
      lookupType = 1;
    } else if (!/[^0-9]/.test(inputValue)) {
      // Only digits found make it here.
      if (inputValue.length === 6 || inputValue.length === 12) {
        // Possible UPC
        lookupType = 3;
      } else if (inputValue.length === 8 || inputValue.length === 13) {
        // Possible EAN
        lookupType = 4;
      }
    } else if (inputValue.length === 10) {
      // Possible FNSKU
      lookupType = 2;
    }

    setSubmitting(true);
    setError();
    setInfo();

    const response = await receivingService.lookupItem(
      lookupType === 1
        ? { lpn: inputValue }
        : lookupType === 2
        ? { fnsku: inputValue }
        : lookupType === 3
        ? { upc: inputValue }
        : { ean: inputValue }
    );

    if (response.success) {
      //If it was an lpn lookup:
      if (lookupType === 1) {
        if (response.item) {
          //Filter the asin flags from other vendors
          if (response.item.asinFlags) {
            const filteredAsinFlags = response.item.asinFlags.filter(
              (flag) => flag.vendorId === vendor.vendorId || flag.vendorId === 0
            );

            response.item.asinFlags = filteredAsinFlags;
          }

          setShowItemDetails(true);
          setShowItemForm(true);
          setSubmitting(false);
          setCurrentItem(response.item);
        } else {
          setShowItemDetails(true);
          setShowItemForm(true);
          setSubmitting(false);
          setCurrentItem({ lpn: inputValue });
        }
      } else {
        // Create an RPN
        let userId = JSON.parse(localStorage.getItem("user")).userID.toString();
        const userIdPadded = userId.padStart(5, "0");
        const token = cryptoRandomString({
          length: 6,
          type: "distinguishable",
        });
        const rpn = `RPN${userIdPadded}${token}`;

        // It was an fnsku, upc, or ean lookup:
        if (response.item) {
          if (!response.item.lpn) {
            response.item.lpn = rpn;
          }

          // filter the asinFlags from other vendors
          if (response.item.asinFlags) {
            const filteredAsinFlags = response.item.asinFlags.filter(
              (flag) => flag.vendorId === vendor.vendorId || flag.vendorId === 0
            );

            response.item.asinFlags = filteredAsinFlags;
          }

          setShowItemDetails(true);
          setShowItemForm(true);
          setSubmitting(false);
          setCurrentItem(response.item);
        } else {
          setShowItemDetails(true);
          setShowItemForm(true);
          setSubmitting(false);
          if (lookupType === 2) {
            setCurrentItem({ lpn: rpn, fnsku: inputValue });
          } else if (lookupType === 3) {
            setCurrentItem({ lpn: rpn, upc: inputValue });
          } else {
            setCurrentItem({ lpn: rpn });
          }
        }
      }
    } else {
      toast.error(
        response.message || "Something went wrong, please try again."
      );
      setError(response.error);
      setSubmitting(false);
    }
  };

  const handleSubmitWithoutLookup = () => {
    let userId = JSON.parse(localStorage.getItem("user")).userID.toString();
    const userIdPadded = userId.padStart(5, "0");
    const token = cryptoRandomString({
      length: 6,
      type: "distinguishable",
    });
    const rpn = `RPN${userIdPadded}${token}`;

    setShowItemDetails(true);
    setShowItemForm(true);
    setCurrentItem({ lpn: rpn });
  };

  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      <Card.Header className="d-flex justify-content-start border-bottom">
        <FontAwesomeIcon icon={faSearch} size="3x" className="me-3" />
        <div>
          <Card.Title>Item Lookup</Card.Title>
          <Card.Subtitle>
            Scan or enter an LPN, FNSKU, UPC, or EAN to look up an item.
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        {error && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertOctagon
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              {error.message}
            </div>
          </Alert>
        )}
        {info && (
          <Alert variant="info" className="flex-fill alert-blink">
            <div className="alert-icon">
              <Info
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">{info.message}</div>
          </Alert>
        )}
        <Form.Label>LPN, FNSKU, UPC, EAN</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            autoFocus
            size="lg"
            placeholder={"Scan or enter LPN, FNSKU, UPC, or EAN..."}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={(e) => {
              e.target.select();
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            disabled={submitting}
          />
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting && (
              <Spinner
                className="me-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {submitting ? "Searching..." : "Search"}
          </Button>
        </InputGroup>
        <div className="text-center">
          <Card.Link
            onClick={() => {
              if (!submitting) {
                handleSubmitWithoutLookup();
              }
            }}
          >
            Continue without LPN, FNSKU, UPC, or EAN
          </Card.Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LookupForm;
