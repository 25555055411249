import React, { createContext, useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Info } from "react-feather";

import ShipmentItems from "./ShipmentItems";
import Shipments from "./Shipments";
import StickySidebar from "./StickySidebar";

const ShipmentsContext = createContext();

const ShipmentAccount = ({
  submitting,
  setSubmitting,
  selectedAccount,
  allShipments,
  getShipments,
  selectedShipment,
  setSelectedShipment,
}) => {
  const [shipments, setShipments] = useState();

  const [shipmentCartons, setShipmentCartons] = useState();

  const [shipmentItems, setShipmentItems] = useState();
  const [selectedShipmentItems, setSelectedShipmentItems] = useState([]);

  // Filter the shipment plans when account is selected.
  useEffect(() => {
    const filteredShipments = allShipments.filter(
      (shipment) =>
        shipment.listingAccountId === selectedAccount.listingAccountId
    );
    setShipments(filteredShipments);
    let updatedShipment;
    if (selectedShipment) {
      updatedShipment = filteredShipments.find(
        (shipment) => shipment.shipmentId === selectedShipment.shipmentId
      );
    }
    // Reset the states
    setSelectedShipment(updatedShipment);

    setShipmentItems();
    setSelectedShipmentItems();
  }, [selectedAccount]);

  const handleScroll = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  return (
    <ShipmentsContext.Provider
      value={{
        submitting,
        setSubmitting,
        selectedAccount,
        shipments,
        setShipments,
        selectedShipment,
        setSelectedShipment,
        shipmentCartons,
        setShipmentCartons,
        shipmentItems,
        setShipmentItems,
        selectedShipmentItems,
        setSelectedShipmentItems,
        handleScroll,
        getShipments,
      }}
    >
      {shipments?.length > 0 ? (
        <Row>
          <Col
            className="sticky-top"
            xl={{ span: 4, order: "last" }}
            style={{ top: 20 }}
          >
            <StickySidebar />
          </Col>
          <Col xl={8}>
            <Shipments />

            {selectedShipment && shipmentItems?.length > 0 ? (
              <div id="shipment-items">
                <ShipmentItems />
              </div>
            ) : shipmentItems && !shipmentItems.length > 0 ? (
              <Alert variant="warning">
                <div className="alert-icon">
                  <Info
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message">
                  There doesn't seem to be any items in shipment{" "}
                  <strong>{selectedShipment?.shipmentId}</strong>. Try selecting
                  a different shipment.
                </div>
              </Alert>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xxl={8}>
            <Alert variant="warning">
              <div className="alert-icon">
                <Info
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message">
                There doesn't seem to be any shipments for{" "}
                <strong>{selectedAccount.listingAccount}</strong>. Try selecting
                a different account to view current shipments.
              </div>
            </Alert>
          </Col>
        </Row>
      )}
    </ShipmentsContext.Provider>
  );
};

export { ShipmentsContext };

export default ShipmentAccount;
