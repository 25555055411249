import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { AlertCircle, Info } from "react-feather";
import { Helmet } from "react-helmet-async";

import Loader from "../../components/Loader";
import DestinationCenters from "./components/shipmentPallets/DestinationCenters";
import ShipmentPalletActions from "./components/shipmentPallets/ShipmentPalletActions";
import ShipmentPallets from "./components/shipmentPallets/ShipmentPallets";

import shipmentService from "../../services/shipmentService";

const ShipmentPalletsPage = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState();
  const [selectedShipmentPallet, setSelectedShipmentPallet] = useState();

  useEffect(() => {
    getDestinationCenters();
  }, []);

  const getDestinationCenters = async () => {
    setLoading(true);

    const response = await shipmentService.getDestinationCenters({
      shipmentStatusId: 3,
    });

    if (response.success) {
      setDestinations(response.destinations);
    } else {
      setError(response);
    }

    setLoading(false);
  };

  const handleScroll = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  return (
    <>
      <Helmet title="Shipments Pallets" />
      <h1 className="mb-3">Shipment Pallets</h1>
      <Container fluid className="p-0">
        {loading ? (
          <Loader />
        ) : (
          <>
            {error && (
              <Alert variant="danger">
                <div className="alert-icon">
                  <AlertCircle
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message">{error.message}</div>
              </Alert>
            )}
            {destinations?.length > 0 ? (
              <Row>
                {selectedShipmentPallet && (
                  <Col xl={{ span: 4, order: "last" }}>
                    <ShipmentPalletActions
                      selectedDestination={selectedDestination}
                      selectedShipmentPallet={selectedShipmentPallet}
                      setSelectedShipmentPallet={setSelectedShipmentPallet}
                      submitting={submitting}
                      setSubmitting={setSubmitting}
                    />
                  </Col>
                )}
                <Col xl={8}>
                  <DestinationCenters
                    destinations={destinations}
                    setSelectedDestination={setSelectedDestination}
                    setSelectedShipmentPallet={setSelectedShipmentPallet}
                    handleScroll={handleScroll}
                  />
                  <div id="shipment-pallets">
                    {selectedDestination && (
                      <ShipmentPallets
                        submitting={submitting}
                        setSubmitting={setSubmitting}
                        setDestinations={setDestinations}
                        selectedDestination={selectedDestination}
                        setSelectedShipmentPallet={setSelectedShipmentPallet}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xxl={8}>
                  <Alert variant="warning">
                    <div className="alert-icon">
                      <Info
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message d-flex justify-content-between align-items-center">
                      <span>
                        There are currently no destination centers that have
                        shipments ready to ship.
                      </span>
                    </div>
                  </Alert>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ShipmentPalletsPage;
