import React from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";

import printService from "../../../../services/printService";

const DisplayListing = ({ currentItem }) => {
  const { processingData, itemData } = currentItem;
  const relevantDetails = [
    { label: "Item Name", value: itemData.itemName },
    { label: "Shipment Box", value: processingData.shipmentBoxId },
    { label: "LPN", value: processingData.lpn?.trim().toUpperCase() },
    {
      label: "ASIN",
      value:
        processingData.receivedAsin.trim().toUpperCase() ||
        processingData.asin.trim().toUpperCase() ||
        "No ASIN...",
    },
    { label: "FNSKU", value: processingData.fnsku?.trim().toUpperCase() },
    { label: "Vendor Name", value: processingData.vendorName },
    { label: "Listed On", value: processingData.listingAccount },
    { label: "Shipping Size", value: processingData.shipmentCategoryLabel },
    { label: "Selling Condition", value: processingData.inspectCondition },
    { label: "Quantity", value: processingData.listedQuantity },
    { label: "Lister Name", value: processingData.listedBy },
    {
      label: "Listing End Time",
      value: new Date(processingData.listedEndTime).toLocaleString(),
    },
  ];

  const handlePrint = (labelType) => {
    if (labelType === "fnsku") {
      const labelData = {
        fnsku: processingData.fnsku,
        title:
          itemData.itemName || `No Title... ${String.fromCodePoint(0x1f631)}`,
        condition: processingData.amazonListingConditionLabel,
        sku: processingData.sku,
        account: processingData.listingAccount,
        quantity: processingData.listedQuantity,
        listingId: processingData.listedId,
      };

      printService.printListingLabel(labelData);
    } else {
      const labelData = {
        shipmentBoxId: processingData.shipmentBoxId,
        shipmentCategory: processingData.shipmentCategoryLabel,
        listingAccount: processingData.listingAccount,
      };

      printService.printBoxLabel(labelData);
    }
  };

  return (
    <Row className="justify-content-center">
      <Col xl={6}>
        <Card>
          <Card.Header className="d-flex justify-content-start">
            <FontAwesomeIcon icon={faInfoCircle} size="3x" className="me-3" />
            <div>
              <Card.Title>Listing Item Info</Card.Title>
              <Card.Subtitle>
                Reprint box labels, FNSKU labels and view details about a
                previously listed item.
              </Card.Subtitle>
            </div>
          </Card.Header>
          <Card.Body>
            <ListGroup variant="flush">
              {relevantDetails
                .filter((detail) => detail.value !== "")
                .map((detail, index) => {
                  if (detail.value) {
                    return (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between"
                      >
                        <div className="text-nowrap me-5">
                          <strong>{detail.label}</strong>
                        </div>
                        <div className="ms-5">{detail.value}</div>
                      </ListGroupItem>
                    );
                  } else {
                    return null;
                  }
                })}
            </ListGroup>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <Button
                  className="me-3"
                  variant="primary"
                  onClick={() => {
                    handlePrint("fnsku");
                  }}
                >
                  Print FNSKU
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handlePrint("box");
                  }}
                >
                  Print Box Label
                </Button>
              </div>
              <Button
                variant="warning"
                onClick={() => (window.location = "/listing")}
              >
                Go Back
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DisplayListing;
