import React, { createContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button, Container } from "react-bootstrap";

import ModalGeneric from "../../components/ModalGeneric";
import DisplayListing from "./components/list/DisplayListing";
import LPNForm from "./components/list/LPNForm";
import ListingItem from "./components/list/ListingItem";

import listingService from "../../services/listingService";

export const ListingItemContext = createContext({
  currentItem: {},
  setCurrentItem: () => {},
  isLoading: false,
  setIsLoading: () => {},
  listingOptions: {},
  setListingOptions: () => {},
  listingRequirements: {},
  setListingRequirements: () => {},
  error: {},
  setError: () => {},
  flags: [],
  setFlags: () => {},
  previouslyListedLPN: false,
  setPreviouslyListedLPN: () => {},
});

const ListingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [listingOptions, setListingOptions] = useState();
  const [listingRequirements, setListingRequirements] = useState();
  const [error, setError] = useState();
  const [flags, setFlags] = useState([]);
  const [previouslyListedLPN, setPreviouslyListedLPN] = useState(false);
  const [isLookUp, setIsLookUp] = useState(true);

  const listingItemContext = useMemo(
    () => ({
      currentItem,
      setCurrentItem,
      isLoading,
      setIsLoading,
      listingOptions,
      setListingOptions,
      listingRequirements,
      setListingRequirements,
      error,
      setError,
      flags,
      setFlags,
      previouslyListedLPN,
      setPreviouslyListedLPN,
    }),
    [
      currentItem,
      setCurrentItem,
      isLoading,
      setIsLoading,
      listingOptions,
      setListingOptions,
      listingRequirements,
      setListingRequirements,
      error,
      setError,
      flags,
      setFlags,
      previouslyListedLPN,
      setPreviouslyListedLPN,
    ]
  );

  const { itemID: itemId } = useParams();

  useEffect(() => {
    const getItem = async () => {
      const response = await listingService
        .getItem({ itemId })
        .catch((error) => {
          console.error(error);
          setError(error.response.data.message);
          setIsLoading(false);
        });

      if (response) {
        setCurrentItem(response.processingItem);
      } else {
        window.location = "/404";
      }
    };

    if (itemId) {
      getItem();
    }
  }, [itemId]);

  useEffect(() => {
    const getFlags = async () => {
      const response = await listingService
        .getFlags({
          vendorId: currentItem.processingData.receivedVendorId,
          asin: currentItem.processingData.receivedAsin,
        })
        .catch((error) => {
          console.error(error);
        });

      if (response.length > 0) {
        setFlags(response);
      }
    };

    if (currentItem && currentItem.processingData.receivedVendorId) {
      getFlags();
    }

    if (currentItem?.processingData?.listedId) {
      setPreviouslyListedLPN(true);
    }
  }, [currentItem]);

  return (
    <ListingItemContext.Provider value={listingItemContext}>
      <Helmet title="Listing" />
      <h3 className="d-sm-block">Listing</h3>
      <Container fluid className="p-0">
        {currentItem?.processingData?.asinRule?.restrictProcessing === true && (
          <ModalGeneric
            showWithoutTrigger={true}
            showCloseButton={false}
            body={`This item should not be processed.`}
            title={`Please Note`}
          />
        )}
        {(itemId && currentItem) || (previouslyListedLPN && isLookUp) ? (
          <DisplayListing currentItem={currentItem} />
        ) : currentItem && listingOptions && listingRequirements ? (
          <>
            <ListingItem />
            <div className="d-flex justify-content-center">
              <Button size="sm" variant="danger" href="/listing">
                Start Over
              </Button>
            </div>
          </>
        ) : (
          <LPNForm isLookUp={isLookUp} setIsLookUp={setIsLookUp} />
        )}
      </Container>
    </ListingItemContext.Provider>
  );
};

export default ListingPage;
