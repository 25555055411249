import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import FormField from "./UIFormField";

export default function UIForm({ generateFormFields, ...formProps }) {
  //these are props that go into the Formik component
  // props  = {
  //   initialValues:{}
  //   validationSchema:{}
  //   onSubmit:{}
  // }

  return (
    <Formik {...formProps}>
      {({ errors }) => (
        <Form>
          {generateFormFields(errors).map((fieldProps) => (
            <FormField key={fieldProps.name} {...fieldProps} />
          ))}

          <Button type="submit">Submit</Button>
        </Form>
      )}
    </Formik>
  );
}
