import React from "react";
import { Card } from "react-bootstrap";

function UICard({ header, body, className, style }) {
  return (
    <Card className={className} style={style}>
      {header && (
        // <Card.Header style={{ backgroundColor: "darkgrey" }}>
        <Card.Header style={{ backgroundColor: "#E3E3E3" }}>
          {header}
        </Card.Header>
      )}
      <Card.Body>{body}</Card.Body>
    </Card>
  );
}

export default UICard;
