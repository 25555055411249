import React from "react";
import { Button } from "react-bootstrap";

function AmazonListingOption({ option, variant, handleSubmitListing }) {
  const { accountName, listingAccountId, fnsku, isPreferredAccount } = option;

  return (
    <div className="d-inline-block">
      <Button
        variant={`${!isPreferredAccount ? "outline-" : ""}${variant}`}
        className="m-2"
        onClick={() => {
          handleSubmitListing({
            listingAccountId: listingAccountId,
            listingAccount: accountName,
            fnsku: fnsku,
          });
        }}
      >
        {accountName}
      </Button>
    </div>
  );
}

export default AmazonListingOption;
