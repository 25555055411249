import React, { useState } from "react";
import ClothingListingOption from "./ClothingListingOption";
import { Card, Button } from "react-bootstrap";
import { Loader } from "react-feather";

const ClothingDataList = ({ clothingData, setClothingData }) => {
  const [submitting, setSubmitting] = useState(false);

  const handleStartOver = () => {
    setClothingData();
  };
  return submitting ? (
    <Loader />
  ) : (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between border-bottom">
          {clothingData.length ? (
            <div>
              <Card.Title>{clothingData[0].brand}</Card.Title>
              <Card.Subtitle>
                {clothingData[0].style} | {clothingData[0].color} |{" "}
                {clothingData[0].size}
              </Card.Subtitle>
            </div>
          ) : (
            "There are no clothing listings for these options. Please try again"
          )}

          <div>
            <Button variant={"danger"} onClick={handleStartOver}>
              Start Over
            </Button>
          </div>
        </Card.Header>
        {clothingData.length ? (
          <div className="row">
            {clothingData.map((clothingListingOption, index) => {
              return (
                <div key={index} className="col-3">
                  <ClothingListingOption
                    clothingListingOption={clothingListingOption}
                    setSubmitting={setSubmitting}
                    handleStartOver={handleStartOver}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </Card>
    </>
  );
};

export default ClothingDataList;
