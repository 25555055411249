import { faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Card } from "react-bootstrap";

import Table from "../.././../../components/tables/Table";

const DestinationCenters = ({
  destinations,
  setSelectedDestination,
  setSelectedShipmentPallet,
  handleScroll,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Destination Center ID",
        accessor: "destinationCenterId",
      },
      {
        Header: "City",
        accessor: "shipToCity",
      },
      {
        Header: "State",
        accessor: "shipToState",
      },
      {
        Header: "Total Pallets",
        accessor: "totalPallets",
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    setSelectedDestination(row.original);
    setSelectedShipmentPallet();
    handleScroll("shipment-pallets");
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faWarehouse} size="3x" className="me-3" />
          <div>
            <Card.Title>Destination Centers</Card.Title>
            <Card.Subtitle>
              All of the destination centers that currently have shipments ready
              to ship.
            </Card.Subtitle>
          </div>
        </div>
      </Card.Header>
      <Card.Body style={{ overflow: "auto" }}>
        <Table
          columns={columns}
          data={destinations}
          onRowClick={handleRowClick}
        />
      </Card.Body>
    </Card>
  );
};

export default DestinationCenters;
