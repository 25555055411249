import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
//import axios from "./axios";
import axios from "axios";

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // const decoded = jwtDecode(accessToken);
  // console.log(decoded);
  // const currentTime = Date.now();
  // console.log(currentTime);
  // console.log(decoded.exp > currentTime);
  //THIS MUST BE CHANGED TO ACTUALLY VALIDATE THE TOKEN
  // return decoded.exp > currentTime;
  return true;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (accessToken, refreshToken) => {
  if (accessToken && refreshToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common.RefreshToken = `RefreshToken ${refreshToken}`;

    const decoded = jwtDecode(accessToken);
    const user = decoded.user || decoded;

    localStorage.setItem("user", JSON.stringify(user));

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.RefreshToken;
  }
};

export { verify, sign, isValidToken, setSession };
