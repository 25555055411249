import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { AlertCircle } from "react-feather";
import toast from "react-hot-toast";

import shipmentService from "../../../../services/shipmentService";

const FnskuForm = ({
  submitting,
  setSubmitting,
  fnsku,
  setFnsku,
  setDesignatedBay,
  quantity,
  setQuantity,
  fnskuInputElementRef,
  rackIdInputElementRef,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;
  const [errorMessage, setErrorMessage] = useState();

  // This useEffect prevents someone from submitting a different fnsku
  // to rack item creation than the one that was searched.
  useEffect(() => {
    setDesignatedBay();
  }, [fnsku]);

  const handleSubmit = async () => {
    if (!fnsku) {
      toast.error("Please enter an FNSKU.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (fnsku.toUpperCase() === "W") {
      toast.error("Please re-scan the FNSKU or enter it manually.");
      setFnsku("");
      return;
    }

    setSubmitting(true);
    setDesignatedBay();
    setErrorMessage();

    const result = await shipmentService.getDesignatedBay({ fnsku, userId });

    if (result.success) {
      if (result.consolidationBay) {
        toast.success("Designated bay successfully found!", { duration: 5000 });
        setDesignatedBay(result.consolidationBay);
        setSubmitting(false);
        if (rackIdInputElementRef.current) {
          rackIdInputElementRef.current.focus();
        }
      } else {
        toast.error("Designated bay not found!", { duration: 5000 });

        const message = (
          <span>
            FNSKU: <strong>{fnsku}</strong> was not found to have a designated
            consolidation bay. Please put the item aside and contact a manager
            for help.
          </span>
        );
        setErrorMessage(message);
        setSubmitting(false);
        if (fnskuInputElementRef.current) {
          fnskuInputElementRef.current.focus();
        }
      }
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setErrorMessage(result.message);
      setSubmitting(false);
    }
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start">
        <FontAwesomeIcon icon={faBarcode} size="3x" className="me-3" />
        <div>
          <Card.Title>FNSKU</Card.Title>
          <Card.Subtitle>
            Scan or enter an item's FNSKU to get it's designated consolidation
            bay.
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        {errorMessage && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertCircle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">{errorMessage}</div>
          </Alert>
        )}
        <Row>
          <Col sm={2}>
            <Form.Label className="mb-0">Quantity</Form.Label>
            <Form.Control
              required
              value={quantity}
              size="lg"
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Col>

          <Col sm={10}>
            <Form.Label className="mb-0">FNSKU</Form.Label>
            <InputGroup>
              <Form.Control
                required
                autoFocus
                size="lg"
                ref={fnskuInputElementRef}
                placeholder="Scan or Enter FNSKU..."
                value={fnsku}
                onChange={(e) => setFnsku(e.target.value)}
                onFocus={(e) => {
                  e.target.select();
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                disabled={submitting}
              />

              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={submitting}
              >
                {submitting ? (
                  <span>
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                    Submitting...
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FnskuForm;
