import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { Check, ChevronDown, ChevronRight, X } from "react-feather";

import ItemLookupResultTable from "./ItemLookupResultTable";

import { ItemLookupContext } from "../../ItemLookupPage";

const ItemLookupResult = () => {
  const { queryResult, queryValue, queryType } = useContext(ItemLookupContext);
  const [headerValue, setHeaderValue] = useState();

  useEffect(() => {
    setHeaderValue(queryValue);
  }, [queryResult]);

  const columns = useMemo(
    () => [
      {
        // Expander cell
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ChevronDown /> : <ChevronRight />}
          </span>
        ),
        disableSortBy: true,
      },
      {
        Header: "LPN",
        accessor: "lpn",
      },
      {
        Header: "ASIN",
        accessor: "receivedAsin",
      },
      {
        Header: "FNSKU",
        accessor: "fnsku",
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Received",
        accessor: (row) => {
          if (row.receivedId) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        Cell: (row) => <div className="text-center">{row.value}</div>,
        sortType: (row) => (row.original.receivedId ? -1 : 1),
      },
      {
        Header: "Inspected",
        accessor: (row) => {
          if (row.inspectedId) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        Cell: (row) => <div className="text-center">{row.value}</div>,
        sortType: (row) => (row.original.inspectedId ? -1 : 1),
      },
      {
        Header: "Listed",
        accessor: (row) => {
          if (row.listedId) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        Cell: (row) => <div className="text-center">{row.value}</div>,
        sortType: (row) => (row.original.listedId ? -1 : 1),
      },
      {
        Header: "Shipment Box ID",
        accessor: "shipmentBoxId",
      },
    ],
    []
  );

  const scColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "Date",
      },
      {
        Header: "SKU",
        accessor: "SKU",
      },
      {
        Header: "Quantity",
        accessor: "Quantity",
      },
      {
        Header: "Price",
        accessor: "Price",
      },
      {
        Header: "Condition",
        accessor: "Condition",
      },
      {
        Header: "ASIN",
        accessor: "ASIN",
      },
    ],
    []
  );

  return (
    <Card>
      <Card.Header className="text-center">
        <Card.Title>
          <h3>{`Result for ${headerValue}`}</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body style={{ overflow: "auto" }}>
        <ItemLookupResultTable
          columns={queryType.value <= 5 ? columns : scColumns}
          data={queryResult}
        />
      </Card.Body>
    </Card>
  );
};

export default ItemLookupResult;
