import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  FormGroup,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import toast from "react-hot-toast";

import Select from "react-select";

import axios from "axios";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const OptionsSelect = ({ clothingOptions, setClothingData }) => {
  const [brand, setBrand] = useState({});
  const [styles, setStyles] = useState({});
  const [style, setStyle] = useState({});
  const [colors, setColors] = useState({});
  const [color, setColor] = useState({});
  const [sizes, setSizes] = useState({});
  const [size, setSize] = useState({});
  const [keyword, setKeyword] = useState();

  useEffect(() => {
    localStorage.setItem(
      "selectedClothingOptions",
      JSON.stringify({ brand, style, color, size })
    );
  }, [brand, style, color, size]);

  const handleSubmitClothingLookup = async () => {
    if (!brand || !style || !color || !size) {
      toast.error("Please select a valid option.");
      return;
    }

    const clothingDataResult = (
      await axios.get(
        `${
          process.env.REACT_APP_HOST_URL
        }/listing/getClothingData?brand=${encodeURIComponent(
          brand.value
        )}&style=${encodeURIComponent(style.value)}&color=${encodeURIComponent(
          color.value
        )}&size=${encodeURIComponent(size.value)}${
          keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
        }`
      )
    ).data;

    console.log(clothingDataResult);

    setClothingData(clothingDataResult.clothingData);
  };

  const brandOptions = clothingOptions.map((option) => {
    if (!option.brand) return { value: "", label: "No Brand" };
    return { value: option.brand, label: option.brand };
  });

  useEffect(() => {
    setBrand(brandOptions[0]);
  }, []);

  useEffect(() => {
    if (!brand.value) return;
    const brandStyles = clothingOptions
      .filter((option) => option.brand === brand.value)[0]
      .styles.map((option) => {
        if (!option.style) return { value: "", label: "No Style" };
        return { value: option.style, label: option.style };
      });
    setStyles([{ value: "all", label: "All Styles" }, ...brandStyles]);
    setStyle(brandStyles[0]);
  }, [brand]);

  useEffect(() => {
    if (!style.value) return;
    const styleOptions = clothingOptions
      .filter((option) => option.brand === brand.value)[0]
      .styles.filter((option) => option.style === style.value)[0];

    if (!styleOptions) {
      setColors({});
      setColor({});
      setSizes({});
      setSize({});
      return;
    }
    const styleColors = styleOptions.colors.map((option) => {
      if (!option.color) {
        return { value: "", label: "No Color" };
      }
      return { value: option.color, label: option.color };
    });
    setColors([{ value: "all", label: "All Colors" }, ...styleColors]);
    setColor(styleColors[0]);
  }, [style]);

  useEffect(() => {
    if (!color.label) return;
    const colorOptions = clothingOptions
      .filter((option) => option.brand === brand.value)[0]
      .styles.filter((option) => option.style === style.value)[0]
      .colors.filter((option) => option.color === color.value)[0];

    if (!colorOptions) {
      setSizes({});
      setSize({});
      return;
    }

    const colorSizes = colorOptions.sizes.map((option) => {
      if (!option.size) return { value: "", label: "No Size" };
      return { value: option.size, label: option.size };
    });
    setSizes([{ value: "all", label: "All Sizes" }, ...colorSizes]);
    setSize(colorSizes[0]);
  }, [color]);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start border-bottom">
        <FontAwesomeIcon icon={faClipboardCheck} size="3x" className="me-3" />
        <div>
          <Card.Title>Brand Select</Card.Title>
          <Card.Subtitle>
            Select a Brand to create a listings for.
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        <Form noValidate>
          <Form.Label>Brands</Form.Label>
          <FormGroup className="mb-3 d-flex">
            <div className="react-select form-control p-0">
              <Select
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    margin: "-1px",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "4px 0 0 4px",
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select a Brand..."
                onChange={(selectedOption) => {
                  setBrand(selectedOption);
                }}
                value={brand}
                // value={brand.value ? brand : brandOptions[0]}
                options={brandOptions}
                isSearchable
                isLoading={!brandOptions}
              />
            </div>
          </FormGroup>
          {brand.label && styles.length && (
            <>
              <Form.Label>Styles</Form.Label>
              <FormGroup className="mb-3 d-flex">
                <div className="react-select form-control p-0">
                  <Select
                    styles={{
                      container: (baseStyles) => ({
                        ...baseStyles,
                        margin: "-1px",
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "4px 0 0 4px",
                      }),
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select a Style..."
                    onChange={(selectedOption) => {
                      setStyle(selectedOption);
                    }}
                    // value={style.value}
                    value={style.value ? style : styles[0]}
                    options={styles}
                    isSearchable
                    isLoading={!styles.length}
                  />
                </div>
              </FormGroup>
            </>
          )}
          {brand.label && style.label && colors.length && (
            <>
              <Form.Label>Colors</Form.Label>
              <FormGroup className="mb-3 d-flex">
                <div className="react-select form-control p-0">
                  <Select
                    styles={{
                      container: (baseStyles) => ({
                        ...baseStyles,
                        margin: "-1px",
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "4px 0 0 4px",
                      }),
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select a Color..."
                    onChange={(selectedOption) => {
                      setColor(selectedOption);
                    }}
                    value={color.value ? color : colors[0]}
                    options={colors}
                    isSearchable
                    isLoading={!colors}
                  />
                </div>
              </FormGroup>
            </>
          )}
          {brand.label && style.label && color.label && sizes.length && (
            <>
              <Form.Label>Sizes</Form.Label>
              <FormGroup className="mb-3 d-flex">
                <div className="react-select form-control p-0">
                  <Select
                    styles={{
                      container: (baseStyles) => ({
                        ...baseStyles,
                        margin: "-1px",
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "4px 0 0 4px",
                      }),
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select a Size..."
                    onChange={(selectedOption) => {
                      setSize(selectedOption);
                    }}
                    value={size.value ? size : sizes[0]}
                    options={sizes}
                    isSearchable
                    isLoading={!sizes}
                  />
                </div>
              </FormGroup>
            </>
          )}
          <>
            <Form.Label>Keyword</Form.Label>
            <FormGroup className="mb-3 d-flex">
              <div className="react-select form-control p-0">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Enter Keyword..."
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </FormGroup>
          </>
          <Button
            className="d-inline"
            variant="primary"
            disabled={!brand || !style || !color || !size}
            onClick={handleSubmitClothingLookup}
          >
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default OptionsSelect;
