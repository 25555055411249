import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Alert, Button, Card, Container } from "react-bootstrap";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../../src/constants";

import useTheme from "../../../src/hooks/useTheme";
import useSidebar from "../../../src/hooks/useSidebar";
import useLayout from "../../../src/hooks/useLayout";

const themeOptions = [
  {
    name: "Default",
    value: THEME.DEFAULT,
  },
  {
    name: "Colored",
    value: THEME.COLORED,
  },
  {
    name: "Dark",
    value: THEME.DARK,
  },
  {
    name: "Light",
    value: THEME.LIGHT,
  },
];

const sidebarPositionOptions = [
  {
    name: "Left",
    value: SIDEBAR_POSITION.LEFT,
  },
  {
    name: "Right",
    value: SIDEBAR_POSITION.RIGHT,
  },
];

const sidebarBehaviorOptions = [
  {
    name: "Sticky",
    value: SIDEBAR_BEHAVIOR.STICKY,
  },
  {
    name: "Fixed",
    value: SIDEBAR_BEHAVIOR.FIXED,
  },
  {
    name: "Compact",
    value: SIDEBAR_BEHAVIOR.COMPACT,
  },
];

const layoutOptions = [
  {
    name: "Fluid",
    value: LAYOUT.FLUID,
  },
  {
    name: "Boxed",
    value: LAYOUT.BOXED,
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Settings = () => {
  const query = useQuery();
  const { theme, setTheme } = useTheme();
  const { position, setPosition, behavior, setBehavior } = useSidebar();
  const { layout, setLayout } = useLayout();

  const setSettingByQueryParam = (name, set) => {
    const value = query.get(name);
    if (value) {
      set(value);
    }
  };

  // Read from query parameter (e.g. ?theme=dark)
  // only for demo purposes
  useEffect(() => {
    setSettingByQueryParam("theme", setTheme);
    setSettingByQueryParam("sidebarPosition", setPosition);
    setSettingByQueryParam("sidebarBehavior", setBehavior);
    setSettingByQueryParam("layout", setLayout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Card>
          <Card.Header>
            <div>Settings</div>
            <br />
            <Alert variant="primary">
              <div className="alert-message">
                <strong>Hey there!</strong> Set your own customized style below.
                Choose the ones that best fits your needs.
              </div>
            </Alert>
          </Card.Header>
          <Card.Body>
            <div className="settings-content">
              <div className="settings-body">
                <div className="mb-3">
                  <span className="d-block font-size-lg fw-bold">
                    Color scheme
                  </span>
                  <span className="d-block text-muted mb-2">
                    The perfect color mode for your app.
                  </span>
                  <div className="row g-0 text-center mx-n1 mb-2">
                    {themeOptions.map(({ name, value }) => (
                      <div className="col-6" key={value}>
                        <label className="mx-1 d-block mb-1">
                          <input
                            className="settings-scheme-label"
                            type="radio"
                            name="theme"
                            value={value}
                            checked={theme === value}
                            onChange={() => setTheme(value)}
                          />
                          <div className="settings-scheme">
                            <div
                              className={`settings-scheme-theme settings-scheme-theme-${value}`}
                            ></div>
                          </div>
                        </label>
                        {name}
                      </div>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="mb-3">
                  <span className="d-block font-size-lg fw-bold">
                    Sidebar position
                  </span>
                  <span className="d-block text-muted mb-2">
                    Toggle the position of the sidebar.
                  </span>
                  <div>
                    {sidebarPositionOptions.map(({ name, value }) => (
                      <label className="me-1" key={value}>
                        <input
                          className="settings-button-label"
                          type="radio"
                          name="sidebarPosition"
                          value={value}
                          checked={position === value}
                          onChange={() => setPosition(value)}
                        />
                        <div className="settings-button">{name}</div>
                      </label>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="mb-3">
                  <span className="d-block font-size-lg fw-bold">
                    Sidebar behavior
                  </span>
                  <span className="d-block text-muted mb-2">
                    Change the behavior of the sidebar.
                  </span>
                  <div>
                    {sidebarBehaviorOptions.map(({ name, value }) => (
                      <label className="me-1" key={value}>
                        <input
                          className="settings-button-label"
                          type="radio"
                          name="sidebarBehavior"
                          value={value}
                          checked={behavior === value}
                          onChange={() => setBehavior(value)}
                        />
                        <div className="settings-button">{name}</div>
                      </label>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="mb-3">
                  <span className="d-block font-size-lg fw-bold">Layout</span>
                  <span className="d-block text-muted mb-2">
                    Toggle container layout system.
                  </span>
                  <div>
                    {layoutOptions.map(({ name, value }) => (
                      <label className="me-1" key={value}>
                        <input
                          className="settings-button-label"
                          type="radio"
                          name="layout"
                          value={value}
                          checked={layout === value}
                          onChange={() => setLayout(value)}
                        />
                        <div className="settings-button">{name}</div>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Settings;
