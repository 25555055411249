import axios from "axios";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getRestrictions = async () => {
  const response = (await axios.get(`${HOST_URL}/management/restrictions`)).data
    .restrictions;

  return response;
};

const deleteRestriction = async (data) => {
  const response = (
    await axios.delete(
      `${HOST_URL}/management/restrictions?asin=${data.asin}&conditionId=${data.conditionId}&sellingPartnerId=${data.sellingPartnerId}`
    )
  ).data;

  return response;
};

const services = {
  getRestrictions,
  deleteRestriction,
};

export default services;
