import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";

const DownloadCSV = ({ headers, data, filename }) => {
  //add space before commas in the data
  const cleanData = data.map((row) => {
    const newRow = {};
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === "string") {
        newRow[key] = row[key].replace(/[,"]/g, ", ");
      } else {
        newRow[key] = row[key];
      }
    });
    return newRow;
  });

  const CSVHeaders = headers.map((column) => ({
    label: column.Header,
    key: column.accessor || column.id,
  }));

  for (const property in data[0]) {
    !CSVHeaders.find((header) => header.key === property) &&
      CSVHeaders.push({ label: property, key: property });
  }

  return (
    <CSVLink
      title="Download CSV"
      data={cleanData}
      headers={CSVHeaders}
      filename={filename.replaceAll(" ", "")}
    >
      <FontAwesomeIcon icon={faFileCsv} size="3x" />
    </CSVLink>
  );
};
export default DownloadCSV;
