import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Container, Card, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const RECEIVEDDISPOSITIONS = ["Received", "Accepted", "Trash", "Liquidated"];
const INSPECTEDDISPOSITIONS = ["Amazon", "Ebay", "Trash", "Liquidated"];
const LISTEDDISPOSITIONS = [
  "Products 360",
  "Refurb360",
  "ClassicGifts",
  "KiwiBanana",
  "FunTimeSales",
  "LemonExplosion",
  "NonAmazon",
  "Liquidated",
];

const LISTEDSTORES = [
  "Products 360",
  "Refurb 360",
  "Classic Gifts",
  "Kiwi Banana",
  "FunTime Sales",
  "Lemon Explosion",
  "Ebay",
  "Liquidated",
];

function getDispositionString(type, disposition) {
  switch (type) {
    case "receiving":
      return RECEIVEDDISPOSITIONS[disposition - 1];
    case "inspecting":
      return INSPECTEDDISPOSITIONS[disposition - 1];
    case "listing":
      const index = LISTEDDISPOSITIONS.indexOf(disposition);
      return LISTEDSTORES[index];
    default:
      return "";
  }
}

function getTimeDifference(previous, current) {
  const milliseconds = Math.abs(new Date(current) - new Date(previous));

  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  if (hours === 0 && minutes === 0 && seconds === 0) {
    return { timeDifferenceString: "", color: "" };
  }

  const timeDifferenceString = `${hours > 0 ? hours + "h " : ""} ${
    minutes > 0 || (hours > 0 && seconds > 0) ? minutes + "m " : ""
  }  ${seconds > 0 ? seconds + "s " : ""}`;

  const color = minutes >= 5 ? "red" : "green";

  return {
    timeDifferenceString,
    color,
  };
}

function EmployeeActivity({}) {
  const { type, userID, name, queryDate } = useParams();
  const [recentEmployeeActivity, setRecentEmployeeActivity] = useState([]);
  const [date, setDate] = useState(queryDate);
  const [dateInput, setDateInput] = useState(queryDate);

  const getRecentEmployeeActivity = async () => {
    const recentEmployeeActivity = (
      await axios.get(`${HOST_URL}/employee/${type}/${dateInput}/${userID}`)
    ).data;
    setRecentEmployeeActivity(recentEmployeeActivity.activity);
  };

  useEffect(() => {
    getRecentEmployeeActivity();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Recent Employee Activity" />
      <Container fluid className="p-0">
        <Card>
          <Card.Body className="mb-5 text-center">
            <h2 className="mt-3">{name}</h2>
            <Col>
              <label className="mx-2">Choose a date:</label>
              <input
                type="date"
                defaultValue={
                  date != ""
                    ? new Date(date).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
                onChange={(e) => setDateInput(e.target.value)}
              />
              <Button
                className="mx-2"
                onClick={() => {
                  setDate(dateInput);
                  getRecentEmployeeActivity();
                }}
              >
                Go
              </Button>
            </Col>

            <h4 className="mt-3">
              {type.toLocaleUpperCase()} - {new Date(date).toLocaleDateString()}
            </h4>
            {recentEmployeeActivity.length > 0 ? (
              <Table striped bordered hover className="w-75 mx-auto mt-3">
                <thead>
                  <tr>
                    <th>Date & Time</th>
                    <th>{type === "listing" ? "Store" : "Disposition"}</th>
                    <th>LPN</th>
                    <th>ASIN</th>
                    <th>Quantity</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {recentEmployeeActivity.map((event, index) => {
                    const { date, disposition, itemID, LPN, ASIN, quantity } =
                      event;
                    let timeDifferenceString, color;

                    if (index < recentEmployeeActivity.length - 1) {
                      ({ timeDifferenceString, color } = getTimeDifference(
                        date,
                        recentEmployeeActivity[index + 1].date
                      ));
                    }

                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <strong>
                            {new Date(date).toLocaleString()}
                            <br />
                            {timeDifferenceString ? (
                              <>
                                <span style={{ color: `${color}` }}>
                                  {timeDifferenceString}
                                </span>

                                <span>(since prev.) </span>
                              </>
                            ) : (
                              ""
                            )}
                          </strong>
                        </td>
                        <td>{getDispositionString(type, disposition)}</td>
                        <td>{LPN}</td>
                        <td>
                          <a
                            target="_blank"
                            href={`https://www.amazon.com/dp/${ASIN}?th=1&psc=1`}
                          >
                            {ASIN}
                          </a>
                        </td>
                        <td>{quantity}</td>
                        <td>
                          <Button
                            onClick={() => {
                              window.open(`/${type}/${itemID}`);
                            }}
                          >
                            View This Activity
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              "No recent activity"
            )}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default EmployeeActivity;
