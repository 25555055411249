import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import StationSelect from "../StationSelect";

import useAuth from "../../hooks/useAuth";

function SignIn() {
  const [selectedStation, setSelectedStation] = useState();
  const { signIn } = useAuth();

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Formik
      initialValues={{
        email: user?.username,
        password: "",
        // stationId: selectedStation,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        // email: Yup.string()
        //   .email("Must be a valid email")
        //   .max(255)
        //   .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (selectedStation) {
            values.stationId = selectedStation;
          }
          await signIn(values.email, values.password, values.stationId);

          window.location = "/";
        } catch (error) {
          const message = error.response.data.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              size="lg"
              //type="email"
              name="email"
              placeholder="Enter your username"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
              autoFocus={!values.email}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Enter your password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
              autoFocus={values.email}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            {/* <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small> */}
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label>Station</Form.Label>
            <StationSelect
              name="stationId"
              setSelectedStation={setSelectedStation}
            />
            {!!touched.stationId && (
              <Form.Control.Feedback type="invalid">
                {errors.stationId}
              </Form.Control.Feedback>
            )}
          </Form.Group> */}

          {/* <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              label="Remember me next time"
              defaultChecked
            />
          </div> */}

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
