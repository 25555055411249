import { X, Check } from "react-feather";
import { Image, Button } from "react-bootstrap";
import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../../../components/tables/DateRangeFilter";
import imageNotFound from "../../../../assets/img/imageNotFound.png";

import { handleFound, handleNotFound } from "../../FulfillmentPicklistPage";

const tableColumns = [
  {
    Header: "Picked",
    accessor: "Picked",
    Cell: ({ value }) => {
      if (value) {
        return (
          <div className="text-center">
            <Check color="#4BBF73" size={24} />
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <X color="#d9534f" size={24} />
          </div>
        );
      }
    },
    sortType: (row) => (row.original.Picked ? -1 : 1),
  },
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => {
      const image = row.images ? row.images[0] : null;
      const asin = row.sku?.split("-")[0];

      return (
        <a
          href={`https://www.amazon.com/dp/${asin}?th=1&psc=1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            thumbnail
            style={{ maxHeight: "75px" }}
            src={image ? image : imageNotFound}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = imageNotFound;
            }}
          />
        </a>
      );
    },
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value }) => (
      <div
        style={{
          maxHeight: "3.6em", // Adjust the value to control the number of lines before ellipsis
          lineHeight: "1.2em", // Adjust the line height for proper spacing
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 3, // Adjust the number of lines to show before ellipsis
          WebkitBoxOrient: "vertical",
        }}
      >
        {value}
      </div>
    ),
  },
  {
    Header: "Channel",
    accessor: "channel",
  },
  {
    Header: "Order Number",
    accessor: "orderNumber",
    Cell: ({ row, value }) => {
      return row.original.channel === "EBAY" ? (
        <a
          href={`https://www.ebay.com/mesh/ord/details?orderid=${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      ) : (
        value
      );
    },
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Quantity Ordered",
    accessor: "quantityOrdered",
  },
  {
    Header: "Quantity Allocated",
    accessor: "quantityAllocated",
  },
  {
    Header: "Bin Location",
    accessor: "binLocation",
  },
  {
    Header: "Ship By",
    accessor: "shipByDate",
    Cell: ({ value }) => {
      return <span>{value ? new Date(value).toLocaleDateString() : ""}</span>;
    },
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Mark As",
    accessor: (row) => {
      return (
        <div className="d-flex">
          <Button
            variant="success"
            onClick={() => handleFound(row)}
            disabled={!row.AllocationRowID}
          >
            Found
          </Button>
          <Button
            className="ms-3"
            variant="warning"
            onClick={() => handleNotFound(row)}
            disabled={!row.AllocationRowID}
          >
            Not Found
          </Button>
        </div>
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
];

export default tableColumns;
