import axios from "axios";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getItem = async ({ queryType, queryValue }) => {
  const response = (
    await axios.get(
      `${HOST_URL}/warehouse/itemLookup?${`queryType=${queryType}`}&${`queryValue=${queryValue}`}`
    )
  ).data.queryResults;

  return response;
};

const services = {
  getItem,
};

export default services;
