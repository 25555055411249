import axios from "axios";
import { buildQueryString, handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

// For Testing Purposes
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//-----------------------------------------------------------------------------
// General Services
//-----------------------------------------------------------------------------

/**
 * Retrieves the shipment categories.
 * @async
 * @returns {Promise<any>} A promise that resolves to the shipment categories data.
 */
const getShipmentCategories = async () => {
  try {
    return (await axios.get(`${HOST_URL}/shipment/getShipmentCategories`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const getListingAccounts = async () => {
  return (await axios.get(`${HOST_URL}/utils/getListingAccounts`)).data
    .listingAccounts;
};

//-----------------------------------------------------------------------------
// Consolidation
//-----------------------------------------------------------------------------

/**
 * Retrieves consolidation bays based on the provided query parameters.
 * @async
 * @param {Object} [queryParams={}] - The query parameters object.
 * @param {number} [params.consolidationBayId] - The consolidation bay ID.
 * @param {number} [params.shipmentRackId] - The shipment rack ID.
 * @returns {Promise<any>} A promise that resolves to the consolidation bays data.
 */
const getConsolidationBays = async (queryParams = {}) => {
  const { consolidationBayId, shipmentRackId, areaId, userId } = queryParams;

  // Form the query string if parameters are passed.
  let queryString = "";
  if (consolidationBayId || shipmentRackId || areaId || userId) {
    queryString =
      "?" +
      buildQueryString({
        consolidationBayId,
        shipmentRackId,
        areaId,
        userId,
      });
  }

  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/consolidation/getConsolidationBays${queryString}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Retrieves consolidation bay areas.
 * @async
 
 * @returns {Promise<any>} A promise that resolves to the consolidation bay areas.
 */
const getAreaOptions = async () => {
  try {
    return (
      await axios.get(`${HOST_URL}/shipment/consolidation/getAreaOptions`)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Updates consolidation bays with the provided data.
 * @async
 * @param {Object[]} data - The array of consolidation bay objects to be updated.
 * @param {number} data[].consolidationBayId - The ID of the consolidation bay.
 * @param {number|null} [data[].shipmentRackId] - The ID of the shipment rack (optional, nullable).
 * @param {number|null} [data[].areaId] - The ID of the area (optional, nullable).
 * @returns {Promise<any>} A promise that resolves to the updated consolidation bays data.
 */
const updateConsolidationBays = async (data) => {
  try {
    return (
      await axios.put(
        `${HOST_URL}/shipment/consolidation/updateConsolidationBays`,
        data
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Retrieves the designated bay for a given FNSKU.
 * @async
 * @param {string} fnsku - The FNSKU (Fulfillment Network SKU) to search for.
 * @returns {Promise<any>} A promise that resolves to the designated bay data.
 */
const getDesignatedBay = async ({ fnsku, userId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/consolidation/searchFnsku/?fnsku=${fnsku}&userId=${userId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

//-----------------------------------------------------------------------------
// Create Shipment
//-----------------------------------------------------------------------------

/**
 * Creates shipments using the provided data.
 *
 * @async
 * @param {Object} data - The data object containing information required for creating shipment.
 * @param {number} data.listingAccountId - The ID of the listing account associated with the shipment.
 * @param {number[]} data.shipmentRackIds - An array of shipment rack IDs to create a shipment with.
 * @returns {Promise<Object>} A Promise that resolves to the response data from the server after creating shipments.
 */
const createShipmentPlans = async (data) => {
  try {
    return (
      await axios.post(
        `${HOST_URL}/shipment/createShipmentPlansFromRackIds`,
        data
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const createShipments = async (data) => {
  try {
    return (
      await axios.post(
        `${HOST_URL}/shipment/createShipmentsFromShipmentPlans`,
        data
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteShipmentPlan = async (shipmentId) => {
  try {
    return (
      await axios.delete(
        `${HOST_URL}/shipment/deleteShipmentPlan/${shipmentId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

//-----------------------------------------------------------------------------
// Build Shipment
//-----------------------------------------------------------------------------

/**
 * Fetches all shipment carton types from the server.
 *
 * @async
 * @returns {Promise<Array>} A promise that resolves to an array of shipment racks with items.
 */
const getShipmentCartonTypes = async () => {
  try {
    const shipmentCartonTypes = (
      await axios.get(`${HOST_URL}/shipment/getShipmentBoxTypes`)
    ).data;
    return { success: true, shipmentCartonTypes }; // Formatting the response to match the rest of the FE >:[
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Checks the eligibility of a shipment carton using the provided data.
 *
 * @async
 * @param {Object} data - An object containing properties for the shipment carton.
 * @param {number} data.shipmentId - The ID of the shipment.
 * @param {number} data.shipmentCategoryId - The ID of the shipment category.
 * @param {number} data.shipmentCartonId - The ID of the shipment carton to be checked for eligibility.
 * @returns {Promise<Object>} - A promise that resolves with the eligibility result data.
 */
const checkShipmentCartonEligibility = async (data) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/checkItemCartonEligibility`, data)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Retrieves eligible shipment cartons for a given user and shipment ID.
 *
 * @async
 * @function
 * @param {Object} queryParams - The query parameters for fetching eligible shipment cartons.
 * @param {number} queryParams.userId - The ID of the user for whom to retrieve eligible cartons.
 * @param {number} queryParams.shipmentId - The ID of the shipment for which to retrieve eligible cartons.
 * @returns {Promise<any>} - A Promise that resolves with the eligible shipment cartons.
 */
const getEligibleShipmentCartons = async (queryParams) => {
  const { userId, shipmentId } = queryParams;

  let queryString = "";
  if (userId || shipmentId) {
    queryString =
      "?" +
      buildQueryString({
        userId,
        shipmentId,
      });
  }
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getEligibleShipmentCartons${queryString}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Creates a new shipment carton using the provided data.
 *
 * @async
 * @param {Object} data - An object containing properties for creating the new shipment carton.
 * @param {number} data.userId - The ID of the user associated with the shipment carton.
 * @param {number} data.listingAccountId - The ID of the listing account related to the shipment carton.
 * @param {number} data.shipmentCategoryId - The ID of the shipment category for the new carton.
 * @param {number} data.shipmentId - The ID of the shipment associated with the carton.
 * @param {number} data.shipmentCartonTypeId - The ID of the shipment carton type.
 * @returns {Promise<Object>} - A promise that resolves with the created shipment carton data.
 */
const createNewShipmentCarton = async (data) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/createNewShipmentCarton`, data)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Closes a shipment carton using the provided carton ID.
 *
 * @async
 * @param {number} shipmentCartonId - The ID of the shipment carton to be closed.
 * @returns {Promise<Object>} - A promise that resolves with the data after closing the shipment carton.
 */
const closeShipmentCarton = async (shipmentCartonId) => {
  return (
    await axios.post(`${HOST_URL}/shipment/closeShipmentCarton`, {
      shipmentCartonId,
    })
  ).data;
};

/**
 * Adds an item to a shipment carton using the provided data.
 *
 * @async
 * @param {Object} data - An object containing properties for adding the item to the shipment carton.
 * @param {number} data.shipmentCartonId - The ID of the shipment carton where the item will be added.
 * @param {string} data.fnsku - The FNSKU (Fulfillment Network Stock Keeping Unit) of the item.
 * @param {number[]} data.shipmentRackItemIds - The IDs of the shipment rack items to be added to the carton.
 * @param {number} data.shipmentRackId - The ID of the shipment rack of the item to be added to the carton.
 * @param {number} data.shipmentId - The ID of the shipment of the item to be added to the carton.
 * @param {number} data.userId - The ID of the user adding the item to the carton.
 * @returns {Promise<Object>} - A promise that resolves with the data after adding the item to the shipment carton.
 */
const addToShipmentCarton = async (data) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/addItemToShipmentCarton`, data)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

//-----------------------------------------------------------------------------
// Shipment Pallets
//-----------------------------------------------------------------------------

/**
 * Retrieves all destination centers and the pallets going to those destinations.
 * Can send a shipmentStatusId to filter the results based on the shipment status.
 *
 * @async
 * @function
 * @param {Object} queryParams - An object containing query parameters.
 * @param {string} queryParams.shipmentStatusId - The ID of the shipment status.
 * @returns {Promise<Object>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */
const getDestinationCenters = async (queryParams = {}) => {
  const { shipmentStatusId } = queryParams;

  let queryString = "";
  if (shipmentStatusId) {
    queryString =
      "?" +
      buildQueryString({
        shipmentStatusId,
      });
  }

  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getDestinationCenters${queryString}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Creates a shipment pallet.
 *
 * @async
 * @function
 * @param {string} destinationCenterId - The ID of the destination center for the pallet.
 * @returns {Promise<Object>} A promise that resolves to the created shipment pallet.
 * @throws {Error} If an error occurs during the request.
 */
const createShipmentPallet = async (destinationCenterId) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/pallets`, {
        destinationCenterId,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const getEligibleShipmentPallets = async ({ shipmentCartonId }) => {
  console.log(shipmentCartonId);
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getEligibleShipmentPallets?shipmentCartonId=${shipmentCartonId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Adds a carton to a shipment pallet if the carton and pallet are going to the same
 * destination.
 *
 * @async
 * @function
 * @param {object} params - The parameters for adding a carton to a pallet.
 * @param {string} params.shipmentPalletId - The ID of the shipment pallet.
 * @param {string} params.shipmentCartonId - The ID of the shipment carton to add.
 * @returns {Promise} A promise that resolves when the carton is added to the pallet.
 * @throws {Error} If an error occurs during the request.
 */
const addCartonToShipmentPallet = async ({
  shipmentPalletId,
  shipmentCartonId,
}) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/addShipmentCartonToPallet`, {
        shipmentPalletId,
        shipmentCartonId,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

//-----------------------------------------------------------------------------
// Shipment Trailers
//-----------------------------------------------------------------------------

const getTrailers = async () => {
  return (await axios.get(`${HOST_URL}/shipment/getShipmentTrailers`)).data;
};

const getTrailerPallets = async ({ shipmentTrailerId }) => {
  return (
    await axios.get(
      `${HOST_URL}/shipment/getShipmentTrailerPallets?shipmentTrailerId=${shipmentTrailerId}`
    )
  ).data;
};

const assignTrailerToPallet = async (shipmentTrailer, palletId) => {
  return (
    await axios.post(`${HOST_URL}/shipment/addPalletToTrailer`, {
      shipmentTrailer,
      palletId,
    })
  ).data;
};

const createShipmentTrailer = async (
  shipmentTrailerType,
  destinationCenterIds
) => {
  return (
    await axios.post(`${HOST_URL}/shipment/createShipmentTrailer`, {
      shipmentTrailerType,
      destinationCenterIds,
    })
  ).data;
};

const editShipmentTrailer = async (
  shipmentTrailerId,
  shipmentTrailerStatus,
  destinationCenterIds
) => {
  return (
    await axios.post(`${HOST_URL}/shipment/editShipmentTrailer`, {
      shipmentTrailerId,
      shipmentTrailerStatus,
      destinationCenterIds,
    })
  ).data;
};

const getShipmentCartonPalletData = async ({ shipmentCartonId }) => {
  return (
    await axios.get(
      `${HOST_URL}/shipment/getShipmentCartonPalletData?shipmentCartonId=${shipmentCartonId}`
    )
  ).data;
};

const getNewPalletFormOptions = async () => {
  return (await axios.get(`${HOST_URL}/shipment/getNewPalletFormOptions`)).data;
};

//-----------------------------------------------------------------------------
// Shipment Racks
//-----------------------------------------------------------------------------

/**
 * Fetches all shipment racks with associated items from the server.
 *
 * @async
 * @returns {Promise<Array>} A promise that resolves to an array of shipment racks with items.
 */
const getShipmentRacksWithItems = async ({ shipmentRackStatusId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getShipmentRacksWithItems${
          shipmentRackStatusId
            ? `?shipmentRackStatusId=${shipmentRackStatusId}`
            : ""
        }`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const getShipmentRacksWithoutItems = async ({ shipmentRackStatusId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getShipmentRacksWithoutItems${
          shipmentRackStatusId
            ? `?shipmentRackStatusId=${shipmentRackStatusId}`
            : ""
        }`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const getShipmentRackItems = async ({ shipmentRackId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getShipmentRackItems?shipmentRackId=${shipmentRackId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Adds an item to a shipment rack.
 * @async
 * @param {Object} data - The data representing the item to be added to the rack.
 * @param {string} data.fnsku - The FNSKU (Fulfillment Network SKU) of the item.
 * @param {number} data.shipmentRackId - The ID of the rack to which the item will be added.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 */
const addItemToRack = async (data) => {
  try {
    return (await axios.post(`${HOST_URL}/shipment/addShipmentRackItem`, data))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const removeItemFromRack = async (shipmentRackItemId) => {
  try {
    return (
      await axios.delete(
        `${HOST_URL}/shipment/removeShipmentRackItem/${shipmentRackItemId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const changeRackToOversizeCategory = async (shipmentRackId) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/changeRackToOversize?shipmentRackId=${shipmentRackId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Retrieves shipments using an asynchronous operation.
 *
 * @async
 * @function
 * @returns {Promise<Array>} A promise that resolves to an array containing shipment data.
 * @throws {Error} If an error occurs during the retrieval process.
 */
const getShipments = async () => {
  try {
    return (await axios.get(`${HOST_URL}/shipment/getShipments`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const getShipmentStatusOptions = async () => {
  return (await axios.get(`${HOST_URL}/shipment/getShipmentStatusOptions`))
    .data;
};

//-----------------------------------------------------------------------------
// FBA Shipment Label
//-----------------------------------------------------------------------------

/**
 * Gets the URL for an FBA Shipment Carton Label.
 *
 * @async
 * @param {Object} data - An object containing properties for getting the FBA shipment label data.
 * @param {number} data.shipmentCartonId - The ID of the shipment carton for which to retrieve the FBA shipment label.
 * @returns {Promise<Object>} - A Promise that resolves with the FBA shipment label data.
 */
const getFbaShipmentLabelUrl = async (data) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/printFbaShipmentLabel`, data)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

//-----------------------------------------------------------------------------
// Shipments
//-----------------------------------------------------------------------------

/**
 * Retrieves shipment items associated with a specific shipment ID.
 *
 * @async
 * @function
 * @param {Object} options - Options for retrieving shipment items.
 * @param {number} options.shipmentId - The ID of the shipment for which to retrieve items.
 * @returns {Promise<Array>} A promise that resolves to an array of shipment item data.
 * @throws {Error} If an error occurs during the retrieval process.
 */
const getShipmentItems = async ({ shipmentId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getShipmentItems?shipmentId=${shipmentId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Closes a shipment with a given shipment ID.
 *
 * @async
 * @function
 * @param {Object} options - Options for closing a shipment.
 * @param {number} options.shipmentId - The ID of the shipment to be closed.
 * @returns {Promise<Object>} A promise that resolves to the response data indicating the status of the closure.
 * @throws {Error} If an error occurs during the closure process.
 */
const closeShipment = async ({ shipmentId }) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/closeShipment`, { shipmentId })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Removes shipment items from a shipment.
 *
 * @async
 * @function
 * @param {Object} options - Options for removing shipment items.
 * @param {number[]} options.shipmentItemIds - An array of IDs of the shipment items to be removed.
 * @returns {Promise<Object>} A promise that resolves to the response data indicating the status of the removal.
 * @throws {Error} If an error occurs during the removal process.
 */
const removeShipmentItems = async ({ shipmentItemIds }) => {
  console.log({ shipmentItemIds });
  // ENDPOINT NOT SET UP YET...
  try {
    // return (
    //   await axios.post(`${HOST_URL}/shipment/removeShipmentItems`, {
    //     shipmentItemIds,
    //   })
    // ).data;
    return { success: false };
  } catch (error) {
    return handleError(error);
  }
};

const getShipmentCartons = async (queryParams) => {
  const { shipmentCartonId, shipmentId } = queryParams;

  // Form the query string if parameters are passed.
  let queryString = "";
  if (shipmentCartonId || shipmentId) {
    queryString =
      "?" +
      buildQueryString({
        shipmentCartonId,
        shipmentId,
      });
  }
  try {
    return (
      await axios.get(`${HOST_URL}/shipment/getShipmentCartons${queryString}`)
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const getShipmentCartonForOversizeItem = async ({
  fnsku,
  shipmentId,
  shipmentRackId,
  userId,
}) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getShipmentCartonForOversizeItem?fnsku=${fnsku}&shipmentId=${shipmentId}&shipmentRackId=${shipmentRackId}&userId=${userId}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const rackFromBatch = async ({ batchId }) => {
  try {
    return (
      await axios.post(`${HOST_URL}/shipment/rackFromBatch`, {
        batchId,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const getDestinationWithMostItems = async ({
  startDate,
  endDate,
  sellingPartnerId,
  category,
}) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/shipment/getDestinationWithMostItems?startDate=${startDate}&endDate=${endDate}&sellingPartnerId=${sellingPartnerId}&shipmentCategory=${category}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  addCartonToShipmentPallet,
  addItemToRack,
  addToShipmentCarton,
  assignTrailerToPallet,
  checkShipmentCartonEligibility,
  closeShipment,
  closeShipmentCarton,
  createNewShipmentCarton,
  createShipmentPallet,
  createShipmentPlans,
  createShipments,
  createShipmentTrailer,
  editShipmentTrailer,
  getConsolidationBays,
  getDesignatedBay,
  getShipmentCartonTypes,
  getEligibleShipmentCartons,
  getFbaShipmentLabelUrl,
  getListingAccounts,
  getNewPalletFormOptions,
  getShipmentCartonPalletData,
  getShipmentCategories,
  getShipmentItems,
  getDestinationCenters,
  getShipmentRacksWithItems,
  getShipmentRacksWithoutItems,
  getShipmentRackItems,
  removeItemFromRack,
  getShipments,
  getShipmentStatusOptions,
  getTrailerPallets,
  getTrailers,
  removeShipmentItems,
  updateConsolidationBays,
  getShipmentCartons,
  getEligibleShipmentPallets,
  deleteShipmentPlan,
  getShipmentCartonForOversizeItem,
  changeRackToOversizeCategory,
  getAreaOptions,
  rackFromBatch,
  getDestinationWithMostItems,
};

export default services;
