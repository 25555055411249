import { faDollyFlatbed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Tab } from "react-bootstrap";
import { Info } from "react-feather";

import ShipmentRackTable from "./ShipmentRacksTable";
import tableColumns from "./tableColumns";

const ShipmentRacks = ({
  selectedAccount,
  accountShipmentRacks,
  accountShipmentCategories,
  setSelectedShipmentRacks,
  submitting,
  activeShipmentCategory,
  setActiveShipmentCategory,
}) => {
  const [filteredShipmentRacks, setFilteredShipmentRacks] = useState([]);

  // Reset activeShipmentCategory when account is selected.
  useEffect(() => {
    setActiveShipmentCategory();
  }, [selectedAccount]);

  // Filter the shipment racks by category when a category is selected.
  useEffect(() => {
    const categoryFilteredShipmentRacks = accountShipmentRacks.filter(
      (shipmentRack) =>
        shipmentRack.shipmentCategoryId === activeShipmentCategory
    );

    if (categoryFilteredShipmentRacks.length > 0) {
      setFilteredShipmentRacks(categoryFilteredShipmentRacks);
    }
  }, [activeShipmentCategory]);

  const handleRowClick = (row) => {
    // if oversize shipment, allow multiple racks to be selected
    if (activeShipmentCategory === 2) {
      row.toggleRowSelected();
    }
    // standard shipment, only allow one rack to be selected
    else {
      setSelectedShipmentRacks([row.original]);
    }
  };

  return (
    <Card>
      <Tab.Container activeKey={activeShipmentCategory}>
        <Card.Header>
          <div className="d-flex justify-content-start mb-3">
            <FontAwesomeIcon icon={faDollyFlatbed} size="3x" className="me-3" />
            <div>
              <Card.Title>Shipment Racks</Card.Title>
              <Card.Subtitle>
                Select a shipment category below to view{" "}
                <strong>{selectedAccount.listingAccount}</strong>'s shipment
                racks.
              </Card.Subtitle>
            </div>
          </div>
          <ButtonGroup className="d-flex">
            {accountShipmentCategories.map((category, index) => {
              return (
                <Button
                  key={index}
                  variant="outline-primary"
                  onClick={() => {
                    setActiveShipmentCategory(category.shipmentCategoryId);
                  }}
                  className={
                    activeShipmentCategory === category.shipmentCategoryId
                      ? "active"
                      : ""
                  }
                  disabled={submitting}
                >
                  {category.shipmentCategory}
                </Button>
              );
            })}
          </ButtonGroup>
        </Card.Header>
        <Tab.Content>
          {accountShipmentCategories.length > 0 ? (
            accountShipmentCategories.map((category, index) => {
              return (
                <Tab.Pane key={index} eventKey={category.shipmentCategoryId}>
                  <Card.Body>
                    {activeShipmentCategory && (
                      <ShipmentRackTable
                        key={activeShipmentCategory}
                        columns={tableColumns}
                        data={filteredShipmentRacks}
                        setSelectedRows={setSelectedShipmentRacks}
                        useCheckboxes={
                          activeShipmentCategory === 1 ? false : true
                        }
                        onRowClick={handleRowClick}
                        submitting={submitting}
                      />
                    )}
                  </Card.Body>
                </Tab.Pane>
              );
            })
          ) : (
            <Card.Body>
              <Alert variant="warning">
                <div className="alert-icon">
                  <Info
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message">
                  There doesn't seem to be any shipment racks for{" "}
                  <strong>{selectedAccount.listingAccount}</strong>. Try
                  selecting a different account to view current shipment racks.
                </div>
              </Alert>
            </Card.Body>
          )}
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default ShipmentRacks;
