import React, { useState } from "react";
import { flushSync } from "react-dom";

import { Alert, Button, Card } from "react-bootstrap";
import { Package, Info } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPallet, faCubes } from "@fortawesome/free-solid-svg-icons";
import Table from "../../../../components/tables/Table";
import Loader from "../../../../components/Loader";

const TrailerPallets = ({
  selectedTrailer,
  pallets,
  handleScroll,
  palletsLoading,
}) => {
  const handleRowClick = (row) => {
    handleScroll("box-items");
  };

  const columns = [
    {
      Header: "Pallet ID",
      accessor: "shipmentPalletId",
    },
    {
      Header: "Destination Center ID",
      accessor: "destinationCenterId",
    },
    {
      Header: "Listing Account",
      accessor: "listingAccount",
    },
  ];

  return (
    <div id="trailer-pallets">
      {palletsLoading ? (
        <Loader />
      ) : pallets.length > 0 ? (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-start">
              <Package className="me-3" size={40} />
              {/* codepen, layers, facubes */}
              <div>
                <Card.Title>Pallets</Card.Title>
                <Card.Subtitle>
                  Trailer {selectedTrailer.shipmentTrailerId}{" "}
                </Card.Subtitle>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table columns={columns} data={pallets} />
          </Card.Body>
        </Card>
      ) : (
        <Alert variant="warning">
          <div className="alert-icon">
            <Info
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message">
            No pallets found on
            <strong> Trailer {selectedTrailer?.shipmentTrailerId}</strong>.
          </div>
        </Alert>
      )}
    </div>
  );
};

export default TrailerPallets;
