import { useState } from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Edit } from "react-feather";
import toast from "react-hot-toast";
import ModalGeneric from "../../../../components/ModalGeneric";
import ASINRuleFormBody from "./ASINRuleFormBody";

export default function ASINRuleForm({ asinRule, vendors, addOrEditASINRule }) {
  const [selectedVendor, setSelectedVendor] = useState("");

  return (
    <Formik
      initialValues={{
        id: asinRule?.id,
        asin: asinRule?.asin || "",
        vendorRestrictedTo: asinRule?.vendorRestrictedTo || null,
        restrictProcessing: asinRule?.restrictProcessing || false,
        restrictLiquidation: asinRule?.restrictLiquidation || false,
        restrictAmazonAllListings: asinRule?.restrictAmazonAllListings || false,
        restrictAmazonNewListing: asinRule?.restrictAmazonNewListing || false,
        restrictEbayNewListing: asinRule?.restrictEbayNewListing || false,
        message: asinRule?.message || "",
      }}
      validationSchema={Yup.object().shape({
        asin: Yup.string()
          .max(75, "ASIN cannot exceed 75 characters.")
          .required("ASIN is required."),
        message: Yup.string().max(250, "Message cannot exceed 250 characters."),
      })}
      enableReinitialize
    >
      {({ handleChange, handleBlur, touched, errors, values, resetForm }) => {
        return asinRule ? (
          <ModalGeneric
            trigger={
              <Button variant="warning" className="mx-2">
                <Edit size={18} />
              </Button>
            }
            title="Edit ASIN Rule"
            body={
              <ASINRuleFormBody
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                vendors={vendors}
                setSelectedVendor={setSelectedVendor}
              />
            }
            actionButtonText={"Edit"}
            actionButtonCallback={() => {
              if (!values.asin) {
                //missing asin, don't allow form to submit:
                toast.error("Unable to submit. You must enter an ASIN.");
              } else {
                // otherwise, submit the form:
                if (selectedVendor) {
                  values.vendorRestrictedTo = selectedVendor.vendorId;
                }
                addOrEditASINRule(values);
                resetForm();
              }
            }}
            onCloseCallback={resetForm}
          />
        ) : (
          <ModalGeneric
            trigger={
              <Button variant="primary">
                Add ASIN Rule
                <FontAwesomeIcon icon={faPlus} className="mx-1" />
              </Button>
            }
            title="Add ASIN Rule"
            body={
              <ASINRuleFormBody
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                vendors={vendors}
                setSelectedVendor={setSelectedVendor}
              />
            }
            actionButtonText={"Add"}
            actionButtonCallback={() => {
              if (!values.asin) {
                //missing asin, don't allow form to submit:
                toast.error("Unable to submit. You must enter an ASIN.");
              } else {
                // otherwise, submit the form:
                if (selectedVendor) {
                  values.vendorRestrictedTo = selectedVendor.vendorId;
                }
                addOrEditASINRule(values);
                resetForm();
              }
            }}
            onCloseCallback={resetForm}
          />
        );
      }}
    </Formik>
  );
}
