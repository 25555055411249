import React, { useContext, useEffect } from "react";
import toast from "react-hot-toast";

import { Alert, Button, Card, Form } from "react-bootstrap";
import { AlertCircle } from "react-feather";
import Select from "react-select";
import QueryOptionRadios from "./QueryOptionRadios";

import itemLookUpService from "../../../../services/itemLookup/itemLookup.service";

import { ItemLookupContext } from "../../ItemLookupPage";

const ItemLookupForm = () => {
  const {
    setQueryResult,
    queryTypes,
    queryValue,
    setQueryValue,
    queryType,
    setQueryType,
    error,
    setError,
    isLoading,
    setIsLoading,
  } = useContext(ItemLookupContext);

  const handleItemLookup = async () => {
    if (!queryValue) {
      toast.error("Please enter a value");
      return;
    }

    if (queryType.value === 1 && queryValue.toUpperCase() === "W") {
      toast.error("Please re-scan LPN or enter it manually");
      setQueryValue("");
      return;
    }

    if (queryType.value === 2 && !/^\d+$/.test(queryValue)) {
      toast.error("Item Listing ID must consist of digits only.");
      setQueryValue("");
      return;
    }

    try {
      setIsLoading(true);
      setQueryResult("");

      const result = await itemLookUpService.getItem({
        queryType: queryType.label,
        queryValue,
      });

      setIsLoading(false);

      if (result?.length > 0) {
        setQueryResult(result);
      } else {
        toast.error(`No item found for ${queryType.label}: ${queryValue}`);
        setQueryValue("");
        setQueryResult("");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.message);
      console.error(error);
      setTimeout(() => {
        setError("");
        setQueryValue("");
        setQueryResult("");
      }, 3000);
    }
  };

  return (
    <Card className="text-center">
      <Card.Header className="text-center">
        <Card.Title>
          <h3>Item Lookup</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {error && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertCircle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message">{error}</div>
          </Alert>
        )}
        <Form.Label>Lookup Type:</Form.Label>
        <div className="d-flex justify-content-center mb-3">
          <Select
            size="lg"
            color="grey"
            className="mx-2 react-select-container mb-3 w-50"
            classNamePrefix="react-select"
            placeholder="Select a Lookup Type"
            onChange={(selectedOption) => {
              setQueryResult("");
              setQueryType(selectedOption);
            }}
            options={queryTypes}
            isSearchable
            value={queryType}
          />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <Form.Control
            className="text-center w-50"
            size="lg"
            type="text"
            placeholder={`Enter ${queryType.label}...`}
            value={queryValue}
            onChange={(e) => {
              setQueryValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleItemLookup();
              }
            }}
            disabled={isLoading}
            required
            autoFocus
          />
        </div>
        <Button
          variant="primary"
          size="lg"
          onClick={() => handleItemLookup()}
          disabled={isLoading}
        >
          Lookup
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ItemLookupForm;
