import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Col, Button } from "react-bootstrap";
import UIDefaultPage from "../../components/UIDefaultPage";
import Table from "../../components/tables/Table";
import columns from "./components/mfnOrders/tableColumns";
import Loader from "../../components/Loader";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const MfnOrdersPage = () => {
  const [mfnOrders, setMfnOrders] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [loadingUpdates, setLoadingUpdates] = useState(false);

  const fetchData = async (options = {}) => {
    setLoadingUpdates(true);
    const { requestUpdate } = options;
    const { orders, lastUpdated } = (
      await axios.get(
        `${HOST_URL}/order/getOrders${
          requestUpdate ? "?requestUpdate=true" : ""
        }`
      )
    ).data;
    setLoadingUpdates(false);

    setMfnOrders(orders);
    setLastUpdated(
      lastUpdated ? new Date(lastUpdated).toLocaleString() : "No data"
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const MfnOrdersTable = () => {
    return (
      <Col>
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-center">
            <div>
              <span className="font-weight-bold">
                Last Requested from Amazon: {lastUpdated}
              </span>
            </div>
            <div>
              <Button
                title="Request updated order data from Amazon"
                onClick={() => fetchData({ requestUpdate: true })}
                disabled={loadingUpdates}
                className="ms-4"
                variant="info"
              >
                {loadingUpdates ? "Refreshing..." : "Request Update"}
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            {mfnOrders ? (
              <Table
                columns={columns}
                data={mfnOrders}
                initialPageSize={30}
                initialFilterValues={{
                  filters: [{ id: "orderStatus", value: "Unshipped" }],
                }}
                size={"sm"}
              />
            ) : (
              <Loader />
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const childTable = <MfnOrdersTable />;
  return <>{<UIDefaultPage title="MFN Orders" children={childTable} />}</>;
};

export default MfnOrdersPage;
