import React, { useState } from "react";
import ModalGeneric from "../../../../components/ModalGeneric";
import { Button, Form } from "react-bootstrap";
import cryptoRandomString from "crypto-random-string";
import listingService from "../../../../services/listingService";
import printService from "../../../../services/printService";
import toast from "react-hot-toast";

const QuantityForm = ({ quantity, setQuantity }) => {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Quantity</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter quantity"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </Form.Group>
    </Form>
  );
};

const QuantityModal = ({
  clothingListingOption,
  setSubmitting,
  handleStartOver,
}) => {
  const [error, setError] = useState({});
  const [quantity, setQuantity] = useState(1);
  const handleSubmit = async (clothingListingOption) => {
    setSubmitting(true);
    // Create an RPN
    const userId = JSON.parse(localStorage.getItem("user"))
      .userID.toString()
      .padStart(2, "0");
    const token = cryptoRandomString({ length: 8, type: "distinguishable" });
    const lpn = `RPN${userId}${token}`;

    const response = await listingService.createAsinListing({
      lpn,
      asin: clothingListingOption.asin,
      quantity: Number(quantity),
      userId,
      //hardcoded to Shop and Smile
      vendorId: 16421,
      // accountIdRestrictedTo: 6,
      forceAmazonListing: true,
    });
    if (response.success) {
      if (response.newListing.disposition !== "notListed") {
        if (!response.newListing.listingId) {
          setSubmitting(false);
          toast.error("Error creating listing, please try again.");
          setError({ message: "Error creating listing, please try again." });
          return;
        }
        await printService.printListingLabel({
          fnsku: response.newListing.fnsku,
          sku: response.newListing.sku,
          amazonConditionLabel: response.newListing.amazonConditionLabel,
          inspectConditionLabel: response.newListing.inspectConditionLabel,
          title: response.newListing.title,
          account: response.newListing.account,
          listingId: response.newListing.listingId,
          userId,
          quantity: Number(response.newListing.quantity),
          inspectConditionCode: response.newListing.inspectConditionCode,
        });
      } else {
        toast.error(response?.newListing?.message);
        setError(response?.newListing?.message);
      }
      setSubmitting(false);
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
      setSubmitting(false);
    }
    setTimeout(() => {
      handleStartOver();
    }, 500);
  };
  return (
    <ModalGeneric
      trigger={<Button variant="info">Select this listing</Button>}
      body={<QuantityForm quantity={quantity} setQuantity={setQuantity} />}
      title={"Choose a quantity"}
      actionButtonText={"Submit"}
      actionButtonCallback={() => handleSubmit(clothingListingOption)}
    ></ModalGeneric>
  );
};

export default QuantityModal;
