export const ignoreOrderCompare = (a, b) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};

export const deepEqual = (object1, object2) => {
  const areObjects = isObject(object1) && isObject(object2);
  if (!areObjects) {
    return false;
  }
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const deepClone = (obj) => {
  if (!isObject(obj)) {
    return obj;
  }

  const clone = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepClone(obj[key]);
    }
  }

  return clone;
};

const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const handleError = (error) => {
  const errorObject = {
    message: "An error occurred.",
    data: null,
    status: null,
    headers: null,
  };

  if (error.config) {
    console.log("Request Configuration:", error.config);
  }

  if (error.response) {
    console.log(error.response);
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    console.log("Response Data:", error.response.data);
    console.log("Response Status:", error.response.status);
    console.log("Response Headers:", error.response.headers);

    errorObject.message = error.response.data.message;
    errorObject.data = error.response.data;
    errorObject.status = error.response.status;
    errorObject.headers = error.response.headers;
  } else if (error.request) {
    // The request was made but no response was received
    console.log("Request:", error.request);

    errorObject.message = "No response received.";
    errorObject.request = error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error:", error.message);

    errorObject.message = error.message;
  }

  return errorObject;
};

/**
 * Builds a query string for a URL based on the provided query parameters.
 * @param {Object} params - The query parameters object.
 * @returns {string} The generated query string.
 */
export const buildQueryString = (params) => {
  const query = Object.entries(params)
    .filter((param) => param[1] && param)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return query;
};

/**
 * Checks to see if a string is in a matching format for an ASIN.
 *
 * @param {string} string - The string to be checked.
 * @returns {boolean} True or false depending on the test.
 */
export const asinCheck = (string) => {
  // ASIN pattern
  const asinRegex = /^B[A-Z0-9]{9}$/;

  // ISBN-10 pattern
  const isbn10Regex = /^\d{10}$/;

  if (asinRegex.test(string) || isbn10Regex.test(string)) {
    return true;
  } else {
    return false;
  }
};
