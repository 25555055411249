import { faDolly } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, ListGroup, Spinner } from "react-bootstrap";
import ShipmentPlanTable from "./ShipmentPlanTable";
import tableColumns from "./shipmentPlanTableColumns";

const ShipmentRackList = ({
  selectedShipmentRacks,
  createShipmentPlans,
  shipmentPlanOptions,
  submitting,
  setSelectedShipmentPlans,
  selectedShipmentPlans,
  createShipments,
  activeShipmentCategory,
}) => {
  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      <Card.Header>
        <div className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faDolly} size="3x" className="me-3" />
          <div>
            {activeShipmentCategory === 1 ? (
              <>
                <Card.Title>Selected Shipment Rack</Card.Title>
                <Card.Subtitle>
                  Click "Create Shipment" to create a shipment with the selected
                  shipment rack below.
                </Card.Subtitle>
              </>
            ) : (
              <>
                <Card.Title>Selected Shipment Racks</Card.Title>
                <Card.Subtitle>
                  Select rows from the shipment racks. Click "Create Shipment"
                  to create a shipment with the selected shipment racks below.
                </Card.Subtitle>
              </>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {selectedShipmentRacks.length > 0 && (
          <>
            <h3>Total Items</h3>
            <h1 className="text-muted">
              {selectedShipmentRacks.reduce((acc, curr) => {
                return acc + curr.totalItems;
              }, 0)}
            </h1>
          </>
        )}

        <ListGroup variant="flush">
          {selectedShipmentRacks?.map((shipmentRack, index) => {
            return (
              <ListGroup.Item
                key={index}
                variant={!shipmentRack.shipmentRackId && "danger"}
              >
                Rack ID
                <h3 className="text-muted">{shipmentRack.shipmentRackId}</h3>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        {selectedShipmentRacks.length > 0 && (
          <div className="d-flex">
            <Button
              className="flex-fill"
              variant="success"
              onClick={createShipmentPlans}
              disabled={submitting}
            >
              {submitting ? (
                <span>
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    role="status"
                  />
                  Submitting...
                </span>
              ) : (
                "Create Shipment"
              )}
            </Button>
          </div>
        )}
        {shipmentPlanOptions && (
          <div className="mt-3">
            <h3>Shipment Plan Options</h3>
            <p>Choose from the following shipment options:</p>
            <ShipmentPlanTable
              columns={tableColumns}
              data={shipmentPlanOptions}
              setSelectedRows={setSelectedShipmentPlans}
              useCheckboxes={true}
              submitting={submitting}
            />
            <Button
              variant="success"
              disabled={!selectedShipmentPlans.length || submitting}
              onClick={createShipments}
            >
              {submitting ? (
                <span>
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    role="status"
                  />
                  Submitting...
                </span>
              ) : (
                "Create Shipment/s"
              )}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ShipmentRackList;
