const reduceStats = (stats) => {
  const reducedStatsByName = stats.reduce((init, stat) => {
    const key = stat.name;
    if (!init[key]) {
      switch (stat.type) {
        case "received":
          init[key] = {
            name: stat.name,
            userID: stat.userID,
            ...calculateReceivedCounts(stats, key),
          };
          break;
        case "inspected":
          init[key] = {
            name: stat.name,
            userID: stat.userID,
            ...calculateInspectedCounts(stats, key),
          };
          break;
        case "listed":
          init[key] = {
            name: stat.name,
            userID: stat.userID,
            ...calculateListedCounts(stats, key),
          };
          break;
        default:
      }
    }
    return init;
  }, []);

  const sortedStatsByName = Object.values(reducedStatsByName).sort(
    (a, b) => b.LPNCount - a.LPNCount
  );

  return Object.values(sortedStatsByName);
};

const statTotals = (stats) => {
  const receivedStats = stats.filter((stat) => stat.type === "received");
  const received = receivedStats.filter((stat) => stat.disposition === 1);
  const receivedItemTotal = received.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const accepted = receivedStats.filter((stat) => stat.disposition === 2);
  const acceptedItemTotal = accepted.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const trashed = receivedStats.filter((stat) => stat.disposition === 3);
  const trashedItemTotal = trashed.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const liquidated = receivedStats.filter((stat) => stat.disposition === 4);
  const liquidatedItemTotal = liquidated.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);

  const inspectedStats = stats.filter((stat) => stat.type === "inspected");
  const amazon = inspectedStats.filter((stat) => stat.disposition === 1);
  const amazonItemTotal = amazon.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const ebay = inspectedStats.filter((stat) => stat.disposition === 2);
  const ebayItemTotal = ebay.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const trashedInspected = inspectedStats.filter(
    (stat) => stat.disposition === 3
  );
  const trashedInspectedItemTotal = trashedInspected.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const liquidatedInspected = inspectedStats.filter(
    (stat) => stat.disposition === 4
  );
  const liquidatedInspectedItemTotal = liquidatedInspected.reduce(
    (init, stat) => {
      return init + stat.quantity;
    },
    0
  );

  const listedStats = stats.filter((stat) => stat.type === "listed");
  const products360 = listedStats.filter(
    (stat) => stat.store === "Products 360" || stat.store === "Products360"
  );
  const products360ItemTotal = products360.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const refurb360 = listedStats.filter((stat) => stat.store === "Refurb360");
  const refurb360ItemTotal = refurb360.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const classicGifts = listedStats.filter(
    (stat) => stat.store === "ClassicGifts"
  );
  const classicGiftsItemTotal = classicGifts.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const kiwiBanana = listedStats.filter((stat) => stat.store === "KiwiBanana");
  const kiwiBananaItemTotal = kiwiBanana.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const funTimeSales = listedStats.filter(
    (stat) => stat.store === "FunTimeSales"
  );
  const funTimeSalesItemTotal = funTimeSales.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const lemonExplosion = listedStats.filter(
    (stat) => stat.store === "LemonExplosion"
  );
  const lemonExplosionItemTotal = lemonExplosion.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const ebayListed = listedStats.filter((stat) => stat.store === "NonAmazon");
  const ebayListedItemTotal = ebayListed.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);
  const liquidatedListed = listedStats.filter(
    (stat) => stat.store === "Liquidated"
  );
  const liquidatedListedItemTotal = liquidatedListed.reduce((init, stat) => {
    return init + stat.quantity;
  }, 0);

  return {
    receiving: [
      {
        received: `${received.length}/${receivedItemTotal}`,
      },
      {
        accepted: `${accepted.length}/${acceptedItemTotal}`,
      },
      {
        trash: `${trashed.length}/${trashedItemTotal}`,
      },
      {
        liquidated: `${liquidated.length}/${liquidatedItemTotal}`,
      },
      {
        total: `${receivedStats.length}/${
          receivedItemTotal +
          acceptedItemTotal +
          trashedItemTotal +
          liquidatedItemTotal
        }`,
      },
    ],
    inspecting: [
      {
        amazon: `${amazon.length}/${amazonItemTotal}`,
      },
      {
        ebay: `${ebay.length}/${ebayItemTotal}`,
      },
      {
        trash: `${trashedInspected.length}/${trashedInspectedItemTotal}`,
      },
      {
        liquidated: `${liquidatedInspected.length}/${liquidatedInspectedItemTotal}`,
      },
      {
        total: `${inspectedStats.length}/${
          amazonItemTotal +
          ebayItemTotal +
          trashedInspectedItemTotal +
          liquidatedInspectedItemTotal
        }`,
      },
    ],
    listing: [
      {
        products360: `${products360.length}/${products360ItemTotal}`,
      },
      {
        refurb360: `${refurb360.length}/${refurb360ItemTotal}`,
      },
      {
        classicGifts: `${classicGifts.length}/${classicGiftsItemTotal}`,
      },
      {
        kiwiBanana: `${kiwiBanana.length}/${kiwiBananaItemTotal}`,
      },
      {
        funTimeSales: `${funTimeSales.length}/${funTimeSalesItemTotal}`,
      },
      {
        lemonExplosion: `${lemonExplosion.length}/${lemonExplosionItemTotal}`,
      },
      {
        ebay: `${ebayListed.length}/${ebayListedItemTotal}`,
      },
      {
        liquidated: `${liquidatedListed.length}/${liquidatedListedItemTotal}`,
      },
      {
        total: `${listedStats.length}/${listedStats.reduce((init, stat) => {
          return (init += Number(stat.quantity));
        }, 0)}`,
      },
    ],
  };
};

const calculateReceivedCounts = (stats, name) => {
  let receivedLPNCount = 0;
  let receivedItemCount = 0;
  let acceptedLPNCount = 0;
  let acceptedItemCount = 0;
  let trashLPNCount = 0;
  let trashItemCount = 0;
  let liquidatedLPNCount = 0;
  let liquidatedItemCount = 0;

  let counts = {
    LPNCount: stats.filter((stat) => stat.name === name).length,
    itemCount: 0,
  };
  stats.forEach((stat) => {
    if (stat.name === name) {
      counts.itemCount += stat.quantity;
      const disposition = stat.disposition;
      switch (disposition) {
        case 1:
          receivedLPNCount++;
          receivedItemCount += stat.quantity;
          break;
        case 2:
          acceptedLPNCount++;
          acceptedItemCount += stat.quantity;
          break;
        case 3:
          trashLPNCount++;
          trashItemCount += stat.quantity;
          break;
        case 4:
          liquidatedLPNCount++;
          liquidatedItemCount += stat.quantity;
          break;
      }
    }
  });
  counts.receivedCounts = `${receivedLPNCount}/${receivedItemCount}`;
  counts.acceptedCounts = `${acceptedLPNCount}/${acceptedItemCount}`;
  counts.trashCounts = `${trashLPNCount}/${trashItemCount}`;
  counts.liquidatedCounts = `${liquidatedLPNCount}/${liquidatedItemCount}`;
  return counts;
};

const calculateInspectedCounts = (stats, name) => {
  let amazonLPNCount = 0;
  let amazonItemCount = 0;
  let ebayLPNCount = 0;
  let ebayItemCount = 0;
  let trashLPNCount = 0;
  let trashItemCount = 0;
  let liquidatedLPNCount = 0;
  let liquidatedItemCount = 0;

  let counts = {
    LPNCount: stats.filter((stat) => stat.name === name).length,
    itemCount: 0,
  };
  stats.forEach((stat) => {
    if (stat.name === name) {
      counts.itemCount += stat.quantity;
      const disposition = stat.disposition;
      switch (disposition) {
        case 1:
          amazonLPNCount++;
          amazonItemCount += stat.quantity;
          break;
        case 2:
          ebayLPNCount++;
          ebayItemCount += stat.quantity;
          break;
        case 3:
          trashLPNCount++;
          trashItemCount += stat.quantity;
          break;
        case 4:
          liquidatedLPNCount++;
          liquidatedItemCount += stat.quantity;
          break;
      }
    }
  });
  counts.amazonCounts = `${amazonLPNCount}/${amazonItemCount}`;
  counts.ebayCounts = `${ebayLPNCount}/${ebayItemCount}`;
  counts.trashCounts = `${trashLPNCount}/${trashItemCount}`;
  counts.liquidatedCounts = `${liquidatedLPNCount}/${liquidatedItemCount}`;
  return counts;
};

const calculateListedCounts = (stats, name) => {
  let products360LPNCount = 0;
  let products360ItemCount = 0;
  let refurb360LPNCount = 0;
  let refurb360ItemCount = 0;
  let classicGiftsLPNCount = 0;
  let classicGiftsItemCount = 0;
  let kiwiBananaLPNCount = 0;
  let kiwiBananaItemCount = 0;
  let funTimeSalesLPNCount = 0;
  let funTimeSalesItemCount = 0;
  let lemonExplosionLPNCount = 0;
  let lemonExplosionItemCount = 0;
  let ebayLPNCount = 0;
  let ebayItemCount = 0;
  let liquidatedLPNCount = 0;
  let liquidatedItemCount = 0;

  let counts = {
    LPNCount: stats.filter((stat) => stat.name === name).length,
    itemCount: 0,
  };
  stats.forEach((stat) => {
    if (stat.name === name) {
      counts.itemCount += stat.quantity;
      const store = stat.store;
      switch (store) {
        case "Products 360":
        case "Products360":
          products360LPNCount++;
          products360ItemCount += stat.quantity;
          break;
        case "Refurb360":
          refurb360LPNCount++;
          refurb360ItemCount += stat.quantity;
          break;
        case "ClassicGifts":
          classicGiftsLPNCount++;
          classicGiftsItemCount += stat.quantity;
          break;
        case "KiwiBanana":
          kiwiBananaLPNCount++;
          kiwiBananaItemCount += stat.quantity;
          break;
        case "FunTimeSales":
          funTimeSalesLPNCount++;
          funTimeSalesItemCount += stat.quantity;
          break;
        case "LemonExplosion":
          lemonExplosionLPNCount++;
          lemonExplosionItemCount += stat.quantity;
          break;
        case "NonAmazon":
          ebayLPNCount++;
          ebayItemCount += stat.quantity;
          break;
        case "Liquidated":
          liquidatedLPNCount++;
          liquidatedItemCount += stat.quantity;
          break;
      }
    }
  });
  counts.products360Counts = `${products360LPNCount}/${products360ItemCount}`;
  counts.refurb360Counts = `${refurb360LPNCount}/${refurb360ItemCount}`;
  counts.classicGiftsCounts = `${classicGiftsLPNCount}/${classicGiftsItemCount}`;
  counts.kiwiBananaCounts = `${kiwiBananaLPNCount}/${kiwiBananaItemCount}`;
  counts.funTimeSalesCounts = `${funTimeSalesLPNCount}/${funTimeSalesItemCount}`;
  counts.lemonExplosionCounts = `${lemonExplosionLPNCount}/${lemonExplosionItemCount}`;
  counts.ebayCounts = `${ebayLPNCount}/${ebayItemCount}`;
  counts.liquidatedCounts = `${liquidatedLPNCount}/${liquidatedItemCount}`;
  return counts;
};

export { reduceStats, statTotals };
