import axios from "axios";
import { handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

// For testing purposes until endpoints are ready
// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getItemById = async (itemID) => {
  const response = (await axios.get(`${HOST_URL}/warehouse/item?id=${itemID}`))
    .data;

  // Check to see if the item exists (all item properties will be blank...)
  if (!response.item.lpn) {
    return undefined;
  }

  return response;
};

const getItem = async ({ lpn, itemId }) => {
  const response = (
    await axios.get(
      `${HOST_URL}/warehouse/getProcessingItem?${lpn ? `lpn=${lpn}` : ""}${
        itemId ? `itemId=${itemId}&step=list` : ""
      }`
    )
  ).data;

  return response;
};

const getFlags = async (data) => {
  const { vendorId, asin } = data;

  const response = (
    await axios.get(
      `${HOST_URL}/utils/getFlags?vendorID=${vendorId}${
        asin ? `&ASIN=${asin}` : ""
      }`
    )
  ).data.flags;

  return response;
};

const getConditions = async () => {
  const response = (
    await axios.get(`${HOST_URL}/utils/getInspectConditionOptions`)
  ).data.conditionOptions;

  const listingConditions = response.filter(
    (condition) => condition.inspectDispositionId === 1
  );

  return listingConditions;
};

const getVendors = async () => {
  const response = (await axios.get(`${HOST_URL}/warehouse/vendor`)).data
    .vendors;

  const optionizedVendors = response.map((vendor) => {
    return {
      vendorId: vendor.vendorId,
      label: `${vendor.vendorName ? vendor.vendorName : "(No Name)"} (${
        vendor.vendorListingSKUPrefix ? vendor.vendorListingSKUPrefix : ""
      }${vendor.vendorListingSKUPrefix && vendor.vendorRMACode ? " - " : ""}${
        vendor.vendorRMACode ? `${vendor.vendorRMACode}` : ""
      })`,
    };
  });

  return optionizedVendors;
};

const getShipmentCategories = async () => {
  const response = (
    await axios.get(`${HOST_URL}/shipment/getShipmentCategories`)
  ).data;

  const optionizedCategories = response.shipmentCategories.map((category) => {
    return {
      shipmentCategoryId: category.shipmentCategoryId,
      label: category.shipmentCategory,
    };
  });

  return optionizedCategories;
};

const getListingOptions = async ({
  lpn,
  asin,
  inspectConditionId,
  receivedVendorId,
  shipmentCategoryId,
  findCandidateListingOptions,
}) => {
  const response = (
    await axios.get(
      `${HOST_URL}/listing/getListingOptions?${
        lpn
          ? `lpn=${lpn}`
          : `asin=${asin}&inspectConditionId=${inspectConditionId}&receivedVendorId=${receivedVendorId}&shipmentCategoryId=${shipmentCategoryId}&findCandidateListingOptions=${findCandidateListingOptions}`
      }`
    )
  ).data;

  return response;
};

const checkItemBoxEligibility = async (data) => {
  const response = (
    await axios.post(`${HOST_URL}/shipment/checkItemBoxEligibility`, data)
  ).data;

  return response;
};

const getRecentEligibleShipmentBox = async (data) => {
  const response = (
    await axios.post(`${HOST_URL}/shipment/getRecentEligibleShipmentBox`, data)
  ).data;

  return response;
};

const createNewShipmentBox = async (data) => {
  const response = (
    await axios.post(`${HOST_URL}/shipment/createNewShipmentBox`, data)
  ).data;

  return response;
};

const submitListing = async (data) => {
  return await axios.post(`${HOST_URL}/listing`, data);
};

const getListingRestrictionsByASIN = async (asin, conditionId) => {
  return (
    await axios.get(
      `${HOST_URL}/listing/getListingRestrictionsByASIN/${asin}${
        conditionId ? `?conditionId=${conditionId}` : ""
      }`
    )
  ).data.listingAccountsWithRestrictions;
};

const createAsinListing = async ({
  lpn,
  asin,
  quantity,
  userId,
  vendorId,
  accountIdRestrictedTo,
  forceAmazonListing,
}) => {
  try {
    return (
      await axios.post(`${HOST_URL}/inspect/asinList`, {
        lpn,
        asin,
        quantity,
        userId,
        vendorId,
        accountIdRestrictedTo,
        forceAmazonListing,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const createFnskuListing = async ({
  lpn,
  fnsku,
  quantity,
  userId,
  accountIdRestrictedTo,
}) => {
  try {
    return (
      await axios.post(`${HOST_URL}/inspect/fnskuList`, {
        lpn,
        fnsku,
        quantity,
        userId,
        accountIdRestrictedTo,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const createSkuListing = async ({
  lpn,
  sku,
  quantity,
  userId,
  accountIdRestrictedTo,
}) => {
  try {
    return (
      await axios.post(`${HOST_URL}/inspect/skuList`, {
        lpn,
        sku,
        quantity,
        userId,
        accountIdRestrictedTo,
      })
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getItem,
  getFlags,
  getConditions,
  getVendors,
  getShipmentCategories,
  getListingOptions,
  checkItemBoxEligibility,
  getRecentEligibleShipmentBox,
  createNewShipmentBox,
  submitListing,
  getItemById,
  getListingRestrictionsByASIN,
  createAsinListing,
  createFnskuListing,
  createSkuListing,
};

export default services;
