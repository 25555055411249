import React from "react";
import AcceptTrackingNumberForm from "./components/accept/AcceptTrackingNumberForm";

import { Container } from "react-bootstrap";

const FullPageAccept = () => {
  return (
    <Container className="d-flex flex-column justify-content-center vh-100 py-5">
      <AcceptTrackingNumberForm />
      <a className="text-center" href="/accept">
        Exit Full Screen
      </a>
    </Container>
  );
};

export default FullPageAccept;
