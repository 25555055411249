import { useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Modal,
  Row,
} from "react-bootstrap";

const ClaimCard = ({ claim, setShowClaimModal }) => {
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  return (
    <>
      <Modal
        show={showImageCarousel}
        onHide={() => setShowImageCarousel(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Damage Report Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            indicators={true}
            controls={true}
            interval={null}
            activeIndex={carouselIndex}
            onSelect={(selectedIndex) => setCarouselIndex(selectedIndex)}
          >
            {claim.claimImages.map((image, index) => {
              return (
                <Carousel.Item key={index}>
                  <Image src={`${image.imageSrc}`} fluid />
                  <Carousel.Caption>
                    <h3 className="text-white">{image.imageId}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-start">
          <Card.Title>
            <h1>Damage Report</h1>
          </Card.Title>
          <Button variant="warning" onClick={() => setShowClaimModal(true)}>
            Edit Damage Report
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title>Damage Report Reason</Card.Title>
              <Card.Subtitle className="mb-3">
                {claim.claimReason}
              </Card.Subtitle>
            </Col>
            <Col>
              <Card.Title>Damage Report Sub Reason</Card.Title>
              <Card.Subtitle className="mb-3">
                {claim.claimSubReason}
              </Card.Subtitle>
            </Col>
          </Row>
          {/* <Card.Title>Claim Reason</Card.Title>
          <Card.Subtitle className="mb-3">{claim.claimReason}</Card.Subtitle>
          <Card.Title>Damage Report Sub Reason</Card.Title>
          <Card.Subtitle className="mb-3">{claim.claimSubReason}</Card.Subtitle> */}
          {claim.claimReasonNote && (
            <>
              <Card.Title>Damage Report Reason Note</Card.Title>
              <Card.Subtitle className="mb-3">
                {claim.claimReasonNote}
              </Card.Subtitle>
            </>
          )}
          <Card.Title>Damage Report Images</Card.Title>
          <Row>
            {claim.claimImages.map((image, index) => {
              return (
                <Col key={index} xs={3} className="mb-3">
                  <Image
                    src={`${image.imageSrc}`}
                    fluid
                    onClick={() => {
                      setShowImageCarousel(true);
                      setCarouselIndex(index);
                    }}
                    className="cursor-pointer"
                  />
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClaimCard;
