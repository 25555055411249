import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Trash2 } from "react-feather";

const Gallery = ({ images, setImages, disabled }) => {
  const deleteImage = (imageId) => {
    setImages((prevState) => {
      return prevState.filter((image) => image.imageId !== imageId);
    });
  };

  return (
    <div className="mt-3 pt-2 border-top">
      <h4>Gallery</h4>
      <Row>
        {images.map((image, index) => {
          return (
            <Col key={index} xs={3} className="mb-3">
              <div className="position-relative">
                <Image src={`${image.imageSrc}`} fluid />
                <Button
                  variant="danger"
                  className="position-absolute top-0 start-0"
                  onClick={() => deleteImage(image.imageId)}
                  disabled={disabled}
                >
                  <Trash2 size={16} />
                </Button>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Gallery;
