import React from "react";
import UIDefaultPage from "../../components/UIDefaultPage";
import PrintFnskuLabelForm from "./components/printFnskuLabel/PrintFnskuLabelForm";

function PrintFnskuLabelPage() {
  return (
    <UIDefaultPage
      title="Print FNSKU label"
      children={<PrintFnskuLabelForm />}
    />
  );
}

export default PrintFnskuLabelPage;
