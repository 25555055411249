import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Dashboards
import Default from "./pages/dashboards/Default";

// Management
import ASINRules from "./pages/management/ASINRules";
import EbayPage from "./pages/management/EbayPage";
import EmployeeActivityTable from "./pages/management/EmployeeActivityTable";
import FBAStorageRestrictionsPage from "./pages/management/FBAStorageRestrictionsPage";
import Flags from "./pages/management/Flags";
import ItemLookupPage from "./pages/management/ItemLookupPage";
import ItemizedActivity from "./pages/management/ItemizedActivity";
import ListingRestrictions from "./pages/management/ListingRestrictions";
import MfnOrdersPage from "./pages/management/MfnOrdersPage";
import OTPPage from "./pages/management/OTPPage";
import PrintFnskuLabelPage from "./pages/management/PrintFnskuLabelPage";
import RestrictionsPage from "./pages/management/RestrictionsPage";
import ShipmentBatchesPage from "./pages/management/ShipmentBatchesPage";
import ShipmentBoxes from "./pages/management/ShipmentBoxes";
import ShipmentsPage from "./pages/management/ShipmentsPage";
import UsersPage from "./pages/management/UsersPage";
import VendorsPage from "./pages/management/VendorsPage";
import EmployeeActivity from "./pages/management/components/itemizedActivity/EmployeeActivity";
import Leaderboard from "./pages/management/Leaderboard";
import VendorActivity from "./pages/management/VendorActivity";

//Warehouse
import Accept from "./pages/warehouse/Accept";
import BuildShipmentPage from "./pages/warehouse/BuildShipmentPage";
import ConsolidatePage from "./pages/warehouse/ConsolidatePage";
import ShipmentRacksPage from "./pages/warehouse/ShipmentRacksPage";
import CreateShipmentPage from "./pages/warehouse/CreateShipmentPage";
import FullPageAccept from "./pages/warehouse/FullPageAccept";
import Inspect from "./pages/warehouse/Inspect";
import ListFnskuPage from "./pages/warehouse/ListFnskuPage";
import ListingPage from "./pages/warehouse/ListingPage";
import PutAway from "./pages/warehouse/PutAway";
import ReceivingPage from "./pages/warehouse/ReceivingPage";
import RecentActivity from "./pages/warehouse/RecentActivity";
import ShipmentPalletsPage from "./pages/warehouse/ShipmentPalletsPage";
import BuildShipmentPalletsPage from "./pages/warehouse/BuildShipmentPalletsPage";
import TrailersPage from "./pages/warehouse/TrailersPage";
import FBALabelPage from "./pages/warehouse/FBALabelPage";
import RackFromBatchPage from "./pages/warehouse/RackFromBatchPage";
import ClothingListingPage from "./pages/warehouse/ClothingListingPage";
import FulfillmentPicklistPage from "./pages/warehouse/FulfillmentPicklistPage";
import AsinImagePage from "./pages/warehouse/AsinImage";
import AlternateListingPage from "./pages/warehouse/AlternateListingPage";
import InventoryPutawayPage from "./pages/warehouse/InventoryPutawayPage";

// Auth
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Settings from "./pages/settings/Settings";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
      {
        path: "dashboard",
        element: <Default />,
      },
      {
        path: "management",
        children: [
          {
            path: "fbaStorageRestrictions",
            element: <FBAStorageRestrictionsPage />,
          },
          {
            path: "vendor",
            element: <VendorsPage />,
          },
          {
            path: "user",
            element: <UsersPage />,
          },
          {
            path: "employee-activity-table",
            element: <EmployeeActivityTable />,
          },
          {
            path: "employeeActivity/:type/:queryDate/:userID/:name",
            element: <EmployeeActivity />,
          },
          {
            path: "itemized-activity",
            element: <ItemizedActivity />,
          },
          {
            path: "leaderboard/",
            element: <Leaderboard />,
          },
          {
            path: "vendorActivity/",
            element: <VendorActivity />,
          },
          {
            path: "otp",
            element: <OTPPage />,
          },
        ],
      },
      {
        path: "processing",
        children: [
          {
            path: "shipments",
            element: <ShipmentsPage />,
          },
          {
            path: "listingRestrictions",
            element: <ListingRestrictions />,
          },
          {
            path: "restrictions",
            element: <RestrictionsPage />,
          },
          {
            path: "flags",
            element: <Flags />,
          },
          {
            path: "asinRules",
            element: <ASINRules />,
          },
          {
            path: "ebay",
            element: <EbayPage />,
          },
          {
            path: "printFnskuLabel",
            element: <PrintFnskuLabelPage />,
          },
          {
            path: "itemLookup",
            element: <ItemLookupPage />,
          },
          {
            path: "mfnOrders",
            element: <MfnOrdersPage />,
          },
        ],
      },
      {
        path: "accept/",
        element: <Accept />,
      },
      {
        path: "receiving/",
        element: <ReceivingPage />,
      },
      {
        path: "receiving/:shipmentID",
        element: <ReceivingPage />,
      },
      {
        path: "listing/",
        element: <ListingPage />,
      },
      {
        path: "listing/:itemID",
        element: <ListingPage />,
      },
      {
        path: "inspecting/",
        element: <Inspect />,
      },
      {
        path: "inspecting/:itemID",
        element: <Inspect />,
      },
      {
        path: "consolidate/",
        element: <ConsolidatePage />,
      },
      {
        path: "shipmentRacks",
        element: <ShipmentRacksPage />,
      },
      {
        path: "createShipment/",
        element: <CreateShipmentPage />,
      },
      {
        path: "buildShipment/",
        element: <BuildShipmentPage />,
      },
      {
        path: "fbaLabel/",
        element: <FBALabelPage />,
      },
      {
        path: "shipmentPallets/",
        element: <ShipmentPalletsPage />,
      },
      {
        path: "buildShipmentPallets/",
        element: <BuildShipmentPalletsPage />,
      },
      {
        path: "trailers/",
        element: <TrailersPage />,
      },
      {
        path: "put-away/",
        element: <PutAway />,
      },
      {
        path: "fulfillmentPicklist/",
        element: <FulfillmentPicklistPage />,
      },
      {
        path: "recentActivity/",
        element: <RecentActivity />,
      },
      {
        path: "alternateListing/",
        element: <AlternateListingPage />,
      },
      { path: "inventoryPutaway", element: <InventoryPutawayPage /> },
      {
        path: "clothingListing/",
        element: <ClothingListingPage />,
      },
      {
        path: "settings/",
        element: <Settings />,
      },
    ],
  },
  {
    path: "accept-fullpage",
    element: (
      <AuthGuard>
        <FullPageAccept />
      </AuthGuard>
    ),
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/display/leaderboard",
    element: <Leaderboard />,
  },
  {
    path: "/asin-image/:asin",
    element: <AsinImagePage />,
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
