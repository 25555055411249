import { useState, useEffect } from "react";
import { Card, Alert, Button, Image } from "react-bootstrap";
import { Info, Trash2, Plus, Package } from "react-feather";
import toast from "react-hot-toast";

import Table from "../.././../../components/tables/Table";
import ModalGeneric from "../../../../components/ModalGeneric";
import UIInput from "../../../../components/UIInput";
import shipmentService from "../../../../services/shipmentService";
import Loader from "../.././../../components/Loader";
import imageNotFound from "../../../../assets/img/imageNotFound.png";

const ShipmentRackItems = ({
  selectedShipmentRack,
  getShipmentRacksWithoutItems,
}) => {
  const [fnskuInput, setFnskuInput] = useState("");
  const [selectedShipmentRackItems, setSelectedShipmentRackItems] = useState();
  const [loading, setLoading] = useState(true);
  const [allowChangeToOversize, setAllowChangeToOversize] = useState(false);

  const tableColumns = [
    {
      id: "image",
      Header: "Item Image",
      accessor: (row) => {
        const image = row.image ? row.image : null;
        return (
          <Image
            thumbnail
            style={{ maxHeight: "75px" }}
            src={image ? image : imageNotFound}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = imageNotFound;
            }}
          />
        );
      },
    },
    {
      Header: "ASIN",
      id: "asin",
      accessor: (row) => {
        if (selectedShipmentRack.listingAccountId === 7) {
          // Ebay
          if (row.fnsku.toUpperCase().startsWith("B"))
            return row.fnsku.toUpperCase().split("-")[0];
          else return "";
        } else return row.asin;
      },
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ value }) => (
        <div
          style={{
            maxHeight: "3.6em", // Adjust the value to control the number of lines before ellipsis
            lineHeight: "1.2em", // Adjust the line height for proper spacing
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3, // Adjust the number of lines to show before ellipsis
            WebkitBoxOrient: "vertical",
          }}
        >
          {value}
        </div>
      ),
    },

    {
      Header: "FNSKU",
      accessor: "fnsku",
    },
    {
      Header: "SKU",
      accessor: "sku",
    },
  ];

  useEffect(() => {
    if (selectedShipmentRack) {
      getShipmentRackItems();
    }
  }, [selectedShipmentRack]);

  const getShipmentRackItems = async () => {
    setLoading(true);

    const result = await shipmentService.getShipmentRackItems({
      shipmentRackId: selectedShipmentRack.shipmentRackId,
    });

    if (result.success) {
      setSelectedShipmentRackItems(result.shipmentRackItems);

      const rackItemWithShipmentId = result.shipmentRackItems.find(
        (rackItem) => rackItem.shipmentId
      );
      if (
        !rackItemWithShipmentId &&
        selectedShipmentRack.shipmentCategoryId === 1
      ) {
        setAllowChangeToOversize(true);
      } else {
        setAllowChangeToOversize(false);
      }
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
    }
    setLoading(false);
  };

  // const addItemToRack = async (shipmentRackId) => {
  //   try {
  //     await shipmentService.addItemToRack({
  //       shipmentRackId,
  //       fnsku: fnskuInput,
  //     });

  //     toast.success(
  //       `Item with FNSKU ${fnskuInput} added to Shipment Rack ${shipmentRackId}`
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(
  //       `Unable to add item with FNSKU ${fnskuInput} to Shipment Rack ${shipmentRackId}`
  //     );
  //   }

  //   setFnskuInput("");

  //   // refetch shipment racks items:
  //   await getShipmentRackItems();
  // };

  // const removeItemFromRack = async (row) => {
  //   try {
  //     await shipmentService.removeItemFromRack(row.shipmentRackItemId);

  //     toast.success(
  //       `Item with FNSKU ${row.fnsku} removed from Shipment Rack ${row.shipmentRackId}`
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(
  //       `Unable to remove item with FNSKU ${row.fnsku} from Shipment Rack ${row.shipmentRackId}`
  //     );
  //   }

  //   // refetch shipment racks items:
  //   await getShipmentRackItems();
  // };

  const changeRackToOversize = async () => {
    try {
      await shipmentService.changeRackToOversizeCategory(
        selectedShipmentRack.shipmentRackId
      );

      toast.success(`Rack was changed to be Oversize`);
    } catch (error) {
      console.log(error);
      toast.error(`Unable to change rack to Oversize`);
    }

    // refetch shipment racks :
    await getShipmentRacksWithoutItems();
  };

  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      <Card.Header>
        <div className="d-flex">
          <div className="me-auto d-flex justify-content-start">
            <Package size="40" className="me-3" />
            <div>
              <Card.Title>
                Shipment Rack {selectedShipmentRack.shipmentRackId}
              </Card.Title>
              {selectedShipmentRackItems && (
                <Card.Subtitle>
                  {selectedShipmentRackItems.length} Items:
                </Card.Subtitle>
              )}
            </div>
          </div>
          <div className="mx-2">
            {allowChangeToOversize && (
              <ModalGeneric
                trigger={<Button>Change Rack to Oversize</Button>}
                body={`Change Shipment Rack ${selectedShipmentRack.shipmentRackId} from standard to oversize?`}
                actionButtonCallback={changeRackToOversize}
              />
            )}
          </div>
          {/* <div>
            <ModalGeneric
              trigger={
                <Button>
                  Add Item <Plus size={16} />
                </Button>
              }
              title={`Add Item to Shipment Rack ${selectedShipmentRack.shipmentRackId}:`}
              type="submit"
              body={
                <UIInput
                  autoFocus
                  value={fnskuInput}
                  onChange={(e) => setFnskuInput(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      addItemToRack(selectedShipmentRack.shipmentRackId);
                    }
                  }}
                  type="text"
                  placeholder="Scan or enter FNSKU"
                  size="md"
                />
              }
              actionButtonText={"Add"}
              actionButtonCallback={() =>
                addItemToRack(selectedShipmentRack.shipmentRackId)
              }
              actionButtonProps={{ disabled: !fnskuInput }}
            />
          </div> */}
        </div>
      </Card.Header>
      <Card.Body className="pt-1">
        {loading ? (
          <Loader />
        ) : selectedShipmentRackItems ? (
          selectedShipmentRackItems.length > 0 ? (
            <Table
              columns={tableColumns}
              data={selectedShipmentRackItems}
              className="pt-0"
            />
          ) : (
            <Alert variant="warning">
              <div className="alert-icon">
                <Info
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message d-flex justify-content-between align-items-center">
                <span>
                  There are no items on Shipment Rack{" "}
                  <strong>{selectedShipmentRack.shipmentRackId}</strong>. Try
                  selecting a different Shipment Rack to view Shipment Rack
                  Items.
                </span>
              </div>
            </Alert>
          )
        ) : (
          <Alert variant="warning">
            <div className="alert-icon">
              <Info
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message d-flex justify-content-between align-items-center">
              <span>
                Unable to retrieve items for Shipment Rack{" "}
                <strong>{selectedShipmentRack.shipmentRackId}</strong>. Please
                try again.
              </span>
            </div>
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default ShipmentRackItems;
