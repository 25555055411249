import { Card, ListGroup, Placeholder } from "react-bootstrap";

const BaysPlaceholder = () => {
  return (
    <Card>
      <Card.Header className="border-bottom">
        <div className="mb-3">
          <div>
            <Placeholder animation="glow">
              <Placeholder size="lg" xs={6} />
              <Placeholder size="md" xs={10} />
            </Placeholder>
          </div>
        </div>
        <div className="d-flex">
          <Placeholder.Button className="flex-fill" variant="warning" xs={6} />
        </div>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="lg" xs={3} />
            </Placeholder>
          </div>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="sm" xs={2} />
            </Placeholder>
          </div>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="md" xs={6} />
            </Placeholder>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="lg" xs={3} />
            </Placeholder>
          </div>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="sm" xs={2} />
            </Placeholder>
          </div>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="md" xs={6} />
            </Placeholder>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="lg" xs={3} />
            </Placeholder>
          </div>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="sm" xs={2} />
            </Placeholder>
          </div>
          <div>
            <Placeholder animation="glow">
              <Placeholder size="md" xs={6} />
            </Placeholder>
          </div>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default BaysPlaceholder;
