import { createContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import UIDefaultPage from "../../components/UIDefaultPage";
import InspectItem from "./components/inspect/InspectItem";
import LPNForm from "./components/inspect/LPNForm";

import inspectService from "../../services/inspect/inspect.service";

export const InspectContext = createContext({
  currentItem: {},
  error: {},
  flags: [],
  inspectConditionId: undefined,
  isLoading: false,
  makeClaim: false,
  previousInspection: {},
  setCurrentItem: () => {},
  setError: () => {},
  setFlags: () => {},
  setInspectConditionId: () => {},
  setIsLoading: () => {},
  setMakeClaim: () => {},
  setPreviousInspection: () => {},
  setShouldSubmitInspection: () => {},
  setShowClaimModal: () => {},
  shouldSubmitInspection: false,
  showClaimModal: false,
  submitClaim: () => {},
  userId: undefined,
});

const Inspect = () => {
  // Data States
  const [flags, setFlags] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [previousInspection, setPreviousInspection] = useState({});
  const [inspectConditionId, setInspectConditionId] = useState();
  const [error, setError] = useState();

  // Boolean States
  const [shouldSubmitInspection, setShouldSubmitInspection] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [makeClaim, setMakeClaim] = useState(false);

  // Render States
  const [isLoading, setIsLoading] = useState(false);

  const userId = JSON.parse(localStorage.getItem("user"))?.userID;

  const inspectContextValue = useMemo(
    () => ({
      currentItem,
      error,
      flags,
      inspectConditionId,
      isLoading,
      makeClaim,
      previousInspection,
      setCurrentItem,
      setError,
      setFlags,
      setInspectConditionId,
      setIsLoading,
      setMakeClaim,
      setPreviousInspection,
      setShouldSubmitInspection,
      setShowClaimModal,
      shouldSubmitInspection,
      showClaimModal,
      userId,
    }),
    [
      currentItem,
      error,
      flags,
      inspectConditionId,
      isLoading,
      makeClaim,
      previousInspection,
      setCurrentItem,
      setError,
      setFlags,
      setInspectConditionId,
      setIsLoading,
      setMakeClaim,
      setPreviousInspection,
      setShouldSubmitInspection,
      setShowClaimModal,
      shouldSubmitInspection,
      showClaimModal,
      userId,
    ]
  );

  //the URL needs to be changed to have query strings
  const { itemID: itemId } = useParams();

  useEffect(() => {
    const getItem = async () => {
      try {
        const item = await inspectService
          .getItemToInspect({ itemId })
          .catch((error) => {
            console.error(error);
            setError(error.response.data.message);
            setIsLoading(false);
          });

        if (Object.values(item).length === 0) {
          toast.error("This item could not be found");
          setTimeout(() => {
            window.location.href = "/inspecting";
          }, 1500);
          return;
        }
        setCurrentItem(item);
      } catch (error) {
        console.log(error);
      }
    };

    if (itemId) {
      getItem();
    }
  }, [itemId]);

  // Get all the flags and set the previousInspection whenever currentItem changes.
  useEffect(() => {
    const getFlags = async () => {
      const response = await inspectService
        .getFlags({
          vendorId: currentItem.processingData.receivedVendorId,
          asin: currentItem.processingData.receivedAsin,
        })
        .catch((error) => {
          console.error(error);
        });

      if (response.length > 0) {
        setFlags(response);
      }
    };

    if (currentItem?.processingData?.receivedVendorId) {
      getFlags();
    }

    if (currentItem?.processingData?.inspectedId) {
      setPreviousInspection({
        inspectedId: currentItem.processingData.inspectedId,
        inspectConditionId: currentItem.processingData.inspectConditionId,
      });
    }
  }, [currentItem]);

  return (
    <InspectContext.Provider value={inspectContextValue}>
      <UIDefaultPage
        title="Inspect Item"
        children={!currentItem ? <LPNForm /> : <InspectItem />}
      />
    </InspectContext.Provider>
  );
};

export default Inspect;
