import React, { useState } from "react";
import { Container, Table, Form } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import FilterOptions from "./FilterOptions";
import Pagination from "./Pagination";
import DefaultColumnFilter from "./DefaultColumnFilter";

function GlobalFilter({ filter, setFilter, total }) {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 350);

  return (
    <>
      <h4>
        Search Entire Table:{" "}
        {`(${total} ${total === 1 ? "record" : "records"})`}
      </h4>
      <Form.Control
        size="lg"
        className="w-50 m-auto text-center"
        placeholder={"Search"}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </>
  );
}

const UITable = ({ data: dataSource, columns: columnsSource }) => {
  //   const columns = useMemo(() => columnsSource, []);
  //   const data = useMemo(() => dataSource, []);
  const columns = columnsSource;
  const data = dataSource;

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    { columns, data, defaultColumn, initialState: { pageSize: 50 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    pageSize,
    setPageSize,
    prepareRow,
    state: { globalFilter, filters, pageIndex },
    filteredRows,
    setGlobalFilter,
    setAllFilters,
    setFilter,
  } = tableInstance;

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          total={filteredRows.length}
        />
        <FilterOptions
          filters={filters}
          setFilter={setFilter}
          setAllFilters={setAllFilters}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Table
          striped
          bordered
          {...getTableProps}
          className="mt-3"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <React.Fragment key={index}>
                      <th>
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </div>
                        <hr />
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Pagination tableInstance={tableInstance} />
      </Container>
    </React.Fragment>
  );
};

export default UITable;
