import React from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

const OtherListingOptions = ({
  handleSubmitListing,
  forceLiquidation,
  restrictEbayNewListing,
  conditionId,
  restrictLiquidation,
}) => {
  return (
    <>
      {!forceLiquidation && (
        <Button
          variant="primary"
          className="m-2"
          onClick={() => {
            if (conditionId === 1 && restrictEbayNewListing) {
              toast.error("This item cannot be listed as new on Ebay.");
              return;
            }
            handleSubmitListing({
              listingAccountId: 7,
              listingAccount: "Ebay",
            });
          }}
        >
          List on Ebay
        </Button>
      )}
      {/* don't show liquidate button if item has ASIN Rule restrictingLiquidation and condition is 'New Sealed' */}
      {!(restrictLiquidation && conditionId === 1) && (
        <Button
          variant="danger"
          className="m-2"
          onClick={() => {
            handleSubmitListing({
              listingAccountId: 8,
              listingAccount: "Liquidate",
            });
          }}
        >
          Liquidate
        </Button>
      )}
    </>
  );
};

export default OtherListingOptions;
