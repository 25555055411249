import { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { format } from "date-fns";
import { Container, Card, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import StatTable from "./components/vendorActivity/StatTable";
import ChooseDates from "../../components/ChooseDates";
import Loader from "../../components/Loader";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function VendorActivity() {
  const isAdmin = JSON.parse(localStorage.getItem("user")).isAdmin;
  const [allStats, setAllStats] = useState();
  const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleString());
  const [showPercents, setShowPercents] = useState();

  const getStats = async (startDate, endDate) => {
    // clear allStats so the loader will show when refetching the data:
    setAllStats();

    const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
    const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");

    // if user selected >1 day, don't show percent comparison to the previous day:
    if (formattedStartDate !== formattedEndDate) {
      setShowPercents(false);
    } else {
      setShowPercents(true);
    }

    try {
      const stats = (
        await axios.get(
          `${HOST_URL}/vendorActivity?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        )
      ).data.stats;
      setAllStats(stats);
      setLastUpdated(new Date().toLocaleString());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      window.location.href = "/404";
    }
  }, [isAdmin]);

  return (
    <Fragment>
      <Helmet title="Vendor Activity" />
      <Container fluid className="p-0 mt-3 text-center">
        <h1 className="text-center">Vendor Activity</h1>
        <h5 className="text-center">Last Updated: {lastUpdated} </h5>
        {isAdmin && (
          <Row className="justify-content-center">
            <Col>
              <ChooseDates goButtonAction={getStats} />
            </Col>
          </Row>
        )}
        {allStats ? (
          <Card.Body className="mt-2">
            <Row>
              <StatTable
                title="Receiving"
                stats={allStats.receivingStats}
                showPercents={showPercents}
              />
              <StatTable
                title="Inspecting"
                stats={allStats.inspectingStats}
                showPercents={showPercents}
              />
            </Row>
          </Card.Body>
        ) : (
          <Loader />
        )}
      </Container>
    </Fragment>
  );
}

export default VendorActivity;
