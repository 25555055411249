import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { AlertOctagon, Flag, Printer } from "react-feather";
import toast from "react-hot-toast";

import ItemForm from "./ItemForm";

import printService from "../../../../services/printService";
import receivingService from "../../../../services/receiving.service";

import { ReceivingContext } from "../../ReceivingPage";

import imageNotFound from "../../../../assets/img/imageNotFound.png";

const Item = () => {
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const {
    currentItem,
    editing,
    setCurrentItem,
    setEditing,
    setItems,
    setShowItemDetails,
    setShowItemForm,
    showItemDetails,
    showItemForm,
    vendor,
  } = useContext(ReceivingContext);

  const formikRef = useRef(null);

  const {
    itemId,
    lpn,
    asin,
    fnsku,
    upc,
    images,
    title,
    returnReason,
    customerComments,
    price,
    valuableSale,
  } = currentItem;

  const handleSubmitItem = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const removeItem = async () => {
    setSubmitting(true);
    // remove shipment item in the BE:
    const response = await receivingService.removeReceivedItem({ itemId });

    if (response.success) {
      if (response.status === 0) {
        toast.success("Item successfully removed from the shipment.");
        setItems((prevState) =>
          prevState.filter((item) => item.itemId !== itemId)
        );
      } else if (response.status === 404) {
        toast.error("The item was not found.");
      }
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
    }

    setShowItemForm(false);
    setShowItemDetails(false);
    setEditing(false);
    setSubmitting(false);
    setCurrentItem();
  };

  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      <Card.Header className="border-bottom">
        <div className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faGem} size="3x" className="me-3" />
          <div>
            <Card.Title>Item</Card.Title>
            <Card.Subtitle>
              Details about the current item with LPN {lpn}.
            </Card.Subtitle>
          </div>
        </div>
        {currentItem.asinRule && (
          <Alert variant="danger" className="flex-fill alert-blink mt-3 mb-0">
            <div className="alert-icon">
              <AlertOctagon
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              <strong>{currentItem.asinRule.message}</strong>
            </div>
          </Alert>
        )}
        {currentItem.asinFlags && currentItem.asinFlags.length > 0 && (
          <Alert variant="warning" className="flex-fill alert-blink mt-3 mb-0">
            <div className="alert-icon">
              <Flag
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              {currentItem.asinFlags
                .filter(
                  (flag) =>
                    flag.vendorId === vendor.vendorId || flag.vendorId === 0
                )
                .map((flag, index) => (
                  <div key={index}>{flag.message}</div>
                ))}
            </div>
          </Alert>
        )}
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between">
          <h4 className="me-3">Details</h4>
          <Button
            variant="secondary"
            onClick={() => setShowItemDetails(!showItemDetails)}
          >
            {showItemDetails ? "Hide Details" : "Show Details"}
          </Button>
        </div>
        <Collapse in={showItemDetails}>
          <div>
            <Row className="mt-3">
              {images && asin && (
                <Col md={3}>
                  <a
                    href={`https://www.amazon.com/dp/${asin}?th=1&psc=1`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      // style={{ maxHeight: "75px" }}
                      fluid
                      thumbnail
                      src={images ? images[0] : imageNotFound}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = imageNotFound;
                      }}
                    />
                  </a>
                </Col>
              )}
              <Col md={9}>
                <Row className="mb-3">
                  {asin && (
                    <Col>
                      <h5 className="mb-0">ASIN</h5>
                      <Card.Text>
                        <a
                          href={`https://www.amazon.com/dp/${asin}?th=1&psc=1`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {asin}
                        </a>
                      </Card.Text>
                    </Col>
                  )}
                  {fnsku && (
                    <Col>
                      <h5 className="mb-0">FNSKU</h5>
                      <Card.Text>{fnsku}</Card.Text>
                    </Col>
                  )}
                  {upc && (
                    <Col>
                      <h5 className="mb-0">UPC</h5>
                      <Card.Text>{upc}</Card.Text>
                    </Col>
                  )}
                  <Col>
                    <h5 className="mb-0">LPN</h5>
                    <Card.Text>
                      {lpn}
                      <Button
                        className="mx-2 px-1 py-0 "
                        variant="info"
                        title="Click to print"
                        onClick={() => {
                          printService.printBarcodeLabel(lpn);
                        }}
                      >
                        <Printer style={{ width: "16px", height: "16px" }} />
                      </Button>
                    </Card.Text>
                  </Col>
                </Row>
                {title && (
                  <div className="mb-3">
                    <h5 className="mb-0">Title</h5>
                    <Card.Text>{title}</Card.Text>
                  </div>
                )}
                {(returnReason || customerComments) && (
                  <Row className="mb-3">
                    {returnReason && (
                      <Col>
                        <div className="">
                          <h5 className="mb-0">Return Reason</h5>
                          <Card.Text>{returnReason}</Card.Text>
                        </div>
                      </Col>
                    )}
                    {customerComments && (
                      <Col>
                        <div className="">
                          <h5 className="mb-0">Customer Comments</h5>
                          <Card.Text>{customerComments}</Card.Text>
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
                {price && (
                  <div className="d-flex">
                    <div className="me-3">
                      <h5 className="mb-0">Price</h5>
                      <Card.Text>{price}</Card.Text>
                    </div>
                    {!valuableSale && (
                      <Alert variant="danger" className="flex-fill alert-blink">
                        <div className="alert-icon">
                          <AlertOctagon
                            className="position-relative top-50 start-50 translate-middle"
                            size={20}
                          />
                        </div>
                        <div className="alert-message">
                          The price is too low for this item. Please select the{" "}
                          <strong>LIQUIDATE</strong> disposition.
                        </div>
                      </Alert>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Collapse>
        {currentItem.itemId ? (
          editing ? (
            <div className="d-flex my-3">
              <Button
                className="flex-fill"
                variant="danger"
                onClick={() => {
                  setEditing(false);
                  setError();
                  setShowItemForm(false);
                  if (formikRef.current) {
                    formikRef.current.resetForm();
                  }
                }}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div className="d-flex mt-3">
              <Button
                className="flex-fill"
                variant="warning"
                onClick={() => {
                  setEditing(true);
                  setShowItemForm(true);
                }}
                disabled={submitting}
              >
                Edit Item
              </Button>
            </div>
          )
        ) : (
          <hr></hr>
        )}
        <Collapse in={showItemForm}>
          <div>
            {error && (
              <Alert variant="danger" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <AlertOctagon
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {error.message}
                </div>
              </Alert>
            )}
            <ItemForm
              formikRef={formikRef}
              setError={setError}
              submitting={submitting}
              setSubmitting={setSubmitting}
            />
            {editing ? (
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      className="flex-fill"
                      variant="success"
                      onClick={() => {
                        handleSubmitItem();
                        setError();
                      }}
                      disabled={submitting}
                    >
                      {submitting && (
                        <Spinner
                          className="me-2"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {submitting ? "Saving..." : "Save"}
                    </Button>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex">
                    <Button
                      className="flex-fill"
                      variant="danger"
                      onClick={() => {
                        removeItem();
                        setError();
                      }}
                    >
                      Remove From Shipment
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <div className="d-flex">
                    <Button
                      className="flex-fill"
                      variant="primary"
                      onClick={() => {
                        handleSubmitItem();
                        setError();
                      }}
                      disabled={submitting}
                    >
                      Add To Shipment
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Collapse>
        <div className="text-center mt-3">
          <Card.Link
            onClick={() => {
              if (!submitting) {
                setEditing(false);
                setShowItemForm(false);
                setCurrentItem();
              }
            }}
          >
            Go Back
          </Card.Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Item;
