import React from "react";
import { Button } from "react-bootstrap";

function UIButton({
  variant,
  text,
  onClick,
  className,
  size = "md",
  disabled = false,
}) {
  return (
    <Button
      size={size}
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {text || "TEST"}
    </Button>
  );
}

export default UIButton;
