import { faEbay } from "@fortawesome/free-brands-svg-icons";
import { faHammer, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Activity,
  AlertOctagon,
  AlignJustify,
  Archive,
  ArrowDown,
  BookOpen,
  Briefcase,
  CheckSquare,
  ChevronsRight,
  Eye,
  Flag,
  Key,
  Layers,
  List,
  LogIn,
  MapPin,
  Menu,
  PlusSquare,
  Printer,
  Search,
  Server,
  Settings,
  Slash,
  Tag,
  Truck,
  Users,
  Package,
} from "react-feather";

const isAdmin = JSON.parse(localStorage.getItem("user"))?.isAdmin;

const homeSection = [
  {
    href: "/dashboard",
    icon: MapPin,
    title: "Dashboard",
  },
];

const adminSection = [
  {
    href: "/management",
    icon: Settings,
    title: "Management",
    children: [
      {
        href: "/management/fbaStorageRestrictions",
        icon: Archive,
        title: "FBA Storage Restrictions",
      },

      {
        href: "/management/vendor",
        icon: Briefcase,
        title: "Vendors",
      },
      {
        href: "/management/user",
        icon: Users,
        title: "Users",
      },
      {
        href: "/management/employee-activity-table",
        icon: Eye,
        title: "Employee Activity",
      },
      {
        href: "/management/itemized-activity",
        icon: Eye,
        title: "Itemized Activity",
      },
      {
        href: "/management/leaderboard",
        icon: Server,
        title: "Leaderboard",
      },
      {
        href: "/management/vendorActivity",
        icon: Activity,
        title: "Vendor Activity",
      },
      {
        href: "/management/otp",
        icon: Key,
        title: "OTPs",
      },
    ],
  },
  {
    href: "/processing",
    icon: Layers,
    title: "Processing",
    children: [
      {
        href: "/processing/shipments",
        icon: Truck,
        title: "Shipments",
      },
      {
        href: "/processing/listingRestrictions",
        icon: Slash,
        title: "Listing Restrictions",
      },
      {
        href: "/processing/restrictions",
        icon: Slash,
        title: "Amazon Restrictions",
      },
      {
        href: "/processing/flags",
        icon: Flag,
        title: "Flags",
      },
      {
        href: "/processing/asinRules",
        icon: AlertOctagon,
        title: "ASIN Rules",
      },
      {
        href: "/processing/ebay",
        icon: () => <FontAwesomeIcon icon={faEbay} />,
        title: "Ebay",
      },
      {
        href: "/processing/itemLookup",
        icon: Search,
        title: "Item Lookup",
      },
      {
        href: "/processing/printFnskuLabel",
        icon: Printer,
        title: "Print FNSKU Label",
      },
      {
        href: "/processing/mfnOrders",
        icon: Tag,
        title: "MFN Orders",
      },
    ],
  },
];

const warehouseSection = [
  {
    href: "/processing",
    icon: Layers,
    title: "Processing",
    children: [
      {
        href: "/accept",
        icon: LogIn,
        title: "Accept",
      },
      {
        href: "/receiving",
        icon: ArrowDown,
        title: "Receive Shipment",
      },
      {
        href: "/inspecting",
        icon: CheckSquare,
        title: "Inspect Item",
      },
      {
        href: "/listing",
        icon: List,
        title: "List Item",
      },
    ],
  },
  {
    href: "/shipping",
    icon: Truck,
    title: "Shipping",
    children: [
      {
        href: "/consolidate",
        icon: () => <FontAwesomeIcon icon={faSitemap} rotation={180} />,
        title: "Consolidation",
      },
      {
        href: "/shipmentRacks",
        icon: AlignJustify,
        title: "Shipment Racks",
      },
      {
        href: "/createShipment",
        icon: PlusSquare,
        title: "Create Shipment",
      },
      {
        href: "/buildShipment",
        icon: () => <FontAwesomeIcon icon={faHammer} />,
        title: "Build Shipment",
      },
      {
        href: "/fbaLabel",
        icon: Printer,
        title: "Print FBA Labels",
      },
    ],
  },
  {
    href: "/miscellaneous",
    icon: Menu,
    title: "Miscellaneous",
    children: [
      {
        href: "/listing",
        icon: Search,
        title: "Lookup Listing",
      },
      {
        href: "/fulfillmentPicklist",
        icon: List,
        title: "Fulfillment Picklist",
      },
      {
        href: "/alternateListing",
        icon: ChevronsRight,
        title: "Alternate Listing",
      },
      {
        href: "/clothingListing",
        icon: ChevronsRight,
        title: "Clothing Listing",
      },
      {
        href: "/inventoryPutaway",
        icon: Package,
        title: "Inventory Putaway",
      },
      {
        href: "/recentActivity",
        icon: BookOpen,
        title: "Recent Activity",
      },
      //https://stackoverflow.com/a/47771259
      ...(!isAdmin
        ? [
            {
              href: "/management/vendor",
              icon: Briefcase,
              title: "Vendors",
            },
          ]
        : []),
    ],
  },

  // {
  //   href: "/shipmentPallets",
  //   icon: () => <FontAwesomeIcon icon={faPallet} />,
  //   title: "Shipment Pallets",
  // },
  // {
  //   href: "/buildShipmentPallets",
  //   icon: () => <FontAwesomeIcon icon={faPallet} />,
  //   title: "Build Shipment Pallets",
  // },
  // {
  //   href: "/trailers",
  //   icon: Truck,
  //   title: "Shipment Trailers",
  // },
  // {
  //   href: "/FBAstaging",
  //   icon: Package,
  //   title: "FBA Staging",
  // },
  // {
  //   href: "/activeListingLookup",
  //   icon: Search,
  //   title: "Active Listing Lookup",
  // },
  // {
  //   href: "/put-away",
  //   icon: ChevronsUp,
  //   title: "Put Away",
  // },
  // {
  //   href: "/shipmentLookup",
  //   icon: Search,
  //   title: "Shipment Lookup",
  // },
];
const settingSection = [
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
  },
];

const navItems = [
  {
    title: "Navigation",
    pages: homeSection,
  },
  isAdmin
    ? {
        title: "Admin",
        pages: adminSection,
      }
    : {
        title: "",
        pages: [],
      },
  {
    title: "Warehouse",
    pages: warehouseSection,
  },
  {
    title: "Settings",
    pages: settingSection,
  },
];

export default navItems;
