import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cryptoRandomString from "crypto-random-string";
import React, { useState, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { AlertOctagon } from "react-feather";
import toast from "react-hot-toast";

import listingService from "../../../../../services/listingService";
import printService from "../../../../../services/printService";

import imageNotFound from "../../../../../assets/img/imageNotFound.png";

const SkuList = ({ submitting, setSubmitting }) => {
  const [error, setError] = useState({});
  const [toggle, setToggle] = useState(true);

  const [sku, setSku] = useState("");
  const [quantity, setQuantity] = useState(1);

  const [validationErrors, setValidationErrors] = useState({});
  const [listing, setListing] = useState({});

  const skuInputRef = useRef(null);

  const handleSubmit = async () => {
    setError({});
    setValidationErrors({});
    if (!validateForm()) {
      return;
    }

    setSubmitting(true);

    // Create an RPN
    const userId = JSON.parse(localStorage.getItem("user"))
      .userID.toString()
      .padStart(2, "0");
    const token = cryptoRandomString({ length: 8, type: "distinguishable" });
    const lpn = `RPN${userId}${token}`;

    const response = await listingService.createSkuListing({
      lpn,
      sku,
      quantity,
      userId,
    });

    if (response.success) {
      setListing(response.newListing);

      if (!response.newListing.listingId) {
        setSubmitting(false);
        toast.error("Error creating listing, please try again.");
        setError({ message: "Error creating listing, please try again." });
        return;
      }

      await printService.printListingLabel({
        fnsku: response.newListing.fnsku,
        sku: response.newListing.sku,
        amazonConditionLabel: response.newListing.amazonConditionLabel,
        inspectConditionLabel: response.newListing.inspectConditionLabel,
        title: response.newListing.title,
        account: response.newListing.account,
        listingId: response.newListing.listingId,
        userId,
        quantity: Number(response.newListing.quantity),
        inspectConditionCode: response.newListing.inspectConditionCode,
      });

      setToggle(false);
      setSubmitting(false);
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
      setSubmitting(false);
      setTimeout(() => {
        if (skuInputRef.current) {
          skuInputRef.current.focus();
        }
      }, 0);
    }
  };

  const validateForm = () => {
    let valid = true;

    // SKU validation --------------------------------
    if (!sku) {
      setValidationErrors((prevState) => {
        return { ...prevState, sku: "FNSKU is required." };
      });
      valid = false;
    }

    return valid;
  };

  const resetState = () => {
    setSku("");
    setError({});
    setQuantity(1);
    setListing({});
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start border-bottom">
        <FontAwesomeIcon icon={faTag} size="3x" className="me-3" />
        <div>
          <Card.Title>SKU List</Card.Title>
          <Card.Subtitle>Enter a SKU to create a listing.</Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        {Object.keys(error).length > 0 && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertOctagon
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              {error.message}
            </div>
          </Alert>
        )}
        <Collapse in={toggle}>
          <div>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>SKU</Form.Label>
                    <Form.Control
                      ref={skuInputRef}
                      autoFocus
                      size="lg"
                      placeholder="Enter a SKU..."
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                      isInvalid={validationErrors.fnsku}
                      disabled={submitting}
                    />
                    {validationErrors.sku && (
                      <Form.Control.Feedback type="invalid">
                        {validationErrors.sku}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col xs="auto" className="align-self-start">
                  {/* Adds space above the button for validation errors */}
                  <div style={{ height: `${29.45}px` }}></div>
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={submitting}
                  >
                    {submitting && (
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {submitting ? "Submitting..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Collapse>
        <Collapse in={!toggle}>
          <div>
            {Object.keys(listing).length > 0 && (
              <>
                <h4>Created Listing</h4>
                <Row className="mt-3">
                  <Col md={3}>
                    <a
                      href={`https://www.amazon.com/dp/${listing.asin}?th=1&psc=1`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        // style={{ maxHeight: "75px" }}
                        fluid
                        thumbnail
                        src={listing.image}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = imageNotFound;
                        }}
                      />
                    </a>
                  </Col>
                  <Col md={9}>
                    {listing.title && (
                      <div className="mb-3">
                        <h5 className="mb-0">Title</h5>
                        <Card.Text>{listing.title}</Card.Text>
                      </div>
                    )}
                    <Row className="mb-3">
                      <Col>
                        <h5 className="mb-0">ASIN</h5>
                        <Card.Text>
                          <a
                            href={`https://www.amazon.com/dp/${listing.asin}?th=1&psc=1`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {listing.asin}
                          </a>
                        </Card.Text>
                      </Col>
                      <Col>
                        <h5 className="mb-0">FNSKU</h5>
                        <Card.Text>{listing.fnsku}</Card.Text>
                      </Col>
                      <Col>
                        <h5 className="mb-0">SKU</h5>
                        <Card.Text>{listing.sku}</Card.Text>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <h5 className="mb-0">Listing Account</h5>
                        <Card.Text>{listing.accountLabel}</Card.Text>
                      </Col>
                      <Col>
                        <h5 className="mb-0">Condition</h5>
                        <Card.Text>{listing.amazonConditionLabel}</Card.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <div className="text-center">
              <Card.Link
                onClick={() => {
                  if (!submitting) {
                    resetState();
                    setToggle(true);
                  }
                }}
              >
                Create a new listing
              </Card.Link>
            </div>
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
};

export default SkuList;
