import React from "react";

import { Col, Row } from "react-bootstrap";

import TaskButtons from "./TaskButtons";

const Header = ({ headerTitle, showTaskButtons }) => {
  return (
    <Row className="mb-2 mb-xl-3 p-2">
      {/* <Row className="mb-2 mb-xl-3 p-2" style={{ backgroundColor: "lightgrey" }}> */}
      <Col md={3} sm={12}>
        <h3 className="d-sm-block">{headerTitle}</h3>
      </Col>
      {/* <Col md={6} sm={12}>
        {"task buttons here"}
        {showTaskButtons && <TaskButtons />}
      </Col>
      <Col md={3} sm={12}>
        More stuff can go here (header)
      </Col> */}
    </Row>
  );
};

export default Header;
