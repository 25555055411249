import axios from "axios";
import { handleError } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const createUser = async (user) => {
  // user = {
  //   "username": "MoneyLover42",
  //   "password": "krabbyPatty",
  //   "realName": "Eugene Krbs",
  //   "isAdmin": false
  // }

  console.log({ user });
  try {
    return (await axios.post(`${HOST_URL}/management/user`, user)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const getUsers = async () => {
  try {
    return (await axios.get(`${HOST_URL}/management/user`)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const updateUser = async (user) => {
  try {
    return (await axios.put(`${HOST_URL}/management/user/${user.userId}`, user))
      .data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const deleteUser = async (userId) => {
  try {
    return (await axios.delete(`${HOST_URL}/management/user/${userId}`)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const services = {
  createUser,
  getUsers,
  updateUser,
  deleteUser,
};

export default services;
