import { useState } from "react";
import Select from "react-select";

const ReceiveVendorDetail = ({
  stats,
  selectedVendorId,
  selectedVendorName,
  selectedReceiveDispositionsWithItems,
  selectedTotalItems,
  showPercents,
}) => {
  const [selectedVendor, setSelectedVendor] = useState({
    value: selectedVendorId,
    label: selectedVendorName,
  });
  const [receiveDispositionsWithItems, setReceiveDispositionsWithItems] =
    useState(selectedReceiveDispositionsWithItems);
  const [totalItems, setTotalItems] = useState(selectedTotalItems);

  const vendorOptions = stats.map((stat) => {
    return { value: stat.vendorId, label: stat.vendorName };
  });

  return (
    <div className="justify-content-center">
      <Select
        className="react-select-container w-50 mb-3 m-auto"
        classNamePrefix="react-select"
        onChange={(selectedValue) => {
          setSelectedVendor(selectedValue);
          const matchingVendor = stats.find(
            (stat) => stat.vendorId === selectedValue.value
          );
          setReceiveDispositionsWithItems(
            matchingVendor.receiveDispositionsWithItems
          );
          setTotalItems(matchingVendor.processedAndLiquidatedItems);
        }}
        options={vendorOptions}
        isSearchable
        value={selectedVendor}
      />
      <div>
        <table className="table table-bordered table-striped text-center m-auto">
          <thead>
            <tr>
              <th scope="col" className="px-4 py-2">
                Received Disposition
              </th>
              <th scope="col" className="px-4 py-2">
                Item Quantity
              </th>
              {showPercents && (
                <th scope="col" className="px-4 py-2">
                  Previous Day Comparison
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {receiveDispositionsWithItems.map((disposition, index) => {
              return (
                <tr key={index}>
                  <td className="px-2">
                    {disposition.receivedDispositionName}
                  </td>
                  <td className="px-2">
                    {disposition.receivedDispositionQuantity}
                  </td>
                  {showPercents && (
                    <td className="px-2">
                      {disposition.percentComparisonToPreviousDay >= 0 ? (
                        <span className="text-success">
                          +{disposition.percentComparisonToPreviousDay}%
                        </span>
                      ) : (
                        <span className="text-danger">
                          {disposition.percentComparisonToPreviousDay}%
                        </span>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="font-weight-bold">
              <td colSpan={3}>Total : {totalItems}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default ReceiveVendorDetail;
