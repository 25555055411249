import { X, Check } from "react-feather";

import { ButtonGroup, Button } from "react-bootstrap";

const tableColumns = [
  {
    Header: "Shipment Rack ID",
    accessor: "shipmentRackId",
  },
  {
    Header: "Total Items On Rack ",
    accessor: "totalItems",
  },
];

export default tableColumns;
