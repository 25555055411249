import { Button } from "react-bootstrap";
import { Printer } from "react-feather";
import printService from "../services/printService";

function PrintButton({ value }) {
  return (
    <Button
      className="mx-2 px-1 py-0 "
      variant="info"
      title="Click to print"
      onClick={() => {
        printService.printBarcodeLabel(value);
      }}
    >
      <Printer style={{ width: "16px", height: "16px" }} />
    </Button>
  );
}

export default PrintButton;
