import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Alert, Button, Card, Form, InputGroup, Image } from "react-bootstrap";
import { AlertCircle } from "react-feather";
import toast from "react-hot-toast";

import printService from "../../../../services/printService";
import shipmentService from "../../../../services/shipmentService";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const CartonForm = ({
  submitting,
  setSubmitting,
  shipmentCartonId,
  setShipmentCartonId,
  setImageSrc,
}) => {
  const [errorMessage, setErrorMessage] = useState();

  const inputElement = useRef(null);

  const handleSubmit = async () => {
    setErrorMessage();

    if (!shipmentCartonId) {
      toast.error("Please enter a Carton ID.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (shipmentCartonId.toString().toUpperCase() === "W") {
      toast.error("Please re-scan the Carton ID or enter it manually.");
      setShipmentCartonId("");
      return;
    }

    setSubmitting(true);

    const result = await shipmentService.getFbaShipmentLabelUrl({
      shipmentCartonId: Number(shipmentCartonId),
    });

    if (result.success) {
      if (result.labelUrl) {
        const fileName = await printService.printFbaShipmentLabel(
          result.labelUrl
        );
        setImageSrc(`${HOST_URL}/print/getLabel/${fileName}`);
      } else {
        setErrorMessage(result.message);
      }

      if (inputElement.current) {
        inputElement.current.focus();
        inputElement.current.select();
      }
      setSubmitting(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faBarcode} size="3x" className="me-3" />
          <div>
            <Card.Title>Shipment Carton</Card.Title>
            <Card.Subtitle>
              Scan or enter a Carton ID to print it's FBA Label.
            </Card.Subtitle>
          </div>
        </Card.Header>
        <Card.Body>
          {errorMessage && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {errorMessage}
              </div>
            </Alert>
          )}
          <Form.Label className="mb-0">Carton ID</Form.Label>
          <InputGroup>
            <Form.Control
              autoFocus
              size="lg"
              placeholder="Scan or Enter Carton ID..."
              ref={inputElement}
              value={shipmentCartonId}
              onChange={(e) => {
                setShipmentCartonId(e.target.value);
                setImageSrc();
              }}
              onFocus={(e) => {
                e.target.select();
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={submitting}
            >
              Submit
            </Button>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default CartonForm;
