import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  Collapse,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { AlertOctagon, Info } from "react-feather";
import toast from "react-hot-toast";
import Select from "react-select";

import receivingService from "../../../../services/receiving.service";
import utilsService from "../../../../services/utils.service";

import { ReceivingContext } from "../../ReceivingPage";

const TNForm = () => {
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [vendorSelectToggle, setVendorSelectToggle] = useState(false);

  const [vendorOptions, setVendorOptions] = useState();

  const [trackingNumber, setTrackingNumber] = useState("");
  const [selectedVendorOption, setSelectedVendorOption] = useState(null);

  const {
    flags,
    setDocuments,
    setFlags,
    setItems,
    setManifest,
    setShipment,
    setVendor,
  } = useContext(ReceivingContext);

  const vendorSelectRef = useRef();

  useEffect(() => {
    if (!vendorOptions) {
      getVendorOptions();
    }
  }, []);

  const getVendorOptions = async () => {
    const response = await utilsService.getVendors();

    if (response.success) {
      const optionizedVendors = response.vendors.map((vendor) => {
        return {
          value: vendor,
          label: `${
            vendor.vendorRMACode ? vendor.vendorRMACode.trim() + " | " : ""
          }${vendor.vendorName.trim()}`,
        };
      });

      setVendorOptions(optionizedVendors);
    }
  };

  const handleSubmitTrackingNumber = async () => {
    setSubmitting(true);
    setError();
    setInfo();

    if (!trackingNumber) {
      toast.error("Please enter a tracking number.");
      setError({ message: "Please enter a tracking number." });
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (trackingNumber.toUpperCase() === "W") {
      toast.error("Please re-scan tracking number or enter it manually.");
      setError({
        message: "Please re-scan tracking number or enter it manually.",
      });
      setTrackingNumber("");
      return;
    }
    let realTrackingNumber = trackingNumber;
    if (trackingNumber.includes(")")) {
      realTrackingNumber = trackingNumber.substring(11).replace(")", "");
      setTrackingNumber(realTrackingNumber);
    }
    const response = await receivingService.getReceivedShipments({
      trackingNumber: realTrackingNumber,
    });

    if (response.success) {
      const shipmentData = response.shipmentData;

      // Some data present if received shipment was already created or vendor
      // was found from the tracking number.
      if (Object.keys(shipmentData).length !== 0) {
        // Check if there are items for the shipment
        if (shipmentData.items) {
          setItems(shipmentData.items);
        }

        // Check if there are documents for the shipment
        if (shipmentData.documents) {
          setDocuments(shipmentData.documents);
        }

        // Check if there is a manifest for the tracking number
        if (shipmentData.manifest) {
          setManifest(shipmentData.manifest);
        }

        // Check if there are flags
        if (shipmentData.flags) {
          setFlags(shipmentData.flags);
        }

        // Check if there is a received shipment.
        if (shipmentData.shipment) {
          setVendor(shipmentData.vendor);
          setSubmitting(false);
          setShipment(shipmentData.shipment);
        } else {
          // No shipment created yet, check for vendor from tracking number
          if (shipmentData.vendor) {
            // Create a new received shipment with found vendor.
            const userId = JSON.parse(
              localStorage.getItem("user")
            ).userID.toString();

            const response = await receivingService.createReceivedShipment({
              userId,
              vendorId: shipmentData.vendor.vendorId,
              trackingNumber: realTrackingNumber,
            });

            if (response.success) {
              setVendor(shipmentData.vendor);

              if (flags) {
                setFlags((prevState) => ({
                  ...prevState,
                  vendorFlags: response.vendorFlags,
                }));
              } else {
                setFlags({ vendorFlags: response.vendorFlags });
              }

              setSubmitting(false);
              setShipment(response.newShipment);
            } else {
              toast.error("Something went wrong, please try again.");
              setError(response.error);
              setSubmitting(false);
            }
          } else {
            setInfo({
              message: "Select a Vendor to continue.",
            });
            setVendorSelectToggle(true);
            setTimeout(() => {
              if (vendorSelectRef.current) {
                vendorSelectRef.current.focus();
              }
            }, 0);
            setSubmitting(false);
          }
        }
      } else {
        setInfo({
          message: "Select a Vendor to continue.",
        });
        setVendorSelectToggle(true);
        setTimeout(() => {
          if (vendorSelectRef.current) {
            vendorSelectRef.current.focus();
          }
        }, 0);
        setSubmitting(false);
      }
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
      setSubmitting(false);
    }
  };

  const handleSubmitVendor = async () => {
    if (!selectedVendorOption) {
      toast.error("Please select a vendor.");
      return;
    }

    setSubmitting(true);
    setError();
    setInfo();

    // Create a new received shipment
    const userId = JSON.parse(localStorage.getItem("user")).userID.toString();

    const response = await receivingService.createReceivedShipment({
      userId,
      vendorId: selectedVendorOption.value.vendorId,
      trackingNumber,
    });

    if (response.success) {
      setVendor(selectedVendorOption.value);

      if (flags) {
        setFlags((prevState) => ({
          ...prevState,
          vendorFlags: response.vendorFlags,
        }));
      } else {
        setFlags({ vendorFlags: response.vendorFlags });
      }

      setSubmitting(false);
      setShipment(response.newShipment);
    } else {
      toast.error("Something went wrong, please try again.");
      setError(response.error);
      setSubmitting(false);
    }
  };

  return (
    <Card>
      <Collapse in={!vendorSelectToggle}>
        <div>
          <Card.Header className="d-flex justify-content-start border-bottom">
            <FontAwesomeIcon icon={faBarcode} size="3x" className="me-3" />
            <div>
              <Card.Title>Tracking Number</Card.Title>
              <Card.Subtitle>
                Scan or enter a tracking number for this incoming shipment.
              </Card.Subtitle>
            </div>
          </Card.Header>
          <Card.Body>
            {error && (
              <Alert variant="danger" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <AlertOctagon
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {error.message}
                </div>
              </Alert>
            )}
            {info && (
              <Alert variant="info" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <Info
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {info.message}
                </div>
              </Alert>
            )}
            <Form.Label>Tracking Number</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                required
                autoFocus
                size="lg"
                placeholder="Scan or Enter Tracking Number..."
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                onFocus={(e) => {
                  e.target.select();
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmitTrackingNumber();
                  }
                }}
                disabled={submitting}
              />
              <Button
                variant="primary"
                onClick={handleSubmitTrackingNumber}
                disabled={submitting}
              >
                {submitting && (
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {submitting ? "Submitting..." : "Submit"}
              </Button>
            </InputGroup>
            <div className="text-center">
              <Card.Link
                onClick={() => {
                  if (!submitting) {
                    setTrackingNumber("");
                    setSelectedVendorOption(null);
                    setVendor();
                    setError();
                    setInfo();
                    setVendorSelectToggle(!vendorSelectToggle);
                    setTimeout(() => {
                      if (vendorSelectRef.current) {
                        vendorSelectRef.current.focus();
                      }
                    }, 0);
                  }
                }}
              >
                Submit without a Tracking Number
              </Card.Link>
            </div>
          </Card.Body>
        </div>
      </Collapse>
      <Collapse in={vendorSelectToggle}>
        <div>
          <Card.Header className="d-flex justify-content-start border-bottom">
            <FontAwesomeIcon icon={faBarcode} size="3x" className="me-3" />
            <div>
              <Card.Title>Vendor</Card.Title>
              <Card.Subtitle>
                Select the appropriate vendor for this incoming shipment.
              </Card.Subtitle>
            </div>
          </Card.Header>
          <Card.Body>
            {error && (
              <Alert variant="danger" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <AlertOctagon
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {error.message}
                </div>
              </Alert>
            )}
            {info && (
              <Alert variant="info" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <Info
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  {info.message}
                </div>
              </Alert>
            )}
            <Form.Label>Vendors</Form.Label>
            <InputGroup className="mb-3">
              <div className="react-select form-control p-0">
                <Select
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      margin: "-1px",
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: "4px 0 0 4px",
                    }),
                  }}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select a Vendor..."
                  onChange={(selectedOption) => {
                    setSelectedVendorOption(selectedOption);
                  }}
                  value={selectedVendorOption}
                  options={vendorOptions}
                  isSearchable
                  isLoading={!vendorOptions}
                  ref={vendorSelectRef}
                />
              </div>
              <Button
                className="d-inline"
                variant="primary"
                disabled={submitting || !selectedVendorOption}
                onClick={handleSubmitVendor}
              >
                {submitting && (
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Continue
              </Button>
            </InputGroup>
            <div className="text-center">
              <Card.Link
                onClick={() => {
                  if (!submitting) {
                    setTrackingNumber("");
                    setSelectedVendorOption(null);
                    setVendor();
                    setError();
                    setInfo();
                    setVendorSelectToggle(!vendorSelectToggle);
                  }
                }}
              >
                Submit with a Tracking Number
              </Card.Link>
            </div>
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

export default TNForm;
