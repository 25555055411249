import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import toast from "react-hot-toast";
import { AlertOctagon } from "react-feather";

import shipmentService from "../../../../services/shipmentService";
import printService from "../../../../services/printService";

const PalletForm = ({
  cartonData,
  destinationOptions,
  setDestinations,
  setSubmitting,
  submitting,
}) => {
  const [error, setError] = useState();
  const [selectedDestinationOption, setSelectedDestinationOption] = useState();

  useEffect(() => {
    if (cartonData && cartonData.shipmentCarton.destinationCenterId) {
      const foundOption = destinationOptions.find(
        (option) =>
          option.value === cartonData.shipmentCarton.destinationCenterId
      );

      if (foundOption) {
        setSelectedDestinationOption(foundOption);
      }
    } else {
      setSelectedDestinationOption();
    }
  }, [cartonData, destinationOptions]);

  useEffect(() => {
    if (error) {
      setError("");
    }
  }, [selectedDestinationOption]);

  const createShipmentPallet = async () => {
    setSubmitting(true);

    const response = await shipmentService.createShipmentPallet(
      selectedDestinationOption.value
    );

    if (response.success) {
      const { newShipmentPallet } = response;

      //print pallet label
      const palletLabelData = {
        shipmentPalletId: newShipmentPallet.shipmentPalletId,
        destinationCenterId: newShipmentPallet.destinationCenterId,
      };

      await printService.printPalletLabel(palletLabelData);

      toast.success("Successfully created Shipment Pallet.");

      setDestinations((prevState) => {
        return prevState.map((destination) => {
          if (
            destination.destinationCenterId.toUpperCase() ===
            newShipmentPallet.destinationCenterId.toUpperCase()
          ) {
            destination.totalPallets++;
            destination.shipmentPallets.push(newShipmentPallet);
          }
          return destination;
        });
      });
      setSelectedDestinationOption();
    } else {
      toast.error("Unable to create Shipment Pallet.");
      setError(response);
    }

    setSubmitting(false);
  };

  return (
    <>
      {error && (
        <Alert variant="danger" className="flex-fill alert-blink">
          <div className="alert-icon">
            <AlertOctagon
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message font-weight-bold">{error.message}</div>
        </Alert>
      )}
      <Form.Label className="mb-0">Destination Center</Form.Label>
      <InputGroup className="mb-3">
        <div className="react-select form-control p-0">
          <Select
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                margin: "-1px",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "4px 0 0 4px",
              }),
            }}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select a Destination Center..."
            onChange={setSelectedDestinationOption}
            value={selectedDestinationOption || ""}
            options={destinationOptions}
            isSearchable
            disabled={submitting || !selectedDestinationOption}
          />
        </div>
        <Button
          className="d-inline"
          variant="success"
          onClick={() => createShipmentPallet()}
          disabled={submitting || !selectedDestinationOption}
        >
          Create
        </Button>
      </InputGroup>
    </>
  );
};

export default PalletForm;
