import React, { useState } from "react";
import UIDefaultPage from "../../components/UIDefaultPage";
import UICard from "../../components/UICard";
import UIInput from "../../components/UIInput";
import UIButton from "../../components/UIButton";
import { Spinner, Alert, ButtonGroup, ToggleButton } from "react-bootstrap";
import toast from "react-hot-toast";

import shipmentService from "../../services/shipmentService";

function RackFromBatchPage() {
  const [batchId, setBatchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [radioValue, setRadioValue] = useState(1);

  const submitBatchId = async () => {
    if (!batchId) {
      setError("Please enter a batch id");
      return;
    }
    if (batchId.toUpperCase() === "W") {
      setError("Please re-scan batch id or enter it manually");
      setBatchId("");
      return;
    }

    try {
      setIsLoading(true);
      const rackResponse = await shipmentService.rackFromBatch({
        batchId,
        shipmentCategoryId: radioValue,
      });
      setIsLoading(false);

      if (rackResponse.success) {
        toast.success("Rack successfully created");
      } else {
        setError(rackResponse.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
      setIsLoading(false);
      setError(error.response.data.message);
      console.log(error);
    }
    setTimeout(() => {
      setBatchId("");
      setError("");
    }, 1500);
  };

  const header = <h4 className="text-center">{"Rack From Batch"}</h4>;

  const body = isLoading ? (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <span className="px-2 font-weight-bold"> Loading... </span>
      <Spinner animation="border" variant="secondary" />
    </div>
  ) : (
    <>
      <div className="ms-3 mb-2">
        <ButtonGroup>
          <ToggleButton
            className="text-nowrap"
            id="boxId"
            type="radio"
            variant="outline-info"
            value={1}
            checked={radioValue === 1}
            onChange={(e) => setRadioValue(Number(e.currentTarget.value))}
            disabled={isLoading}
          >
            Standard
          </ToggleButton>
          <ToggleButton
            id="fnsku"
            type="radio"
            variant="outline-info"
            value={2}
            checked={radioValue === 2}
            onChange={(e) => setRadioValue(Number(e.currentTarget.value))}
            disabled={isLoading}
          >
            Oversize
          </ToggleButton>
        </ButtonGroup>
      </div>
      {error && (
        <Alert className="p-3 justify-content-center" variant="danger">
          <span className="text-center">{error}</span>
        </Alert>
      )}

      <UIInput
        required
        autoFocus
        value={batchId}
        onChange={(e) => {
          e.preventDefault();
          setBatchId(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            submitBatchId();
          }
        }}
        type="text"
        placeholder="Enter a Batch ID"
        size="lg"
        className="my-3 w-75 m-auto text-center"
      />

      <UIButton
        variant="primary"
        text="Submit"
        onClick={() => submitBatchId()}
      />
    </>
  );
  const children = (
    <UICard className="text-center w-75 m-auto" header={header} body={body} />
  );
  return <UIDefaultPage title={"Rack From Batch"} children={children} />;
}

export default RackFromBatchPage;
