import React from "react";
import { Col, Button, Card, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HomepageCard = ({ section }) => {
  const navigate = useNavigate();

  const DashboardButton = ({ page }) => (
    <Col className="p-2">
      <Button
        variant="outline-dark"
        size="lg"
        onClick={() => navigate(page.href)}
        className="w-100"
        style={{ height: "88px" }}
      >
        <page.icon className="feather align-middle" color="#3F80EA" />
        <br />
        {page.title}
      </Button>
    </Col>
  );

  return (
    <Col xs={4}>
      <Card className="text-center">
        <Card.Header>
          <h3>{section.title}</h3>
        </Card.Header>
        <Card.Body>
          <Row sm={3} className="justify-content-center">
            {section.children.map((page) => (
              <DashboardButton page={page} />
            ))}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default HomepageCard;
