import axios from "axios";
import { handleError } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

//---------------------------------------------------------------------------
// General API calls
//---------------------------------------------------------------------------
const getListingAccounts = async () => {
  const response = (await axios.get(`${HOST_URL}/management/listingAccounts`))
    .data.listingAccounts;

  console.log({ response });

  return response;
};

//---------------------------------------------------------------------------
// Restrictions API calls
//---------------------------------------------------------------------------
const updateRestrictedShipmentCategories = async (data) => {
  // Separate the shipmentCategoryIds that need to be inserted and deleted
  const allCategoryIds = [1, 2, 3, 4, 5, 6, 7];
  const shipmentCategoryIdsToDelete = allCategoryIds.filter(
    (id) => !data.shipmentCategoryIds.includes(id)
  );

  const insertData = data;
  const deleteData = {
    listingAccountId: data.listingAccountId,
    shipmentCategoryIds: shipmentCategoryIdsToDelete,
  };

  const response1 = (
    await axios.post(
      `${HOST_URL}/management/shipmentCategoryRestrictions`,
      insertData
    )
  ).data;

  const response2 = (
    await axios.post(
      `${HOST_URL}/management/shipmentCategoryRestrictions/delete`,
      deleteData
    )
  ).data;

  let response3;
  //isActive only gets passed through if it was changed from initial value
  if (data.hasOwnProperty("isActive")) {
    response3 = (
      await axios.post(`${HOST_URL}/management/listingAccountEdit`, data)
    ).data;
    if (!response3.success) {
      return { success: false };
    }
  }

  if (!response1.success || !response2.success) {
    return { success: false };
  }

  return { success: true };
};

const getRestrictions = async () => {
  const response = (await axios.get(`${HOST_URL}/management/restrictions`)).data
    .restrictions;

  return response;
};

const deleteRestriction = async (data) => {
  const response = (
    await axios.delete(
      `${HOST_URL}/management/restrictions?asin=${data.asin}&conditionId=${data.conditionId}&sellingPartnerId=${data.sellingPartnerId}`
    )
  ).data;

  return response;
};

const getOtps = async ({ isAdmin }) => {
  const response = (
    await axios.get(`${HOST_URL}/management/otps?isAdmin=${isAdmin}`)
  ).data.otps;

  return response;
};

const services = {
  getListingAccounts,
  updateRestrictedShipmentCategories,
  getRestrictions,
  deleteRestriction,
  getOtps,
};

export default services;
