import React from "react";
import InspectOption from "./InspectOption";

function InspectOptions({ optionsGroup, options, submitInspection }) {
  return (
    <div className="my-3">
      <div className="text-center">
        <h3>{optionsGroup}</h3>
      </div>
      {optionsGroup === "List" ? (
        <div className="d-grid gap-4">
          <div className="d-grid gap-2 p-2">
            {options
              .filter((option) => option.label.includes("New"))
              .map((option) => (
                <InspectOption
                  key={option.inspectConditionId}
                  option={option}
                  submitInspection={submitInspection}
                  variant={"success"}
                />
              ))}
          </div>
          <div className="d-grid gap-2 p-2">
            {options
              .filter((option) => !option.label.includes("New"))
              .map((option) => (
                <InspectOption
                  key={option.inspectConditionId}
                  option={option}
                  submitInspection={submitInspection}
                  variant={"success"}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className="d-grid gap-2 p-2">
          {options.map((option) => (
            <InspectOption
              key={option.inspectConditionId}
              option={option}
              submitInspection={submitInspection}
              variant={"danger"}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default InspectOptions;
