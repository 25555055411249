import { Fragment, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Alert, Button, Row, Col } from "react-bootstrap";
import listingService from "../../services/listingService";
import ASINandConditionForm from "./components/listingRestrictions/ASINandConditionForm";
import ListingRestrictionsByStore from "./components/listingRestrictions/ListingRestrictionsByStore";

const ListingRestrictions = () => {
  const [asin, setAsin] = useState("");
  const [selectedCondition, setSelectedCondition] = useState();
  const [listingRestrictions, setListingRestrictions] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showError, setShowError] = useState(false);

  const getRestrictions = async (asin, conditionId) => {
    try {
      const result = await listingService.getListingRestrictionsByASIN(
        asin.toUpperCase(),
        conditionId
      );
      setListingRestrictions(result);
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response.data.message);
      setShowError(true);
    }
  };

  return (
    <Fragment>
      <Helmet title="Listing Restrictions" />
      <h1 className="h3 mb-3">Listing Restrictions</h1>
      <Card>
        {(listingRestrictions || showError) && (
          <div>
            <Button
              title={"Click to enter another ASIN"}
              onClick={() => {
                window.location.href = "/processing/listingRestrictions";
              }}
              className="py-2 px-4 ms-3 mt-3"
            >
              Back
            </Button>
          </div>
        )}
        <Card.Body className="w-25 m-auto">
          {showError ? (
            <Alert className="p-3" variant="danger">
              <div>
                Unable to get Amazon listing restrictions for ASIN '{asin}'.
                {errorMessage ? <p>Error: {errorMessage}</p> : ""}
              </div>
            </Alert>
          ) : !listingRestrictions ? (
            <ASINandConditionForm
              asin={asin}
              setAsin={setAsin}
              selectedCondition={selectedCondition}
              setSelectedCondition={setSelectedCondition}
              getRestrictions={getRestrictions}
            />
          ) : (
            <ListingRestrictionsByStore
              asin={asin}
              selectedCondition={selectedCondition.label}
              listingRestrictionsByStore={listingRestrictions}
            />
          )}
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ListingRestrictions;
