import React, { useMemo, useState, useEffect } from "react";

import { Button, Card, Form } from "react-bootstrap";
import { Truck } from "react-feather";

import Loader from "../../../../components/Loader";
import Table from "../../../../components/tables/Table";
import TrailerCreateModal from "./TrailerCreateModal";

const Trailers = ({
  allTrailers,
  setSelectedTrailer,
  getTrailers,
  handleScroll,
  isLoading,
}) => {
  const handleRowClick = async (row) => {
    setSelectedTrailer(row.original);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Trailer ID",
        accessor: "shipmentTrailerId",
      },
      {
        Header: "Destination Center IDs",
        accessor: "destinationCenterIds",
        Cell: ({ value }) => {
          return <span>{value.split(",").sort().join(", ")}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "trailerStatus",
      },
      {
        Header: "Type",
        accessor: "trailerType",
      },
    ],
    []
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <Truck className="me-3" size={40} />
            <Card.Title>Trailers</Card.Title>
          </div>
          <TrailerCreateModal
            getTrailers={getTrailers}
            setSelectedTrailer={setSelectedTrailer}
          />
        </Card.Header>
        <Card.Body>
          {allTrailers && (
            <Table
              columns={columns}
              data={allTrailers}
              onRowClick={handleRowClick}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Trailers;
