import { X, Check } from "react-feather";

export const shipmentRacksTableColumns = [
  {
    Header: "Shipment Rack ID",
    accessor: "shipmentRackId",
  },
  {
    Header: "Total Items On Rack",
    accessor: "totalItems",
  },
];

export const shipmentRackItemsTableColumns = [
  {
    Header: "FNKSU",
    accessor: "fnsku",
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "In Carton?",
    accessor: "inShipmentCarton",
    Cell: ({ value }) => {
      if (value) {
        return <Check color="#4BBF73" size={24} />;
      } else {
        return <X color="#d9534f" size={24} />;
      }
    },
    sortType: (row) => (row.original.inShipmentCarton ? -1 : 1),
  },
];
