import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
import avatar from "../../assets/img/avatars/avatar-1.jpg";

const SignInPage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <div className="text-center mt-4">
        <h2>Welcome back, {user?.name}</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            {/* <div className="text-center">
              <img
                src={avatar}
                alt={user?.name}
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              />
            </div> */}
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
