import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Card,
  Col,
  ListGroup,
  Row,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";

import imageNotFound from "../../../../assets/img/imageNotFound.png";

import { deepClone } from "../../../../utils/helper";
import shipmentService from "../../../../services/shipmentService";

const ShipmentRackItems = ({
  selectedShipmentRack,
  setSelectedShipmentRack,
  selectedShipmentRackItems,
  shipments,
  setShipments,
  activeKey,
  setActiveKey,
}) => {
  const [confirmingCloseShipmentId, setConfirmingCloseShipmentId] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);

  const shipmentRackItemsRef = useRef(null);

  useEffect(() => {
    let reducedShipments = selectedShipmentRackItems.reduce((result, item) => {
      //Clone the item first to preserve the selectedShipmentRack' items.
      const clonedItem = deepClone(item);

      //Add quantity properties to the cloned item.
      clonedItem.quantity = 1;
      clonedItem.quantityInShipmentCarton = 0;

      //Find an existing shipment.
      const existingShipmentIndex = result.findIndex(
        (shipment) => shipment.shipmentId === clonedItem.shipmentId
      );

      if (existingShipmentIndex !== -1) {
        //If an existing shipment is found, find an existing item.
        const foundShipmentRackItem = result[
          existingShipmentIndex
        ].shipmentRackItems.find(
          (thisItem) => thisItem.fnsku === clonedItem.fnsku
        );

        if (foundShipmentRackItem) {
          //If an existing item is found, update the quantities.
          foundShipmentRackItem.quantity++;
          result[existingShipmentIndex].totalShipmentRackItems++;

          if (clonedItem.inShipmentCarton) {
            foundShipmentRackItem.quantityInShipmentCarton++;
          }
        } else {
          if (clonedItem.inShipmentCarton) {
            clonedItem.quantityInShipmentCarton++;
          }

          result[existingShipmentIndex].shipmentRackItems.push(clonedItem);
          result[existingShipmentIndex].totalShipmentRackItems++;
        }
      } else {
        if (clonedItem.inShipmentCarton) {
          clonedItem.quantityInShipmentCarton++;
        }

        result.push({
          amazonShipmentId: clonedItem.amazonShipmentId,
          destinationCenterId: clonedItem.shipTo,
          shipToCity: clonedItem.shipToCity,
          shipToState: clonedItem.shipToState,
          shipmentStatus: clonedItem.shipmentStatus,
          shipmentStatusId: clonedItem.shipmentStatusId,
          shipmentId: clonedItem.shipmentId,
          shipTo: clonedItem.shipTo,
          totalShipmentRackItems: 1,
          isShipmentPlan: clonedItem.isShipmentPlan,
          shipmentRackItems: [clonedItem],
        });
      }

      return result;
    }, []);

    //Sort by shipmentId, putting null shipmentId last
    reducedShipments.sort((a, b) => {
      if (a.shipmentId === b.shipmentId) {
        return 0;
      } else if (a.shipmentId) {
        return -1;
      } else {
        return 1;
      }
    });

    const groupOfItemsNotInShipments = reducedShipments.find(
      (shipment) => shipment.shipmentId === null
    );
    const itemsOnlyInShipmentPlan = selectedShipmentRackItems.filter(
      (item) => item.shipmentStatusId === 1 && item.isShipmentPlan
    );

    if (itemsOnlyInShipmentPlan.length && groupOfItemsNotInShipments) {
      groupOfItemsNotInShipments.shipmentRackItems = [
        ...groupOfItemsNotInShipments.shipmentRackItems,
        ...itemsOnlyInShipmentPlan,
      ];
    } else if (itemsOnlyInShipmentPlan.length) {
      reducedShipments.push({
        amazonShipmentId: null,
        destinationCenterId: null,
        shipToCity: null,
        shipToState: null,
        shipmentStatus: null,
        shipmentStatusId: null,
        shipmentId: null,
        shipTo: null,
        totalShipmentRackItems: itemsOnlyInShipmentPlan.length,
        shipmentRackItems: itemsOnlyInShipmentPlan,
      });
    }

    reducedShipments = reducedShipments.filter(
      (shipment) =>
        !shipment.isShipmentPlan ||
        shipment.shipmentStatusId > 1 ||
        shipment.shipmentId === null
    );

    setShipments(reducedShipments);
    setActiveKey(null);
    setConfirmingCloseShipmentId(false);
  }, [selectedShipmentRack, selectedShipmentRackItems]);

  const handleAccordionClick = (eventKey) => {
    // If the clicked accordion panel is already open, close it by setting activeKey to null
    setActiveKey((prevKey) => (prevKey === eventKey ? null : eventKey));
  };

  const closeShipment = async () => {
    setSubmitting(true);
    const result = await shipmentService
      .closeShipment({
        shipmentId: confirmingCloseShipmentId,
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
    console.log(result);
    setConfirmingCloseShipmentId(false);

    if (result.success) {
      toast.success("Shipment closed successfully.");
      setSelectedShipmentRack({ ...selectedShipmentRack });
    } else {
      toast.error(result.message);
    }
    setSubmitting(false);
  };
  useEffect(() => {
    if (shipmentRackItemsRef.current) {
      shipmentRackItemsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  const CloseShipmentConfirmModal = () => {
    const { amazonShipmentId } =
      confirmingCloseShipmentId &&
      shipments.find(
        (shipment) => shipment.shipmentId === confirmingCloseShipmentId
      );
    return (
      <Modal
        show={confirmingCloseShipmentId}
        onHide={() => setConfirmingCloseShipmentId(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Close Shipment {amazonShipmentId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitting
            ? "Please wait. This might take up to 3 minutes."
            : ` This will submit the box content feed to Amazon. Any items not yet in
          a carton will be removed from this Amazon shipment. Are you sure you
          want to continue?`}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button
              variant="secondary"
              onClick={() => setConfirmingCloseShipmentId(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={closeShipment}
              disabled={submitting}
            >
              {submitting ? "Closing Shipment" : "Continue"}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CloseShipmentConfirmModal />

      <Card>
        <Card.Header
          className="d-flex justify-content-between"
          ref={shipmentRackItemsRef}
        >
          <div className="d-flex justify-content-start">
            <FontAwesomeIcon icon={faGem} size="3x" className="me-3" />
            <div>
              <Card.Title>Shipment Rack Items</Card.Title>
              <Card.Subtitle>
                All of shipment rack{" "}
                <strong>{selectedShipmentRack.shipmentRackId}</strong>'s items
                sorted by shipment.
              </Card.Subtitle>
            </div>
          </div>
          <h1>{selectedShipmentRack.shipmentRackId}</h1>
        </Card.Header>
        <Card.Body>
          <Accordion
            activeKey={activeKey}
            onSelect={handleAccordionClick}
            alwaysOpen
            flush
            className="border"
          >
            {shipments.map((shipment, index) => {
              const quantityInShipmentCarton =
                shipment.shipmentRackItems.reduce((acc, curr) => {
                  return acc + curr.quantityInShipmentCarton;
                }, 0);

              // Boolean to check if shipment has all rack items in cartons.
              const shipmentComplete =
                shipment.totalShipmentRackItems === quantityInShipmentCarton;
              // Boolean to check if shipment has status of closed.

              const isSelectedShipmentOpen =
                shipment.shipmentStatusId !== 3 && shipment.shipmentId;

              return (
                <Accordion.Item key={index} eventKey={shipment.shipmentId}>
                  <Accordion.Header>
                    {(shipment.shipmentId && !shipment.isShipmentPlan) ||
                    (shipment.shipmentId && shipment.shipmentStatusId > 1) ? (
                      <>
                        <div className="w-75">
                          <h3 style={{ userSelect: "text" }}>
                            {shipment.amazonShipmentId}
                          </h3>
                          <Row>
                            <Col>
                              <div>Location:</div>
                              <div>
                                <strong>{shipment.shipTo}</strong>
                                {shipment.shipToCity &&
                                  `, ${shipment.shipToCity}, ${shipment.shipToState}`}
                              </div>
                            </Col>
                            <Col>
                              <div>Status:</div>
                              <div
                                className={
                                  shipmentComplete ? "text-success" : ""
                                }
                              >
                                {shipment.shipmentStatus}
                              </div>
                            </Col>
                            <Col>
                              <div>Total Items:</div>
                              <div
                                className={
                                  shipmentComplete ? "text-success" : ""
                                }
                              >
                                {quantityInShipmentCarton} /{" "}
                                {shipment.totalShipmentRackItems}
                              </div>
                            </Col>
                            <Col>
                              <div>Complete:</div>
                              <div>
                                {shipmentComplete ? (
                                  <Check color="#4BBF73" size={24} />
                                ) : (
                                  <X color="#d9534f" size={24} />
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          {isSelectedShipmentOpen && (
                            <Button
                              size="lg"
                              variant="danger"
                              onClick={() =>
                                setConfirmingCloseShipmentId(
                                  shipment.shipmentId
                                )
                              }
                              disabled={confirmingCloseShipmentId}
                            >
                              Close Shipment
                            </Button>
                          )}
                        </div>
                      </>
                    ) : (
                      <h3>No Shipment Available</h3>
                    )}
                  </Accordion.Header>

                  <Accordion.Body className="p-0">
                    <ListGroup key={index} variant="flush">
                      {shipment.shipmentRackItems
                        .sort((a, b) => {
                          if (
                            (a.quantity === a.quantityInShipmentCarton) ===
                            (b.quantity === b.quantityInShipmentCarton)
                          ) {
                            return 0;
                          } else if (
                            a.quantity === a.quantityInShipmentCarton
                          ) {
                            return 1;
                          } else {
                            return -1;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <ListGroup.Item
                              key={index}
                              variant={
                                item.shipmentStatusId < 2
                                  ? "danger"
                                  : item.quantity ===
                                      item.quantityInShipmentCarton && "success"
                              }
                            >
                              <Row>
                                <Col>
                                  <Image
                                    style={{ maxHeight: "75px" }}
                                    fluid
                                    thumbnail
                                    src={
                                      item.images
                                        ? item.images[0]
                                        : imageNotFound
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = imageNotFound;
                                    }}
                                  />
                                </Col>
                                <Col>
                                  FNSKU
                                  <h4 className="text-muted">{item.fnsku}</h4>
                                </Col>
                                <Col>
                                  SKU
                                  <h4 className="text-muted">{item.sku}</h4>
                                </Col>
                                {item.shipmentStatusId > 1 && (
                                  <Col>
                                    Quantity in Cartons?
                                    <h4 className="text-muted">
                                      {item.quantityInShipmentCarton} /{" "}
                                      {item.quantity}
                                    </h4>
                                  </Col>
                                )}
                              </Row>
                            </ListGroup.Item>
                          );
                        })}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
};

export default ShipmentRackItems;
