import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Image, Row } from "react-bootstrap";
import { AlertCircle, Camera, Trash2 } from "react-feather";
import Webcam from "react-webcam";

const Gallery = ({ images, setImages, disabled }) => {
  const deleteImage = (imageId) => {
    setImages((prevState) => {
      return prevState.filter((image) => image.imageId !== imageId);
    });
  };

  return (
    <Row>
      {images.map((image, index) => {
        return (
          <Col key={index} xs={3} className="mb-3">
            <div className="position-relative">
              <Image src={`${image.imageSrc}`} fluid />
              <Button
                variant="danger"
                className="position-absolute top-0 start-0"
                onClick={() => deleteImage(image.imageId)}
                disabled={disabled}
              >
                <Trash2 size={16} />
              </Button>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

const ImageCapture = ({ images, setImages, disabled = false }) => {
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const webcamRef = useRef(null);

  // Capture image
  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setImages((prevState) => {
      return prevState.concat({ imageId: new Date().toISOString(), imageSrc });
    });
  };

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraAvailable(videoDevices.length > 0);
      } catch (error) {
        console.error("Error checking camera availability:", error);
      }
    };

    checkCameraAvailability();
  }, []);

  return (
    <>
      {cameraAvailable ? (
        showCamera ? (
          <div className="text-center mb-3">
            <div className="d-grid gap-2">
              <Button
                variant="secondary"
                size="lg"
                onClick={() => setShowCamera(false)}
              >
                Hide Camera
              </Button>
            </div>
            <Webcam
              className="my-3"
              audio={false}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
            />
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                size="lg"
                onClick={captureImage}
                disabled={disabled}
              >
                <Camera size={20} />
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-grid gap-2">
            <Button
              className="mb-3"
              variant="secondary"
              size="lg"
              onClick={() => setShowCamera(true)}
            >
              Show Camera
            </Button>
          </div>
        )
      ) : (
        <Alert variant="danger" className="alert">
          <div className="alert-icon">
            <AlertCircle
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message">
            No Camera Detected. Please connect a webcam and try again.
          </div>
        </Alert>
      )}
      <Gallery images={images} setImages={setImages} disabled={disabled} />
    </>
  );
};

export default ImageCapture;
