import React from "react";
import { Helmet } from "react-helmet-async";

import UIDefaultPage from "../../components/UIDefaultPage";
import EmployeeActivity from "./components/employeeActivityTable/EmployeeActivity";

const EmployeeTable = () => {
  return (
    <UIDefaultPage
      title={"Employee Activity"}
      children={<EmployeeActivity />}
    />
  );
};

export default EmployeeTable;
