import React, { useState, useEffect } from "react";
import { Col, Container, Row, ListGroup, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import FnskuList from "./components/alternateListing/fnskuListing/FnskuList";
import VendorSelect from "./components/alternateListing/asinListing/VendorSelect";
import AsinList from "./components/alternateListing/asinListing/AsinList";
import SkuList from "./components/alternateListing/skuListing/SkuList";

import useLocalStorage from "../../hooks/useLocalStorage";

const Navigation = ({ activeTab, setActiveTab, submitting }) => {
  return (
    <ListGroup horizontal>
      <ListGroup.Item
        tag="a"
        className="text-center"
        onClick={() => {
          setActiveTab(0);
        }}
        action
        active={activeTab === 0}
        disabled={submitting}
      >
        ASIN
      </ListGroup.Item>
      <ListGroup.Item
        tag="a"
        className="text-center"
        onClick={() => {
          setActiveTab(1);
        }}
        action
        active={activeTab === 1}
        disabled={submitting}
      >
        FNSKU
      </ListGroup.Item>
      <ListGroup.Item
        tag="a"
        className="text-center"
        onClick={() => {
          setActiveTab(2);
        }}
        action
        active={activeTab === 2}
        disabled={submitting}
      >
        SKU
      </ListGroup.Item>
    </ListGroup>
  );
};

const AlternateListingPage = () => {
  // Active Tabs:
  // 0 = Asin, 1 = FNSKU, 2 = SKU
  const [alternateListingTab, setAlternateListingTab] = useLocalStorage(
    "alternateListingTab",
    0 // Initial value for the tab index
  );
  const [vendor, setVendor] = useState({});
  const [submitting, setSubmitting] = useState(false);

  // Every time the tab is changed, set the localStorage.
  useEffect(() => {
    setAlternateListingTab(alternateListingTab);
  }, [alternateListingTab]);

  return (
    <>
      <Helmet title="Alternate Listing" />
      <h1 className="mb-3">Alternate Listing</h1>
      <Container fluid className="p-0">
        <Row className="justify-content-center mb-3">
          <Col xl={6}>
            <Navigation
              activeTab={alternateListingTab}
              setActiveTab={setAlternateListingTab}
              submitting={submitting}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {alternateListingTab === 0 ? (
            <Col xl={6}>
              {Object.keys(vendor).length === 0 ? (
                <VendorSelect setVendor={setVendor} />
              ) : (
                <AsinList
                  vendor={vendor}
                  setVendor={setVendor}
                  submitting={submitting}
                  setSubmitting={setSubmitting}
                />
              )}
            </Col>
          ) : alternateListingTab === 1 ? (
            <Col xl={6}>
              <FnskuList
                submitting={submitting}
                setSubmitting={setSubmitting}
              />
            </Col>
          ) : (
            <Col xl={6}>
              <SkuList submitting={submitting} setSubmitting={setSubmitting} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default AlternateListingPage;
