import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { AlertOctagon, AlertTriangle, Info } from "react-feather";
import toast from "react-hot-toast";

import shipmentService from "../../../../services/shipmentService";

const CartonForm = ({
  cartonData,
  setCartonData,
  setSubmitting,
  setToggle,
  submitting,
  toggle,
}) => {
  const [error, setError] = useState("");
  const [shipmentCartonId, setShipmentCartonId] = useState("");
  const [shipmentPalletId, setShipmentPalletId] = useState("");

  const cartonIdRef = useRef();
  const palletIdRef = useRef();

  useEffect(() => {
    if (error) {
      setError("");
    }
  }, [shipmentCartonId]);

  const handleSubmitCartonId = async () => {
    //// Basic Validation
    // Check for input.
    if (!shipmentCartonId) {
      toast.error("Please enter a Carton ID.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (shipmentCartonId.toUpperCase() === "W") {
      toast.error("Please re-scan the Carton ID or enter it manually.");
      setShipmentCartonId("");
      return;
    }

    // Check for characters that aren't digits.
    const nonDigitRegex = /[^0-9]/; // Evals to true if not 0-9 digits.
    if (nonDigitRegex.test(shipmentCartonId)) {
      toast.error("The Carton ID must only be digits 0-9.");
      setShipmentCartonId("");
      return;
    }

    // Submit the shipment carton id.
    setSubmitting(true);
    const response = await shipmentService.getEligibleShipmentPallets({
      shipmentCartonId,
    });

    if (response.success) {
      delete response.success;
      setCartonData(response);

      if (response.assignedShipmentPallet) {
        const message = `Carton ${shipmentCartonId} is already on Pallet ${response.assignedShipmentPallet.shipmentPalletId}. Scan a different Carton ID and try again.`;
        setError({ message });
        setTimeout(() => {
          if (cartonIdRef.current) {
            cartonIdRef.current.focus();
          }
        }, 0);
      }
    } else {
      setError(response);
      setTimeout(() => {
        if (cartonIdRef.current) {
          cartonIdRef.current.focus();
        }
      }, 0);
    }

    setSubmitting(false);
  };

  const handleSubmitPalletId = async () => {
    //// Basic Validation
    // Check for input.
    if (!shipmentPalletId) {
      toast.error("Please enter a Pallet ID.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (shipmentPalletId.toUpperCase() === "W") {
      toast.error("Please re-scan the Pallet ID or enter it manually.");
      setShipmentCartonId("");
      return;
    }

    // Check for characters that aren't digits.
    const nonDigitRegex = /[^0-9]/; // Evals to true if not 0-9 digits.
    if (nonDigitRegex.test(shipmentPalletId)) {
      toast.error("The Pallet ID must only be digits 0-9.");
      setShipmentCartonId("");
      return;
    }
    const foundPallet = cartonData.eligibleShipmentPallets.find(
      (pallet) => Number(pallet.shipmentPalletId) === Number(shipmentPalletId)
    );

    if (foundPallet) {
      const response = await shipmentService.addCartonToShipmentPallet({
        shipmentPalletId,
        shipmentCartonId,
      });

      if (response.success) {
        toast.success("Carton successfully added to pallet!");
        setShipmentCartonId("");

        setError();
        setTimeout(() => {
          if (cartonIdRef.current) {
            cartonIdRef.current.focus();
          }
        }, 0);
      } else {
        toast.error("Something went wrong, please try again.");
        setError(response.error);
      }
    } else {
      const message = `Pallet ${shipmentPalletId} is not on the list of eligible pallets for Carton ${shipmentCartonId}`;
      setError({ message });
      setShipmentPalletId("");
      setTimeout(() => {
        if (palletIdRef.current) {
          palletIdRef.current.focus();
        }
      }, 0);
    }
  };

  return (
    <>
      {error && (
        <Alert variant="danger" className="flex-fill alert-blink">
          <div className="alert-icon">
            <AlertOctagon
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message font-weight-bold">{error.message}</div>
        </Alert>
      )}
      <Form.Label className="mb-0">Carton ID</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          autoFocus
          size="lg"
          ref={cartonIdRef}
          placeholder="Scan or Enter Carton ID..."
          value={shipmentCartonId}
          onChange={(e) => {
            setShipmentCartonId(e.target.value);
            setCartonData();
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSubmitCartonId();
            }
          }}
          disabled={submitting}
        />
        <Button
          variant={"primary"}
          onClick={() => handleSubmitCartonId()}
          disabled={submitting}
        >
          Continue
        </Button>
      </InputGroup>
      {cartonData && cartonData.eligibleShipmentPallets && (
        <>
          {cartonData.eligibleShipmentPallets.length > 0 ? (
            <>
              <Alert variant="info" className="flex-fill alert-blink">
                <div className="alert-icon">
                  <Info
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message font-weight-bold">
                  <h4>Eligible Pallets</h4>
                  {cartonData.eligibleShipmentPallets.map((pallet, index) => {
                    if (
                      cartonData.eligibleShipmentPallets.length - 1 ===
                      index
                    ) {
                      return <span key={index}>{pallet.shipmentPalletId}</span>;
                    }

                    return <span key={index}>{pallet.shipmentPalletId}, </span>;
                  })}
                </div>
              </Alert>
              <Form.Label className="mb-0">Pallet ID</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  autoFocus
                  size="lg"
                  ref={palletIdRef}
                  placeholder="Scan or Enter Pallet ID..."
                  value={shipmentPalletId}
                  onChange={(e) => {
                    setShipmentPalletId(e.target.value);
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitPalletId();
                    }
                  }}
                />
                <Button
                  variant="success"
                  onClick={() => handleSubmitPalletId()}
                >
                  Add
                </Button>
              </InputGroup>
            </>
          ) : (
            <Alert variant="warning" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertTriangle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold d-flex justify-content-between">
                <span className="align-self-center">
                  There are no eligible pallets for this carton.
                </span>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  Create New Pallet
                </Button>
              </div>
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default CartonForm;
