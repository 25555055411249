import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Card, Spinner } from "react-bootstrap";
import { Trash2, X, Check } from "react-feather";

import DataTable from "../../../../components/Datatable";
import ModalGeneric from "../../../../components/ModalGeneric";
import ASINRuleForm from "./ASINRuleForm";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const ASINRulesTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [asinRules, setASINRules] = useState([]);
  const [vendors, setVendors] = useState();

  const getASINRules = async () => {
    try {
      const result = (await axios.get(`${HOST_URL}/utils/getASINRules`)).data
        .asinRules;
      setASINRules(result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getVendors = async () => {
    try {
      const vendorDataResponse = (
        await axios.get(`${HOST_URL}/warehouse/vendor`)
      ).data;
      setVendors(vendorDataResponse.vendors);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getASINRules();
    getVendors();
  }, []);

  const addOrEditASINRule = async (asinRule) => {
    try {
      const result = (
        await axios.post(`${HOST_URL}/utils/createOrUpdateASINRule`, {
          ...asinRule,
        })
      ).data;

      // check if failed bec. asin rule already exists for this asin:
      if (result.success === false) {
        toast.error(`Error: ${result.message}`);
      } else {
        // adding or editing asin rule was successful:
        if (!asinRule.id) toast.success("Added ASIN Rule");
        else toast.success("Edited ASIN Rule");
        await getASINRules();
      }
    } catch (error) {
      if (!asinRule.id) toast.error("Error adding ASIN Rule");
      else toast.error("Error editing ASIN Rule");
      console.log(error);
    }
  };

  const deleteASINRule = async (id) => {
    try {
      await axios.delete(`${HOST_URL}/utils/deleteASINRule/${id}`);
      toast.success(`Deleted ASIN Rule`);
      await getASINRules();
    } catch (error) {
      toast.error("Error deleting ASIN Rule");
      console.log(error);
    }
  };

  const tableColumns = [
    {
      Header: "ASIN",
      accessor: "asin",
    },
    {
      Header: "Vendor Restricted To",
      accessor: (asinRule) => {
        if (vendors) {
          const matchingVendor = vendors.find((vendor) => {
            return vendor.vendorId === asinRule.vendorRestrictedTo;
          });
          return matchingVendor?.vendorName;
        } else return "";
      },
    },
    {
      Header: "Restrict Processing",
      accessor: (asinRule) => {
        if (asinRule.restrictProcessing) {
          return <Check color="#4BBF73" size={24} />;
        } else {
          return <X color="#d9534f" size={24} />;
        }
      },
      Cell: (row) => <div className="text-center">{row.value}</div>,
    },
    {
      Header: "Restrict Liquidation",
      accessor: (asinRule) => {
        if (asinRule.restrictLiquidation) {
          return <Check color="#4BBF73" size={24} />;
        } else {
          return <X color="#d9534f" size={24} />;
        }
      },
      Cell: (row) => <div className="text-center">{row.value}</div>,
    },
    {
      Header: "Restrict All Amazon Listings",
      accessor: (asinRule) => {
        if (asinRule.restrictAmazonAllListings) {
          return <Check color="#4BBF73" size={24} />;
        } else {
          return <X color="#d9534f" size={24} />;
        }
      },
      Cell: (row) => <div className="text-center">{row.value}</div>,
    },
    {
      Header: "Restrict New Amazon Listings",
      accessor: (asinRule) => {
        if (asinRule.restrictAmazonNewListing) {
          return <Check color="#4BBF73" size={24} />;
        } else {
          return <X color="#d9534f" size={24} />;
        }
      },
      Cell: (row) => <div className="text-center">{row.value}</div>,
    },
    {
      Header: "Restrict New eBay Listings",
      accessor: (asinRule) => {
        if (asinRule.restrictEbayNewListing) {
          return <Check color="#4BBF73" size={24} />;
        } else {
          return <X color="#d9534f" size={24} />;
        }
      },
      Cell: (row) => <div className="text-center">{row.value}</div>,
    },
    {
      Header: "Message",
      accessor: "message",
    },
    {
      Header: "Actions",
      disableSortBy: true,
      accessor: (asinRule) => {
        return (
          <div>
            <ASINRuleForm
              asinRule={asinRule}
              vendors={vendors}
              addOrEditASINRule={addOrEditASINRule}
            />
            <ModalGeneric
              trigger={
                <Button variant="danger">
                  <Trash2 size={18} />
                </Button>
              }
              title="Delete ASIN Rule"
              type="submit"
              body={
                <div>
                  <div className="d-inline-block">
                    Are you sure you would like to delete the ASIN Rule on ASIN
                  </div>
                  <div className="font-weight-bold d-inline-block mx-1">
                    {asinRule.asin}
                  </div>
                  ?
                </div>
              }
              actionButtonText={"Delete"}
              actionButtonCallback={(asinRule) => deleteASINRule(asinRule.id)}
              actionButtonCallbackParams={{ asinRule }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Card>
      <Card.Body>
        {isLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <span className="px-2 font-weight-bold"> Loading... </span>
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : asinRules.length > 0 ? (
          <>
            <div className="d-flex justify-content-end ">
              <ASINRuleForm
                addOrEditASINRule={addOrEditASINRule}
                vendors={vendors}
              />
            </div>
            <DataTable columns={tableColumns} data={asinRules} />
          </>
        ) : (
          <h3 className="text-center">No Data To Display</h3>
        )}
      </Card.Body>
    </Card>
  );
};

export default ASINRulesTable;
