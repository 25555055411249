import React, { useEffect, useState } from "react";
import { Alert, Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Eye } from "react-feather";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";
import ShipmentAccount from "./components/shipments/ShipmentAccount";
import DetermineShipmentLocationModal from "./components/shipments/ShipmentLocationModal";
import shipmentService from "../../services/shipmentService";
import utilsService from "../../services/utils.service";

const Navigation = ({
  submitting,
  selectedAccount,
  setSelectedAccount,
  listingAccounts,
  allShipments,
}) => {
  return (
    <>
      <ListGroup horizontal>
        {listingAccounts &&
          listingAccounts.map((account, index) => {
            return (
              <ListGroup.Item
                tag="a"
                key={index}
                onClick={() => setSelectedAccount(account)}
                action
                active={selectedAccount === account}
                disabled={submitting}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <span>{account.listingAccount}</span>
                  <Badge bg="dark">
                    {allShipments?.filter(
                      (shipmentAccount) =>
                        shipmentAccount.listingAccountId ===
                        account.listingAccountId
                    ).length > 0
                      ? allShipments?.filter(
                          (shipmentAccount) =>
                            shipmentAccount.listingAccountId ===
                            account.listingAccountId
                        ).length
                      : 0}
                  </Badge>
                </div>
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </>
  );
};

const ShipmentsPage = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();

  const [listingAccounts, setListingAccounts] = useState();
  const [allShipments, setAllShipments] = useState();
  const [selectedAccount, setSelectedAccount] = useState();

  const [selectedShipment, setSelectedShipment] = useState();
  useEffect(() => {
    getListingAccounts();
    getShipments();
  }, []);

  const getListingAccounts = async () => {
    //setLoading(true);

    const response = await utilsService.getListingAccounts({
      amazonOnly: true,
    });

    if (response.success) {
      setListingAccounts(response.listingAccounts);
    } else {
      toast.error("Something went wrong. Please try again.");
      setError(response);
    }

    //setLoading(false);
  };

  const getShipments = async () => {
    setLoading(true);

    const response = await shipmentService.getShipments();

    if (response.success) {
      setAllShipments(response.shipments);
    } else {
      toast.error("Something went wrong. Please try again.");
      setError(response);
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet title="Shipments" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Shipments</h1>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Row className="mb-3">
              <Col xxl={8}>
                <Navigation
                  submitting={submitting}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  listingAccounts={listingAccounts}
                  allShipments={allShipments}
                />
              </Col>
              <Col className="text-end">
                <DetermineShipmentLocationModal
                  listingAccounts={listingAccounts}
                  selectedAccount={selectedAccount}
                />
              </Col>
            </Row>
            {selectedAccount ? (
              <ShipmentAccount
                submitting={submitting}
                setSubmitting={setSubmitting}
                selectedAccount={selectedAccount}
                allShipments={allShipments}
                getShipments={getShipments}
                selectedShipment={selectedShipment}
                setSelectedShipment={setSelectedShipment}
              />
            ) : (
              allShipments && (
                <Row>
                  <Col xxl={8}>
                    <Alert variant="primary">
                      <div className="alert-icon">
                        <Eye
                          className="position-relative top-50 start-50 translate-middle"
                          size={20}
                        />
                      </div>
                      <div className="alert-message">
                        Select an <strong>account</strong> to view shipments.
                      </div>
                    </Alert>
                  </Col>
                </Row>
              )
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ShipmentsPage;
