import React, { useState, useContext } from "react";

import UIButton from "../../../../components/UIButton";
import UICard from "../../../../components/UICard";
import UIInput from "../../../../components/UIInput";

import { Alert, Spinner, Button, Row, Col } from "react-bootstrap";
import { AlertCircle } from "react-feather";
import toast from "react-hot-toast";

import { ListingItemContext } from "../../ListingPage";
import listingService from "../../../../services/listingService";

function LPNForm({ isLookUp, setIsLookUp }) {
  const [value, setValue] = useState("");

  const {
    setCurrentItem,
    setListingOptions,
    setListingRequirements,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(ListingItemContext);

  const handleSubmit = async () => {
    // Check if the lpnValue exists.
    if (!value) {
      toast.error("Please enter an LPN / RPN.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (value.toUpperCase() === "W") {
      toast.error("Please re-scan LPN / RPN or enter it manually.");
      setValue("");
      return;
    }

    setIsLoading(true);

    let response;
    if (isLookUp) {
      setIsLookUp(true);
      response = await listingService.getItem({ lpn: value }).catch((error) => {
        console.error(error);
        setError(error.response.data.message);
        setIsLoading(false);
      });
    } else {
      response = await listingService
        .getListingOptions({ lpn: value })
        .catch((error) => {
          console.error(error);
          setError(error.response.data.message);
          setIsLoading(false);
        });
    }

    setIsLoading(false);

    const { processingItem, amazonListingOptions, listingRequirements } =
      response;

    if (!processingItem?.processingData?.lpn) {
      toast.error(`No item found for LPN / RPN: ${value}`);
      setValue("");
      return;
    }
    if (isLookUp && !processingItem?.processingData?.listedId) {
      toast.error(`This item was not yet listed: ${value}`);
      setValue("");
      return;
    }

    setCurrentItem(processingItem);
    setListingOptions(amazonListingOptions);
    setListingRequirements(listingRequirements);
  };

  const header = (
    <Row>
      <Col sm={{ span: 4, offset: 4 }} className="text-center">
        <h4>List Item</h4>
      </Col>
      <Col sm={{ span: 4 }} className="d-flex justify-content-end">
        <Button
          variant="info"
          onClick={() => {
            setIsLookUp(!isLookUp);
          }}
        >
          {isLookUp ? "List LPN" : "Look Up LPN"}
        </Button>
      </Col>
    </Row>
  );

  const body = isLoading ? (
    <Spinner animation="border" variant="secondary" />
  ) : (
    <>
      {error && (
        <Alert className="p-3 justify-content-center" variant="danger">
          <span className="text-center">{error}</span>
        </Alert>
      )}
      {isLookUp && (
        <Alert variant="warning" className="flex-fill alert-blink">
          <div className="alert-icon">
            <AlertCircle
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message">
            You are currently looking up an already listed LPN or RPN. To list
            an LPN or RPN, click the "List LPN" button.
          </div>
        </Alert>
      )}
      <UIInput
        required
        autoFocus
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
        type="text"
        placeholder="Enter an LPN or RPN"
        size="lg"
        className="my-3 w-75 m-auto text-center"
      />
      <UIButton
        variant="primary"
        text="Search"
        onClick={() => {
          handleSubmit();
        }}
      />
    </>
  );
  return (
    <UICard header={header} body={body} className="m-auto w-75 text-center" />
  );
}

export default LPNForm;
