import axios from "axios";
import { handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getClaimReasons = async () => {
  try {
    return (await axios.get(`${HOST_URL}/claims/getClaimReasons`)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Retrieves claims from the server based on the provided claimId.
 * @async
 * @param {Object} options - The options for retrieving claims.
 * @param {string} options.claimId - The claimId used to filter the claims. Optional.
 * @returns {Promise<Object>} A promise that resolves to the claims data.
 */
const getClaims = async ({ claimId }) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/claims/getClaims${claimId ? `?claimId=${claimId}` : ""}`
      )
    ).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Creates a claim.
 *
 * @async
 * @param {Object} data - The data for the claim update.
 * @param {number} data.claimReasonId - The ID of the claim reason.
 * @param {number} data.claimSubReasonId = the ID of the claim sub reason.
 * @param {string=} data.claimReasonNote - The note pertaining to a claim sub reason.
 * @param {string} data.lpn - The LPN (license plate number) for the claim.
 * @param {number} data.userId - The ID of the user associated with the claim.
 * @param {Array} data.claimImages - An array of claim images.
 * @param {string} data.claimImages[].imageSrc - The source of the claim image.
 * @param {string} data.claimImages[].imageId - The ID of the claim image.
 * @returns {Promise<Object>} A promise that resolves to the updated claim data.
 */
const createClaim = async (data) => {
  try {
    return (await axios.post(`${HOST_URL}/claims/createClaim`, data)).data;
  } catch (error) {
    return handleError(error);
  }
};

/**
 * Updates a claim.
 * @async
 * @param {number} claimId - The ID of the claim to update.
 * @param {Object} data - The data for the claim update.
 * @param {number} data.claimReasonId - The ID of the claim reason (integer).
 * @param {Array} data.claimImages - An array of claim images.
 * @param {string} data.claimImages[].imageSrc - The source of the claim image (string).
 * @param {string} data.claimImages[].imageId - The ID of the claim image (string).
 * @returns {Promise<Object>} A promise that resolves to the updated claim data.
 */
const updateClaim = async (claimId, data) => {
  try {
    return (await axios.put(`${HOST_URL}/claims/updateClaim/${claimId}`, data))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getClaimReasons,
  getClaims,
  createClaim,
  updateClaim,
};

export default services;
