import { useEffect, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import Select from "react-select";
import { Info } from "react-feather";

import ImageCapture from "../../../../components/ImageCapture";

import claimService from "../../../../services/claim.service";

const ClaimForm = ({
  claim,
  images,
  setImages,
  setClaimReasonId,
  setClaimSubReasonId,
  claimReasonNote,
  setClaimReasonNote,
  isLoading,
}) => {
  // Select states
  const [loading, setLoading] = useState(false);
  const [reasonOptions, setReasonOptions] = useState();
  const [subReasonOptions, setSubReasonOptions] = useState();
  const [selectedReasonOption, setSelectedReasonOption] = useState();
  const [selectedSubReasonOption, setSelectedSubReasonOption] = useState();

  useEffect(() => {
    const setSelectOptions = async () => {
      setLoading(true);
      const result = await claimService.getClaimReasons();

      if (result.success) {
        setReasonOptions(
          result.claimReasons.reasons.map((reason) => {
            return {
              label: reason.claimReason,
              value: reason.claimReasonId,
            };
          })
        );

        setSubReasonOptions(
          result.claimReasons.subReasons.map((reason) => {
            return {
              claimReasonId: reason.claimReasonId,
              label: reason.claimSubReason,
              value: reason.claimSubReasonId,
            };
          })
        );

        setLoading(false);
      }
    };

    if (!reasonOptions) {
      setSelectOptions();
    }
  }, []);

  useEffect(() => {
    if (claim && reasonOptions && subReasonOptions) {
      setSelectedReasonOption(
        reasonOptions.filter(
          (option) => option.value === claim.claimReasonId
        )[0]
      );
      setSelectedSubReasonOption(
        subReasonOptions.filter(
          (option) => option.value === claim.claimSubReasonId
        )[0]
      );
    }
  }, [reasonOptions, subReasonOptions]);

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label className="mb-0">Damage Report Reason</Form.Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select a Damage Report Reason..."
          onChange={(selectedOption) => {
            setClaimReasonId(selectedOption.value);
            setSelectedReasonOption(selectedOption);

            setClaimSubReasonId();
            setSelectedSubReasonOption(null);
          }}
          options={reasonOptions}
          value={selectedReasonOption}
          isSearchable
          isLoading={loading}
          disabled={isLoading}
        />
      </Form.Group>
      {selectedReasonOption && (
        <Form.Group className="mb-3">
          <Form.Label className="mb-0">Damage Report Sub Reason</Form.Label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select a Damage Report Sub Reason..."
            onChange={(selectedOption) => {
              setClaimSubReasonId(selectedOption.value);
              setSelectedSubReasonOption(selectedOption);
              setClaimReasonNote();
            }}
            options={subReasonOptions.filter(
              (option) => option.claimReasonId === selectedReasonOption.value
            )}
            value={selectedSubReasonOption}
            isSearchable
            isLoading={loading}
            disabled={isLoading}
          />
        </Form.Group>
      )}
      {selectedReasonOption &&
        selectedSubReasonOption &&
        selectedSubReasonOption.label === "Other" && (
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Claim Reason Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              style={{ resize: "none" }}
              placeholder="Please Describe the Claim Sub Reason..."
              onChange={(e) => {
                setClaimReasonNote(e.target.value);
              }}
              value={claimReasonNote}
              disabled={isLoading}
            />
          </Form.Group>
        )}
      {selectedReasonOption && selectedSubReasonOption && (
        <>
          <Form.Label className="mb-0">Damage Report Images</Form.Label>
          <Alert variant="info" className="flex-fill alert-blink">
            <div className="alert-icon">
              <Info
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              Please take a clear image for each of the following:
              <div>- The item taken out of the packaging.</div>
              <div>- The LPN label.</div>
              <div>- The Serial Number on the item (if the item has one).</div>
              {selectedSubReasonOption.value === 5 && (
                <div>- The item's empty packaging.</div>
              )}
              {selectedSubReasonOption.value === 16 && (
                <div>- The Serial Number on the item's packaging.</div>
              )}
            </div>
          </Alert>
          <ImageCapture
            images={images}
            setImages={setImages}
            disabled={isLoading}
          />
        </>
      )}
    </Form>
  );
};

export default ClaimForm;
