import { faBox, faPallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Collapse, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";
import CartonForm from "./components/buildShipmentPallets/CartonForm";
import PalletForm from "./components/buildShipmentPallets/PalletForm";

import shipmentService from "../../services/shipmentService";

const BuildShipmentPalletsPage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [toggle, setToggle] = useState(true);

  const [destinations, setDestinations] = useState();
  const [destinationOptions, setDestinationOptions] = useState();
  const [cartonData, setCartonData] = useState();

  useEffect(() => {
    getDestinationCenters();
  }, []);

  const getDestinationCenters = async () => {
    setLoading(true);

    const response = await shipmentService.getDestinationCenters();

    if (response.success) {
      setDestinations(response.destinations);
      setDestinationOptions(
        response.destinations.map((destination) => {
          return {
            value: destination.destinationCenterId,
            label: `${destination.destinationCenterId}, ${destination.shipToCity}, ${destination.shipToState}`,
          };
        })
      );
    } else {
      toast.error(response.message);
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet title="Build Shipment Pallets" />
      <h1 className="mb-3">Build Shipment Pallets</h1>
      <Container fluid className="p-0">
        {loading ? (
          <Loader />
        ) : (
          <>
            {destinations?.length > 0 && (
              <Row>
                <Col xl={{ span: 6, offset: 3 }}>
                  <Card>
                    <Collapse in={toggle}>
                      <div>
                        <Card.Header className="d-flex justify-content-between">
                          <div className="d-flex justify-content-start">
                            <FontAwesomeIcon
                              icon={faBox}
                              className="me-3"
                              size="3x"
                            />
                            <div>
                              <Card.Title>Add Carton To Pallet</Card.Title>
                              <Card.Subtitle>
                                Scan or enter a Carton ID and then scan an
                                eligible Pallet ID to add the carton to the
                                cooresponding pallet.
                              </Card.Subtitle>
                            </div>
                          </div>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setToggle(!toggle);
                            }}
                          >
                            Create New Pallet
                          </Button>
                        </Card.Header>
                        <Card.Body>
                          <CartonForm
                            cartonData={cartonData}
                            setCartonData={setCartonData}
                            setSubmitting={setSubmitting}
                            setToggle={setToggle}
                            submitting={submitting}
                            toggle={toggle}
                          />
                        </Card.Body>
                      </div>
                    </Collapse>
                    <Collapse in={!toggle}>
                      <div>
                        <Card.Header className="d-flex justify-content-between">
                          <div className="d-flex justify-content-start">
                            <FontAwesomeIcon
                              icon={faPallet}
                              className="me-3"
                              size="3x"
                            />
                            <div>
                              <Card.Title>Create Shipment Pallet</Card.Title>
                              <Card.Subtitle>
                                Select a destination center to create a pallet
                                for.
                              </Card.Subtitle>
                            </div>
                          </div>
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setToggle(!toggle);
                            }}
                          >
                            Add Carton to Pallet
                          </Button>
                        </Card.Header>
                        <Card.Body>
                          <PalletForm
                            cartonData={cartonData}
                            destinationOptions={destinationOptions}
                            setDestinations={setDestinations}
                            setSubmitting={setSubmitting}
                            submitting={submitting}
                          />
                        </Card.Body>
                      </div>
                    </Collapse>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default BuildShipmentPalletsPage;
