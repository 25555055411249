import React from "react";
import { Helmet } from "react-helmet-async";

import UIDefaultPage from "../../components/UIDefaultPage";
import EmployeeBody from "./components/itemizedActivity/EmployeeBody";

const EmployeeTable = () => {
  return (
    <UIDefaultPage title={"Itemized Activity"} children={<EmployeeBody />} />
  );
};

export default EmployeeTable;
