import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { AlertCircle, Package } from "react-feather";
import toast from "react-hot-toast";
import Select from "react-select";

import printService from "../../../../services/printService";
import shipmentService from "../../../../services/shipmentService";

import useLocalStorage from "./../../../../hooks/useLocalStorage";
import Loader from "../../../../components/Loader";

const CartonForm = ({
  submitting,
  setSubmitting,
  allShipmentCategories,
  shipmentCartonTypes,
  selectedAccount,
  selectedShipmentRack,
  selectedShipmentRackItems,
  shipments,
  setActiveKey,
  setSelectedShipmentRackItems,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [fnsku, setFnsku] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [shipmentCartonId, setShipmentCartonId] = useState("");
  const [shipment, setShipment] = useState();

  const [eligibleShipmentCartons, setEligibleShipmentCartons] = useState([]);

  const [showCartonInput, setShowCartonInput] = useState(false);
  const [isEligible, setIsEligible] = useState(true);

  // Create new shipment carton states
  const [showModal, setShowModal] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [shipmentCartonTypeOptions, setShipmentCartonTypeOptions] = useState();

  const user = useLocalStorage("user")[0];

  const fnskuInputElement = useRef(null);
  const cartonIdInputElement = useRef(null);

  const isOversize = selectedShipmentRack.shipmentCategoryId === 2;

  // Optionize the shipment carton types
  useEffect(() => {
    setShipmentCartonTypeOptions(
      shipmentCartonTypes.map((type) => {
        return {
          value: type.shipmentCartonTypeId,
          label: type.shipmentCartonType,
          type: type.shipmentCartonType,
        };
      })
    );
  }, []);

  // Reset states when selectedShipmentRack changes.
  useEffect(() => {
    setFnsku("");
    setShipmentCartonId("");
    setQuantity(1);
    setShipment();
    setEligibleShipmentCartons([]);
    setShowCartonInput(false);
  }, [selectedShipmentRack]);

  const handleSubmitFnsku = () => {
    setErrorMessage();

    if (!fnsku) {
      toast.error("Please enter an FNSKU.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (fnsku.toUpperCase() === "W") {
      toast.error("Please re-scan the FNSKU or enter it manually.");
      setFnsku("");
      return;
    }

    // Check if fnsku exists on the rack.
    const foundShipmentRackItem = selectedShipmentRackItems.find(
      (item) => item.fnsku.toUpperCase() === fnsku.toUpperCase()
    );

    if (!foundShipmentRackItem) {
      setErrorMessage(`${fnsku} was not found on the selected shipment rack.`);
      return;
    }

    // Quantity Check
    const foundShipment = shipments.find((shipment) => {
      const foundShipmentRackItem = shipment.shipmentRackItems.find(
        (item) => item.fnsku.toUpperCase() === fnsku.toUpperCase()
      );

      if (foundShipmentRackItem) {
        if (
          foundShipmentRackItem.quantity -
            foundShipmentRackItem.quantityInShipmentCarton >=
          quantity
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    if (foundShipment) {
      setShipment(foundShipment);
      setShowCartonInput(true);
      setActiveKey([foundShipment.shipmentId]);

      if (!isOversize) {
        getEligibleShipmentCartons(foundShipment);
      } else {
        setSelectValue(
          shipmentCartonTypeOptions.find((cartonType) => cartonType.value === 4)
        );
      }
    } else {
      setErrorMessage(
        quantity === 1
          ? `All items matching FNSKU: ${fnsku} are already in a carton or do not have a shipment.`
          : `The quantity for ${fnsku} is too high. Reduce the quantity and try again.`
      );
      return;
    }
  };

  const handleAddToShipmentCarton = async () => {
    setErrorMessage();

    if (!shipmentCartonId) {
      toast.error("Please enter a Carton ID.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (shipmentCartonId.toString().toUpperCase() === "W") {
      toast.error("Please re-scan the Carton ID or enter it manually.");
      setShipmentCartonId("");
      return;
    }

    const foundEligibleShipmentCarton = eligibleShipmentCartons.find(
      (shipmentCarton) =>
        shipmentCarton.shipmentCartonId === Number(shipmentCartonId) // Was changed to string for W check.
    );

    if (!foundEligibleShipmentCarton) {
      setErrorMessage(
        "This item is not eligible for this shipment carton. Scan another Carton ID or create a new shipment carton."
      );
      setIsEligible(false);
      setShowModal(true);
      return;
    }

    setSubmitting(true);

    const matchingShipmentRackIds = selectedShipmentRackItems
      .map((item) => {
        if (
          shipment.shipmentId === item.shipmentId &&
          item.inShipmentCarton === false &&
          item.fnsku.toUpperCase() === fnsku.toUpperCase()
        ) {
          return item.shipmentRackItemId;
        }
        return null;
      })
      .filter((id) => id !== null);

    // Only take the quantity
    let shipmentRackItemIds = [];
    for (let i = 0; i < quantity; i++) {
      shipmentRackItemIds.push(matchingShipmentRackIds[i]);
    }

    const data = {
      shipmentCartonId: Number(shipmentCartonId),
      fnsku,
      shipmentRackItemIds,
      shipmentRackId: selectedShipmentRack.shipmentRackId,
      shipmentId: shipment.shipmentId,
      userId: user.userID,
    };

    const result = await shipmentService.addToShipmentCarton(data);

    if (result.success) {
      if (result.completedRackOrShipment.completedShipmentRack) {
        toast.success("This rack is now completed. Refreshing the page...", {
          duration: 5000,
        });

        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      }

      setSelectedShipmentRackItems(result.shipmentRackItems);
      setFnsku("");
      setShipmentCartonId("");
      setShowCartonInput(false);
      setIsEligible(true);
      setSubmitting(false);

      if (fnskuInputElement.current) {
        fnskuInputElement.current.focus();
      }
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setErrorMessage(result.message);
      setSubmitting(false);
    }
  };

  const handleCreateShipmentCarton = async () => {
    setSubmitting(true);

    const data = {
      userId: user.userID,
      listingAccountId: selectedShipmentRack.listingAccountId,
      shipmentCategoryId: selectedShipmentRack.shipmentCategoryId,
      shipmentId: shipment.shipmentId,
      shipmentCartonTypeId: selectValue.value,
    };

    const result = await shipmentService.createNewShipmentCarton(data);

    if (result.success) {
      setShowModal(false);

      const labelData = {
        shipmentCartonId: result.newShipmentCartonId,
        shipmentCategory: allShipmentCategories.find(
          (category) =>
            category.shipmentCategoryId ===
            selectedShipmentRack.shipmentCategoryId
        ).shipmentCategory,
        listingAccount: selectedAccount.listingAccount,
        amazonShipmentId: shipment.amazonShipmentId,
        destinationCenterId: shipment.shipTo,
      };

      printService.printCartonLabel(labelData);
      getEligibleShipmentCartons(shipment);

      if (cartonIdInputElement.current) {
        setTimeout(() => {
          cartonIdInputElement.current.focus();
        }, 1000);
      }
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setErrorMessage(result.message);
      setSubmitting(false);
    }
  };

  const getEligibleShipmentCartons = async (shipment) => {
    setSubmitting(true);

    const result = await shipmentService.getEligibleShipmentCartons({
      userId: user.userID,
      shipmentId: shipment.shipmentId,
    });

    if (result.success) {
      setEligibleShipmentCartons(result.eligibleShipmentCartons);
      setSubmitting(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setErrorMessage(result.message);
      setSubmitting(false);
    }
  };

  const getShipmentCartonForOversizeItem = async () => {
    setSubmitting(true);

    const shipmentCartonIdResult =
      await shipmentService.getShipmentCartonForOversizeItem({
        fnsku,
        shipmentId: shipment.shipmentId,
        shipmentRackId: selectedShipmentRack.shipmentRackId,
        userId: user.userID,
      });

    if (shipmentCartonIdResult.success) {
      const shipmentCartonId = shipmentCartonIdResult.shipmentCartonId;

      const labelUrlResult = await shipmentService.getFbaShipmentLabelUrl({
        shipmentCartonId: Number(shipmentCartonId),
      });

      if (labelUrlResult.success) {
        if (labelUrlResult.labelUrl) {
          const fileName = await printService.printFbaShipmentLabel(
            labelUrlResult.labelUrl
          );
        } else {
          setErrorMessage(labelUrlResult.message);
        }

        setSubmitting(false);
      } else {
        toast.error("Something went wrong. Please try again.", {
          duration: 5000,
        });
        setSubmitting(false);
      }
    }

    setSelectedShipmentRackItems(shipmentCartonIdResult.shipmentRackItems);
    setFnsku("");
    setShipmentCartonId("");
    setShowCartonInput(false);
    setIsEligible(true);
    setSubmitting(false);

    if (fnskuInputElement.current) {
      fnskuInputElement.current.focus();
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            {isEligible ? "Select Shipment Carton Type" : "Ineligible Carton"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isEligible ? (
            <Form.Group>
              <Form.Label className="mb-0">Shipment Carton Type</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name="shipmentCartonType"
                size="lg"
                placeholder="Select Shipment Carton Type..."
                onChange={(selectedOption) => {
                  setSelectValue(selectedOption);
                }}
                value={selectValue}
                options={shipmentCartonTypeOptions}
                autoFocus
              />
            </Form.Group>
          ) : (
            <Alert variant="danger" className="mb-0">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message">
                This item is not eligible for this shipment carton. Scan another
                Carton ID or create a new shipment carton.
              </div>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isEligible ? (
            <>
              <Button
                variant="success"
                onClick={handleCreateShipmentCarton}
                disabled={!selectValue || submitting}
              >
                Create Shipment Carton
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setSelectValue();
                  setShowModal(false);
                }}
                disabled={submitting}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                setShowModal(false);
                setIsEligible(true);
              }}
            >
              Acknowledge
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Card className="sticky-top" style={{ top: 20 }}>
        <Card.Header className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faBarcode} size="3x" className="me-3" />
          <div>
            <Card.Title>Shipment Carton</Card.Title>
            <Card.Subtitle>
              Scan or enter an FNSKU.
              {isOversize
                ? " Then add to shipment."
                : " Then scan or enter a Carton ID to check the item's eligibility to be placed in the corresponding carton."}
            </Card.Subtitle>
          </div>
        </Card.Header>
        <Card.Body>
          {submitting && <Loader />}
          {errorMessage && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {errorMessage}
              </div>
            </Alert>
          )}
          <Row>
            {!isOversize && (
              <Col xxl={2}>
                <Form.Label className="mb-0">Quantity</Form.Label>
                <Form.Control
                  className="mb-3"
                  size="lg"
                  type="text"
                  min={1}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(Number(e.target.value));
                    setShowCartonInput(false);
                    setShipment();
                  }}
                />
              </Col>
            )}
            <Col xxl={10}>
              <Form.Label className="mb-0">FNSKU</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  autoFocus
                  size="lg"
                  ref={fnskuInputElement}
                  placeholder="Scan or Enter FNSKU..."
                  value={fnsku}
                  onChange={(e) => {
                    setFnsku(e.target.value);
                    setShowCartonInput(false);
                    setShipment();
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitFnsku();
                    }
                  }}
                  disabled={submitting}
                />
                <Button
                  variant="primary"
                  onClick={handleSubmitFnsku}
                  disabled={submitting}
                >
                  Continue
                </Button>
              </InputGroup>
            </Col>
          </Row>

          {showCartonInput && (
            <>
              <Alert variant="info">
                <div className="alert-icon">
                  <Package
                    className="position-relative top-50 start-50 translate-middle"
                    size={20}
                  />
                </div>
                <div className="alert-message">
                  <h4>
                    <strong>
                      {shipment.amazonShipmentId}, {shipment.shipTo}
                    </strong>
                  </h4>
                  {eligibleShipmentCartons.length > 0 ? (
                    <>
                      Here are the eligible carton IDs for this item:
                      <div>
                        {eligibleShipmentCartons.map(
                          (shipmentCarton, index) => {
                            if (index !== eligibleShipmentCartons.length - 1) {
                              return (
                                <span key={index}>
                                  {shipmentCarton.shipmentCartonId},{" "}
                                </span>
                              );
                            } else {
                              return (
                                <span key={index}>
                                  {shipmentCarton.shipmentCartonId}
                                </span>
                              );
                            }
                          }
                        )}
                      </div>
                    </>
                  ) : (
                    !isOversize && (
                      <Alert variant="warning">
                        <div className="alert-icon">
                          <AlertCircle
                            className="position-relative top-50 start-50 translate-middle"
                            size={20}
                          />
                        </div>
                        <div className="alert-message">
                          There are currently no eligible cartons for this item.
                          Please create a new carton to continue.
                        </div>
                      </Alert>
                    )
                  )}
                </div>
              </Alert>
              {isOversize ? (
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    variant="primary"
                    onClick={getShipmentCartonForOversizeItem}
                    disabled={submitting}
                  >
                    Add to Shipment
                  </Button>
                </div>
              ) : (
                <>
                  <Form.Label className="mb-0">Carton ID</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      autoFocus
                      size="lg"
                      ref={cartonIdInputElement}
                      placeholder="Scan or Enter Carton ID..."
                      value={shipmentCartonId}
                      onChange={(e) => {
                        setShipmentCartonId(e.target.value);
                      }}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleAddToShipmentCarton();
                        }
                      }}
                    />
                    <Button
                      variant="primary"
                      onClick={() => handleAddToShipmentCarton()}
                      disabled={
                        submitting || eligibleShipmentCartons.length === 0
                      }
                    >
                      Add To Carton
                    </Button>
                  </InputGroup>
                  <div className="d-flex">
                    <Button
                      className="flex-fill"
                      variant="primary"
                      onClick={() => setShowModal(true)}
                      disabled={submitting}
                    >
                      Create New Carton
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default CartonForm;
