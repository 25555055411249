import React, { useEffect, useState } from "react";
import AcceptTrackingNumberForm from "./components/accept/AcceptTrackingNumberForm";
import { useParams } from "react-router-dom";

import { Container, Image, Card } from "react-bootstrap";
import UICard from "../../components/UICard";

import utilsService from "../../services/utils.service";
import imageNotFound from "../../assets/img/imageNotFound.png";

const AsinImagePage = () => {
  const { asin } = useParams();
  const [imageSrc, setImageSrc] = useState(imageNotFound);

  useEffect(() => {
    const getAsinImage = async () => {
      const response = await utilsService.getAsinImage(asin);

      if (response.asinImage) {
        setImageSrc(response.asinImage);
        console.log("thinks theres image", response.asinImage);
      } else {
        setImageSrc(imageNotFound);
      }
    };

    getAsinImage();
  }, []);

  const pageBody = <Image src={imageSrc} />;

  return (
    <Container className="d-flex flex-column justify-content-center vh-100 py-5">
      <UICard body={pageBody} className="text-center" />
    </Container>
  );
};

export default AsinImagePage;
