import OtpItem from "./OtpItem";
function OtpList({ otps }) {
  return (
    <>
      {otps.map((otp) => (
        <OtpItem key={otp.otp} otpData={otp} />
      ))}
    </>
  );
}

export default OtpList;
