import { X, Check } from "react-feather";

import { ButtonGroup, Button } from "react-bootstrap";
import { handleModal } from "../../VendorsPage";
const isAdmin = JSON.parse(localStorage.getItem("user"))?.isAdmin;

const tableColumns = [
  {
    Header: "Master Vendor",
    accessor: "masterVendorName",
  },
  {
    Header: "Store",
    accessor: "storeName",
  },
  {
    Header: "Vendor",
    accessor: "vendorName",
  },
  {
    Header: "RMA Code",
    accessor: "vendorRMACode",
  },
  {
    Header: "Listing SKU",
    accessor: "vendorListingSKUPrefix",
  },
  {
    Header: "Restrict All Amazon Listings",
    accessor: "restrictAmazonAllListings",
    Cell: ({ value }) => {
      if (value) {
        return (
          <div className="text-center">
            <Check color="#4BBF73" size={24} />
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <X color="#d9534f" size={24} />
          </div>
        );
      }
    },
    sortType: (row) => (row.original.restrictAmazonAllListings ? -1 : 1),
  },
  {
    Header: "Restrict New Amazon Listings",
    accessor: "restrictAmazonNewListing",
    Cell: ({ value }) => {
      if (value) {
        return (
          <div className="text-center">
            <Check color="#4BBF73" size={24} />
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <X color="#d9534f" size={24} />
          </div>
        );
      }
    },
    sortType: (row) => (row.original.restrictAmazonNewListing ? -1 : 1),
  },
  {
    Header: "Restrict New eBay Listings",
    accessor: "restrictEbayNewListing",
    Cell: ({ value }) => {
      if (value) {
        return (
          <div className="text-center">
            <Check color="#4BBF73" size={24} />
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <X color="#d9534f" size={24} />
          </div>
        );
      }
    },
    sortType: (row) => (row.original.restrictEbayNewListing ? -1 : 1),
  },
  {
    Header: "Preferred Listing Account",
    accessor: "preferredListingAccount",
  },
  {
    Header: "Is Signed Up For Claims",
    accessor: "isSignedUpForClaims",
    Cell: ({ value }) => {
      if (value) {
        return (
          <div className="text-center">
            <Check color="#4BBF73" size={24} />
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <X color="#d9534f" size={24} />
          </div>
        );
      }
    },
    sortType: (row) => (row.original.isSignedUpForClaims ? -1 : 1),
  },
  isAdmin
    ? {
        Header: "Actions",
        accessor: (row) => {
          return (
            <ButtonGroup size="sm">
              <Button
                variant="warning"
                onClick={() =>
                  handleModal({
                    show: true,
                    type: 1,
                    data: row,
                  })
                }
              >
                Edit
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  handleModal({
                    show: true,
                    type: 2,
                    data: row,
                  })
                }
              >
                Delete
              </Button>
            </ButtonGroup>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      }
    : { Header: " " },
];

export default tableColumns;
