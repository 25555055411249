import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

import Select from "react-select";

import vendorService from "../../../../../services/management/vendor.service";

const VendorSelect = ({ setVendor }) => {
  const [vendorOptions, setVendorOptions] = useState();
  const [selectedVendorOption, setSelectedVendorOption] = useState();

  useEffect(() => {
    if (!vendorOptions) {
      getVendorOptions();
    }
  }, []);

  const getVendorOptions = async () => {
    const response = await vendorService.getVendors();

    if (response.success) {
      const optionizedVendors = response.vendors.map((vendor) => {
        return {
          value: vendor,
          label: `${
            vendor.vendorRMACode ? vendor.vendorRMACode.trim() + " | " : ""
          }${vendor.vendorName.trim()}`,
        };
      });

      setVendorOptions(optionizedVendors);
    }
  };

  const handleSubmitVendor = async () => {
    if (!selectedVendorOption) {
      toast.error("Please select a vendor.");
      return;
    }
    setVendor(selectedVendorOption.value);
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-start border-bottom">
        <FontAwesomeIcon icon={faClipboardCheck} size="3x" className="me-3" />
        <div>
          <Card.Title>Vendor Select</Card.Title>
          <Card.Subtitle>
            Select a vendor to create ASIN listings for.
          </Card.Subtitle>
        </div>
      </Card.Header>
      <Card.Body>
        <Form noValidate>
          <Form.Label>Vendors</Form.Label>
          <InputGroup>
            <div className="react-select form-control p-0">
              <Select
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    margin: "-1px",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "4px 0 0 4px",
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select a Vendor..."
                onChange={(selectedOption) => {
                  setSelectedVendorOption(selectedOption);
                }}
                value={selectedVendorOption}
                options={vendorOptions}
                isSearchable
                isLoading={!vendorOptions}
              />
            </div>
            <Button
              className="d-inline"
              variant="primary"
              disabled={!selectedVendorOption}
              onClick={handleSubmitVendor}
            >
              Continue
            </Button>
          </InputGroup>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default VendorSelect;
