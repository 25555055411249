import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Fade,
  Form,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { ArrowUp } from "react-feather";
import toast from "react-hot-toast";

import printService from "../../../../services/printService";
import shipmentService from "../../../../services/shipmentService";

import { ShipmentsContext } from "./ShipmentAccount";

const StickySidebar = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showCloseShipmentModal, setShowCloseShipmentModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showCreateShipmentModal, setShowCreateShipmentModal] = useState(false);
  const [showDeleteShipmentPlanModal, setShowDeleteShipmentPlanModal] =
    useState(false);

  const [quantityValues, setQuantityValues] = useState([]);

  const {
    submitting,
    setSubmitting,
    selectedAccount,
    selectedShipment,
    shipmentCartons,
    selectedShipmentItems,
    getShipments,
  } = useContext(ShipmentsContext);

  const isSelectedShipmentClosed = selectedShipment?.shipmentStatusId === 3;

  useEffect(() => {
    setShowCloseShipmentModal(false);
    setShowPrintModal(false);
    setShowCreateShipmentModal(false);
  }, [selectedShipment]);

  useEffect(() => {
    setQuantityValues(selectedShipmentItems?.map((item) => item.quantity));
  }, [selectedShipmentItems]);

  // Handles when item quantity is changed.
  const handleQuantityChange = (index, newValue) => {
    const newQuantityValues = [...quantityValues];
    newQuantityValues[index] = newValue;
    setQuantityValues(newQuantityValues);
  };

  // For the scroll button in card header.
  const toggleScrollButton = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 0 ? setShowScrollButton(true) : setShowScrollButton(false);
  };
  window.addEventListener("scroll", toggleScrollButton);

  const printFbaShipmentLabel = async (shipmentCartonId) => {
    setSubmitting(true);

    const result = await shipmentService.getFbaShipmentLabelUrl({
      shipmentCartonId: Number(shipmentCartonId),
    });

    if (result.success) {
      if (result.labelUrl) {
        await printService.printFbaShipmentLabel(result.labelUrl);
      } else {
        toast.error(result.message);
      }
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
    }

    setSubmitting(false);
  };
  // Create the shipment from shipment plan.

  const createShipment = async () => {
    setSubmitting(true);

    const result = await shipmentService.createShipments({
      shipmentIds: [selectedShipment.shipmentId],
    });

    if (result.success) {
      await getShipments();
      toast.success("Shipment has been created successfully!");
    } else {
      if (result.erroredShipments.length > 0) {
        toast.error(
          "Some shipments have not been created successfully. Please try again.",
          {
            duration: 2500,
          }
        );
      }
    }

    setShowCreateShipmentModal(false);
    setSubmitting(false);
  };

  // Closes the shipment.
  const closeShipment = async () => {
    setSubmitting(true);

    const result = await shipmentService.closeShipment({
      shipmentId: selectedShipment.shipmentId,
    });

    if (result.success) {
      toast.success("Shipment closed successfully.");
    } else {
      toast.error(result.message);
    }

    setShowCloseShipmentModal(false);
    setSubmitting(false);
  };

  // Remove items from the shipment.
  const removeShipmentItems = async () => {
    // Get the right quantity of shipment item ids to remove.
    const shipmentItemIds = [];
    for (let i = 0; i < selectedShipmentItems.length; i++) {
      const items = selectedShipmentItems[i].originalShipmentItems;

      for (let j = 0; j < quantityValues[i]; j++) {
        shipmentItemIds.push(items[j].shipmentItemId);
      }
    }

    setSubmitting(true);

    const response = await shipmentService.removeShipmentItems({
      shipmentItemIds,
    });

    if (response.success) {
    } else {
      console.log(response);
      toast.error("Something went wrong. Please try again.");
    }

    setSubmitting(false);
  };

  const deleteShipmentPlan = async () => {
    setSubmitting(true);

    const result = await shipmentService.deleteShipmentPlan(
      selectedShipment.shipmentId
    );

    if (result.success) {
      await getShipments();
      toast.success("Shipment plan has been deleted successfully!");
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 2500,
      });
    }

    setSubmitting(false);
  };

  const CloseShipmentConfirmModal = () => {
    return (
      <Modal
        show={showCloseShipmentModal}
        onHide={() => setShowCloseShipmentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Close Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will submit the box content feed to Amazon. Any items not yet in
          a carton will be removed from this Amazon shipment. Are you sure you
          want to continue?
        </Modal.Body>
        <Modal.Footer>
          {submitting ? (
            "Closing Shipment"
          ) : (
            <>
              <Button variant="danger" onClick={closeShipment}>
                Continue
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowCloseShipmentModal(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  const PrintLabelsModal = () => {
    return (
      <Modal
        size="sm"
        show={showPrintModal}
        onHide={() => setShowPrintModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Print FBA Shipment Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table size="sm" striped>
            <thead>
              <tr>
                <th>Carton ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {shipmentCartons?.map((carton, index) => {
                return (
                  <tr key={index}>
                    <td>{carton.shipmentCartonId}</td>
                    <td className="text-end">
                      <Button
                        className="flex-fill"
                        variant="primary"
                        onClick={() =>
                          printFbaShipmentLabel(carton.shipmentCartonId)
                        }
                        disabled={submitting}
                      >
                        Print Label
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => setShowPrintModal(false)}
            disabled={submitting}
          >
            Finished
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const CreateShipmentConfirmModal = () => {
    return (
      <Modal
        show={showCreateShipmentModal}
        onHide={() => setShowCreateShipmentModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create Shipment From Shipment Plan{" "}
            {selectedShipment && selectedShipment.amazonShipmentId}?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          {submitting ? (
            "Creating Shipment"
          ) : (
            <>
              <Button variant="primary" onClick={createShipment}>
                Continue
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowCreateShipmentModal(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  };
  const DeleteShipmentPlanModal = () => {
    return (
      <Modal
        show={showDeleteShipmentPlanModal}
        onHide={() => setShowDeleteShipmentPlanModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Shipment Plan{" "}
            {selectedShipment && selectedShipment.amazonShipmentId}?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          {submitting ? (
            "Deleting Shipment Plan"
          ) : (
            <>
              <Button variant="danger" onClick={deleteShipmentPlan}>
                Delete
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteShipmentPlanModal(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CloseShipmentConfirmModal />
      <PrintLabelsModal />
      <CreateShipmentConfirmModal />
      <DeleteShipmentPlanModal />
      <Card className="sticky-top shadow border" style={{ top: 20 }}>
        <Card.Header className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <FontAwesomeIcon
              icon={faClipboardList}
              size="3x"
              className="me-3"
            />
            <div>
              <Card.Title>Summary</Card.Title>
              <Card.Subtitle>
                Detailed information regarding selected shipments and items.
              </Card.Subtitle>
            </div>
          </div>
          <Fade in={showScrollButton}>
            <Button
              style={{ display: showScrollButton ? "inline" : "none" }}
              size="sm"
              variant="light"
              title="Back to Top"
              onClick={() => window.scrollTo(0, 0)}
            >
              <ArrowUp size={20} />
            </Button>
          </Fade>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <h4>Account</h4>
            {selectedAccount.listingAccount}
          </ListGroup.Item>
          {selectedShipment && (
            <ListGroup.Item>
              <h4>Shipment {selectedShipment.isShipmentPlan && "Plan"}</h4>
              <Table size="sm" striped style={{ tableLayout: "fixed" }}>
                <tbody>
                  <tr>
                    <td>Status:</td>
                    <td className="text-end">
                      {selectedShipment.shipmentStatus}
                    </td>
                  </tr>
                  <tr>
                    <td>Shipment ID:</td>
                    <td className="text-end">
                      {selectedShipment.amazonShipmentId}
                    </td>
                  </tr>
                  <tr>
                    <td>Destination ID:</td>
                    <td className="text-end">{selectedShipment.shipTo}</td>
                  </tr>
                  <tr>
                    <td>Total Units:</td>
                    <td className="text-end">{selectedShipment.totalItems}</td>
                  </tr>
                  <tr>
                    <td>Total SKUs:</td>
                    <td className="text-end">{selectedShipment.units}</td>
                  </tr>
                </tbody>
              </Table>
              <Row className="mt-3">
                {/* <Col>
                  <div className="d-flex">
                    <Button
                      className="flex-fill"
                      variant="outline-info"
                      onClick={() => {
                        console.log("View Cartons Clicked");
                      }}
                      // href={`/processing/shipmentCartons?shipmentId=${selectedShipment?.shipmentId}`}
                      disabled={submitting || true}
                    >
                      View Cartons
                    </Button>
                  </div>
                </Col> */}
                {selectedShipment.isShipmentPlan ? (
                  <>
                    <Col>
                      <Button
                        variant="outline-success"
                        className="w-100"
                        onClick={() => setShowCreateShipmentModal(true)}
                        disabled={showCreateShipmentModal || submitting}
                      >
                        Create Shipment from Plan
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="outline-danger"
                        className="w-100"
                        onClick={() => setShowDeleteShipmentPlanModal(true)}
                        disabled={showDeleteShipmentPlanModal || submitting}
                      >
                        Delete Shipment Plan
                      </Button>
                    </Col>
                  </>
                ) : !isSelectedShipmentClosed ? (
                  <Col>
                    <Button
                      variant="outline-warning"
                      onClick={() => setShowCloseShipmentModal(true)}
                      disabled={showCloseShipmentModal || submitting}
                    >
                      Close Shipment
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      variant="outline-primary"
                      onClick={() => setShowPrintModal(true)}
                      disabled={showPrintModal || submitting}
                    >
                      Print Labels
                    </Button>
                  </Col>
                )}
              </Row>
            </ListGroup.Item>
          )}
          {/* {selectedShipmentItems?.length > 0 && (
            <ListGroup.Item>
              <h4>Selected Shipment Items</h4>
              <Table size="sm" striped>
                <thead>
                  <tr>
                    <th>FNSKU</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedShipmentItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.fnsku}</td>
                        <td>{item.sku}</td>
                        <td>
                          <Form.Control
                            type="number"
                            name="quantity"
                            size="sm"
                            min={1}
                            max={item.quantity}
                            value={quantityValues[index] || ""}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                Number(e.target.value)
                              )
                            }
                            disabled={submitting}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Row className="mt-3">
                <Col>
                  <div className="d-flex">
                    <Button
                      className="flex-fill"
                      variant="outline-danger"
                      onClick={() => {
                        console.log("Close Shipment Clicked");
                        removeShipmentItems();
                      }}
                      disabled={submitting}
                    >
                      Remove Items From Shipment
                    </Button>
                  </div>
                </Col>
              </Row>
            </ListGroup.Item>
          )} */}
        </ListGroup>
      </Card>
    </>
  );
};

export default StickySidebar;
