import { useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { AlertCircle, Camera } from "react-feather";
import Webcam from "react-webcam";

const Capture = ({ setImages, disabled = false }) => {
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const webcamRef = useRef(null);

  // Capture image
  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setImages((prevState) => {
      return prevState.concat({ imageId: new Date().toISOString(), imageSrc });
    });
  };

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraAvailable(videoDevices.length > 0);
      } catch (error) {
        console.error("Error checking camera availability:", error);
      }
    };

    checkCameraAvailability();
  }, []);

  return (
    <>
      {cameraAvailable ? (
        showCamera ? (
          <div className="text-center">
            <div className="d-grid gap-2">
              <Button
                variant="secondary"
                size="lg"
                onClick={() => setShowCamera(false)}
              >
                Hide Camera
              </Button>
            </div>
            <Webcam
              className="my-3"
              audio={false}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
            />
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                size="lg"
                onClick={captureImage}
                disabled={disabled}
              >
                <Camera size={20} />
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-grid gap-2">
            <Button
              variant="secondary"
              size="lg"
              onClick={() => setShowCamera(true)}
            >
              Show Camera
            </Button>
          </div>
        )
      ) : (
        <Alert variant="danger" className="flex-fill alert-blink">
          <div className="alert-icon">
            <AlertCircle
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message">
            No Camera Detected. Please connect a webcam and try again.
          </div>
        </Alert>
      )}
    </>
  );
};

export default Capture;
