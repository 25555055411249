import React from "react";

import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../../../components/tables/DateRangeFilter";

const columns = [
  {
    Header: "Store Name",
    accessor: "storeName",
  },
  {
    Header: "Amazon Order ID",
    accessor: "amazonOrderId",
  },
  {
    Header: "Order Status",
    accessor: "orderStatus",
  },
  {
    Header: "Order Type",
    accessor: "orderType",
  },
  {
    Header: "Premium Order",
    accessor: ({ isPremiumOrder }) => (isPremiumOrder === false ? "No" : "Yes"),
    id: "premiumOrder",
  },
  {
    Header: "Number Of Items Unshipped",
    accessor: "numberOfItemsUnshipped",
  },
  {
    Header: "Latest Ship Date",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ latestShipDate }) => new Date(latestShipDate),
    id: "latestShipDate",
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Ship Service Level",
    accessor: "shipServiceLevel",
  },
  {
    Header: "Order Total",
    accessor: "orderTotal",
  },
  {
    Header: "Last Update Date",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ lastUpdateDate }) => new Date(lastUpdateDate),
    id: "lastUpdateDate",
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
];

export default columns;
