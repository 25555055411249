import React, { createContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import Item from "./components/receive/Item";
import Items from "./components/receive/Items";
import Lookup from "./components/receive/Lookup";
import Shipment from "./components/receive/Shipment";
import TNForm from "./components/receive/TNForm";

import receivingService from "../../services/receiving.service";

const ReceivingContext = createContext();

const ReceivingPage = () => {
  const [shipment, setShipment] = useState();
  const [items, setItems] = useState([]);
  const [vendor, setVendor] = useState();
  const [documents, setDocuments] = useState([]);
  const [manifest, setManifest] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [flags, setFlags] = useState();

  const [editing, setEditing] = useState(false);
  const [showItemForm, setShowItemForm] = useState(false);
  const [showItemDetails, setShowItemDetails] = useState(true);
  const [loadingShipment, setLoadingShipment] = useState(true);

  const { shipmentID } = useParams();

  // For coming to this page with a shipmentID param.
  useEffect(() => {
    const getReceivedShipment = async () => {
      const response = await receivingService.getReceivedShipments({
        shipmentId: shipmentID,
      });

      if (response.success) {
        const shipmentData = response.shipmentData;

        // Some data present if received shipment was already created or vendor
        // was found from the tracking number.
        if (Object.keys(shipmentData).length !== 0) {
          // Check if there are items for the shipment
          if (shipmentData.items) {
            setItems(shipmentData.items);
          }

          // Check if there are documents for the shipment
          if (shipmentData.documents) {
            setDocuments(shipmentData.documents);
          }

          // Check if there is a manifest for the tracking number
          if (shipmentData.manifest) {
            setManifest(shipmentData.manifest);
          }

          // Check if there are flags
          if (shipmentData.flags) {
            setFlags(shipmentData.flags);
          }

          // Check if there is a received shipment.
          if (shipmentData.shipment) {
            setVendor(shipmentData.vendor);
            // setSubmitting(false);
            setShipment(shipmentData.shipment);
          }
        }
      } else {
        toast.error("Something went wrong, please try again.");
      }
      setLoadingShipment(false);
    };

    if (shipmentID) {
      getReceivedShipment();
    } else {
      setLoadingShipment(false);
    }
  }, [shipmentID]);

  return (
    <ReceivingContext.Provider
      value={{
        currentItem,
        documents,
        editing,
        flags,
        items,
        manifest,
        setCurrentItem,
        setDocuments,
        setEditing,
        setFlags,
        setItems,
        setManifest,
        setShipment,
        setShowItemDetails,
        setShowItemForm,
        setVendor,
        shipment,
        showItemDetails,
        showItemForm,
        vendor,
      }}
    >
      <Helmet title="Receiving" />
      <h3 className="d-sm-block">Receiving</h3>
      <Container fluid className="p-0">
        {loadingShipment ? (
          <Loader />
        ) : !shipment ? (
          <Row className="justify-content-center">
            <Col xl={6}>
              <TNForm />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col xl={3}>{shipment && <Shipment />}</Col>
              <Col xl={6}>{currentItem ? <Item /> : <Lookup />}</Col>
              <Col xl={3}>{items && items.length > 0 && <Items />}</Col>
            </Row>
          </>
        )}
      </Container>
    </ReceivingContext.Provider>
  );
};

export { ReceivingContext };

export default ReceivingPage;
