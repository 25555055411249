import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { AlertCircle, Flag } from "react-feather";
import toast from "react-hot-toast";

import ModalGeneric from "../../../../components/ModalGeneric";
import ClaimCard from "./ClaimCard";
import ClaimModal from "./ClaimModal";
import InspectOptions from "./InspectOptions";
import ProcessingItemData from "./ProcessingItemData";

import printService from "../../../../services/printService";
import claimService from "../../../../services/claim.service";
import inspectService from "../../../../services/inspect/inspect.service";

import { InspectContext } from "../../Inspect";

const InspectItem = () => {
  const [inspectOptions, setInspectOptions] = useState();

  const {
    currentItem,
    setIsLoading,
    flags,
    userId,
    inspectConditionId,
    showClaimModal,
    setShowClaimModal,
    setMakeClaim,
    shouldSubmitInspection,
  } = useContext(InspectContext);

  const { processingData, itemData } = currentItem;
  const { price, valuableSale } = itemData;
  const { lpn, receivedAsin: asin, claim } = processingData;

  const lowPriceNote =
    price && !valuableSale ? `Current price is ${price}` : null;

  const claimCardRef = useRef(null);
  const startedAt = useRef(new Date().toISOString());

  useEffect(() => {
    const getInspectOptions = async () => {
      const inspectOptions = await inspectService.getInspectOptions();
      setInspectOptions(inspectOptions);
    };

    getInspectOptions();
  }, []);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const submitInspection = async ({ inspectConditionId }) => {
    setIsLoading(true);

    const result = await inspectService.submitInspection({
      lpn,
      asin,
      startedAt: startedAt.current,
      userId,
      inspectConditionId,
      note: lowPriceNote,
      quantity: currentItem?.processingData?.receivedQuantity || 1,
    });

    if (!result.listingId) {
      setIsLoading(false);
      toast.error("Error submitting inspection");
      return;
    }

    if (inspectConditionId < 6) {
      await printService.printListingLabel({
        fnsku: result.fnsku,
        sku: result.sku,
        amazonConditionLabel: result.amazonConditionLabel,
        inspectConditionLabel: result.inspectConditionLabel,
        title: result.title,
        account: result.account,
        listingId: result.listingId,
        userId,
        quantity: result.quantity,
        inspectConditionCode: result.inspectConditionCode,
      });
    }

    // submit inspection succeeded, item was listed on Ebay, failed to list on Amazon bec. of MISSING_DIMENSIONS error:
    if (result.missingDimensionsError) {
      toast.success(
        "Inspection submitted. Listing on Amazon failed due to MISSING_DIMENSIONS error. Item was listed on Ebay. ",
        { duration: 7000 }
      );
      setTimeout(() => {
        window.location.href = "/inspecting";
      }, 7000);
    } else {
      toast.success("Inspection submitted");
      setTimeout(() => {
        window.location.href = "/inspecting";
      }, 1500);
    }
  };

  const submitClaim = async ({
    claimReasonId,
    claimSubReasonId,
    claimReasonNote,
    claimImages,
  }) => {
    setIsLoading(true);

    if (claim) {
      const result = await claimService.updateClaim(claim.claimId, {
        claimReasonId,
        claimSubReasonId,
        claimReasonNote,
        claimImages,
      });

      if (result.success) {
        toast.success("Damage Report successfully updated.");
        setShowClaimModal(false);
        setMakeClaim(false);
        processingData.claim = result.updatedClaim;
      } else {
        toast.error("Error updating Damage Report.");
      }
      setIsLoading(false);
    } else {
      const result = await claimService.createClaim({
        claimReasonId,
        claimSubReasonId,
        claimReasonNote,
        lpn,
        userId,
        claimImages,
      });

      if (result.success) {
        toast.success("Damage Report successfully submitted.");
        setShowClaimModal(false);
        setMakeClaim(false);
        processingData.claim = result.newClaim;
      } else {
        toast.error("Error submitting Damage Report.");
      }
      setIsLoading(false);
    }

    if (shouldSubmitInspection) {
      submitInspection({
        inspectConditionId,
      });
    }
  };

  return (
    <>
      <ClaimModal
        claim={claim}
        showClaimModal={showClaimModal}
        setShowClaimModal={setShowClaimModal}
        submitClaim={submitClaim}
        shouldSubmitInspection={shouldSubmitInspection}
        inspectConditionId={inspectConditionId}
        submitInspection={submitInspection}
      />
      {currentItem.processingData.asinRule?.restrictProcessing === true && (
        <ModalGeneric
          showWithoutTrigger={true}
          showCloseButton={false}
          body={`This item should not be processed.`}
          title={`Please Note`}
        />
      )}
      <Row>
        <Col xl={8}>
          {currentItem.processingData.asinRule?.restrictProcessing === true && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                This item should not be processed.
              </div>
            </Alert>
          )}
          {flags.length > 0 && (
            <Alert variant="warning" className="flex-fill alert-blink">
              <div className="alert-icon">
                <Flag
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold">
                {flags.map((flag, index) => (
                  <li key={index}>{flag}</li>
                ))}
              </div>
            </Alert>
          )}
          {claim && (
            <Alert variant="warning" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold d-flex justify-content-between align-items-center">
                <span>This item has a Damage Report submitted for it.</span>
                <Button
                  variant="warning"
                  onClick={() => {
                    handleScroll(claimCardRef.current);
                  }}
                >
                  Go to Damage Report
                </Button>
              </div>
            </Alert>
          )}
          {currentItem.processingData.inspectedId && (
            <Alert variant="danger" className="flex-fill alert-blink">
              <div className="alert-icon">
                <AlertCircle
                  className="position-relative top-50 start-50 translate-middle"
                  size={20}
                />
              </div>
              <div className="alert-message font-weight-bold d-flex justify-content-between align-items-center">
                <span>
                  This Item was already inspected. Redoing it will overwrite the
                  previous inspection.
                </span>
                {!claim &&
                  processingData.isSignedUpForClaims &&
                  processingData.inspectConditionId > 1 && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        setShowClaimModal(true);
                      }}
                    >
                      Make A Damage Report
                    </Button>
                  )}
              </div>
            </Alert>
          )}
          {currentItem && <ProcessingItemData processingItem={currentItem} />}
          {claim && (
            <div ref={claimCardRef}>
              <ClaimCard claim={claim} setShowClaimModal={setShowClaimModal} />
            </div>
          )}
        </Col>
        {inspectOptions && (
          <Col xl={4}>
            <Card className="sticky-top" style={{ top: 20 }}>
              <Card.Body>
                <InspectOptions
                  submitInspection={submitInspection}
                  optionsGroup={"List"}
                  options={inspectOptions.listOptions}
                />
                <InspectOptions
                  submitInspection={submitInspection}
                  optionsGroup={"Liquidate"}
                  options={inspectOptions.liquidateOptions}
                />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default InspectItem;
