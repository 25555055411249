import { createContext, useEffect, useReducer } from "react";

//import axios from "../utils/axios";
import axios from "axios";

import { decode } from "jsonwebtoken";
import { isValidToken, setSession } from "../utils/jwt";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const refreshToken = window.localStorage.getItem("refreshToken");

        if (
          accessToken &&
          isValidToken(accessToken) &&
          refreshToken &&
          isValidToken(refreshToken)
        ) {
          setSession(accessToken, refreshToken);

          const response = await axios.get(`${HOST_URL}/auth/getSession`);

          const { user, accessToken: newAccessToken } = response.data;

          if (newAccessToken) {
            setSession(newAccessToken, refreshToken);
          }
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password, stationId) => {
    const response = await axios.post(`${HOST_URL}/auth/createSession`, {
      username: email,
      password,
      stationId,
    });
    const { accessToken, refreshToken, user } = response.data;
    if (!refreshToken) {
      throw new Error("No refresh token");
    }
    setSession(accessToken, refreshToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    const refreshToken = window.localStorage.getItem("refreshToken");
    handleSignOut(refreshToken);
  };

  const signUp = async (values) => {
    const response = await axios.post(`${HOST_URL}/auth/signUp`, values);
    const { accessToken, user, vendor } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    setSession(accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
        vendor,
      },
    });
  };

  const checkExpiredRefreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const decoded = decode(refreshToken);

    if (decoded?.exp < Math.floor(Date.now() / 1000)) {
      handleSignOut(refreshToken);
    }
  };

  const handleSignOut = async (refreshToken) => {
    setSession(null);
    await axios.patch(`${HOST_URL}/auth/invalidateSession`, {
      refreshToken,
    });

    dispatch({ type: SIGN_OUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        checkExpiredRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
