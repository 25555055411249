import Alert from "react-bootstrap/Alert";

const UIAlert = ({
  alertShow = false,
  alertVariant = "success",
  alertHeading = "",
  alertMessage = "",
  alertFlags = [],
  onClose,
}) => {
  let textColor;

  if (alertVariant === "success") {
    textColor = { color: "#0f5132" };
  }

  if (alertVariant === "danger") {
    textColor = { color: "#842029" };
  }

  if (alertVariant === "warning") {
    textColor = { color: "#664d03" };
  }

  return (
    <Alert
      show={alertShow}
      variant={alertVariant}
      onClose={onClose}
      dismissible
    >
      <div className="alert-message mb-3 text-center" style={textColor}>
        <h3 className="alert-heading">{alertHeading}</h3>
        <hr />
        <p className="mb-0">{alertMessage}</p>
        {alertFlags.length > 0 && (
          <div className="my-3">
            <h4 style={textColor}>Flags</h4>
            {alertFlags.map((flag, index) => (
              <div key={index}>{flag}</div>
            ))}
          </div>
        )}
      </div>
    </Alert>
  );
};

export default UIAlert;
