import React, { useEffect, useState } from "react";

import { Button, Card, Container, Modal } from "react-bootstrap";
import { Briefcase } from "react-feather";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Table from "../../components/tables/Table";
import VendorForm from "./components/vendors/VendorForm";

import vendorService from "../../services/management/vendor.service";
import utilsService from "../../services/utils.service";

import tableColumns from "./components/vendors/tableColumns";

// Exporting setting modal state to the tableColumns module.
export let handleModal = () => {};

const VendorsPage = () => {
  const isAdmin = JSON.parse(localStorage.getItem("user")).isAdmin;

  // Modal/Form Types:
  // 0 = Create Vendor
  // 1 = Edit Vendor
  // 3 = Delete Vendor
  const [modalState, setModalState] = useState({
    show: false,
    type: 0,
    data: undefined,
  });
  const [vendors, setVendors] = useState();

  // States for the form selects
  const [masterVendorOptions, setMasterVendorOptions] = useState();
  const [storeOptions, setStoreOptions] = useState();
  const [preferredListingAccountOptions, setPreferredListingAccountOptions] =
    useState();

  handleModal = (modalState) => {
    setModalState(modalState);
  };

  // Get all the vendors from the BE
  const getVendors = async () => {
    const result = await vendorService.getVendors();

    if (result.success) {
      setVendors(result.vendors);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  // Get all the master vendors from the BE for select options
  const getMasterVendors = async () => {
    const result = await utilsService.getMasterVendors();

    if (result.success) {
      const options = [{ value: "", label: "None" }].concat(
        result.masterVendors.map((masterVendor) => {
          return { value: masterVendor.vendorId, label: masterVendor.name };
        })
      );

      setMasterVendorOptions(options);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  // Get all the stores from the BE for select options
  const getStores = async () => {
    const result = await utilsService.getStores();

    const options = [{ value: "", label: "None" }].concat(
      result.stores.map((store) => {
        return { value: store.storeId, label: store.storeName };
      })
    );

    if (result.success) {
      setStoreOptions(options);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  // Get all the listing accounts from the BE for select options
  const getListingAccounts = async () => {
    const result = await utilsService.getListingAccounts();

    const options = [{ value: "", label: "None" }].concat(
      result.listingAccounts.map((account) => {
        return {
          value: account.listingAccountId,
          label: account.listingAccount,
        };
      })
    );

    if (result.success) {
      setPreferredListingAccountOptions(options);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getVendors();
    getMasterVendors();
    getStores();
    getListingAccounts();
  }, []);

  // CRUD Operations
  const createVendor = async (vendor) => {
    const result = await vendorService.createVendor(vendor);

    if (result.success) {
      toast.success(`Added ${vendor.vendorName}`);
      setModalState((prevState) => ({
        ...prevState,
        show: false,
        data: undefined,
      }));
      setTimeout(() => {
        getVendors();
      }, 3000);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const editVendor = async (vendor) => {
    const result = await vendorService.updateVendor(vendor);

    if (result.success) {
      toast.success(`Edited ${vendor.vendorName}`);
      setModalState((prevState) => ({
        ...prevState,
        show: false,
        data: undefined,
      }));
      setTimeout(() => {
        getVendors();
      }, 3000);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const deleteVendor = async (vendorId) => {
    const result = await vendorService.deleteVendor(vendorId);

    if (result.success) {
      toast.success(`Deleted ${modalState.data.vendorName}`);
      setModalState((prevState) => ({
        ...prevState,
        show: false,
        data: undefined,
      }));
      setTimeout(() => {
        getVendors();
      }, 3000);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Vendors" />
      <Container fluid className="p-0">
        <h1 className="mb-3">Vendors</h1>
        <Modal
          show={modalState.show}
          onHide={() =>
            setModalState((prevState) => ({
              ...prevState,
              show: false,
              data: undefined,
            }))
          }
          backdrop="static"
          size="sm"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modalState.type === 0
                ? "Create Vendor"
                : modalState.type === 1
                ? "Edit Vendor"
                : "Delete Vendor"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalState.type === 2 ? (
              <>
                <span>
                  Are you sure you want to delete{" "}
                  <strong>{modalState.data?.vendorName}</strong>? This action
                  cannot be undone.
                </span>
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    variant="danger"
                    onClick={() => {
                      deleteVendor(modalState.data.vendorId);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setModalState((prevState) => ({
                        ...prevState,
                        show: false,
                        data: undefined,
                      }))
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <VendorForm
                modalState={modalState}
                setModalState={setModalState}
                createVendor={createVendor}
                editVendor={editVendor}
                masterVendorOptions={masterVendorOptions}
                storeOptions={storeOptions}
                preferredListingAccountOptions={preferredListingAccountOptions}
              />
            )}
          </Modal.Body>
        </Modal>

        {vendors && (
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="d-flex">
                <Briefcase className="me-3" size={40} />
                <div>
                  <Card.Title>Vendors</Card.Title>

                  {isAdmin && (
                    <Card.Subtitle>
                      Create, Search, Edit, and Delete vendors
                    </Card.Subtitle>
                  )}
                </div>
              </div>
              {isAdmin && (
                <span>
                  <Button
                    variant="primary"
                    onClick={() =>
                      setModalState({ show: true, type: 0, data: undefined })
                    }
                  >
                    Create Vendor
                  </Button>
                </span>
              )}
            </Card.Header>
            <Card.Body className="pb-0">
              <Table columns={tableColumns} data={vendors} />
            </Card.Body>
          </Card>
        )}
      </Container>
    </React.Fragment>
  );
};

export default VendorsPage;
