import React from "react";
import { Button } from "react-bootstrap";

function Pagination({ tableInstance }) {
  const {
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    preFilteredRows,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <div className="w-50 m-auto d-flex justify-content-around">
      <div>
        Show:{" "}
        <select
          value={[20, 30, 40, 50, 100].includes(pageSize) ? pageSize : "All"}
          onChange={({ target }) => {
            const { value } = target;
            if (value !== "All") {
              setPageSize(Number(value));
            } else {
              setPageSize(preFilteredRows.length);
            }
          }}
        >
          {[20, 30, 40, 50, 100, "All"].map((pageSizeOption) => (
            <option key={pageSizeOption} value={pageSizeOption}>
              {pageSizeOption}
            </option>
          ))}
        </select>{" "}
        records
      </div>

      <span>
        Page {pageIndex + 1} of {pageOptions.length}{" "}
      </span>
      <span>
        Go to page:{" "}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
          style={{ width: "50px" }}
        />
      </span>
      <div>
        <Button
          className="mx-1"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </Button>
        <Button
          className="mx-1"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </Button>
        <Button
          className="mx-1"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next
        </Button>
        <Button
          className="mx-1"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>
      </div>
    </div>
  );
}

export default Pagination;
