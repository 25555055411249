import React from "react";

import { Formik } from "formik";
import { Button, Form, Spinner, Alert } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";

const VendorForm = ({
  modalState,
  setModalState,
  createVendor,
  editVendor,
  masterVendorOptions,
  storeOptions,
  preferredListingAccountOptions,
}) => {
  let masterVendorId,
    storeId,
    vendorId,
    vendorName,
    vendorRMACode,
    vendorListingSKUPrefix,
    restrictAmazonAllListings,
    restrictAmazonNewListing,
    restrictEbayNewListing,
    preferredListingAccountId,
    isSignedUpForClaims,
    claimsLoginUsername,
    claimsLoginPassword;

  if (modalState.data) {
    ({
      masterVendorId,
      storeId,
      vendorId,
      vendorName,
      vendorRMACode,
      vendorListingSKUPrefix,
      restrictAmazonAllListings,
      restrictAmazonNewListing,
      restrictEbayNewListing,
      preferredListingAccountId,
      isSignedUpForClaims,
      claimsLoginUsername,
      claimsLoginPassword,
    } = modalState.data);
  }

  return (
    <Formik
      initialValues={{
        masterVendorId: masterVendorId || "",
        storeId: storeId || "",
        vendorId: vendorId || "",
        vendorName: vendorName || "",
        vendorRMACode: vendorRMACode || "",
        vendorListingSKUPrefix: vendorListingSKUPrefix || "",
        restrictAmazonAllListings: restrictAmazonAllListings || false,
        restrictAmazonNewListing: restrictAmazonNewListing || false,
        restrictEbayNewListing: restrictEbayNewListing || false,
        preferredListingAccountId: preferredListingAccountId || "",
        isSignedUpForClaims: isSignedUpForClaims || false,
        claimsLoginUsername: claimsLoginUsername || "",
        claimsLoginPassword: claimsLoginPassword || "",
      }}
      validationSchema={Yup.object().shape({
        vendorName: Yup.string()
          .max(50, "Vendor Name must be less than 50 characters long.")
          .required("Vendor Name is required."),
        vendorListingSKUPrefix: Yup.string().max(
          50,
          "Vendor Listing SKU Prefix must be less than 50 characters long."
        ),
        claimsLoginUsername: Yup.string()
          // .when("isSignedUpForClaims", {
          //   is: (isSignedUpForClaims) => isSignedUpForClaims === true,
          //   then: Yup.string().required("Claims Login Username is required."),
          // })
          .max(
            50,
            "Claims Login Username must be less than 50 characters long."
          ),
        claimsLoginPassword: Yup.string()
          // .when("isSignedUpForClaims", {
          //   is: (isSignedUpForClaims) => isSignedUpForClaims === true,
          //   then: Yup.string().required("Claims Login Password is required."),
          // })
          .max(
            50,
            "Claims Login Password must be less than 50 characters long."
          ),
      })}
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) => {
        if (
          (values.claimsLoginUsername || values.claimsLoginPassword) &&
          !values.isSignedUpForClaims
        ) {
          setErrors({
            submit:
              'Must check off "Signed Up For Claims" when entering claims login info.',
          });
          setSubmitting(false);
          return;
        }

        if (modalState.type === 0) {
          createVendor(values);
        } else {
          editVendor(values);
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
        values,
      }) => (
        <Form>
          {masterVendorOptions && (
            <Form.Group className="mb-3">
              <Form.Label className="m-0">Master Vendor</Form.Label>
              <Select
                className={`react-select-container${
                  isSubmitting ? "-disabled" : ""
                }`}
                classNamePrefix="react-select"
                name="masterVendorId"
                placeholder="Select a Master Vendor..."
                onChange={(selectedOption) => {
                  setFieldValue("masterVendorId", selectedOption.value);
                }}
                value={masterVendorOptions.find(
                  (option) => option.value === values.masterVendorId
                )}
                options={masterVendorOptions}
                isSearchable
                isDisabled={isSubmitting}
              />
            </Form.Group>
          )}
          {storeOptions && (
            <Form.Group className="mb-3">
              <Form.Label className="m-0">Store</Form.Label>
              <Select
                className={`react-select-container${
                  isSubmitting ? "-disabled" : ""
                }`}
                classNamePrefix="react-select"
                name="storeId"
                placeholder="Select a Store..."
                onChange={(selectedOption) => {
                  setFieldValue("storeId", selectedOption.value);
                }}
                value={storeOptions.find(
                  (option) => option.value === values.storeId
                )}
                options={storeOptions}
                isSearchable
                isDisabled={isSubmitting}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Vendor Name</Form.Label>
            <Form.Control
              type="text"
              name="vendorName"
              placeholder="Enter Vendor Name..."
              value={values.vendorName}
              isInvalid={Boolean(touched.vendorName && errors.vendorName)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.vendorName && (
              <Form.Control.Feedback type="invalid">
                {errors.vendorName}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Vendor RMA Code</Form.Label>
            <Form.Control
              type="text"
              name="vendorRMACode"
              value={values.vendorRMACode}
              placeholder="Enter Vendor RMA Code..."
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Vendor Listing SKU</Form.Label>
            <Form.Control
              type="text"
              name="vendorListingSKUPrefix"
              placeholder="Enter Vendor Listing SKU..."
              value={values.vendorListingSKUPrefix}
              isInvalid={Boolean(
                touched.vendorListingSKUPrefix && errors.vendorListingSKUPrefix
              )}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.vendorListingSKUPrefix && (
              <Form.Control.Feedback type="invalid">
                {errors.vendorListingSKUPrefix}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Listing Restrictions</Form.Label>
            <Form.Check
              id="raal"
              name="restrictAmazonAllListings"
              label={<span htmlFor="raal">Restrict All Amazon Listings</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictAmazonAllListings}
              checked={values.restrictAmazonAllListings}
              disabled={isSubmitting}
            />
            <Form.Check
              id="ranl"
              name="restrictAmazonNewListing"
              label={<span htmlFor="ranl">Restrict New Amazon Listings</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictAmazonNewListing}
              checked={values.restrictAmazonNewListing}
              disabled={isSubmitting}
            />
            <Form.Check
              id="renl"
              name="restrictEbayNewListing"
              label={<span htmlFor="renl">Restrict New eBay Listings</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictEbayNewListing}
              checked={values.restrictEbayNewListing}
              disabled={isSubmitting}
            />
          </Form.Group>
          {preferredListingAccountOptions && (
            <Form.Group className="mb-3">
              <Form.Label className="m-0">Preferred Listing Account</Form.Label>
              <Select
                className={`react-select-container${
                  isSubmitting ? "-disabled" : ""
                }`}
                classNamePrefix="react-select"
                name="preferredListingAccountId"
                placeholder="Select a Preferred Listing Account..."
                onChange={(selectedOption) => {
                  setFieldValue(
                    "preferredListingAccountId",
                    selectedOption.value
                  );
                }}
                value={preferredListingAccountOptions.find(
                  (option) => option.value === values.preferredListingAccountId
                )}
                options={preferredListingAccountOptions}
                isSearchable
                isDisabled={isSubmitting}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Claims</Form.Label>
            <Form.Check
              id="isufc"
              name="isSignedUpForClaims"
              label={<span htmlFor="renl">Signed Up For Claims</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.isSignedUpForClaims}
              checked={values.isSignedUpForClaims}
              disabled={isSubmitting}
            />
            <Form.Label className="m-0">Claims Login Username</Form.Label>
            <Form.Control
              type="text"
              name="claimsLoginUsername"
              placeholder="Enter Claims Login Username..."
              value={values.claimsLoginUsername}
              isInvalid={Boolean(
                touched.claimsLoginUsername && errors.claimsLoginUsername
              )}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("claimsLoginUsername", e.target.value);
                if (e.target.value) {
                  setFieldValue("isSignedUpForClaims", true);
                }
              }}
              disabled={isSubmitting}
            />
            {!!touched.claimsLoginUsername && (
              <Form.Control.Feedback type="invalid">
                {errors.claimsLoginUsername}
              </Form.Control.Feedback>
            )}
            <Form.Label className="m-0">Claims Login Password</Form.Label>
            <Form.Control
              type="text"
              name="claimsLoginPassword"
              placeholder="Enter Claims Login Password..."
              value={values.claimsLoginPassword}
              isInvalid={Boolean(
                touched.claimsLoginPassword && errors.claimsLoginPassword
              )}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.claimsLoginPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.claimsLoginPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <div className="d-flex justify-content-between">
            <Button
              className="d-flex align-items-center"
              variant="success"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {modalState.type === 0 && !isSubmitting
                ? "Create"
                : modalState.type === 0 && isSubmitting
                ? "Creating..."
                : !isSubmitting
                ? "Save"
                : "Saving..."}
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                setModalState((prevState) => ({
                  ...prevState,
                  show: false,
                  data: undefined,
                }))
              }
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VendorForm;
