import React, { useContext } from "react";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import { PlusSquare } from "react-feather";
import AmazonListingOptions from "./AmazonListingOptionsList";
import OtherListingOptions from "./OtherListingOptions";

import { ListingItemContext } from "../../ListingPage";

const ListingOptions = ({
  isSubmitting,
  isEditing,
  isSubmitted,
  handleSubmitChanges,
  handleSubmitListing,
  conditionId,
  restrictLiquidation,
}) => {
  const { listingOptions, listingRequirements, isLoading, error } =
    useContext(ListingItemContext);

  const {
    forceEbayListing,
    forceNewAsUsed,
    forceLiquidation,
    restrictAmazonNewListing,
    restrictEbayNewListing,
    isVendorRestricted,
    isVendorRestrictedAsin,
    isInAmazonCatalog,
  } = listingRequirements;

  const showAmazonListingOptions =
    listingOptions?.availableListingOptions?.length > 0 &&
    !forceEbayListing &&
    !forceLiquidation &&
    isInAmazonCatalog;

  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      <Card.Header style={{ backgroundColor: "#E3E3E3" }} className="pb-0">
        <Card.Title className="mb-0">
          <div className="text-center">
            <h3>
              Listing Options
              {!isSubmitted && !isEditing && (
                <Button
                  className="mx-2 px-1 py-0"
                  variant="info"
                  title={"Check eligibility on other accounts."}
                  onClick={() => handleSubmitChanges(true)}
                >
                  <PlusSquare size={16} />
                </Button>
              )}
            </h3>
          </div>
        </Card.Title>
      </Card.Header>
      <Card.Body className="text-center">
        {isSubmitted || isEditing ? (
          isSubmitted ? (
            "Listing Submitted"
          ) : (
            "Finish editing to show listing options"
          )
        ) : isLoading || isSubmitting ? (
          <>
            <span className="font-weight-bold">
              {isLoading ? "Getting listing options" : "Submitting Listing"}
            </span>
            <div>
              <Spinner animation="border" variant="secondary" />
            </div>
          </>
        ) : error ? (
          <>
            <Alert className="p-3 justify-content-center" variant="danger">
              <span className="text-center">{error}</span>
            </Alert>
            <a className="btn btn-danger" href="/listing">
              Start Over
            </a>
          </>
        ) : (
          <>
            {/* show alert if item has ASIN Rule restrictingLiquidation and condition is 'New Sealed' */}
            {restrictLiquidation && conditionId === 1 && (
              <Alert
                className="p-3 text-center justify-content-center font-weight-bold"
                variant="danger"
              >
                Due to an ASIN Rule, this item cannot be liquidated.
              </Alert>
            )}
            {forceEbayListing && (
              <Alert
                className="p-3 text-center justify-content-center font-weight-bold"
                variant="danger"
              >
                {isVendorRestricted
                  ? `This vendor does not allow Amazon listings. Choose another option below.`
                  : isVendorRestrictedAsin
                  ? `This ASIN is restricted by the vendor. Choose another option below.`
                  : `This ASIN in this condition is not able to be listed on
                Amazon. Confirm that the ASIN and condition are correct or
                choose another option below.`}
              </Alert>
            )}
            {showAmazonListingOptions && (
              <>
                {/* if fbaMetrics exists for this item, show fields: */}
                {Object.keys(listingOptions.fbaMetrics).length !== 0 && (
                  <div className="d-flex justify-content-center">
                    <p className="pe-2">
                      Inventory Last 90 Days:{" "}
                      {listingOptions.fbaMetrics.inventoryLast90Days}
                    </p>
                    <p className="ps-2">
                      Shipped Last 90 days:{" "}
                      {listingOptions.fbaMetrics.shippedLast90Days}
                    </p>
                  </div>
                )}
                <AmazonListingOptions
                  options={listingOptions?.availableListingOptions}
                  restrictAmazonNewListing={restrictAmazonNewListing}
                  conditionId={conditionId}
                  handleSubmitListing={handleSubmitListing}
                />
              </>
            )}
            <OtherListingOptions
              handleSubmitListing={handleSubmitListing}
              forceLiquidation={forceLiquidation}
              restrictEbayNewListing={restrictEbayNewListing}
              conditionId={conditionId}
              restrictLiquidation={restrictLiquidation}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ListingOptions;
