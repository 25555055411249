import React, { useState } from "react";
import { Button, Card, Form, InputGroup, Alert } from "react-bootstrap";
import { X, AlertOctagon } from "react-feather";
import toast from "react-hot-toast";

import shipmentService from "../../../../services/shipmentService";

const ShipmentPalletActions = ({
  submitting,
  setSubmitting,
  selectedDestination,
  selectedShipmentPallet,
  setSelectedShipmentPallet,
}) => {
  const [shipmentCartonId, setShipmentCartonId] = useState("");
  const [hovered, setHovered] = useState(false);
  const [error, setError] = useState();

  const addCartonToShipmentPallet = async () => {
    if (!shipmentCartonId) {
      toast.error("Please enter a Carton ID.");
      return;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (shipmentCartonId.toUpperCase() === "W") {
      toast.error("Please re-scan the Carton ID or enter it manually.");
      setShipmentCartonId("");
      return;
    }

    // Check for characters that aren't digits.
    const nonDigitRegex = /[^0-9]/; // Evals to true if not 0-9 digits.
    if (nonDigitRegex.test(shipmentCartonId)) {
      toast.error("The Carton ID must only be digits 0-9.");
      setShipmentCartonId("");
      return;
    }

    setSubmitting(true);

    const response = await shipmentService.addCartonToShipmentPallet({
      shipmentCartonId,
      shipmentPalletId: selectedShipmentPallet.shipmentPalletId,
    });

    if (response.success) {
      toast.success(
        `Added Carton ${shipmentCartonId} to Shipment Pallet ${selectedShipmentPallet.shipmentPalletId}.`
      );
    } else {
      toast.error("Something went wrong. Please try again.");
      setError(response);
    }

    setShipmentCartonId("");
    setSubmitting(false);
  };

  return (
    <Card className="sticky-top shadow border" style={{ top: 20 }}>
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div>
            <Card.Title>
              Shipment Pallet ID: {selectedShipmentPallet.shipmentPalletId}
            </Card.Title>
            <Card.Subtitle>
              Destination Center ID: {selectedDestination.destinationCenterId}
            </Card.Subtitle>
          </div>
          <X
            className={`${hovered ? "text-primary cursor-pointer" : ""}`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => setSelectedShipmentPallet()}
          />
        </div>
      </Card.Header>
      <Card.Body>
        {error && (
          <Alert variant="danger" className="flex-fill alert-blink">
            <div className="alert-icon">
              <AlertOctagon
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              {error.message}
            </div>
          </Alert>
        )}
        <Form.Label className="mb-0">Carton ID</Form.Label>
        <InputGroup>
          <Form.Control
            autoFocus
            onChange={(e) => setShipmentCartonId(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                addCartonToShipmentPallet();
              }
            }}
            placeholder="Scan or enter Carton ID..."
            size="lg"
            type="text"
            value={shipmentCartonId}
          />
          <Button
            variant="primary"
            onClick={() => addCartonToShipmentPallet()}
            disabled={!shipmentCartonId || submitting}
          >
            Add to Pallet
          </Button>
        </InputGroup>
      </Card.Body>
    </Card>
  );
};

export default ShipmentPalletActions;
