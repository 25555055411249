import React from "react";
import { FormControl } from "react-bootstrap";

function UIInput({
  required,
  autoFocus = false,
  type,
  value,
  placeholder,
  onChange,
  onKeyUp,
  size,
  className,
  refValue,
  disabled = false,
}) {
  return (
    <FormControl
      required
      autoFocus={autoFocus}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onKeyUp={onKeyUp}
      size={size}
      className={className}
      ref={refValue}
      disabled={disabled}
    />
  );
}

export default UIInput;
