import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import UICard from "../../../../components/UICard";
import imageNotFound from "../../../../assets/img/imageNotFound.png";

function CurrentItem({ currentItem, originalListingMessage }) {
  const body = (
    <>
      <h2>{originalListingMessage}</h2>
      <Row>
        <Col md={4}>
          <img
            className="img-fluid img-thumbnail"
            src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${currentItem.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = imageNotFound;
            }}
            alt="Item"
          />
        </Col>
        <Col md={4}>
          <h4>Item Name</h4>
          <p>{currentItem.itemName}</p>
        </Col>
        <Col md={4}>
          <h4>ASIN</h4>
          <p>{currentItem.asin}</p>
        </Col>
      </Row>
    </>
  );
  const header = (
    <>
      <div className="d-flex justify-content-between">
        <span>{currentItem.fnsku}</span>
        <Button
          variant={"outline-danger"}
          onClick={() => window.location.reload()}
        >
          Start over
        </Button>
      </div>
    </>
  );
  return <UICard header={header} body={body} />;
}

export default CurrentItem;
