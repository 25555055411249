import React, { useState, useEffect, Fragment } from "react";

import { Table, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";

import ChooseDates from "../../../../components/ChooseDates";
import shipmentService from "../../../../services/shipmentService";

const DetermineShipmentLocationModal = ({
  listingAccounts,
  selectedAccount,
}) => {
  const determineShipmentLocationOptions = listingAccounts.map((account) => ({
    value: account.sellingPartnerId,
    label: account.listingAccount,
  }));
  const categoryOptions = [
    { value: 1, label: "Standard" },
    { value: 2, label: "Oversize" },
  ];

  const [showModal, setShowModal] = useState();
  const [selectedListingAccount, setSelectedListingAccount] = useState();
  const [category, setCategory] = useState(categoryOptions[0]);

  const [calculatedLocationShipments, setCalculatedLocationShipments] =
    useState();
  const [showAlert, setShowAlert] = useState(false);
  const [shipmentDates, setShipmentDates] = useState();

  useEffect(() => {
    const selectAccount = async () => {
      const pageSelectedAccount = determineShipmentLocationOptions.find(
        (option) => option.value === selectedAccount.sellingPartnerId
      );
      setSelectedListingAccount(pageSelectedAccount);
    };
    selectedAccount && selectAccount();
  }, [selectedAccount]);

  const LocationShipmentsTable = () => {
    let destination;
    return (
      <>
        {calculatedLocationShipments.length > 0 ? (
          <Table bordered style={{ tableLayout: "fixed" }}>
            {calculatedLocationShipments.map((shipment, index) => {
              let newDest = false;
              if (shipment.shipTo !== destination) {
                destination = shipment.shipTo;
                newDest = true;
              }
              return (
                <Fragment key={index}>
                  {newDest && (
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        <th className="border-0"></th>
                      </tr>
                      <tr>
                        <th className="border-end-0">
                          Destination: {shipment.shipTo}
                        </th>
                        <th className="border-start-0">
                          Total: {shipment.destCatQty}
                        </th>
                      </tr>
                      <tr>
                        <th>Shipment ID</th>
                        <th>Units</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td>{shipment.amazonShipmentId}</td>
                      <td>{shipment.shipmentQty}</td>
                    </tr>
                  </tbody>
                </Fragment>
              );
            })}
          </Table>
        ) : (
          "No shipments found."
        )}
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setCalculatedLocationShipments()}
          >
            Clear
          </Button>
          <CSVLink
            data={calculatedLocationShipments}
            headers={["shipTo", "amazonShipmentId", "shipmentQty", "createdAt"]}
            filename={`${selectedListingAccount.label.replace(
              " ",
              ""
            )}_${shipmentDates[0]
              .toLocaleDateString()
              .replaceAll("/", "-")}-${shipmentDates[1]
              .toLocaleDateString()
              .replaceAll("/", "-")}_${category.label}.csv`}
          >
            <Button variant="primary">Download</Button>
          </CSVLink>
        </Modal.Footer>
      </>
    );
  };

  const determineShipmentLocation = async (startDate, endDate) => {
    setShipmentDates([startDate, endDate]);

    if (selectedListingAccount) {
      setShowAlert(false);

      const response = await shipmentService.getDestinationWithMostItems({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        sellingPartnerId: selectedListingAccount.value,
        category: category.value,
      });

      if (response.success) {
        setCalculatedLocationShipments(response.shipments);
      } else {
        toast.error("Something went wrong. Please try again.");
        //setError(response);
      }
    } else {
      setShowAlert(true);
    }
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} disabled={showModal}>
        Determine Shipment Location
      </Button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setShowAlert(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Determine Shipment Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!calculatedLocationShipments ? (
            <Col md={6} className="m-auto text-center">
              <Row>
                <label>Account:</label>
                <Select
                  name="listingAccount"
                  placeholder="Select Account..."
                  defaultValue={selectedListingAccount}
                  onChange={setSelectedListingAccount}
                  options={determineShipmentLocationOptions}
                  isSearchable
                />
              </Row>
              <Alert variant="danger" show={showAlert}>
                Please select an account.
              </Alert>
              <Row className="my-4">
                <label>Shipment Category:</label>
                <Select
                  name="shipmentCategory"
                  defaultValue={category}
                  onChange={setCategory}
                  options={categoryOptions}
                  isSearchable={false}
                />
              </Row>
              <Row className="m-3">
                <ChooseDates
                  goButtonAction={determineShipmentLocation}
                  immediateAction={false}
                  maxWeek={false}
                />
              </Row>
            </Col>
          ) : (
            <>
              <h5>
                Account: {selectedListingAccount.label}
                <br />
                Dates: {shipmentDates[0].toLocaleDateString()} -{" "}
                {shipmentDates[1].toLocaleDateString()}
                <br />
                Shipment Category: {category.label}
              </h5>

              <LocationShipmentsTable />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetermineShipmentLocationModal;
