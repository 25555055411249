import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import UICard from "../../../../components/UICard";
import UIInput from "../../../../components/UIInput";
import UIButton from "../../../../components/UIButton";
import printService from "../../../../services/printService";
import { getLabelData } from "../../../../services/printFnskuLabel.service";
import toast from "react-hot-toast";

import { Spinner } from "react-bootstrap";

function PrintFnskuLabelForm() {
  const [queryValue, setQueryValue] = useState("");
  const [loading, setLoading] = useState(false);

  const resetPage = () => {
    setQueryValue("");
  };

  const printFnskuLabel = async () => {
    if (!queryValue) {
      toast.error("Enter an FNSKU to print a label");
      return;
    }

    setLoading(true);

    // let fnsku, listingId;
    const labelDataParams = {};
    if (!isNaN(queryValue)) {
      labelDataParams.listingId = queryValue;
    } else {
      labelDataParams.fnsku = queryValue;
    }

    try {
      const { fnskuLabelData } = await getLabelData(labelDataParams);
      setLoading(false);

      if (fnskuLabelData && Object.keys(fnskuLabelData).length === 0) {
        toast.error("No label data found");
      }

      if (fnskuLabelData && Object.keys(fnskuLabelData).length > 0) {
        if (Object.values(fnskuLabelData).includes(null)) {
          toast.error("No label data found");
          resetPage();
          return;
        }

        await printService.printListingLabel(fnskuLabelData);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    resetPage();
  };

  const header = <h4 className="text-center">{"Print FNSKU label"}</h4>;

  const body = loading ? (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <span className="px-2 font-weight-bold"> Loading... </span>
      <Spinner animation="border" variant="secondary" />
    </div>
  ) : (
    <>
      <UIInput
        required
        autoFocus
        value={queryValue}
        onChange={(e) => {
          e.preventDefault();
          setQueryValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            printFnskuLabel();
          }
        }}
        type="text"
        placeholder="Enter an FNSKU or ListingID to print a label"
        size="lg"
        className="my-3 w-75 m-auto text-center"
      />

      <UIButton
        variant="primary"
        text="Submit"
        onClick={() => printFnskuLabel()}
      />
    </>
  );

  return (
    <UICard className="text-center w-75 m-auto" header={header} body={body} />
  );
}

export default PrintFnskuLabelForm;
