import React from "react";
import { Button } from "react-bootstrap";

function formatColumnName(name) {
  switch (name) {
    case "asin":
    case "lpn":
      return name.toUpperCase();
    default:
      return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
  }
}

function FilterOptions({
  filters,
  setFilter,
  setAllFilters,
  globalFilter,
  setGlobalFilter,
}) {
  let allFilters = filters;
  const hasGlobal = globalFilter && globalFilter.length > 0;

  //TODO:add global filter removing

  // if (globalFilter && !filters.some((f) => f.id === "global")) {
  //   allFilters = [...filters, { id: "global", value: globalFilter }];

  //   console.log(allFilters);
  // }

  const hasFilters = allFilters.length > 0;

  return (
    <>
      <div className="m-auto my-2 d-flex justify-content-center">
        {allFilters.map((filter) => (
          <Button
            className="mx-2"
            key={filter.id}
            variant="warning"
            onClick={() =>
              !hasGlobal ? setFilter(filter.id, "") : setGlobalFilter("")
            }
          >{`Clear ${formatColumnName(filter.id)} filter`}</Button>
        ))}
        {hasFilters ? (
          <Button
            className="mx-2"
            variant="danger"
            onClick={() => {
              setAllFilters([]);
              setGlobalFilter("");
            }}
          >
            Clear all filters
          </Button>
        ) : null}
      </div>
    </>
  );
}

export default FilterOptions;
