import React from "react";
import UIDefaultPage from "../../components/UIDefaultPage";

import { Tab, Tabs, Row, Col } from "react-bootstrap";

import FlagTable from "./components/flags/FlagTable";
import flagTypes from "./components/flags/flagTypes";

const children = (
  <Row>
    <Col xl={8}>
      <Tabs
        defaultActiveKey={flagTypes[0].flagType}
        transition={true}
        className="mb-0"
      >
        {flagTypes.map((fType) => {
          return (
            <Tab
              eventKey={fType.flagType}
              key={fType.flagType}
              title={fType.title}
            >
              <FlagTable
                flagType={fType.flagType}
                title={fType.title}
                tableColumns={fType.tableColumns}
                initialFormValues={fType.initialFormValues}
              />
            </Tab>
          );
        })}
      </Tabs>
    </Col>
  </Row>
);

const Flags = () => {
  return <UIDefaultPage title={"Flags"} children={children} />;
};

export default Flags;
