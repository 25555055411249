import React, { useState, useEffect } from "react";

import Vendor from "../pages/warehouse/models/Vendor";
import Select from "react-select";

import axios from "axios";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const optionizeVendor = (vendor) => {
  return {
    value: vendor.vendorId,
    label: `${vendor.vendorName ? vendor.vendorName : "(No Name)"} (${
      vendor.vendorListingSKUPrefix ? vendor.vendorListingSKUPrefix : ""
    }${vendor.vendorListingSKUPrefix && vendor.vendorRMACode ? " - " : ""}${
      vendor.vendorRMACode ? `${vendor.vendorRMACode}` : ""
    })`,
  };
};

function VendorSelect({ vendorId, setSelectedVendor }) {
  const [vendors, setVendors] = useState();
  const [vendor, setVendor] = useState();

  useEffect(() => {
    const getVendors = async () => {
      try {
        const vendorDataResponse = (
          await axios.get(`${HOST_URL}/warehouse/vendor`)
        ).data;
        setVendors(vendorDataResponse.vendors);
        return vendorDataResponse;
      } catch (error) {
        console.log(error);
      }
    };
    getVendors();
  }, []);

  useEffect(() => {
    if (vendorId && vendors) {
      const foundVendor = vendors.find((v) => v.vendorId === vendorId);
      const vendor = optionizeVendor(foundVendor);
      setVendor(vendor);
    }
  }, [vendors]);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder="Select Vendor"
      onChange={(selectedValue) => {
        const selectedVendorRaw = selectedValue.label.split("(");
        const selectedVendorName = selectedVendorRaw[0];
        const selectedVendorSKUPrefixRaw = selectedVendorRaw[1].split("-")[0];
        const selectedVendorSKUPrefix = selectedVendorSKUPrefixRaw;
        const selectedVendorRMACodeRaw = selectedVendorRaw[1].split("-")[1];
        const selectedVendorRMACode = selectedVendorRMACodeRaw?.substring(
          0,
          selectedVendorRMACodeRaw.length - 1
        );

        setVendor(selectedValue);

        setSelectedVendor(
          new Vendor(
            selectedValue.value,
            selectedVendorRMACode,
            selectedVendorName,
            selectedVendorSKUPrefix
          )
        );
      }}
      options={
        vendors &&
        vendors.map((vendor) => {
          return optionizeVendor(vendor);
        })
      }
      value={vendor}
      isSearchable
      autoFocus
    />
  );
}

export default VendorSelect;
