import { Button } from "react-bootstrap";
import Select from "react-select";
import EmployeeSelect from "./EmployeeSelect";

const dispositions = [
  "Received",
  "Accepted",
  "Liquidated",
  "Amazon",
  "Ebay",
  "Trash",
  "Liquidated",
  "Refurb 360",
  "Classic Gifts",
  "Kiwi Banana",
  "Pomegranate Pop(FunTime Sales)",
  "Lemon Explosion",
  "Ebay",
  "Liquidated",
  "Products 360",
];

function getStepUrl(row) {
  switch (row.step) {
    case "Received":
      return `/receiving/${row.itemId}`;
    case "Inspected":
      return `/inspecting/${row.itemId}`;
    case "Listed":
      return `/listing/${row.itemId}`;
  }
}

const columns = [
  {
    Header: "Date",
    accessor: (columns) => new Date(columns["date"]).toLocaleString(),
  },

  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => {
      return (
        <a
          href={`https://www.amazon.com/dp/${value}?th=1&psc=1`}
          target="_blank"
          rel="noreferrer"
          title="View on Amazon.com"
        >
          {value}
        </a>
      );
    },
  },

  {
    Header: "Quantity",
    accessor: "quantity",
  },

  {
    Header: "LPN",
    accessor: "lpn",
  },
  {
    Header: "Name",
    accessor: "name",
    Filter: ({ column }) => {
      return <EmployeeSelect column={column} />;
    },
    filter: (rows, columns, value) => {
      return !value
        ? rows
        : rows.filter((row) => row.original.userId === value);
    },
  },
  {
    Header: "Disposition",
    accessor: "disposition",

    Filter: ({
      filter,
      onChange,
      column: { filterValue, preFilteredRows, setFilter },
    }) => {
      return (
        <Select
          placeholder="Select a disposition"
          defaultValue={{ value: "", label: "All Dispositions" }}
          onChange={(selectedValue) => setFilter(selectedValue.value)}
          options={[{ value: "", label: "All Dispositions" }].concat(
            dispositions.map((disposition) => ({
              value: disposition,
              label: disposition,
            }))
          )}
        />
      );
    },
  },
  {
    Header: "Step",
    accessor: "step",
    Cell: ({ row: { original } }) => {
      return (
        <Button
          onClick={() => {
            window.open(getStepUrl(original));
          }}
        >
          {`View ${original.step}`}
        </Button>
      );
    },
    Filter: ({ column }) => {
      const { filterValue, preFilteredRows, setFilter } = column;
      return (
        <Select
          placeholder="Select a step"
          defaultValue={{ value: "", label: "All Steps" }}
          onChange={(selectedValue) => {
            setFilter(selectedValue.value);
          }}
          options={[
            { value: "", label: "All Steps" },
            { value: "receive", label: "Received" },
            { value: "inspect", label: "Inspected" },
            { value: "list", label: "Listed" },
          ]}
        />
      );
    },
  },
];

export default columns;
