import { useEffect, useState } from "react";
import { Alert, Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Eye } from "react-feather";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";
import ShipmentRackList from "./components/createShipment/ShipmentRackList";
import ShipmentRacks from "./components/createShipment/ShipmentRacks";

import shipmentService from "../../services/shipmentService";
import utilsService from "../../services/utils.service";
import { set } from "date-fns";

const Navigation = ({
  loading,
  selectedAccount,
  setSelectedAccount,
  listingAccounts,
  allShipmentRacks,
  submitting,
}) => {
  return (
    <ListGroup horizontal>
      {listingAccounts &&
        listingAccounts.map((account, index) => {
          return (
            <ListGroup.Item
              tag="a"
              key={index}
              onClick={() => {
                setSelectedAccount(account);
              }}
              action
              active={
                selectedAccount?.listingAccountId === account?.listingAccountId
              }
              disabled={allShipmentRacks.length === 0 || submitting}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span>{account.listingAccount}</span>
                <Badge bg="dark">
                  {allShipmentRacks?.filter(
                    (shipmentRack) =>
                      shipmentRack.listingAccountId === account.listingAccountId
                  ).length > 0
                    ? allShipmentRacks?.filter(
                        (shipmentRack) =>
                          shipmentRack.listingAccountId ===
                          account.listingAccountId
                      ).length
                    : 0}
                </Badge>
              </div>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};

const CreateShipmentPage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [listingAccounts, setListingAccounts] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [allShipmentCategories, setAllShipmentCategories] = useState([]);
  const [allShipmentRacks, setAllShipmentRacks] = useState([]);
  const [accountShipmentCategories, setAccountShipmentCategories] = useState(
    []
  );
  const [accountShipmentRacks, setAccountShipmentRacks] = useState([]);
  const [selectedShipmentRacks, setSelectedShipmentRacks] = useState([]);

  const [shipmentPlanOptions, setShipmentPlanOptions] = useState();
  const [selectedShipmentPlans, setSelectedShipmentPlans] = useState([]);
  const [activeShipmentCategory, setActiveShipmentCategory] = useState();

  useEffect(() => {
    getListingAccounts();
    getShipmentRacks();
    getShipmentCategories();
  }, []);

  // When an account is selected, filter the shipment racks and categories.
  useEffect(() => {
    const accountFilteredShipmentRacks = allShipmentRacks.filter(
      (shipmentRack) =>
        shipmentRack?.listingAccountId === selectedAccount.listingAccountId
    );

    if (accountFilteredShipmentRacks.length > 0) {
      setAccountShipmentRacks(accountFilteredShipmentRacks);
    } else {
      setAccountShipmentRacks([]);
    }

    const reducedShipmentCategories = allShipmentCategories.reduce(
      (result, category) => {
        const foundCategory = accountFilteredShipmentRacks.find(
          (shipmentRack) =>
            shipmentRack.shipmentCategoryId === category.shipmentCategoryId
        );

        if (foundCategory) {
          result.push(category);
        }

        return result;
      },
      []
    );

    setAccountShipmentCategories(reducedShipmentCategories);
    setSelectedShipmentRacks([]);
  }, [selectedAccount]);

  const getListingAccounts = async () => {
    setLoading(true);

    const result = await utilsService.getListingAccounts({ amazonOnly: true });

    if (result.success) {
      // Remove the liquidate account from the array of listing accounts.
      const filteredAccounts = result.listingAccounts.filter(
        (account) => account.listingAccountId !== 8
      );
      setListingAccounts(filteredAccounts);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const getShipmentRacks = async () => {
    setLoading(true);

    const result = await shipmentService.getShipmentRacksWithoutItems({
      shipmentRackStatusId: 3,
    });

    if (result.success) {
      // const availableShipmentRacks = result.shipmentRacksWithItems.map(
      //   (rack) => {
      //     return {
      //       ...rack,
      //       totalItems: rack?.shipmentRackItems?.length,
      //     };
      //   }
      // );

      const availableShipmentRacks = result.shipmentRacks;

      setAllShipmentRacks(availableShipmentRacks);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const getShipmentCategories = async () => {
    setLoading(true);

    const result = await shipmentService.getShipmentCategories();

    if (result.success) {
      setAllShipmentCategories(result.shipmentCategories);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const createShipmentPlans = async () => {
    setSubmitting(true);

    const shipmentRackIds = selectedShipmentRacks.map(
      (shipmentRack) => shipmentRack.shipmentRackId
    );
    const listingAccountId = selectedAccount.listingAccountId;

    const result = await shipmentService.createShipmentPlans({
      listingAccountId,
      shipmentRackIds,
      shipmentCategoryId: activeShipmentCategory,
    });

    if (result.success) {
      toast.success("Shipment plans have been created successfully!", {
        duration: 5000,
      });

      setShipmentPlanOptions(result.shipmentPlansResult.createdShipmentPlans);
      setSubmitting(false);
    } else {
      if (result.shipmentPlansResult.noItems) {
        toast.error("There are no items on the selected rack/s", {
          duration: 5000,
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          duration: 5000,
        });
      }

      setSubmitting(false);
    }
  };

  const createShipments = async () => {
    setSubmitting(true);
    const shipmentIds = selectedShipmentPlans.map(
      (shipmentPlan) => shipmentPlan.shipmentId
    );
    const shipmentRackIds = selectedShipmentRacks.map(
      (shipmentRack) => shipmentRack.shipmentRackId
    );

    const result = await shipmentService.createShipments({
      shipmentIds,
      shipmentRackIds,
    });

    if (result.success) {
      setSelectedShipmentPlans([]);
      setSelectedShipmentRacks([]);
      toast.success("Shipments have been created successfully!");
      setTimeout(() => {
        window.location.reload(false);
      }, 2500);
    } else {
      console.log(result);
      toast.error(result.message, {
        duration: 10000,
      });
    }

    setSubmitting(false);
  };

  return (
    <>
      <Helmet title="Create Shipment" />
      <h1 className="mb-3">Create Shipment</h1>
      <Container fluid className="p-0">
        {loading || !allShipmentRacks.length ? (
          <Loader />
        ) : (
          <>
            <Row className="mb-3">
              <Col xxl={8}>
                <Navigation
                  loading={loading}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  listingAccounts={listingAccounts}
                  allShipmentRacks={allShipmentRacks}
                  submitting={submitting}
                />
              </Col>
            </Row>
            {!selectedAccount ? (
              <Row>
                <Col xxl={8}>
                  <Alert variant="primary">
                    <div className="alert-icon">
                      <Eye
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message">
                      Select an <strong>account</strong> to view shipment racks.
                    </div>
                  </Alert>
                </Col>
              </Row>
            ) : (
              <Row className="mb-3">
                <Col xxl={8}>
                  <ShipmentRacks
                    selectedAccount={selectedAccount}
                    accountShipmentRacks={accountShipmentRacks}
                    accountShipmentCategories={accountShipmentCategories}
                    setSelectedShipmentRacks={setSelectedShipmentRacks}
                    submitting={submitting}
                    activeShipmentCategory={activeShipmentCategory}
                    setActiveShipmentCategory={setActiveShipmentCategory}
                  />
                </Col>
                {accountShipmentRacks.length > 0 && activeShipmentCategory && (
                  <Col xxl={4}>
                    <ShipmentRackList
                      selectedShipmentRacks={selectedShipmentRacks}
                      createShipmentPlans={createShipmentPlans}
                      shipmentPlanOptions={shipmentPlanOptions}
                      submitting={submitting}
                      setSelectedShipmentPlans={setSelectedShipmentPlans}
                      selectedShipmentPlans={selectedShipmentPlans}
                      createShipments={createShipments}
                      activeShipmentCategory={activeShipmentCategory}
                    />
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default CreateShipmentPage;
