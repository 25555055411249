import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import EmployeeTable from "./EmployeeTable";
import {
  reduceStats,
  statTotals as getStatTotals,
} from "./statReduceFunctions";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const receivedColumns = ["Received", "Accepted", "Trash", "Liquidated"];
const inspectedColumns = ["Amazon", "Ebay", "Trash", "Liquidated"];
const listedColumns = [
  "Products 360",
  "Refurb 360",
  "Classic Gifts",
  "Kiwi Banana",
  "FunTime Sales",
  "Lemon Explosion",
  "Ebay",
  "Liquidated",
];

function EmployeeBody() {
  const [receivedStats, setReceivedStats] = useState([]);
  const [inspectedStats, setInspectedStats] = useState([]);
  const [listedStats, setListedStats] = useState([]);
  const [statTotals, setStatTotals] = useState({});
  const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleString());
  const [queryDate, setQueryDate] = useState(new Date().toISOString());

  const getStats = async () => {
    try {
      const stats = (
        await axios.get(`${HOST_URL}/employee/list?queryDate=${queryDate}`)
      ).data.stats;
      setReceivedStats(
        reduceStats(stats.filter((stat) => stat.type === "received"))
      );
      setInspectedStats(
        reduceStats(stats.filter((stat) => stat.type === "inspected"))
      );
      setListedStats(
        reduceStats(stats.filter((stat) => stat.type === "listed"))
      );
      setStatTotals(getStatTotals(stats));
      setLastUpdated(new Date().toLocaleString());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="p-0 mt-3 text-center">
        <h1 className="text-center">Employees</h1>
        <h5 className="text-center">Last Updated: {lastUpdated} </h5>
        <Col>
          <label className="mx-2">Choose a date:</label>
          <input
            type="date"
            defaultValue={new Date().toISOString().split("T")[0]}
            onChange={(e) => setQueryDate(e.target.value)}
          />
          <Button className="mx-2" onClick={getStats}>
            Go
          </Button>
        </Col>

        <Card.Body className="mt-4">
          <Row>
            <EmployeeTable
              title="Receiving"
              columns={receivedColumns}
              stats={receivedStats}
              queryDate={queryDate}
              statTotals={statTotals.receiving}
            />
          </Row>
          <Row>
            <EmployeeTable
              title="Inspecting"
              columns={inspectedColumns}
              stats={inspectedStats}
              queryDate={queryDate}
              statTotals={statTotals.inspecting}
            />
          </Row>
          <Row>
            <EmployeeTable
              title="Listing"
              columns={listedColumns}
              stats={listedStats}
              queryDate={queryDate}
              statTotals={statTotals.listing}
            />
          </Row>
        </Card.Body>
      </Container>
    </React.Fragment>
  );
}

export default EmployeeBody;
