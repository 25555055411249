// import React from "react";

// import { Button } from "react-bootstrap";

// import { Formik } from "formik";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import ModalGeneric from "../../../../components/ModalGeneric";

// import UserFormBody from "./UserFormBody";

// export default function UserForm({ user, addUser, editUser }) {
//   let userId, username, password, listingSku, realName, printerId, isAdmin;
//   if (user) {
//     ({ userId, username, password, listingSku, realName, printerId, isAdmin } =
//       user);
//   }

//   return (
//     <Formik
//       initialValues={{
//         userId: userId || null,
//         username: username || "",
//         password: password || "",
//         listingSku: listingSku || "",
//         realName: realName || "",
//         printerId: printerId || "",
//         isAdmin: isAdmin || false,
//       }}
//       enableReinitialize
//     >
//       {({
//         errors,
//         handleBlur,
//         handleChange,
//         handleSubmit,
//         isSubmitting,
//         touched,
//         values,
//         resetForm,
//         setFieldValue,
//       }) => {
//         return user ? (
//           <ModalGeneric
//             trigger={
//               <Button variant="warning" className="mx-2">
//                 Edit
//               </Button>
//             }
//             title="Edit User"
//             body={
//               <UserFormBody
//                 values={values}
//                 handleChange={handleChange}
//                 setFieldValue={setFieldValue}
//               />
//             }
//             actionButtonText={"Edit"}
//             actionButtonCallback={() => {
//               editUser(values);
//               resetForm();
//             }}
//           />
//         ) : (
//           <ModalGeneric
//             trigger={
//               <Button variant="primary">
//                 Add User
//                 <FontAwesomeIcon icon={faPlus} className="mx-1" />
//               </Button>
//             }
//             title="Add User"
//             body={
//               <UserFormBody
//                 values={values}
//                 handleChange={handleChange}
//                 setFieldValue={setFieldValue}
//               />
//             }
//             actionButtonText={"Add"}
//             actionButtonCallback={() => {
//               addUser(values);
//               resetForm();
//             }}
//           />
//         );
//       }}
//     </Formik>
//   );
// }

import React from "react";

import { Formik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";

const UserForm = ({ modalState, setModalState, createUser, updateUser }) => {
  let userId, username, password, realName, isAdmin;

  if (modalState.data) {
    ({ userId, username, password, realName, isAdmin } = modalState.data);
  }

  const createSchema = Yup.object().shape({
    realName: Yup.string()
      .max(50, "Full Name must be less than 50 characters long.")
      .required("Full Name is required."),
    username: Yup.string()
      .max(50, "Username must be less than 50 characters long.")
      .required("Username is required."),
    password: Yup.string()
      .max(250, "Password must be less than 250 characters long.")
      .required("Password is required."),
  });

  const updateSchema = Yup.object().shape({
    realName: Yup.string()
      .max(50, "Full Name must be less than 50 characters long.")
      .required("Full Name is required."),
    username: Yup.string()
      .max(50, "Username must be less than 50 characters long.")
      .required("Username is required."),
  });

  return (
    <Formik
      initialValues={{
        userId: userId || "",
        username: username || "",
        password: password || "",
        realName: realName || "",
        isAdmin: isAdmin || false,
      }}
      validationSchema={modalState.type === 0 ? createSchema : updateSchema}
      enableReinitialize
      onSubmit={(values) => {
        if (modalState.type === 0) {
          createUser(values);
        } else {
          updateUser(values);
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
      }) => (
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Full Name</Form.Label>
            <Form.Control
              type="text"
              name="realName"
              placeholder="Enter Full Name..."
              value={values.realName}
              isInvalid={Boolean(touched.realName && errors.realName)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.realName && (
              <Form.Control.Feedback type="invalid">
                {errors.realName}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={values.username}
              isInvalid={Boolean(touched.username && errors.username)}
              placeholder="Enter Username..."
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.username && (
              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Password</Form.Label>
            <Form.Control
              type="text"
              name="password"
              placeholder="Enter Password..."
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="m-0">Permissions</Form.Label>
            <Form.Check
              id="ia"
              name="isAdmin"
              label={<span htmlFor="ia">Make Administrator?</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.isAdmin}
              checked={values.isAdmin}
              disabled={isSubmitting}
            />
          </Form.Group>

          <div className="d-flex justify-content-between">
            <Button
              className="d-flex align-items-center"
              variant="success"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {modalState.type === 0 && !isSubmitting
                ? "Create"
                : modalState.type === 0 && isSubmitting
                ? "Creating..."
                : !isSubmitting
                ? "Save"
                : "Saving..."}
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                setModalState((prevState) => ({
                  ...prevState,
                  show: false,
                  data: undefined,
                }))
              }
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
