import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import toast from "react-hot-toast";

import Table from "../../../../components/tables/Table";
import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../../../components/tables/DateRangeFilter";
import { deepClone } from "../../../../utils/helper";

import shipmentService from "../../../../services/shipmentService";

import { ShipmentsContext } from "./ShipmentAccount";

const Shipments = () => {
  const [loading, setLoading] = useState(false);
  const {
    selectedAccount,
    shipments,
    setSelectedShipment,
    setShipmentCartons,
    setShipmentItems,
    setSelectedShipmentItems,
    handleScroll,
  } = useContext(ShipmentsContext);

  const handleRowClick = (row) => {
    getShipmentItems(row.original);
    getShipmentCartons(row.original);

    // Reset the states
    setSelectedShipmentItems();

    // handleScroll("shipment-items");
  };

  const getShipmentItems = async (shipment) => {
    setLoading(true);

    const response = await shipmentService.getShipmentItems({
      shipmentId: shipment.shipmentId,
    });

    if (response.success) {
      shipment.totalItems = response.shipmentItems.length;

      const reducedShipmentItems = response.shipmentItems.reduce(
        (result, item) => {
          //Clone the item first to preserve the shipment items.
          const clonedItem = deepClone(item);

          //Find an existing shipment.
          const existingShipmentItemIndex = result.findIndex(
            (item) =>
              item.fnsku.toUpperCase() === clonedItem.fnsku.toUpperCase()
          );

          if (existingShipmentItemIndex !== -1) {
            //If an existing item is found, update the quantity and add item to array.
            result[existingShipmentItemIndex].quantity++;
            result[existingShipmentItemIndex].originalShipmentItems.push(
              clonedItem
            );
          } else {
            result.push({
              fnsku: clonedItem.fnsku,
              sku: clonedItem.sku,
              shipmentId: clonedItem.shipmentId,
              quantity: 1,
              originalShipmentItems: [clonedItem],
            });
          }

          return result;
        },
        []
      );

      setShipmentItems(reducedShipmentItems);
      setSelectedShipment(shipment);
    } else {
      console.log(response);
      toast.error("Something went wrong. Please try again.");
    }

    setLoading(false);
  };

  const getShipmentCartons = async (shipment) => {
    setLoading(true);

    const response = await shipmentService.getShipmentCartons({
      shipmentId: shipment.shipmentId,
    });

    if (response.success) {
      setShipmentCartons(response.shipmentCartons);
    } else {
      console.log(response);
      toast.error("Something went wrong. Please try again.");
    }

    setLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Shipment ID",
        accessor: "amazonShipmentId",
      },
      {
        Header: "Destination ID",
        accessor: "shipTo",
      },
      {
        Header: "City",
        accessor: "shipToCity",
      },
      {
        Header: "State",
        accessor: "shipToState",
      },
      {
        Header: "Total Skus",
        accessor: "units",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        id: "createdAt",

        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
        Filter: DateRangeFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Rack IDs",
        accessor: "shipmentRackIds",
      },
    ],
    []
  );

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <FontAwesomeIcon icon={faTruck} size="3x" className="me-3" />
          <div>
            <Card.Title>Shipments</Card.Title>
            <Card.Subtitle>
              All the shipments for account{" "}
              <strong>{selectedAccount.listingAccount}</strong>.
            </Card.Subtitle>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <h1>{selectedAccount.listingAccount}</h1>
        </div>
      </Card.Header>
      <Card.Body>
        {shipments && (
          <Table
            columns={columns}
            data={shipments}
            onRowClick={handleRowClick}
            downloadFile={`Shipments_${selectedAccount.listingAccount}`}
            layoutFixed={false}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default Shipments;
