import React, { useMemo, useState } from "react";
import { Col, Form, Row, Button, Alert } from "react-bootstrap";
import { Plus } from "react-feather";

export default function TrailerCreateForm({
  trailerType,
  setTrailerType,
  destinationCenterIds,
  setDestinationCenterIds,
  showTrailerAlert,
  showDestinationAlert,
}) {
  const [dcAutoFocus, setDCAutoFocus] = useState(false);

  const changeDestinationCenters = (index, newValue) => {
    const newDestinationCenters = destinationCenterIds.map((centerId, i) => {
      if (i === index) {
        return newValue;
      } else {
        return centerId;
      }
    });
    setDestinationCenterIds(newDestinationCenters);
  };

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Trailer Type</Form.Label>
        <Form.Select
          value={trailerType}
          onChange={(e) => setTrailerType(e.target.value)}
        >
          <option value="">Select a Trailer Type</option>
          <option value="1">Locked</option>
          <option value="2">Unlocked</option>
        </Form.Select>
        {showTrailerAlert && (
          <Alert variant="warning">Please select a trailer type.</Alert>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Destination Centers</Form.Label>
        {destinationCenterIds.map((centerId, index) => (
          <Form.Control
            key={index}
            type="text"
            name={`destinationCenterId${index + 1}`}
            placeholder="Enter Destination Center ID..."
            value={centerId}
            onChange={(e) => changeDestinationCenters(index, e.target.value)}
            autoFocus={dcAutoFocus}
          />
        ))}
        <div className="d-flex justify-content-end ">
          <Button
            className="m-2"
            variant="info"
            title={"Add a destination center."}
            onClick={() => {
              setDestinationCenterIds([...destinationCenterIds, ""]);
              setDCAutoFocus(true);
            }}
          >
            <Plus size={16} />
          </Button>
        </div>
        {showDestinationAlert && (
          <Alert variant="warning">Please enter a destination center ID.</Alert>
        )}
      </Form.Group>
    </Form>
  );
}
