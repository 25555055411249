import axios from "axios";
import * as JSPM from "jsprintmanager";
import toast from "react-hot-toast";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const printBarcodeLabel = async (lpn) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  const labelData = { lpn, userId };

  const response = (
    await axios.post(`${HOST_URL}/print/barcodeLabel`, labelData)
  ).data;

  let fileName;
  if (response.success) {
    fileName = response.fileName;
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();

      const my_file = new JSPM.PrintFile(
        `${HOST_URL}/print/getLabel/${fileName}`,
        JSPM.FileSourceType.URL,
        "BarcodeLabel.png",
        1
      );

      cpj.files.push(my_file);

      cpj.onFinished = () => {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      };

      cpj.sendToClient();
    }
  };
};

export const printListingLabel = async (labelData) => {
  const response = (
    await axios.post(`${HOST_URL}/print/listingLabel`, labelData)
  ).data;

  let fileName;
  if (response.success) {
    fileName = response.fileName;
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();

      const my_file = new JSPM.PrintFile(
        `${HOST_URL}/print/getLabel/${fileName}`,
        JSPM.FileSourceType.URL,
        "ListingLabel.png",
        labelData.quantity
      );

      cpj.files.push(my_file);

      cpj.onFinished = () => {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      };

      cpj.sendToClient();
    }
  };
};

export const printCartonLabel = async (labelData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  labelData.userId = userId;

  const response = (
    await axios.post(`${HOST_URL}/print/cartonLabel`, labelData)
  ).data;

  let fileName;
  if (response.success) {
    fileName = response.fileName;
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();

      const my_file = new JSPM.PrintFile(
        `${HOST_URL}/print/getLabel/${fileName}`,
        JSPM.FileSourceType.URL,
        "CartonLabel.png",
        1
      );

      cpj.files.push(my_file);

      cpj.onFinished = () => {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      };

      cpj.sendToClient();
    }
  };
};

export const printPalletLabel = async (labelData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  labelData.userId = userId;
  const response = (
    await axios.post(`${HOST_URL}/print/palletLabel`, labelData)
  ).data;

  let fileName;
  if (response.success) {
    fileName = response.fileName;
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();

      const my_file = new JSPM.PrintFile(
        `${HOST_URL}/print/getLabel/${fileName}`,
        JSPM.FileSourceType.URL,
        "PalletLabel.png",
        1
      );

      cpj.files.push(my_file);

      cpj.onFinished = () => {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      };

      cpj.sendToClient();
    }
  };
};

export const printFbaShipmentLabel = async (labelUrl) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  const response = (
    await axios.post(`${HOST_URL}/print/fbaShipmentLabel`, {
      labelUrl,
      userId,
    })
  ).data;

  let fileName;
  if (response.success) {
    fileName = response.fileName;
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      // Getting the list of printers
      const printerList = await JSPM.JSPrintManager.getPrintersInfo();

      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter("iDPRT SP410");

      // This is for printing a pdf directly.
      // const my_file = new JSPM.PrintFilePDF(
      //   `${HOST_URL}/print/getLabel/${fileName}`,
      //   JSPM.FileSourceType.URL,
      //   "FbaShipmentLabel.pdf",
      //   1
      // );

      const my_file = new JSPM.PrintFile(
        `${HOST_URL}/print/getLabel/${fileName}`,
        JSPM.FileSourceType.URL,
        "FbaShipmentLabel.png",
        1
      );

      cpj.files.push(my_file);

      cpj.jobInfo = {
        states: "",
        printedPages: 0,
        printedBytes: 0,
        totalPages: 0,
        totalBytes: 0,
      };

      cpj.onUpdated = function (data) {
        console.warn(data);
        if (data.id) {
          if (
            data["completed-pages"] &&
            data["completed-pages"] > cpj.jobInfo.printedPages
          ) {
            cpj.jobInfo.printedPages = data["completed-pages"];
          }
          if (
            data["total-pages"] &&
            data["total-pages"] > cpj.jobInfo.totalPages
          ) {
            cpj.jobInfo.totalPages = data["total-pages"];
          }
          if (
            data["total-bytes"] &&
            data["total-bytes"] > cpj.jobInfo.totalBytes
          ) {
            cpj.jobInfo.totalBytes = data["total-bytes"];
          }
          if (
            data["printed-bytes"] &&
            data["printed-bytes"] > cpj.jobInfo.printedBytes
          ) {
            cpj.jobInfo.printedBytes = data["printed-bytes"];
          }
          if (data["state-description"]) {
            cpj.jobInfo.states += "," + data["state-description"];
          }
        }

        if (data.result === "ClientPrintJob done") {
          // SOME SOURCES ABOUT THE MEANING OF SOME STATES
          // RETAINED: https://stackoverflow.com/q/26862882
          // COMPLETE: https://learn.microsoft.com/en-us/dotnet/api/system.printing.printjobstatus

          let success =
            (cpj.jobInfo.totalBytes > 0 &&
              cpj.jobInfo.printedBytes > 0 &&
              cpj.jobInfo.printedBytes >= cpj.jobInfo.totalBytes) ||
            (cpj.jobInfo.totalPages > 0 &&
              cpj.jobInfo.printedPages > 0 &&
              cpj.jobInfo.printedPages >= cpj.jobInfo.totalPages) ||
            cpj.jobInfo.states.includes("PRINTED") ||
            cpj.jobInfo.states.includes("RETAINED") ||
            cpj.jobInfo.states.includes("COMPLETE");

          if (success) {
            console.log(`Print success`);
            toast.dismiss(printingToast);
            toast.success("Finished printing!");
          } else {
            console.log(`Print with error`);
          }
        }
      };

      cpj.onFinished = function (data) {
        console.warn(data);
      };

      cpj.onError = function (data) {
        console.warn(data);
      };

      const result = await cpj.sendToClient();

      if (result) {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      }
    }
  };

  return fileName;
};

/* param - shipmentRackList - array of objects,
 ** each object has shipmentRackId (int) and bayName (string)
 */
export const printRackLabels = async (shipmentRackList) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  const response = (
    await axios.post(`${HOST_URL}/print/rackLabels`, {
      shipmentRackList,
      userId,
    })
  ).data;

  let fileNameList;
  if (response.success) {
    fileNameList = response.fileNameList;
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label(s)...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();

      fileNameList.forEach((fileName, index) => {
        const my_file = new JSPM.PrintFile(
          `${HOST_URL}/print/getLabel/${fileName}`,
          JSPM.FileSourceType.URL,
          `RackLabel-${index + 1}.png`,
          1
        );

        cpj.files.push(my_file);
      });

      cpj.onFinished = () => {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      };

      cpj.sendToClient();
    }
  };
};

export const generateFbaShipmentLabels = async ({
  shipmentId,
  start,
  quantity,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  const response = (
    await axios.post(`${HOST_URL}/print/generateFbaShipmentLabels`, {
      userId,
      shipmentId,
      start,
      quantity,
    })
  ).data;

  let fileNameList;
  if (response.success) {
    fileNameList = response.fileNameList;
    console.log(fileNameList);
  } else {
    toast.error(response.message);
    return;
  }

  const printingToast = toast.loading("Printing label(s)...");

  JSPM.JSPrintManager.license_url = `${HOST_URL}/jspm`;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = async function () {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.InstalledPrinter("iDPRT SP410");

      fileNameList.forEach((fileName, index) => {
        const my_file = new JSPM.PrintFile(
          `${HOST_URL}/print/getLabel/${fileName}`,
          JSPM.FileSourceType.URL,
          `FbaShipmentLabel-${index + 1}.png`,
          1
        );

        cpj.files.push(my_file);
      });

      cpj.onFinished = () => {
        toast.dismiss(printingToast);
        toast.success("Finished printing!");
      };

      cpj.sendToClient();
    }
  };
};

const services = {
  printBarcodeLabel,
  printListingLabel,
  printCartonLabel,
  printPalletLabel,
  printFbaShipmentLabel,
  printRackLabels,
  generateFbaShipmentLabels,
};

export default services;
