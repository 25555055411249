import Select from "react-select";
import axios from "axios";
import { useEffect, useState } from "react";
import DefaultColumnFilter from "./DefaultColumnFilter";

const EmployeeSelect = ({ column }) => {
  const { setFilter } = column;
  const HOST_URL = process.env.REACT_APP_HOST_URL;
  const [employeeList, setEmployeeList] = useState(undefined);

  const getEmployeeList = async () => {
    try {
      const results = (await axios.get(`${HOST_URL}/employee/employeeList`))
        .data.employeeList;
      setEmployeeList(results);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  return employeeList ? (
    <Select
      defaultValue={{ value: "", label: "All Names" }}
      onChange={(selectedValue) => {
        setFilter(selectedValue.value);
      }}
      options={[{ value: "", label: "All Names" }].concat(
        employeeList.map((employee) => ({
          value: employee.userId,
          label: employee.name,
        }))
      )}
    />
  ) : (
    <DefaultColumnFilter column={column} />
  );
};

export default EmployeeSelect;
