import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { fetchEventSource } from "@microsoft/fetch-event-source";

import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
  ProgressBar,
} from "react-bootstrap";
import { AlertCircle, File, CheckCircle, XCircle } from "react-feather";
import toast from "react-hot-toast";
import PerfectScrollbar from "react-perfect-scrollbar";

import ebayListingDataExample from "../../assets/img/ebayListingDataExample.png";
import ebayListingDataBoxIdExample from "../../assets/img/ebayListingDataBoxIdExample.png";
import nodeIdsExample from "../../assets/img/nodeIdsExample.png";

import DownloadCsv from "./components/ebay/DownloadCsv";

const HOST_URL = process.env.REACT_APP_HOST_URL;

// Controller to abort fetch request
let ctrl;

const EbayPage = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState();
  const [showHelp, setShowHelp] = useState(false);
  const [error, setError] = useState();
  const [file, setFile] = useState("");
  const [csvData, setCsvData] = useState();
  const [summary, setSummary] = useState();

  const getAmazonNodeIds = async () => {
    setProgress(0);
    setEstimatedTimeRemaining();
    setCsvData();
    setSummary();
    setError();
    setIsProcessing(true);

    const formData = new FormData();
    formData.append("file", file);

    ctrl = new AbortController();

    let response;
    await fetchEventSource(`${HOST_URL}/ebay/getAmazonNodeIds`, {
      openWhenHidden: true,
      method: "POST",
      headers: {
        Accept: "text/event-stream",
      },
      body: formData,
      signal: ctrl.signal,
      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        const parsedData = JSON.parse(event.data);

        if (parsedData.final) {
          response = parsedData;
        } else {
          setProgress(parsedData.progress);
          parsedData.timeRemaining &&
            setEstimatedTimeRemaining(parsedData.timeRemaining);
        }
      },
      onclose() {
        console.log("Connection closed");
      },
      onerror(err) {
        console.log("There was an error from server", err);
        setError("There was an error processing your file. Please try again.");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      },
    }).catch((error) => {
      console.error(error);
      setError(error.message);
      setIsProcessing(false);
      return;
    });

    if (response?.success) {
      toast.success("CSV file successfully processed!");
      const nodeIds = response.nodeIds;

      setCsvData({
        headers: [
          { label: "ASIN", key: "asin" },
          { label: "Node Id", key: "nodeId" },
        ],
        data: nodeIds,
        filename: "Amazon_Node_Ids",
      });

      setSummary(response?.summary);
    } else if (!response?.success) {
      setError(response?.message);
    }

    setIsProcessing(false);
    setFile("");

    return "finished";
  };

  const getEbayListingData = async (isBoxId) => {
    setProgress(0);
    setCsvData();
    setSummary();
    setError();
    setIsProcessing(true);

    const formData = new FormData();
    formData.append("file", file);

    ctrl = new AbortController();

    let response;
    await fetchEventSource(
      `${HOST_URL}/ebay/${
        isBoxId ? "getEbayListingDataByBoxId" : "getEbayListingData"
      }`,
      {
        openWhenHidden: true,
        method: "POST",
        headers: {
          Accept: "text/event-stream",
        },
        body: formData,
        signal: ctrl.signal,
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          const parsedData = JSON.parse(event.data);

          if (parsedData.final) {
            response = parsedData;
          } else {
            setProgress(parsedData.progress);
            parsedData.timeRemaining &&
              setEstimatedTimeRemaining(parsedData.timeRemaining);
          }
        },
        onclose() {
          console.log("Connection closed.");
        },
        onerror(err) {
          console.log("There was an error from server", err);
          setError(
            "There was an error processing your file. Please try again."
          );
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        },
      }
    ).catch((error) => {
      console.error(error);
      setError(error.message);
      setIsProcessing(false);
      return;
    });

    if (response?.success) {
      toast.success("CSV file successfully processed!");
      const listingData = response.listingData;

      setCsvData({
        headers: [
          { label: "LPN", key: "identifier" },
          { label: "Client Prefix", key: "clientPrefix" },
          { label: "Condition", key: "condition" },
          { label: "Condition Notes", key: "conditionNote" },
          { label: "ASIN", key: "asin" },
          { label: "Variation Notes", key: "variationNote" },
          { label: "Quantity", key: "quantity" },
          { label: "Inspector", key: "inspectedBy" },
          { label: "Warehouse Location", key: "location" },
          { label: "Inspected Date", key: "date" },
          { label: "Master SKU", key: "masterSku" },
        ],
        data: listingData,
        filename: "Ebay_Listing_Data",
      });

      setSummary(response?.summary);
    } else if (!response?.success) {
      setError(response?.message);
    }

    setIsProcessing(false);
    setFile("");

    return "finished";
  };

  return (
    <>
      <Helmet title="Ebay" />
      <h1 className="h3 mb-3">Ebay</h1>
      <Container fluid className="p-0">
        <Row>
          <Col xl={{ span: 6, offset: 3 }}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <Card.Title>Process CSV File</Card.Title>
                <Button
                  variant="outline-secondary"
                  onClick={() => setShowHelp(true)}
                  size="sm"
                  active={showHelp}
                >
                  Help
                </Button>
              </Card.Header>
              <Card.Body>
                {error && (
                  <Alert variant="danger" className="flex-fill alert-blink">
                    <div className="alert-icon">
                      <AlertCircle
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message">{error}</div>
                  </Alert>
                )}
                {csvData && (
                  <Alert variant="info" className="flex-fill alert-blink">
                    <div className="alert-icon">
                      <File
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message d-flex justify-content-between align-items-center">
                      Your CSV file is ready for download. Click the button to
                      start your download.
                      <DownloadCsv
                        headers={csvData.headers}
                        data={csvData.data}
                        filename={csvData.filename}
                      />
                    </div>
                  </Alert>
                )}
                <Form
                  onSubmit={async (e) => {
                    // Differentiates between functions and waits to clear
                    // the Form.Control until processing is done.
                    e.preventDefault();
                    const submitter = e.nativeEvent.submitter.name;
                    let result;
                    if (submitter === "nodeIds") {
                      result = await getAmazonNodeIds();
                    } else if (submitter === "listingData") {
                      result = await getEbayListingData(false);
                    } else if (submitter === "listingDataBoxId") {
                      result = await getEbayListingData(true);
                    }

                    result && e.target.reset();
                  }}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Select a CSV file to process.</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      accept=".csv"
                      disabled={isProcessing}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <Button
                      variant="primary"
                      type="submit"
                      name="nodeIds"
                      disabled={!file || isProcessing}
                    >
                      Get Amazon Node Ids
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      name="listingDataBoxId"
                      disabled={!file || isProcessing}
                    >
                      Get Listing Data (Box Id)
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      name="listingData"
                      disabled={!file || isProcessing}
                    >
                      Get Listing Data (LPN)
                    </Button>
                  </div>
                </Form>
                {isProcessing && (
                  <div className="mt-3">
                    <div className="text-center mb-3">Processing...</div>
                    <ProgressBar
                      striped
                      animated
                      variant="primary"
                      now={progress}
                      label={`${Number(progress).toFixed()}%`}
                    />
                    <div className="text-center mb-3">
                      Time Remaining: ~{estimatedTimeRemaining}
                    </div>
                    <div className="text-end mt-3">
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() => {
                          ctrl && ctrl.abort();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                {summary && (
                  <div className="d-flex justify-content-evenly mt-3">
                    <Alert variant="success" className="flex-fill mb-0">
                      <div className="alert-icon">
                        <CheckCircle
                          className="position-relative top-50 start-50 translate-middle"
                          size={20}
                        />
                      </div>
                      <div className="alert-message">
                        Success: {Number(summary.successPercentage).toFixed()}%
                      </div>
                    </Alert>
                    <Alert variant="danger" className="flex-fill ms-3 mb-0">
                      <div className="alert-icon">
                        <XCircle
                          className="position-relative top-50 start-50 translate-middle"
                          size={20}
                        />
                      </div>
                      <div className="alert-message">
                        Total Failures: {summary.totalFailures}
                      </div>
                    </Alert>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Offcanvas show={showHelp} onHide={() => setShowHelp(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h4>Process CSV File Help</h4>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <PerfectScrollbar>
          <Offcanvas.Body>
            <h5>Get Amazon Node Ids</h5>
            <p>
              Upload a CSV file that contains a header row and a single column
              of ASINs. The returned file will contain two columns containing
              the original ASIN as well as the Amazon node id that corresponds
              to its product category if it has one.
            </p>
            <img
              className="img-fluid img-thumbnail"
              src={nodeIdsExample}
              alt="Example"
            />
            <hr></hr>
            <h5>Get Listing Data (Box Id)</h5>
            <p>
              Upload a CSV file that contains a header row and a single column
              of Box Ids. The returned file will contain multiple columns
              containing the original Box Ids as Warehouse Locations, as well as
              the listing data that corresponds to its Box Id if it exists.
            </p>
            <img
              className="img-fluid img-thumbnail"
              src={ebayListingDataBoxIdExample}
              alt="Example"
            />
            <hr></hr>
            <h5>Get Listing Data (LPN)</h5>
            <p>
              Upload a CSV file that contains a header row and two columns, the
              first containing LPNs or RPNs and the second one containing the
              warehouse location. The returned file will contain multiple
              columns containing the original LPNs or RPNs, Warehouse Location
              as well as the listing data that corresponds to its LPN or RPN if
              it exists.
            </p>
            <img
              className="img-fluid img-thumbnail"
              src={ebayListingDataExample}
              alt="Example"
            />
          </Offcanvas.Body>
        </PerfectScrollbar>
      </Offcanvas>
    </>
  );
};

export default EbayPage;
