import React from "react";
import { Card, Col } from "react-bootstrap";

export default function EmployeeTable({
  title,
  columns,
  stats,
  queryDate,
  statTotals,
}) {
  return (
    <Col>
      <Card className="px-4 pt-3">
        <h3 className="text-center">{title}</h3>
        <div className="row align-items-center justify-content-center">
          {stats && stats.length === 0 ? (
            <h1 className="text-center">No Data To Display</h1>
          ) : (
            <table className="table table-bordered table-striped text-center table-hover table-clickable">
              <thead>
                <tr>
                  <th scope="col">Ranking:</th>
                  <th scope="col">Name:</th>
                  {columns.map((column) => (
                    <th key={column}>{column}</th>
                  ))}
                  <th>Quantity (unique/total):</th>
                </tr>
              </thead>
              <tbody>
                {stats &&
                  stats.map((stat, index) => {
                    const { name, LPNCount, itemCount, userID, ...rest } = stat;
                    return (
                      <tr
                        className="cursor-pointer"
                        key={index}
                        title={`Click to see ${name}'s ${title.toLowerCase()} stats for ${new Date(
                          queryDate
                        ).toLocaleDateString()}`}
                        onClick={() => {
                          window.location = `/management/employeeActivity/${title.toLowerCase()}/${queryDate}/${userID}/${name}`;
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        {Object.values(rest).map((value, index) => (
                          <td key={index}>{value}</td>
                        ))}
                        <td>{`${LPNCount}/${itemCount}`}</td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr className="font-weight-bold">
                  <td colSpan={2}>Totals: </td>
                  {statTotals &&
                    statTotals.map((total, index) => {
                      return <td key={index}>{Object.values(total)[0]}</td>;
                    })}
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </Card>
    </Col>
  );
}
