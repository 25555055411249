import React, { useEffect, useRef, useState } from "react";

import { Formik, Field } from "formik";
import { Alert, Button, Form, Card } from "react-bootstrap";
import { AlertCircle } from "react-feather";
import { ignoreOrderCompare } from "../../../../utils/helper";
import toast from "react-hot-toast";

import managementService from "../../../../services/management/management.service";

const FBAStorageRestrictionsForm = ({
  selectedAccount,
  setListingAccounts,
  setShowCard,
}) => {
  const initialValues = {
    shipmentCategoryIds:
      selectedAccount.restrictedShipmentCategoryIds.map(String),
    isActive: selectedAccount.isActive,
  };
  const [currentValues, setCurrentValues] = useState(initialValues);

  const formikRef = useRef();
  useEffect(() => {
    formikRef.current.resetForm();
  }, [selectedAccount]);

  const Checkbox = (props) => {
    return (
      <Field name={props.name}>
        {({ field, form }) => (
          <Form.Check
            {...field}
            id={props.id}
            label={props.label}
            type="checkbox"
            checked={!field.value.includes(props.value)}
            onChange={() => {
              const set = new Set(field.value);
              if (set.has(props.value)) {
                set.delete(props.value);
              } else {
                set.add(props.value);
              }
              setCurrentValues({ shipmentCategoryIds: Array.from(set) });
              form.setFieldValue(field.name, Array.from(set));
            }}
          />
        )}
      </Field>
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.isActive === null) {
          setErrors({ isActive: "Required" });
          return;
        }
        setSubmitting(true);

        const submittingToast = toast.loading("Saving changes...");

        const data = {
          listingAccountId: selectedAccount.listingAccountId,
          shipmentCategoryIds: values.shipmentCategoryIds.map((id) =>
            parseInt(id)
          ),
        };

        if (initialValues.isActive != values.isActive) {
          data.isActive = values.isActive;
        }

        const result = await managementService
          .updateRestrictedShipmentCategories(data)
          .catch((error) => {
            console.error(error);
            setErrors({
              submit:
                error?.response?.data?.message ||
                error?.message ||
                "Something went wrong.",
            });
            toast.dismiss(submittingToast);
            setSubmitting(false);
          });

        if (result.success) {
          const response = await managementService
            .getListingAccounts()
            .catch((error) => {
              console.error(error);
              setErrors({
                submit:
                  error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong.",
              });
            });

          toast.dismiss(submittingToast);
          toast.success("Changes successfully saved!");

          setListingAccounts(response);
        }

        setSubmitting(false);
        setShowCard(false);
      }}
    >
      {({ errors, handleSubmit, handleReset, isSubmitting, values }) => (
        <Form onSubmit={handleSubmit}>
          {errors?.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-icon d-flex align-items-center">
                <AlertCircle />
              </div>
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="mx-1">
              <Field
                type="radio"
                name="isActive"
                value="1"
                checked={values.isActive == 1}
              />
              {"  "}
              Active
            </Form.Label>

            <Form.Label className="mx-1">
              <Field
                type="radio"
                name="isActive"
                value="0"
                checked={values.isActive == 0}
              />
              {"  "}
              Inactive
            </Form.Label>
            {errors?.isActive && (
              <Alert className="my-3" variant="danger">
                <div className="alert-icon d-flex align-items-center">
                  <AlertCircle />
                </div>
                <div className="alert-message">{errors.isActive}</div>
              </Alert>
            )}
          </Form.Group>
          <Card.Title tag="h5" className="text-center">
            Update FBA Storage Restrictions:
          </Card.Title>
          <Form.Group className="mb-3">
            <Checkbox
              id="stnd"
              label={<span htmlFor="stnd">Enable Standard</span>}
              name="shipmentCategoryIds"
              value="1"
            />
            <Checkbox
              id="over"
              label={<span htmlFor="over">Enable Oversize</span>}
              name="shipmentCategoryIds"
              value="2"
            />
            <Checkbox
              id="appa"
              label={<span htmlFor="appa">Enable Apparel</span>}
              name="shipmentCategoryIds"
              value="3"
            />
            <Checkbox
              id="shoe"
              label={<span htmlFor="shoe">Enable Shoes</span>}
              name="shipmentCategoryIds"
              value="4"
            />
            <Checkbox
              id="heav"
              label={<span htmlFor="heav">Enable Heavy</span>}
              name="shipmentCategoryIds"
              value="5"
            />
            <Checkbox
              id="jewl"
              label={<span htmlFor="jewl">Enable Jewelry</span>}
              name="shipmentCategoryIds"
              value="6"
            />
            <Checkbox
              id="hazm"
              label={<span htmlFor="hazm">Enable Hazmat</span>}
              name="shipmentCategoryIds"
              value="7"
            />
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button
              type="submit"
              variant="success"
              disabled={
                isSubmitting ||
                (ignoreOrderCompare(
                  initialValues.shipmentCategoryIds,
                  currentValues.shipmentCategoryIds
                ) &&
                  initialValues.isActive == values.isActive)
              }
            >
              Save Changes
            </Button>
            <Button
              variant="danger"
              onClick={handleReset}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FBAStorageRestrictionsForm;
