import { useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import toast from "react-hot-toast";

import Loader from "../../../../components/Loader";
import UIButton from "../../../../components/UIButton";
import UICard from "../../../../components/UICard";
import UIInput from "../../../../components/UIInput";

import { getItemToInspect } from "../../../../services/inspect/inspect.service";

import { InspectContext } from "../../Inspect";

export default function LPNForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [lpnValue, setLpnValue] = useState("");

  const { setCurrentItem, error, setError } = useContext(InspectContext);

  const header = <h4 className="text-center">{"Inspect Item"}</h4>;

  const getShipmentItem = async () => {
    if (!lpnValue) {
      toast.error("Please enter an LPN");
      return;
    }
    if (lpnValue.toUpperCase() === "W") {
      toast.error("Please re-scan LPN or enter it manually");
      setLpnValue("");
      return;
    }

    try {
      setIsLoading(true);
      const itemToInspect = await getItemToInspect({ lpn: lpnValue });

      setIsLoading(false);

      if (Object.keys(itemToInspect.processingData).length === 0) {
        toast.error(`No item found for LPN: ${lpnValue}`);
        setLpnValue("");
        return;
      }

      if (
        itemToInspect?.itemData?.price &&
        !itemToInspect?.itemData?.valuableSale
      ) {
        toast.error(
          `This item has a price of ${itemToInspect.itemData.price} and should be liquidated.`,
          { duration: 10000 }
        );
      }

      setCurrentItem(itemToInspect);
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.message);
      console.log(error);
    }
  };

  const body = isLoading ? (
    <Loader />
  ) : (
    <>
      {error && (
        <Alert className="p-3 justify-content-center" variant="danger">
          <span className="text-center">{error}</span>
        </Alert>
      )}
      <UIInput
        required
        autoFocus
        value={lpnValue}
        onChange={(e) => {
          e.preventDefault();
          setLpnValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            getShipmentItem();
          }
        }}
        type="text"
        placeholder="Enter an LPN"
        size="lg"
        className="my-3 w-75 m-auto text-center"
      />

      <UIButton
        variant="primary"
        text="Submit"
        onClick={() => getShipmentItem()}
      />
    </>
  );
  return (
    <UICard className="text-center w-75 m-auto" header={header} body={body} />
  );
}
