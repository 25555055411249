import { Col, Form, Row } from "react-bootstrap";

import VendorSelect from "./VendorSelect";

export default function ASINRuleFormBody({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  vendors,
  setSelectedVendor,
}) {
  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>ASIN</Form.Label>
        <Form.Control
          type="text"
          name="asin"
          placeholder="Enter ASIN..."
          value={values.asin}
          isInvalid={Boolean(touched.asin && errors.asin)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.asin && (
          <Form.Control.Feedback type="invalid">
            {errors.asin}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Vendor Restricted To</Form.Label>
        <VendorSelect
          vendors={vendors}
          vendorId={values["vendorRestrictedTo"]}
          setSelectedVendor={setSelectedVendor}
        />
      </Form.Group>
      <Row>
        <Form.Label>Listing Restrictions</Form.Label>
        <Col>
          <Form.Group className="mb-3">
            <Form.Check
              id="raal"
              name="restrictAmazonAllListings"
              label={<span htmlFor="raal">Restrict All Amazon Listings</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictAmazonAllListings}
              checked={values.restrictAmazonAllListings}
            />
            <Form.Check
              id="ranl"
              name="restrictAmazonNewListing"
              label={<span htmlFor="ranl">Restrict New Amazon Listings</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictAmazonNewListing}
              checked={values.restrictAmazonNewListing}
            />
            <Form.Check
              id="renl"
              name="restrictEbayNewListing"
              label={<span htmlFor="renl">Restrict New eBay Listings</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictEbayNewListing}
              checked={values.restrictEbayNewListing}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Check
              id="rp"
              name="restrictProcessing"
              label={<span htmlFor="rp">Restrict Processing</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictProcessing}
              checked={values.restrictProcessing}
            />
            <Form.Check
              id="rl"
              name="restrictLiquidation"
              label={<span htmlFor="rl">Restrict Liquidation</span>}
              type="checkbox"
              onChange={handleChange}
              value={values.restrictLiquidation}
              checked={values.restrictLiquidation}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          name="message"
          placeholder="Enter message..."
          value={values.message}
          isInvalid={Boolean(touched.message && errors.message)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {!!touched.message && (
          <Form.Control.Feedback type="invalid">
            {errors.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  );
}
