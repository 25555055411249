import React from "react";
import UIDefaultPage from "../../components/UIDefaultPage";
import AcceptTrackingNumberForm from "./components/accept/AcceptTrackingNumberForm";

const Accept = () => {
  return (
    <>
      <UIDefaultPage title="Accept" children={<AcceptTrackingNumberForm />} />
      <div className="text-center mt-5">
        <a href="/accept-fullpage">Show Full Screen</a>
      </div>
    </>
  );
};

export default Accept;
