import React, { useState } from "react";

import { Button } from "react-bootstrap";

import toast from "react-hot-toast";

import ModalGeneric from "../../../../components/ModalGeneric";
import shipmentService from "../../../../services/shipmentService";
import TrailerCreateForm from "./TrailerCreateForm";

const TrailerCreateModal = ({ getTrailers, setSelectedTrailer }) => {
  const [showTrailerAlert, setShowTrailerAlert] = useState(false);
  const [showDestinationAlert, setShowDestinationAlert] = useState(false);

  const [trailerType, setTrailerType] = useState();
  const [destinationCenterIds, setDestinationCenterIds] = useState([""]);

  const handleCreateTrailerSubmit = async () => {
    setShowTrailerAlert(false);
    setShowDestinationAlert(false);
    // check trailer type and destination are set:
    if (!trailerType) {
      setShowTrailerAlert(true);
      return;
    }
    const filteredDestinations = destinationCenterIds.filter(
      (centerId) => centerId !== ""
    );

    if (!filteredDestinations.length && trailerType !== "2") {
      setShowDestinationAlert(true);
      return;
    }
    const response = await shipmentService
      .createShipmentTrailer(trailerType, filteredDestinations)
      .catch((error) => {
        console.error(error);
        // setError(error);
        // setIsLoading(false);
        toast.error("Error creating new trailer.");
      });
    console.log(response);
    if (response && response.newShipmentTrailer) {
      toast.success("New trailer created.");
      getTrailers();
      setSelectedTrailer(response.newShipmentTrailer);
      //select new trailer?
    } else {
      toast.error("Error creating new trailer.");
    }
  };

  return (
    <ModalGeneric
      trigger={
        <Button className="mx-2" title={"Create a new trailer."}>
          Create Trailer
        </Button>
      }
      body={
        <TrailerCreateForm
          trailerType={trailerType}
          setTrailerType={setTrailerType}
          destinationCenterIds={destinationCenterIds}
          setDestinationCenterIds={setDestinationCenterIds}
          showTrailerAlert={showTrailerAlert}
          showDestinationAlert={showDestinationAlert}
        />
      }
      title="Create Shipment Trailer"
      actionButtonText="Create"
      onCloseCallback={() => {
        setShowTrailerAlert(false);
        setShowDestinationAlert(false);
      }}
      actionButtonCallback={handleCreateTrailerSubmit}
      closeAfterAction={false}
    ></ModalGeneric>
  );
};
export default TrailerCreateModal;
