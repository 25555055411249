import { X, Check } from "react-feather";

import { ButtonGroup, Button } from "react-bootstrap";

const tableColumns = [
  {
    Header: "Shipment ID",
    accessor: "amazonShipmentId",
  },
  {
    Header: "Destination Center",
    accessor: "shipTo",
  },
  {
    Header: "Total Units",
    accessor: "totalUnits",
  },
];

export default tableColumns;
