import React, { useState } from "react";

import * as Yup from "yup";

import Form from "../../../../components/UIForm";

import UICard from "../../../../components/UICard";
import UIAlert from "../../../../components/UIAlert";

import useBackground from "../../../../hooks/useBackground";

import { useAsyncFn } from "../../../../hooks/useAsync";
import { callAccept } from "../../../../services/accept/accept.service";

const AcceptTrackingNumberForm = () => {
  const [alert, setAlert] = useState({});

  const { setBackground } = useBackground();

  const showAlertFeedback = ({
    variant = "success",
    heading,
    message,
    alertFlags,
  }) => {
    setBackground(variant);
    setAlert({
      show: true,
      variant,
      heading,
      message,
      alertFlags,
    });
    setTimeout(() => {
      setBackground();
      setAlert({});
    }, 3000);
  };

  const changeBackground = () => {
    setBackground("warning");
    setTimeout(() => {
      setBackground();
    }, 3000);
  };

  const showAlert = (type, options) => {
    const { trackingNumber, flags, error } = options;

    switch (type) {
      case "success":
        showAlertFeedback({
          heading: "Shipment Accepted!",
          message: `Shipment with tracking number: ${trackingNumber} has been accepted.`,
        });
        break;
      case "warning":
        flags.length > 0
          ? showAlertFeedback({
              variant: "warning",
              heading: "Shipment Accepted with Tracking Number Flags!",
              message: `Shipment with tracking number: ${trackingNumber} has been accepted with flags.`,
              alertFlags: flags,
            })
          : changeBackground();
        break;
      case "danger":
        showAlertFeedback({
          variant: "danger",
          heading: "Error!",
          message: error,
        });
        break;
      default:
        break;
    }
  };

  //try to get error from useAsyncFn
  const {
    loading,
    // error,
    execute: submit,
    // value: acceptResponse,
  } = useAsyncFn(callAccept);

  const submitTrackingNumberForm = async (values, formProps) => {
    let trackingNumber = values.trackingNumber;

    if (trackingNumber.includes(")")) {
      trackingNumber = trackingNumber.substring(11).replace(")", "");
    }
    const { resetForm } = formProps;

    if (trackingNumber === "NoRead") {
      showAlertFeedback({
        variant: "danger",
        heading: "Scan Error!",
        message: "Scan failed to read barcode. Please try again.",
      });
      resetForm();
      return;
    }

    try {
      const result = await submit({ trackingNumber });
      const { flags } = result;
      showAlert(
        result.flags.length > 0 || !result.vendorId ? "warning" : "success",
        {
          trackingNumber,
          flags,
        }
      );
    } catch (error) {
      showAlert("danger", { error });
      return;
    }
  };

  //this is an array that the form component uses to build the input fields
  const generateAcceptFormFields = (errors) => {
    return [
      {
        width: "75",
        name: "trackingNumber",
        placeholder: "Enter a Tracking Number",
        size: "lg",
        fieldClassName: "",
        errorAlertClassName: "",
        autoFocus: true,
        errorMessage: errors && errors["trackingNumber"],
      },
    ];
  };

  const header = <h4>Accept</h4>;

  const body = loading ? (
    <div>Loading...</div>
  ) : (
    <>
      {alert.show && (
        <UIAlert
          alertShow={alert.show}
          alertVariant={alert.variant}
          alertHeading={alert.heading}
          alertMessage={alert.message}
          alertFlags={alert.alertFlags}
          onClose={() => {
            setAlert({
              show: false,
              variant: "",
              heading: "",
              message: "",
            });
          }}
        />
      )}
      <Form
        //this might be able to be condensed into one config array, and spread
        //into the form component
        generateFormFields={generateAcceptFormFields}
        initialValues={{
          trackingNumber: "",
        }}
        validationSchema={Yup.object({
          trackingNumber: Yup.string().required("Tracking number is required"),
        })}
        onSubmit={submitTrackingNumberForm}
      />
    </>
  );

  return (
    <UICard header={header} body={body} className="text-center w-75 m-auto" />
  );
};

export default AcceptTrackingNumberForm;
