import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import toast from "react-hot-toast";

import { Col, Container, Row, Spinner } from "react-bootstrap";
import RestrictionsTable from "./components/restrictions/RestrictionsTable";

import restrictionsService from "../../services/restrictionsService";

const RestrictionsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [restrictions, setRestrictions] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const getResources = async () => {
      const response = await restrictionsService
        .getRestrictions()
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });

      setRestrictions(response);

      setIsLoading(false);
    };

    getResources();
  }, []);

  const deleteRestriction = async ({ asin, conditionId, sellingPartnerId }) => {
    const response = await restrictionsService
      .deleteRestriction({ asin, conditionId, sellingPartnerId })
      .catch((error) => {
        console.error(error);
      });

    // Refresh the page
    if (response.success) {
      toast.success("Restriction successfully deleted!");

      setTimeout(() => {
        window.location = "/management/restrictions";
      }, 3000);
    }

    console.log(response);
  };

  return (
    <>
      <Helmet title="Amazon Restrictions" />
      <h1 className="h3 mb-3">Amazon Restrictions</h1>
      {isLoading ? (
        <div className="d-flex flex-column align-items-center justify-content-center h-50">
          <span className="px-2 font-weight-bold"> Loading... </span>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <Container fluid className="p-0">
          <Row>
            <Col xl={8}>
              <RestrictionsTable
                restrictions={restrictions}
                deleteRestriction={deleteRestriction}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default RestrictionsPage;
