import { useState } from "react";
import { Alert, Button, Col, Image, Row, Spinner } from "react-bootstrap";
import { AlertCircle } from "react-feather";

import scanService from "../../../../services/scan.service";

const Scan = ({ setImages, disabled = false }) => {
  const [error, setError] = useState();
  const [scanning, setScanning] = useState(false);
  const [scanTimeout, setScanTimeout] = useState();
  const [preview, setPreview] = useState();

  const handleScan = async () => {
    if (scanTimeout) {
      clearTimeout(scanTimeout);
    }

    setScanning(true);

    const timeout = setTimeout(() => {
      setScanning(false);
    }, 15000);
    setScanTimeout(timeout);

    try {
      const imageSrc = await scanService.scan();
      console.log(imageSrc);

      if (imageSrc) {
        clearTimeout(scanTimeout);
        setPreview({
          imageId: new Date().toISOString(),
          imageSrc,
        });
      }

      setScanning(false);
    } catch (error) {
      console.log(error);
      setError(error);
      setScanning(false);
    }
  };

  const handleConfirm = () => {
    setImages((prevState) => {
      return prevState.concat(preview);
    });
    setPreview();
  };

  return (
    <>
      {error && (
        <Alert variant="danger" className="alert">
          <div className="alert-icon">
            <AlertCircle
              className="position-relative top-50 start-50 translate-middle"
              size={20}
            />
          </div>
          <div className="alert-message">{error.message}</div>
        </Alert>
      )}
      <div className="text-center">
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            size="lg"
            onClick={handleScan}
            disabled={scanning || disabled}
          >
            {scanning && (
              <Spinner
                className="me-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {scanning ? "Scanning..." : "Scan"}
          </Button>
        </div>
      </div>
      {preview && (
        <div className="border-bottom mt-3">
          <h4>Preview</h4>
          <Image src={preview.imageSrc} fluid thumbnail />
          <Row className="mt-3">
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="success"
                  onClick={handleConfirm}
                >
                  Keep
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <Button
                  className="flex-fill"
                  variant="secondary"
                  onClick={() => setPreview()}
                >
                  Discard
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Scan;
