const flagTypes = [
  //TRACKING NUMBER
  {
    flagType: "trackingNumber",
    title: "Tracking Flags",
    tableColumns: [
      {
        Header: "Tracking Number",
        accessor: "trackingNumber",
      },
      {
        Header: "Message",
        accessor: "message",
      },
    ],
    initialFormValues: [
      {
        name: "flagID",
        default: null,
      },
      {
        label: "Tracking Number",
        name: "trackingNumber",
        default: "",
      },
      {
        label: "Message",
        name: "message",
        default: "",
      },
    ],
  },
  //ORDERID
  {
    flagType: "orderId",
    title: "Order ID Flags",
    tableColumns: [
      {
        Header: "Order ID",
        accessor: "orderId",
      },
      {
        Header: "Message",
        accessor: "message",
      },
    ],
    initialFormValues: [
      {
        name: "flagID",
        default: null,
      },
      {
        label: "Order ID",
        name: "orderId",
        default: "",
      },
      {
        label: "Message",
        name: "message",
        default: "",
      },
    ],
  },
  //VENDOR
  {
    flagType: "vendor",
    title: "Vendor Flags",
    tableColumns: [
      {
        Header: "Vendor",
        accessor: "vendorName",
      },
      {
        Header: "Message",
        accessor: "message",
      },
    ],
    initialFormValues: [
      {
        name: "flagID",
        default: null,
      },
      {
        name: "vendorId",
        default: null,
      },
      {
        label: "Message",
        name: "message",
        default: "",
      },
    ],
  },
  //ASIN
  {
    flagType: "asin",
    title: "ASIN Flags",
    tableColumns: [
      {
        Header: "ASIN",
        accessor: "flagASIN",
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Vendor",
        accessor: "vendorName",
      },
    ],
    initialFormValues: [
      {
        name: "flagID",
        default: null,
      },
      {
        label: "ASIN",
        name: "flagASIN",
        default: "",
      },
      {
        label: "Message",
        name: "message",
        default: "",
      },
      {
        name: "vendorId",
        default: null,
      },
    ],
  },
];

export default flagTypes;
