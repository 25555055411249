import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import { Container, Card, Button, Table } from "react-bootstrap";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const RecentActivity = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [recentActivity, setRecentActivity] = useState([]);

  useEffect(() => {
    const getRecentActivity = async () => {
      const recentActivity = (
        await axios.get(`${HOST_URL}/recentActivity/${user.userID}`)
      ).data;
      setRecentActivity(recentActivity);
    };
    getRecentActivity();
  }, [user.userID]);

  return (
    <React.Fragment>
      <Helmet title="Recent Activity" />
      <Container fluid className="p-0">
        <Card>
          <Card.Header className="text-center">
            <h1>Recent Activity</h1>
          </Card.Header>
          <Card.Body className="mb-5 text-center">
            <h3>{user.name}</h3>
            {recentActivity.length > 0 ? (
              <Table bordered hover className="w-50 m-auto">
                <tbody>
                  {recentActivity.map((event, index) => (
                    <tr key={index}>
                      <td>
                        <strong>{new Date(event.date).toLocaleString()}</strong>
                      </td>
                      {event.type === "shipment" ? (
                        <td colSpan="2">
                          {event.ReceivedShipmentID ? (
                            <Button
                              onClick={() => {
                                window.open(
                                  `/receiving/${event.ReceivedShipmentID}`
                                );
                              }}
                            >
                              Go to Shipment
                            </Button>
                          ) : (
                            "Not Received Yet"
                          )}
                        </td>
                      ) : event.type === "inspect" ? (
                        <td>
                          {event.InspectedItemID ? (
                            <Button
                              onClick={() => {
                                window.open(
                                  `/inspecting/${event.InspectedItemID}`
                                );
                              }}
                            >
                              Go to Inspect
                            </Button>
                          ) : (
                            <p>Not Inspected Yet</p>
                          )}
                        </td>
                      ) : (
                        <td>
                          {event.ListingID ? (
                            <Button
                              onClick={() => {
                                window.open(`/listing/${event.ListingID}`);
                              }}
                            >
                              Go to Listing
                            </Button>
                          ) : (
                            <p>Not Listed Yet</p>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              "No recent activity"
            )}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default RecentActivity;
