import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import UIButton from "../../../../components/UIButton";
import UICard from "../../../../components/UICard";
import UIInput from "../../../../components/UIInput";
const HOST_URL = process.env.REACT_APP_HOST_URL;
const StartOverButton = () => (
  <UIButton
    variant={"danger"}
    text="Start Over"
    className={"mx-3"}
    onClick={() => (window.location = "/inventoryPutaway")}
  />
);
const RackPutawayForm = () => {
  //const userId = JSON.parse(localStorage.getItem("user")).userID;

  const [rackIdValue, setRackIdValue] = useState("");
  const [location, setLocation] = useState("");
  const [locationsList, setLocationsList] = useState();

  useEffect(() => {
    //get list of locations
    const getLocations = async () => {
      try {
        const response = (
          await axios.get(`${HOST_URL}/channelSales/getWHLocations`)
        ).data;
        if (response.locations) {
          setLocationsList(response.locations);
        } else {
          toast.error();
        }
      } catch (error) {
        console.log(error.response.data);
      }
    };
    getLocations();
  }, []);

  const checkLocation = async () => {
    if (!rackIdValue) {
      toast.error("Please enter a Rack ID");
      return false;
    }
    if (!location) {
      toast.error("Please enter a location");
      return false;
    }

    // Check if the value is "W". ¯\_(ツ)_/¯
    if (rackIdValue.toUpperCase() === "W") {
      toast.error("Please re-scan Rack ID or enter it manually.");
      setRackIdValue("");
      return false;
    }

    if (location.toUpperCase() === "W") {
      toast.error("Please re-scan Location or enter it manually.");
      setLocation("");
      return false;
    }

    const foundLocation = locationsList.find(
      (locationOption) => locationOption.BinLocation === location
    );

    if (!foundLocation) {
      // if location is not in list
      toast.error("Invalid Location");
      setLocation("");

      return false;
    } else {
      console.log(foundLocation);
      return foundLocation.RowAutoID;
    }
  };

  const submitPutAway = async () => {
    const foundLocationId = await checkLocation();
    if (!foundLocationId) {
      return;
    }
    try {
      const body = {
        shipmentRackId: rackIdValue,
        locationId: foundLocationId,
        //userId: userId,
      };
      const response = (
        await axios.post(`${HOST_URL}/channelSales/insertInventoryItems`, body)
      ).data;
      if (response.success) {
        toast.success("Putaway successful");
        console.log(response.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error(response.message);
      }

      console.log(response);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const header = (
    <h4>
      <div>Transfer Rack Items to Warehouse Location</div>
    </h4>
  );
  const body = (
    <>
      <h4>Rack ID:</h4>
      <UIInput
        required
        autoFocus
        value={rackIdValue}
        onChange={(e) => {
          setRackIdValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            submitPutAway(e);
          }
        }}
        type="text"
        placeholder="Enter a Rack ID"
        size="lg"
        className="my-3 w-75 m-auto text-center"
      />
      <h4>Location:</h4>
      <UIInput
        required
        value={location}
        onChange={(e) => {
          setLocation(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            submitPutAway(e);
          }
        }}
        type="text"
        placeholder="Enter a Location"
        size="lg"
        className="my-3 w-75 m-auto text-center"
      />
      <UIButton
        variant="primary"
        text={"Submit"}
        onClick={(e) => submitPutAway(e)}
      />
      <StartOverButton />
    </>
  );
  return (
    <UICard header={header} body={body} className="text-center w-75 m-auto" />
  );
};

export default RackPutawayForm;
