import React from "react";

import { Alert, Col, Row } from "react-bootstrap";

import imageNotFound from "../../../../assets/img/imageNotFound.png";

const ItemLookupResultExtra = ({ rowData }) => {
  console.log(rowData);

  return (
    <Row>
      <Col md={3} className="text-center">
        <a
          href={`https://www.amazon.com/dp/${rowData.receivedAsin}?th=1&psc=1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="img-thumbnail"
            style={{ maxHeight: "120px" }}
            src={
              rowData.imageLink
                ? rowData.imageLink
                : `https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${rowData.receivedAsin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = imageNotFound;
            }}
            alt="Item"
          />
        </a>
      </Col>
      <Col md={3}>
        {rowData.receivedId && (
          <Alert className="p-2" variant="info">
            <div className="d-flex flex-column w-100">
              <h4>Received</h4>
              <span className="font-weight-bold">{rowData.vendorName}</span>
              <div className="d-flex justify-content-between text-muted">
                <div>{rowData.receivedBy}</div>
                <div>{new Date(rowData.receivedEndTime).toLocaleString()}</div>
              </div>
            </div>
          </Alert>
        )}
      </Col>
      <Col md={3}>
        {rowData.inspectedId && (
          <Alert className="p-2" variant="info">
            <div className="d-flex flex-column w-100">
              <h4>Inspected</h4>
              <span className="font-weight-bold">
                {rowData.inspectCondition}
              </span>
              <div className="d-flex justify-content-between text-muted">
                <div>{rowData.inspectedBy}</div>
                <div>{new Date(rowData.inspectedEndTime).toLocaleString()}</div>
              </div>
            </div>
          </Alert>
        )}
      </Col>
      <Col md={3}>
        {rowData.listedId && (
          <Alert className="p-2" variant="info">
            <div className="d-flex flex-column w-100">
              <h4>Listed</h4>
              <Row className="font-weight-bold">
                <Col>{rowData.listedAsin}</Col>
                <Col className="d-flex justify-content-end">
                  {rowData.listingAccount}
                </Col>
              </Row>
              <span className="font-weight-bold">{rowData.vendorName}</span>
              <div className="d-flex justify-content-between text-muted">
                <div>{rowData.listedBy}</div>
                <div>{new Date(rowData.listedEndTime).toLocaleString()}</div>
              </div>
            </div>
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default ItemLookupResultExtra;
