import React, { useEffect, useState } from "react";

import {
  Accordion,
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Fade,
  Form,
} from "react-bootstrap";
import { ArrowUp } from "react-feather";
import Select from "react-select";
import PalletForm from "./PalletForm";
import TrailerEditForm from "./TrailerEditForm";

const StickySidebar = ({
  selectedTrailer,
  getTrailers,
  setPallets,
  handleScroll,
  setSelectedTrailer,
}) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setEditing(false);
  }, [selectedTrailer]);

  const toggleScrollButton = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 0 ? setShowScrollButton(true) : setShowScrollButton(false);
  };

  window.addEventListener("scroll", toggleScrollButton);

  return (
    <Card className="sticky-top shadow border" style={{ top: 20 }}>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>
          <h3>
            Trailer {selectedTrailer ? selectedTrailer.shipmentTrailerId : ""}
          </h3>
        </Card.Title>
        <Fade in={showScrollButton}>
          <Button
            style={{ display: showScrollButton ? "inline" : "none" }}
            size="sm"
            variant="light"
            title="Back to Top"
            onClick={() => window.scrollTo(0, 0)}
          >
            <ArrowUp size={20} />
          </Button>
        </Fade>
      </Card.Header>

      {selectedTrailer && (
        <>
          {selectedTrailer.shipmentTrailerStatus === 1 && (
            <Card.Body>
              <PalletForm
                selectedTrailer={selectedTrailer}
                setPallets={setPallets}
                handleScroll={handleScroll}
              />
            </Card.Body>
          )}
          <Card.Body>
            {editing ? (
              <TrailerEditForm
                selectedTrailer={selectedTrailer}
                setEditing={setEditing}
                getTrailers={getTrailers}
                setSelectedTrailer={setSelectedTrailer}
              />
            ) : (
              <>
                <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                    <Row>
                      <Col className="fw-bold">Status:</Col>
                      <Col>{selectedTrailer.trailerStatus}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col className="fw-bold">Destination Center IDs:</Col>
                      <Col>
                        {selectedTrailer.destinationCenterIds
                          .split(",")
                          .sort()
                          .map((dest, index) => (
                            <div key={index}>{dest}</div>
                          ))}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col className="fw-bold">Type:</Col>
                      <Col>{selectedTrailer.trailerType}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
                <div className="d-flex justify-content-end ">
                  <Button variant="warning" onClick={() => setEditing(true)}>
                    Edit
                  </Button>
                </div>
              </>
            )}
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default StickySidebar;
