import { faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Form, ListGroup } from "react-bootstrap";
import { AlertCircle, Printer } from "react-feather";
import toast from "react-hot-toast";

import shipmentService from "../../../../services/shipmentService";
import printService from "../../../../services/printService";
import ModalGeneric from "../../../../components/ModalGeneric";

const Bay = ({ bay, setUpdatedBays, submitting }) => {
  // The values for the form controls of the bay.
  const [shipmentRackId, setShipmentRackId] = useState("");

  useEffect(() => {
    if (bay.shipmentRackId) {
      setShipmentRackId(bay.shipmentRackId);
    }
  }, []);

  useEffect(() => {
    updateBay();
  }, [shipmentRackId]);

  // Updates the updatedBays state for submission of changes.
  const updateBay = () => {
    setUpdatedBays((prevState) => {
      return prevState.map((stateBay) => {
        if (stateBay.consolidationBayId === bay.consolidationBayId) {
          return {
            ...stateBay,
            shipmentRackId: shipmentRackId ? Number(shipmentRackId) : null,
          };
        }
        return stateBay;
      });
    });
  };

  return (
    <ListGroup.Item variant={!bay.shipmentRackId && "danger"}>
      <div className="d-flex justify-content-between align-items-start">
        <h3>Bay {bay.consolidationBayId}</h3>
        <Badge bg="info" className="me-1 mb-1">
          {bay.consolidationBayName}
        </Badge>
      </div>

      <Form.Group>
        <Form.Label className="mb-0">Rack ID</Form.Label>
        <Form.Control
          className={!bay.shipmentRackId && "border border-2 border-danger"}
          type="text"
          min={0}
          size="sm"
          placeholder="Scan or Enter Rack ID..."
          value={shipmentRackId}
          onChange={(e) => {
            setShipmentRackId(e.target.value);
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          disabled={submitting}
        />
      </Form.Group>
    </ListGroup.Item>
  );
};

const Bays = ({ bays, setBays, setDesignatedBay }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userID;

  const [updatedBays, setUpdatedBays] = useState(bays);
  const [edit, setEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [updateErrors, setUpdateErrors] = useState([]);
  const [showUpdateErrors, setShowUpdateErrors] = useState(false); // To make alert dismissible
  const [rackIdToPrint, setRackIdToPrint] = useState("");

  useEffect(() => {
    setUpdatedBays(bays);
    setDesignatedBay();
  }, [bays]);

  const handleSubmit = async () => {
    setSubmitting(true);

    const changedBays = updatedBays.filter((updateBay) => {
      const foundBay = bays.find(
        (bay) => bay.consolidationBayId === updateBay.consolidationBayId
      );

      if (foundBay.shipmentRackId !== updateBay.shipmentRackId) {
        return updateBay;
      }
      return null;
    });

    if (changedBays.length === 0) {
      toast.error(
        'To update consolidation bays, scan or enter Rack IDs, then click "Save".',
        {
          duration: 5000,
        }
      );
      setSubmitting(false);
      return;
    }

    const result = await shipmentService.updateConsolidationBays({
      changedBays,
      userId,
    });

    let updatedRackList = [];
    if (result.success) {
      if (result.result.updateErrors.length > 0) {
        toast.error("Some bays have failed to update!", {
          duration: 5000,
        });

        setShowUpdateErrors(true);
        // get the racks that were updated successfully
        // and structure the data properly for the printing service:
        updatedRackList = changedBays.reduce((acc, curr) => {
          const foundMatchingUpdateError = result.result.updateErrors.find(
            (errorBay) => errorBay.shipmentRackId === curr.shipmentRackId
          );
          // if no error, so rack updated properly, add to list of rack labels to print:
          if (!foundMatchingUpdateError && curr.shipmentRackId !== null)
            acc.push({
              shipmentRackId: curr.shipmentRackId,
              bayName: curr.consolidationBayName,
            });

          return acc;
        }, []);
        //  print rack labels for updated bays:
        if (updatedRackList.length > 0) {
          printService.printRackLabels(updatedRackList);
        }
      } else {
        toast.success("All bays have been successfully updated!", {
          duration: 5000,
        });

        // get the racks that were updated
        // and structure the data properly for the printing service:
        updatedRackList = changedBays.reduce((acc, curr) => {
          if (curr.shipmentRackId !== null) {
            acc.push({
              shipmentRackId: curr.shipmentRackId,
              bayName: curr.consolidationBayName,
            });
          }
          return acc;
        }, []);
        //  print rack labels for updated bays:
        printService.printRackLabels(updatedRackList);
      }

      setBays(result.result.updatedBays);
      setUpdateErrors(result.result.updateErrors);
      setEdit(false);
      setSubmitting(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setSubmitting(false);
    }
  };

  return (
    <Card>
      <Card.Header className="border-bottom">
        <div className="mb-3 d-flex justify-content-start">
          <FontAwesomeIcon icon={faWarehouse} size="3x" className="me-3" />
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title>Consolidation Bays</Card.Title>
              <Card.Subtitle>Assign racks to consolidation bays.</Card.Subtitle>
            </div>
            <ModalGeneric
              trigger={
                <Button
                  className="btn-info"
                  title="Click to print a rack label"
                >
                  <Printer className="cursor-pointer " />
                </Button>
              }
              title="Print Rack Label"
              body={
                <>
                  <Form.Label>Enter Rack ID:</Form.Label>
                  <Form.Control
                    required
                    value={rackIdToPrint}
                    size="lg"
                    onChange={(e) => setRackIdToPrint(e.target.value)}
                    autoFocus
                  />
                </>
              }
              actionButtonText={"Print"}
              actionButtonCallback={() => {
                // find the bay with this shipmentRackId:
                const foundBay = updatedBays.find(
                  (bay) => bay.shipmentRackId == rackIdToPrint
                );
                if (foundBay) {
                  // found matching bay for this rack, print rack label:
                  printService.printRackLabels([
                    {
                      shipmentRackId: rackIdToPrint,
                      bayName: foundBay.consolidationBayName,
                    },
                  ]);
                } else {
                  toast.error("Invalid Rack ID.");
                }
              }}
              onCloseCallback={() => setRackIdToPrint("")}
            />
          </div>
        </div>
        <div className="d-flex mb-3">
          {edit ? (
            <>
              <Button
                className="flex-fill"
                variant={edit ? "success" : "warning"}
                onClick={() => handleSubmit()}
                disabled={submitting}
              >
                {submitting ? "Saving..." : "Save"}
              </Button>
              <Button
                className="flex-fill ms-3"
                variant="danger"
                onClick={() => {
                  setEdit(false);
                }}
                disabled={submitting}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              className="flex-fill"
              variant={edit ? "success" : "warning"}
              onClick={() => setEdit(true)}
              disabled={submitting}
            >
              Edit
            </Button>
          )}
        </div>
        {showUpdateErrors && updateErrors.length > 0 && (
          <Alert
            variant="danger"
            className="flex-fill alert-blink border-danger"
            onClose={() => setShowUpdateErrors(false)}
            dismissible
          >
            <div className="alert-icon">
              <AlertCircle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message font-weight-bold">
              <h4>Rack Assignment Errors</h4>
              <hr></hr>
              {updateErrors.map((error, index) => {
                return (
                  <div key={index}>
                    Rack ID: {error.shipmentRackId} - {error.message}
                  </div>
                );
              })}
            </div>
          </Alert>
        )}
      </Card.Header>
      <ListGroup variant="flush">
        {bays.map((bay, index) => {
          return edit ? (
            <Bay
              key={index}
              bay={bay}
              setUpdatedBays={setUpdatedBays}
              submitting={submitting}
            />
          ) : (
            <ListGroup.Item
              key={index}
              variant={!bay.shipmentRackId && "danger"}
            >
              <div className="d-flex justify-content-between align-items-start">
                <h3>Bay {bay.consolidationBayId}</h3>
                <Badge bg="info" className="me-1 mb-1">
                  {bay.consolidationBayName}
                </Badge>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <Form.Label className="mb-0">Rack ID</Form.Label>
                  <div className={bay.shipmentRackId && "text-muted"}>
                    {bay.shipmentRackId
                      ? bay.shipmentRackId
                      : "No Assigned Rack"}
                  </div>
                </div>
                {bay.areaName && (
                  <div className="text-muted text-end">
                    Area
                    <br />
                    {bay.areaName}
                  </div>
                )}
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Card>
  );
};

export default Bays;
