import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { Formik } from "formik";

import { Edit, Plus } from "react-feather";

import ModalGeneric from "../../../../components/ModalGeneric";
import VendorSelect from "../../../../components/VendorSelect";

const FlagForm = ({ flag, addFlag, editFlag, initialFormValues }) => {
  const [selectedVendor, setSelectedVendor] = useState("");

  const reducedInitialFormValues = initialFormValues.reduce((obj, item) => {
    obj[item.name] = item.default;
    return obj;
  }, {});

  const initialValues = Object.assign({}, reducedInitialFormValues, flag);

  let triggerButtonText, actButtonText, modalTitle, buttonVariant, icon;
  if (flag) {
    triggerButtonText = <Edit size={18} />;
    actButtonText = "Edit";
    buttonVariant = "warning";
    modalTitle = "Edit Flag";
    icon = undefined;
  } else {
    triggerButtonText = "Add Flag";
    actButtonText = "Add";
    buttonVariant = "primary";
    modalTitle = "Add Flag";
    icon = <Plus size={18} className="mx-1" />;
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
        setFieldValue,
      }) => {
        return (
          <ModalGeneric
            trigger={
              <Button variant={buttonVariant} className="mx-2">
                {triggerButtonText}
                {icon}
              </Button>
            }
            title={modalTitle}
            body={
              <Form>
                {initialFormValues.map((formValue) => {
                  if (
                    formValue.name !== "flagID" &&
                    formValue.name !== "vendorId"
                  ) {
                    return (
                      <Form.Group key={formValue.name} className="mb-3">
                        <Form.Label>{formValue.label}</Form.Label>
                        <Form.Control
                          type="text"
                          name={formValue.name}
                          value={values[formValue.name]}
                          placeholder={`Enter ${formValue.label}`}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    );
                  } else if (formValue.name === "vendorId") {
                    return (
                      <Form.Group key={formValue.name} className="mb-3">
                        <Form.Label>Vendor</Form.Label>
                        <VendorSelect
                          vendorId={values["vendorId"]}
                          setSelectedVendor={setSelectedVendor}
                        />
                      </Form.Group>
                    );
                  }
                })}
              </Form>
            }
            actionButtonText={actButtonText}
            actionButtonCallback={() => {
              if (selectedVendor) {
                values.vendorId = selectedVendor.vendorId;
                values.vendorName = selectedVendor.vendorName;
              }
              flag ? editFlag(values) : addFlag(values);
              resetForm();
            }}
            onCloseCallback={() => {
              resetForm();
            }}
          />
        );
      }}
    </Formik>
  );
};

export default FlagForm;
