import { useEffect, useState, useRef } from "react";
import { Alert, Badge, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Eye } from "react-feather";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

import Loader from "../../components/Loader";
import CartonForm from "./components/buildShipment/CartonForm";
import ShipmentRackItems from "./components/buildShipment/ShipmentRackItems";
import ShipmentRacks from "./components/buildShipment/ShipmentRacks";

import shipmentService from "../../services/shipmentService";
import utilsService from "../../services/utils.service";

const Navigation = ({
  selectedAccount,
  setSelectedAccount,
  listingAccounts,
  allShipmentRacks,
  submitting,
}) => {
  return (
    <ListGroup horizontal>
      {listingAccounts &&
        listingAccounts.map((account, index) => {
          return (
            <ListGroup.Item
              tag="a"
              key={index}
              onClick={() => {
                setSelectedAccount(account);
              }}
              action
              active={
                selectedAccount?.listingAccountId === account?.listingAccountId
              }
              disabled={allShipmentRacks.length === 0 || submitting}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span>{account.listingAccount}</span>
                <Badge bg="dark">
                  {allShipmentRacks?.filter(
                    (shipmentRack) =>
                      shipmentRack.listingAccountId === account.listingAccountId
                  ).length > 0
                    ? allShipmentRacks?.filter(
                        (shipmentRack) =>
                          shipmentRack.listingAccountId ===
                          account.listingAccountId
                      ).length
                    : 0}
                </Badge>
              </div>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};

const BuildShipmentPage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [listingAccounts, setListingAccounts] = useState([]);
  const [allShipmentCategories, setAllShipmentCategories] = useState([]);
  const [allShipmentRacks, setAllShipmentRacks] = useState([]);
  const [shipmentCartonTypes, setShipmentCartonTypes] = useState([]);

  const [accountShipmentCategories, setAccountShipmentCategories] = useState(
    []
  );
  const [accountShipmentRacks, setAccountShipmentRacks] = useState([]);

  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedShipmentRack, setSelectedShipmentRack] = useState();
  const [selectedShipmentRackItems, setSelectedShipmentRackItems] = useState();

  const [shipments, setShipments] = useState([]); // For shipmentRackItems component
  const [activeKey, setActiveKey] = useState(null); // For the accordion in shipmentRackItems

  useEffect(() => {
    getListingAccounts();
    getShipmentRacks();
    getShipmentCategories();
    getShipmentCartonTypes();
  }, []);

  // When an account is selected, filter the shipment racks and categories.
  useEffect(() => {
    const accountFilteredShipmentRacks = allShipmentRacks.filter(
      (shipmentRack) =>
        shipmentRack.listingAccountId === selectedAccount.listingAccountId
    );

    if (accountFilteredShipmentRacks.length > 0) {
      setAccountShipmentRacks(accountFilteredShipmentRacks);
    } else {
      setAccountShipmentRacks([]);
    }

    const reducedShipmentCategories = allShipmentCategories.reduce(
      (result, category) => {
        const foundCategory = accountFilteredShipmentRacks.find(
          (shipmentRack) =>
            shipmentRack.shipmentCategoryId === category.shipmentCategoryId
        );

        if (foundCategory) {
          result.push(category);
        }

        return result;
      },
      []
    );

    setAccountShipmentCategories(reducedShipmentCategories);
    setSelectedShipmentRack();
  }, [selectedAccount]);

  const getListingAccounts = async () => {
    setLoading(true);

    const result = await utilsService.getListingAccounts();

    if (result.success) {
      // Remove the liquidate account from the array of listing accounts.
      const filteredAccounts = result.listingAccounts.filter(
        (account) => account.listingAccountId !== 8
      );
      setListingAccounts(filteredAccounts);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const getShipmentRacks = async () => {
    setLoading(true);

    const result = await shipmentService.getShipmentRacksWithoutItems({
      shipmentRackStatusId: 4,
    });
    const result2 = await shipmentService.getShipmentRacksWithoutItems({
      shipmentRackStatusId: 3,
    });

    if (result.success) {
      const allRacks = result.shipmentRacks.concat(result2.shipmentRacks);
      setAllShipmentRacks(allRacks);
      // setAllShipmentRacks(result.shipmentRacks);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const getShipmentCategories = async () => {
    setLoading(true);

    const result = await shipmentService.getShipmentCategories();

    if (result.success) {
      setAllShipmentCategories(result.shipmentCategories);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const getShipmentCartonTypes = async () => {
    setLoading(true);

    const result = await shipmentService.getShipmentCartonTypes();

    if (result.success) {
      setShipmentCartonTypes(result.shipmentCartonTypes);
      setLoading(false);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
      setLoading(false);
    }
  };

  const getShipmentRackItemsForSelectedShipmentRack = async () => {
    const result = await shipmentService.getShipmentRackItems({
      shipmentRackId: selectedShipmentRack.shipmentRackId,
    });

    if (result.success) {
      setSelectedShipmentRackItems(result.shipmentRackItems);
    } else {
      toast.error("Something went wrong. Please try again.", {
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    if (selectedShipmentRack) {
      getShipmentRackItemsForSelectedShipmentRack();
    }
  }, [selectedShipmentRack]);

  return (
    <>
      <Helmet title="Build Shipment" />
      <h1 className="mb-3">Build Shipment</h1>
      <Container fluid className="p-0">
        {loading || !allShipmentRacks.length ? (
          <Loader />
        ) : (
          <>
            <Row className="mb-3">
              <Col xxl={8}>
                <Navigation
                  loading={loading}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  listingAccounts={listingAccounts}
                  allShipmentRacks={allShipmentRacks}
                  submitting={submitting}
                />
              </Col>
            </Row>
            {!selectedAccount ? (
              <Row>
                <Col xl={8}>
                  <Alert variant="primary">
                    <div className="alert-icon">
                      <Eye
                        className="position-relative top-50 start-50 translate-middle"
                        size={20}
                      />
                    </div>
                    <div className="alert-message">
                      Select an <strong>account</strong> to view shipment racks.
                    </div>
                  </Alert>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xl={8}>
                  <ShipmentRacks
                    selectedAccount={selectedAccount}
                    accountShipmentRacks={accountShipmentRacks}
                    accountShipmentCategories={accountShipmentCategories}
                    setSelectedShipmentRack={setSelectedShipmentRack}
                    submitting={submitting}
                  />
                  {selectedShipmentRack && selectedShipmentRackItems && (
                    <ShipmentRackItems
                      selectedShipmentRack={selectedShipmentRack}
                      setSelectedShipmentRack={setSelectedShipmentRack}
                      selectedShipmentRackItems={selectedShipmentRackItems}
                      shipments={shipments}
                      setShipments={setShipments}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                    />
                  )}
                </Col>
                {selectedShipmentRack && (
                  <Col xl={4}>
                    <CartonForm
                      submitting={submitting}
                      setSubmitting={setSubmitting}
                      allShipmentCategories={allShipmentCategories}
                      shipmentCartonTypes={shipmentCartonTypes}
                      selectedAccount={selectedAccount}
                      selectedShipmentRack={selectedShipmentRack}
                      selectedShipmentRackItems={selectedShipmentRackItems}
                      setSelectedShipmentRack={setSelectedShipmentRack}
                      setSelectedShipmentRackItems={
                        setSelectedShipmentRackItems
                      }
                      shipments={shipments}
                      setActiveKey={setActiveKey}
                    />
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default BuildShipmentPage;
