import { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import listingService from "../../../../services/listingService";

const ASINandConditionForm = ({
  asin,
  setAsin,
  selectedCondition,
  setSelectedCondition,
  getRestrictions,
}) => {
  const [conditionOptions, setConditionOptions] = useState();
  const [selectAllConditions, setSelectAllConditions] = useState(false);
  const [showAsinAlert, setShowAsinAlert] = useState(false);
  const [showConditionAlert, setShowConditionAlert] = useState(false);

  useEffect(() => {
    // get condition options from the BE:
    const getConditions = async () => {
      try {
        const conditions = await listingService.getConditions();
        setConditionOptions(conditions);
      } catch (error) {
        console.error(error);
      }
    };

    getConditions();
  }, []);

  const handleAsinChanged = (value) => {
    setAsin(value);
    setShowAsinAlert(false);
  };

  const handleSelectAllChanged = () => {
    setSelectAllConditions(!selectAllConditions);
    setSelectedCondition("");
    setShowConditionAlert(false);
  };

  const handleSelectCondition = (value) => {
    setSelectedCondition(value);
    setShowConditionAlert(false);
  };

  const handleSubmit = async () => {
    // check asin and condition are set:
    if (!asin) {
      setShowAsinAlert(true);
      return;
    }
    if (!selectedCondition && selectAllConditions === false) {
      setShowConditionAlert(true);
      return;
    }

    // get restrictions for asin and condition:
    await getRestrictions(asin, selectedCondition.inspectConditionId);
  };

  return (
    <>
      <Form.Label className="d-flex justify-content-center">
        Check for Amazon Listing Restrictions:
      </Form.Label>
      <Form.Label>ASIN:</Form.Label>
      <Form.Control
        className="mb-3"
        type="text"
        name="asin"
        placeholder="Enter an ASIN"
        value={asin}
        onChange={(e) => handleAsinChanged(e.target.value)}
        autoFocus="autofocus"
      />
      <div className="d-flex">
        <Form.Label className="pe-3">Condition:</Form.Label>
        <Form.Check
          type="checkbox"
          label="All Conditions"
          id="allConditionsCheckbox"
          name="Select All Conditions"
          onChange={handleSelectAllChanged}
          value={selectAllConditions}
          checked={selectAllConditions}
        />
      </div>
      <Select
        color="grey"
        className="react-select-container mb-3"
        classNamePrefix="react-select"
        placeholder="Select a Condition"
        onChange={handleSelectCondition}
        options={conditionOptions}
        isSearchable
        value={selectedCondition}
        isDisabled={selectAllConditions}
      />
      {showAsinAlert && (
        <Alert className="p-2 justify-content-center" variant="danger">
          Please enter an ASIN
        </Alert>
      )}
      {showConditionAlert && (
        <Alert className="py-2 justify-content-center" variant="danger">
          Please select a condition or check off 'All Conditions'
        </Alert>
      )}
      <div className="text-center mt-4 mb-2">
        <Button onClick={handleSubmit}>Get Amazon Listing Restrictions</Button>
      </div>
    </>
  );
};

export default ASINandConditionForm;
