import { Accordion, Card, Button } from "react-bootstrap";

const ListingRestrictionsByStore = ({
  asin,
  selectedCondition,
  listingRestrictionsByStore,
}) => {
  const storesWithRestrictions = [];
  const storesWithNoRestrictions = [];

  // separate the stores by restrictions/no restrictions:
  listingRestrictionsByStore.forEach((listingStore) => {
    if (listingStore.restrictions.length > 0) {
      storesWithRestrictions.push(listingStore);
    } else {
      storesWithNoRestrictions.push(listingStore);
    }
  });

  return (
    <>
      <h3>ASIN: {asin} </h3>
      <h3>
        {selectedCondition
          ? "Condition: " + selectedCondition
          : "All Conditions"}
      </h3>
      {storesWithRestrictions.length > 0 && (
        <Accordion className="mt-3 mb-4">
          {storesWithRestrictions.map((store, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>
                  {store.listingAccount} -
                  <span className="mx-2 text-danger">
                    {selectedCondition
                      ? "Restricted"
                      : store.restrictions.length + " Restriction(s)"}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {store.restrictions.map((restriction, index) => {
                    return (
                      <Card
                        className="p-2 m-1"
                        key={index}
                        style={{ borderWidth: 2 }}
                      >
                        <p>{restriction.condition}</p>
                        <p>{restriction.reasons[0].message}</p>
                        {restriction.reasons[0].reasonCode ===
                          "APPROVAL_REQUIRED" && (
                          <Button
                            variant="primary"
                            title={"Click to get approval for this restriction"}
                            onClick={() => {
                              window.open(
                                `${restriction.reasons[0].links[0].resource}`,
                                "_blank"
                              );
                            }}
                          >
                            Get Approval
                          </Button>
                        )}
                      </Card>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
      {storesWithNoRestrictions.length > 0 && (
        <Accordion className="mt-3 mb-3">
          {storesWithNoRestrictions.map((store, index) => {
            return (
              <Accordion.Item key={index} className="p-3">
                {store.listingAccount} -
                <span className="mx-2 text-success">
                  {selectedCondition
                    ? "Not Restricted"
                    : store.restrictions.length + " Restrictions"}
                </span>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
    </>
  );
};

export default ListingRestrictionsByStore;
