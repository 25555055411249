import axios from "axios";
import { handleError, buildQueryString } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getMasterVendors = async () => {
  try {
    return (await axios.get(`${HOST_URL}/utils/masterVendor`)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const getVendors = async () => {
  try {
    return (await axios.get(`${HOST_URL}/utils/vendors`)).data;
  } catch (error) {
    return handleError(error);
  }
};

const getStores = async () => {
  try {
    return (await axios.get(`${HOST_URL}/utils/stores`)).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

/**
 * Retrieves listing accounts based on the provided query parameters.
 * @async
 * @param {Object} [queryParams={}] - The query parameters object.
 * @param {boolean} [queryParams.amazonOnly] - Specifies whether to retrieve only Amazon accounts.
 * @param {number} [queryParams.listingAccountId] - The ID of the listing account.
 * @returns {Promise<any>} A promise that resolves to the listing accounts data.
 */
const getListingAccounts = async (queryParams = {}) => {
  const { amazonOnly, listingAccountId } = queryParams;

  // Form the query string if parameters are passed.
  let queryString = "";
  if (amazonOnly || listingAccountId) {
    queryString = "?" + buildQueryString(queryParams);
  }

  try {
    return (await axios.get(`${HOST_URL}/utils/listingAccounts${queryString}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const getAsinImage = async (asin) => {
  try {
    return (await axios.get(`${HOST_URL}/utils/getASINImage?asin=${asin}`))
      .data;
  } catch (error) {
    return handleError(error);
  }
};

const services = {
  getMasterVendors,
  getStores,
  getListingAccounts,
  getVendors,
  getAsinImage,
};

export default services;
