import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import MiscSection from "./MiscSection";
import ProcessingSection from "./ProcessingSection";
import ShipmentSection from "./ShipmentSection";
import HomepageCard from "./HomepageCard";

import dashboardItems from "../../../components/sidebar/dashboardItems";

const homepageDashboard = ["Admin", "Warehouse"];

const Default = () => {
  return (
    <>
      <Helmet title="Returns Worldwide Dashboard" />
      <Container fluid className="p-0">
        <Row>
          <Col className="text-center mb-3">
            <h2>Quick Navigation Dashboard </h2>
          </Col>
        </Row>
        {/*<Row>
          <Col xs={4}>
            <ProcessingSection />
          </Col>
          <Col xs={4}>
            <ShipmentSection />
          </Col>
          <Col xs={4}>
            <MiscSection />
          </Col>
        </Row> */}

        {dashboardItems &&
          dashboardItems.map(
            (item, index) =>
              homepageDashboard.includes(item.title) && (
                <>
                  <h4 className="text-center">{item.title}</h4>
                  <Row className="justify-content-center">
                    {item.pages.map((section) => (
                      <HomepageCard section={section} />
                    ))}
                  </Row>
                </>
              )
          )}
      </Container>
    </>
  );
};

export default Default;
