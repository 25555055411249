import Select from "react-select";
import ListingForm from "./ListingForm";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";

const EditListingParameters = ({
  currentItem,
  setCurrentItem,
  listingRequirements,
  isEditing,
  setIsEditing,
  isLoading,
  setIsLoading,
  changes,
  setChanges,
  showBoxOptions,
  conditionOptions,
  setConditionOptions,
  vendorOptions,
  setVendorOptions,
  shipmentCategoryOptions,
  handleSubmitChanges,
  selectedShipmentCategoryOption,
  setSelectedShipmentCategoryOption,
}) => {
  const { restrictAmazonNewListing, restrictEbayNewListing, forceNewAsUsed } =
    listingRequirements;

  const itemTextStyle = { color: "#495057", fontSize: "0.95rem" };

  return (
    <Card>
      <Card.Body>
        {forceNewAsUsed && (
          <Alert
            className="p-3 text-center justify-content-center font-weight-bold"
            variant="warning"
          >{`This item can not be listed as "New Sealed" on ${
            restrictAmazonNewListing && !restrictEbayNewListing
              ? "Amazon"
              : restrictAmazonNewListing && restrictEbayNewListing
              ? "Amazon or Ebay"
              : "Ebay"
          }`}</Alert>
        )}
        {!isEditing ? (
          <>
            <Row>
              <Col lg={2}>
                <div className="mb-3">
                  <div>ASIN</div>
                  <Card.Text style={itemTextStyle}>
                    {currentItem?.processingData.asin
                      ? currentItem?.processingData.asin
                      : currentItem?.processingData.receivedAsin
                      ? currentItem?.processingData.receivedAsin
                      : "No ASIN..."}
                  </Card.Text>
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <div>Condition</div>
                  <Card.Text style={itemTextStyle}>
                    {changes?.inspectConditionLabel}
                  </Card.Text>
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <div>Vendor</div>
                  <Card.Text style={itemTextStyle}>
                    {changes?.vendorLabel}
                  </Card.Text>
                </div>
              </Col>
              <Col lg={2}>
                <div className="mb-3">
                  <div>Shipping Category</div>
                  <Card.Text style={itemTextStyle}>
                    {changes?.shipmentCategoryLabel}
                  </Card.Text>
                </div>
              </Col>
              <Col lg={2}>
                <div className="mb-3">
                  <div>Quantity</div>
                  <Card.Text style={itemTextStyle}>
                    {currentItem.processingData.quantity || 1}
                  </Card.Text>
                </div>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                variant="warning"
                onClick={() => {
                  setIsEditing(true);
                }}
                disabled={isLoading}
              >
                Edit
              </Button>
            </div>
          </>
        ) : (
          <ListingForm
            currentItem={currentItem}
            setCurrentItemItem={setCurrentItem}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            allowNewCondition={
              !(restrictAmazonNewListing && restrictEbayNewListing)
            }
            conditionOptions={conditionOptions}
            setConditionOptions={setConditionOptions}
            vendorOptions={vendorOptions}
            shipmentCategoryOptions={shipmentCategoryOptions}
            setSelectedShipmentCategoryOption={
              setSelectedShipmentCategoryOption
            }
            selectedShipmentCategoryOption={selectedShipmentCategoryOption}
            setVendorOptions={setVendorOptions}
            handleSubmitChanges={handleSubmitChanges}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            changes={changes}
            setChanges={setChanges}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default EditListingParameters;
