import axios from "axios";

const HOST_URL = process.env.REACT_APP_HOST_URL;

//this should only take one param, either fnsku or listingId
export async function getLabelData({ fnsku, listingId }) {
  let fnskuLabelData = {};
  let listingIdMatches = [];

  try {
    ({ fnskuLabelData, listingIdMatches } = (
      await axios.get(
        `${HOST_URL}/warehouse/getFnskuLabelData?${
          fnsku ? `fnsku=${fnsku}` : ""
        }${listingId ? `listingId=${listingId}` : ""}`
      )
    ).data);
  } catch (error) {
    console.log(error);
  }

  console.log({ fnskuLabelData, listingIdMatches });

  return { fnskuLabelData };
  //   return { fnskuLabelData, listingIdMatches };
}
